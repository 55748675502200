import { Link } from "react-router-dom";
import { Menu, Dropdown } from "antd";
import { FaClipboardList, FaPen } from "react-icons/fa";
import { BarsOutlined } from "@ant-design/icons";
import { deleteMeeting } from "../../apiParsing/meeting";
import moment from "moment";

export const menu = (
  <Menu>
    <Menu.Item>
      <Link to="/meetings">
        <div className="d-flex align-items-center">
          <FaPen className="text-warning" />
          <span className="ms-2">Edit</span>
        </div>
      </Link>
    </Menu.Item>

    <Menu.Item>
      <Link to="/meetings">
        <div className="d-flex align-items-center">
          <FaClipboardList className="text-danger" />
          <span className="ms-2">Delete</span>
        </div>
      </Link>
    </Menu.Item>
  </Menu>
);

export const meetingColumns = [
  {
    title: "ID",
    dataIndex: "meeting_id",
    defaultSortOrder: "descend",
    sorter: (a, b) => a.meeting_id - b.meeting_id,
  },
  {
    title: "Subject",
    dataIndex: "subject",
    defaultSortOrder: "descend",
    sorter: (a, b) => a.subject.localeCompare(b.subject),
  },
  {
    title: "Date",
    dataIndex: "startdate",
    sorter: (a, b) => moment(a.startdate).unix() - moment(b.startdate).unix(),
  },
  {
    title: "Action",
    dataIndex: "meeting_id",
    render: (meeting_id, index) => (
      <div key={index}>
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item>
                <Link to={`/edit-meetings/${meeting_id}`}>
                  <div className="d-flex align-items-center">
                    <FaPen className="text-warning" />
                    <span className="ms-2">Edit</span>
                  </div>
                </Link>
              </Menu.Item>

              <Menu.Item>
                <Link to="/meetings">
                  <div
                    className="d-flex align-items-center"
                    onClick={() => {
                      deleteMeeting(meeting_id);
                    }}
                  >
                    <FaClipboardList className="text-danger" />
                    <span className="ms-2">Delete</span>
                  </div>
                </Link>
              </Menu.Item>
            </Menu>
          }
          placement="bottom"
          className="action-button"
        >
          <BarsOutlined />
        </Dropdown>
      </div>
    ),
  },
];

export const meetingColumnsHome = [
  {
    title: "ID",
    dataIndex: "meeting_id",
    defaultSortOrder: "descend",
    sorter: (a, b) => a.meeting_id - b.meeting_id,
  },
  {
    title: "主題",
    dataIndex: "subject",
    defaultSortOrder: "descend",
    sorter: (a, b) => a.subject.localeCompare(b.subject),
  },
  {
    title: "日付",
    dataIndex: "startdate",
    sorter: (a, b) => moment(a.startdate).unix() - moment(b.startdate).unix(),
  },
  // {
  //   title: "アクション",
  //   dataIndex: "meeting_id",
  //   render: (meeting_id, index) => (
  //     <div key={index}>
  //       <Dropdown
  //         overlay={
  //           <Menu>
  //             <Menu.Item>
  //               <Link to={`/edit-meetings/${meeting_id}`}>
  //                 <div className="d-flex align-items-center">
  //                   <FaPen className="text-warning" />
  //                   <span className="ms-2">Edit</span>
  //                 </div>
  //               </Link>
  //             </Menu.Item>

  //             <Menu.Item>
  //               <Link to="/meetings">
  //                 <div
  //                   className="d-flex align-items-center"
  //                   onClick={() => {
  //                     deleteMeeting(meeting_id);
  //                   }}
  //                 >
  //                   <FaClipboardList className="text-danger" />
  //                   <span className="ms-2">Delete</span>
  //                 </div>
  //               </Link>
  //             </Menu.Item>
  //           </Menu>
  //         }
  //         placement="bottom"
  //         className="action-button"
  //       >
  //         <BarsOutlined />
  //       </Dropdown>
  //     </div>
  //   ),
  // },
];
