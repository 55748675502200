import { sendRequest } from "../../services";
import { apipaths } from "../../services/apiPaths";

export const notesListAction = () => async (dispatch) => {
  const { success, data } = await sendRequest(apipaths.notesList);
  const { notes, companies, employees, projects, clients } = data;
  if (success) {
    dispatch({ type: "ADD_NOTES", payload: notes });
    dispatch({ type: "ADD_PROJECTS", payload: projects });
    dispatch({ type: "ADD_EMPLOYEES", payload: employees });
    dispatch({ type: "ADD_COMAPNIES", payload: companies });
    dispatch({ type: "ADD_CLIENTS", payload: clients });
  }
};
