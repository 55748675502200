import React, { useEffect, useState } from "react";

function UserSelect({
  formdata,
  setFormdata,
  data,
  inputClass = "form-select",
}) {
  const { label, placeholder, key, options, required = false } = data;
  return (
    <>
      <label>
        {label}
        {required && <span className="text-danger">*</span>}
      </label>
      <select
        defaultValue={formdata[key] ? formdata[key] : ""}
        className={inputClass}
        id={key}
        required={required}
        onChange={(e) => setFormdata({ ...formdata, [key]: e.target.value })}
      >
        <option value="">{placeholder}</option>
        {options?.map((opt, index) => {
          const { label, value } = opt;
          return (
            <option key={index} value={value}>
              {label}
            </option>
          );
        })}
      </select>
    </>
  );
}

export default UserSelect;
