import React, { useEffect, useState } from "react";
import Heading from "../Heading";
import TableCard from "../TableCard";
import CardContainer from "../Card/cardContainer";
import { teamColumns, teamColumnsHome } from "../../Data/Columns/teamsColumn";
import { addActionColumn } from "../../helpers/companyHelpers";
import {
  teamsFormLayout,
  teamsFormLayoutJap,
} from "../../Data/FormsLayout/teamsFormLayout";
import {
  companyFormLayoutHandlerDrop,
  formModalController,
  modalInitialValue,
} from "../../helpers/commonHelpers";
import ModalContainer from "../Modal";
import { useDispatch, useSelector } from "react-redux";
import { teamsListAction } from "../actions/teamListAction";
import { deleteHandler, updateHandler } from "../../apiParsing/data";
import { apipaths } from "../../services/apiPaths";
import { addTeamHandler } from "../../apiParsing/team";
import { employeesListAction } from "../actions/employeesListAction";
import useTranslation from "../customHooks/translations";

function Teams() {
  const [modal, setModal] = useState(modalInitialValue);
  const { team } = useSelector((state) => state.team);
  const { employees } = useSelector((state) => state.employee);

  const dispatch = useDispatch();
  const translation = useTranslation();

  useEffect(() => {
    dispatch(teamsListAction());
    dispatch(employeesListAction());
  }, []);

  useEffect(() => {
    if (employees) {
      employees.map((emp) => (emp.name = emp.first_name + " " + emp.last_name));
      companyFormLayoutHandlerDrop(
        employees,
        teamsFormLayout,
        "employee_id",
        "name"
      );
    }
  }, [employees]);

  useEffect(() => {
    if (employees) {
      employees.map((emp) => (emp.name = emp.first_name + " " + emp.last_name));
      companyFormLayoutHandlerDrop(
        employees,
        teamsFormLayoutJap,
        "employee_id",
        "name"
      );
    }
  }, [employees]);

  return (
    <>
      <ModalContainer
        isOpen={modal.open}
        title={modal.title}
        closeIcon={() => setModal(modalInitialValue)}
        content={modal.content}
        footer={false}
      />
      <div className="main-container">
        <Heading title={translation.teams} />
        <div className="content-wrapper px-5">
          <CardContainer hideTotal={true} />
        </div>

        <div className="px-5">
          <TableCard
            button={{
              title: translation.add_team,
              onClick: () =>
                formModalController(
                  setModal,
                  teamsFormLayout,
                  teamsFormLayoutJap,
                  `${translation.add_team}`,
                  `Add-team-form`,
                  {},
                  (data) =>
                    addTeamHandler(data, setModal, dispatch, teamsListAction)
                ),
            }}
            columns={addActionColumn(
              {},
              teamColumns,
              (data) =>
                formModalController(
                  setModal,
                  teamsFormLayout,
                  teamsFormLayoutJap,
                  `${translation.edit_team}`,
                  `edit-team-form`,
                  data,
                  (data) =>
                    updateHandler(
                      apipaths.addTeam,
                      data,
                      "team_id",
                      setModal,
                      dispatch,
                      teamsListAction
                    )
                ),
              (data) =>
                deleteHandler(
                  apipaths.deleteTeam,
                  data?.["team_id"],
                  setModal,
                  dispatch,
                  teamsListAction
                ),
              translation._language
            )}
            jaCol={addActionColumn(
              {},
              teamColumnsHome,
              (data) =>
                formModalController(
                  setModal,
                  teamsFormLayout,
                  teamsFormLayoutJap,
                  `${translation.edit_team}`,
                  `edit-team-form`,
                  data,
                  (data) =>
                    updateHandler(
                      apipaths.addTeam,
                      data,
                      "team_id",
                      setModal,
                      dispatch,
                      teamsListAction
                    )
                ),
              (data) =>
                deleteHandler(
                  apipaths.deleteTeam,
                  data?.["team_id"],
                  setModal,
                  dispatch,
                  teamsListAction
                ),
              translation._language
            )}
            data={team}
            title={translation.teams}
          />
        </div>
      </div>
    </>
  );
}

export default Teams;
