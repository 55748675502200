export const projectFormLayout = [
  {
    id: 1,
    label: "Name",
    type: "text",
    placeholder: "Name",
    key: "name",
    required: true,
  },
  {
    id: 2,
    label: "Start Date",
    type: "date",
    placeholder: "Start Date",
    key: "startdate",
    required: true,
  },
  {
    id: 3,
    label: "End Date",
    type: "date",
    key: "enddate",
    required: true,
  },
  {
    id: 4,
    label: "Project Cost",
    type: "text",
    placeholder: "Enter Project Cost",
    key: "project_cost",
    required: false,
  },
  {
    id: 5,
    label: "Company",
    type: "select",
    placeholder: "Select Company",
    key: "company_id",
    id: "company_id",
    options: [
      {
        label: "Company A",
        value: "company-a",
      },
      {
        label: "Company B",
        value: "company-b",
      },
    ],
    required: false,
  },
  {
    id: 6,
    label: "Client",
    type: "select",
    placeholder: "Select Client",
    key: "client_id",
    options: [
      {
        label: "Client A",
        value: "client-a",
      },
      {
        label: "Client B",
        value: "client-b",
      },
    ],
    required: false,
  },
  {
    id: 7,
    label: "Incharge Employee",
    type: "select",
    placeholder: "Select Incharge Employee",
    key: "employee_id",
    options: [
      {
        label: "Employee A",
        value: "emp-a",
      },
      {
        label: "Employee B",
        value: "emp-b",
      },
    ],
    required: false,
  },
  // {
  //   id: 8,
  //   label: "Company Project Profile",
  //   type: "select",
  //   placeholder: "Select Company Project Profile",
  //   key: "companyProjectProfile",
  //   options: [
  //     {
  //       label: "Company A",
  //       value: "company-a",
  //     },
  //     {
  //       label: "Company B",
  //       value: "company-b",
  //     },
  //   ],
  //   required: false,
  // },
  {
    id: 9,
    label: "Activity Status",
    type: "select",
    placeholder: "Select Activity Status",
    key: "activity_status",
    options: [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Super Active",
        value: "Super Active",
      },
      {
        label: "Less Active",
        value: "Less Active",
      },
      {
        label: "Normal",
        value: "Normal",
      },
      {
        label: "Paused",
        value: "Paused",
      },
      {
        label: "Suspended",
        value: "Suspended",
      },
      {
        label: "Completed",
        value: "Completed",
      },
      {
        label: "Scrapped",
        value: "Scrapped",
      },
      {
        label: "Prospective",
        value: "Prospective",
      },
    ],
    required: false,
  },
  {
    id: 10,
    label: "Stage",
    type: "select",
    placeholder: "Select Stage",
    key: "stage",
    options: [
      {
        label: "Introduction",
        value: "Introduction",
      },
      {
        label: "Negotiation",
        value: "Negotiation",
      },
      {
        label: "Contracted",
        value: "Contracted",
      },
      {
        label: "Beginning",
        value: "Beginning",
      },
      {
        label: "Intermediate",
        value: "Intermediate",
      },
      {
        label: "Advance",
        value: "Advance",
      },
      {
        label: "Closing",
        value: "Closing",
      },
      {
        label: "Finished",
        value: "Finished",
      },
    ],
    required: false,
  },
  {
    id: 11,
    label: "NDA Status",
    type: "select",
    placeholder: "Select NDA Status",
    key: "nda_status",
    options: [
      {
        label: "Signed",
        value: "Signed",
      },
      {
        label: "Not Signed",
        value: "Not Signed",
      },
      {
        label: "Under Process",
        value: "Under Process",
      },
      {
        label: "Expired",
        value: "Expired",
      },
    ],
    required: false,
  },
  {
    id: 12,
    label: "Contract Status",
    type: "select",
    placeholder: "Select Contract Status",
    key: "contract_status",
    options: [
      {
        label: "Signed",
        value: "Signed",
      },
      {
        label: "Not Signed",
        value: "Not Signed",
      },
      {
        label: "Under Process",
        value: "Under Process",
      },
      {
        label: "Expired",
        value: "Expired",
      },
    ],
    required: false,
  },
  {
    id: 13,
    label: "Description",
    type: "textarea",
    placeholder: "Enter Description",
    key: "desc",
    containerClass: "col-lg-12 col-md-12 col-sm-12",
    rows: 5,
  },
  {
    id: 14,
    label: "Remarks",
    type: "textarea",
    placeholder: "Enter Remarks",
    key: "remarks",
    containerClass: "col-lg-6 col-md-6 col-sm-12",
    rows: 5,
  },
  {
    id: 15,
    label: "Keypoints",
    type: "textarea",
    placeholder: "Enter Keypoints",
    key: "keypoints",
    containerClass: "col-lg-6 col-md-6 col-sm-12",
    rows: 5,
  },
];

export const projectFormLayoutJap = [
  {
    id: 1,
    label: "名前",
    placeholder: "名前",
    type: "text",
    key: "name",
    required: true,
  },
  {
    id: 2,
    label: "開始日",
    placeholder: "開始日",
    type: "date",
    required: true,
  },
  {
    id: 3,
    label: "終了日",
    placeholder: "終了日",
    type: "date",
    required: true,
  },
  {
    id: 4,
    label: "プロジェクト費用",
    placeholder: "プロジェクト費用",
    type: "text",
    key: "project_cost",
    required: false,
  },
  {
    id: 5,
    label: "会社",
    placeholder: "会社",
    type: "select",
    key: "company_id",
    id: "company_id",
    options: [
      {
        label: "Client A",
        value: "client-a",
      },
      {
        label: "Client B",
        value: "client-b",
      },
    ],
    required: false,
  },
  {
    id: 6,
    label: "クライアント",
    placeholder: "クライアント",
    type: "select",
    key: "client_id",
    options: [
      {
        label: "Client A",
        value: "client-a",
      },
      {
        label: "Client B",
        value: "client-b",
      },
    ],
    required: false,
  },
  {
    id: 7,
    label: "社員",
    placeholder: "社員",
    type: "select",
    key: "employee_id",
    options: [
      {
        label: "Employee A",
        value: "emp-a",
      },
      {
        label: "Employee B",
        value: "emp-b",
      },
    ],
    required: false,
  },
  // {
  //   id: 8,
  //   label: "Company Project Profile",

  //   type: "select",
  //   key: "companyProjectProfile",
  //   options: [
  //     {
  //       label: "Company A",
  //       value: "company-a",
  //     },
  //     {
  //       label: "Company B",
  //       value: "company-b",
  //     },
  //   ],
  //   required: false,
  // },
  {
    id: 9,
    label: "活動状態",
    placeholder: "活動状態",
    type: "select",
    key: "activity_status",
    options: [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Super Active",
        value: "Super Active",
      },
      {
        label: "Less Active",
        value: "Less Active",
      },
      {
        label: "Normal",
        value: "Normal",
      },
      {
        label: "Paused",
        value: "Paused",
      },
      {
        label: "Suspended",
        value: "Suspended",
      },
      {
        label: "Completed",
        value: "Completed",
      },
      {
        label: "Scrapped",
        value: "Scrapped",
      },
      {
        label: "Prospective",
        value: "Prospective",
      },
    ],
    required: false,
  },
  {
    id: 10,
    label: "ステージ",
    placeholder: "ステージ",
    type: "select",
    key: "stage",
    options: [
      {
        label: "Introduction",
        value: "Introduction",
      },
      {
        label: "Negotiation",
        value: "Negotiation",
      },
      {
        label: "Contracted",
        value: "Contracted",
      },
      {
        label: "Beginning",
        value: "Beginning",
      },
      {
        label: "Intermediate",
        value: "Intermediate",
      },
      {
        label: "Advance",
        value: "Advance",
      },
      {
        label: "Closing",
        value: "Closing",
      },
      {
        label: "Finished",
        value: "Finished",
      },
    ],
    required: false,
  },
  {
    id: 11,
    label: "NDAの状態",
    placeholder: "NDAの状態",
    type: "select",
    key: "nda_status",
    options: [
      {
        label: "Signed",
        value: "Signed",
      },
      {
        label: "Not Signed",
        value: "Not Signed",
      },
      {
        label: "Under Process",
        value: "Under Process",
      },
      {
        label: "Expired",
        value: "Expired",
      },
    ],
    required: false,
  },
  {
    id: 12,
    label: "契約の状態",
    placeholder: "契約の状態",
    type: "select",
    key: "contract_status",
    options: [
      {
        label: "Signed",
        value: "Signed",
      },
      {
        label: "Not Signed",
        value: "Not Signed",
      },
      {
        label: "Under Process",
        value: "Under Process",
      },
      {
        label: "Expired",
        value: "Expired",
      },
    ],
    required: false,
  },
  {
    id: 13,
    label: "説明",
    placeholder: "説明",
    type: "textarea",
    key: "desc",
    containerClass: "col-lg-12 col-md-12 col-sm-12",
    rows: 5,
  },
  {
    id: 14,
    label: "備考",
    placeholder: "備考",
    type: "textarea",
    key: "remarks",
    containerClass: "col-lg-6 col-md-6 col-sm-12",
    rows: 5,
  },
  {
    id: 15,
    label: "キーポイント",
    placeholder: "キーポイント",
    type: "textarea",
    key: "keypoints",
    containerClass: "col-lg-6 col-md-6 col-sm-12",
    rows: 5,
  },
];
