import React, { useState } from "react";
import Heading from "../Heading";
import CardContainer from "../Card/cardContainer";
import { useNavigate, useParams } from "react-router-dom";
import FormContainer from "../Form";
import { userFormLayout } from "../../Data/FormsLayout/userFormLayout";

function UserForm() {
  const { id } = useParams();
  const [formlayout] = useState(userFormLayout);
  const navigate = useNavigate();

  const onCancel = () => {
    navigate(-1);
  };

  const onSubmit = (formdata) => {};
  return (
    <>
      <div className="main-container">
        <Heading title={`${id} User`} />
        <div className="content-wrapper px-5">
          <CardContainer hideTotal={true} />
          <div className="content-container">
            <div className="p-3">
              <button className="btn btn-primary btn-sm px-5 py-2">
                Users
              </button>
            </div>
            <hr />
            <div className="pt-1 pb-4 px-3">
              <FormContainer
                layout={formlayout}
                onCancel={onCancel}
                onSubmit={onSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserForm;
