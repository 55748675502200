const initialState = { industries: [] };
export const industryReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case "ADD_INDUSTRIES":
            return { ...state, industries: payload };
        default:
            return state;
    }
};
