const initialState = { memos: [] };
export const memoReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "ADD_MEMO":
      return { ...state, memos: payload };
    default:
      return state;
  }
};
