export const clientFormLayout = [
  {
    id: 1,
    label: "First Name",
    type: "text",
    placeholder: "First Name",
    key: "first_name",
    required: true,
  },
  {
    id: 2,
    label: "First Name in japanese",
    type: "text",
    placeholder: "First Name in japanese",
    key: "first_name_in_japanese",
  },
  {
    id: 3,
    label: "Last Name",
    type: "text",
    placeholder: "Last Name",
    key: "last_name",
    required: true,
  },
  {
    id: 4,
    label: "Last name in japanese",
    type: "text",
    placeholder: "Last name in japanese",
    key: "last_name_in_japanese",
  },
  {
    id: 5,
    label: "Gender",
    type: "select",
    placeholder: "Select Gender",
    key: "gender",
    options: [
      {
        label: "Male",
        value: "Male",
      },
      {
        label: "Female",
        value: "Female",
      },
      {
        label: "Other",
        value: "Other",
      },
    ],
  },
  {
    id: 6,
    label: "Position",
    type: "text",
    placeholder: "Position",
    key: "position",
  },
  {
    id: 7,
    label: "Department",
    type: "text",
    placeholder: "Department",
    key: "dept",
  },
  {
    id: 8,
    label: "Telephone 1",
    type: "text",
    placeholder: "Telephone 1",
    key: "tel1",
  },
  {
    id: 9,
    label: "Telephone 2",
    type: "text",
    placeholder: "Telephone 2",
    key: "tel2",
  },
  {
    id: 10,
    label: "Fax",
    type: "text",
    placeholder: "Fax",
    key: "fax",
  },
  {
    id: 11,
    label: "Email 1",
    type: "email",
    placeholder: "Email 1",
    key: "email1",
  },
  {
    id: 12,
    label: "Email 2",
    type: "email",
    placeholder: "Email 2",
    key: "email2",
  },
  {
    id: 13,
    label: "Email 3",
    type: "email",
    placeholder: "Email 3",
    key: "email3",
  },
  {
    id: 14,
    label: "Company",
    type: "select",
    placeholder: "Select Company",
    key: "company_id",
    options: [
      {
        label: "Company 1",
        value: "com1",
      },
      {
        label: "Company 2",
        value: "com2",
      },
    ],
  },
  {
    id: 15,
    label: "Address",
    type: "text",
    placeholder: "Address",
    key: "address",
  },
  {
    id: 16,
    label: "City",
    type: "text",
    placeholder: "City",
    key: "city",
  },
  {
    id: 17,
    label: "Country",
    type: "text",
    placeholder: "Country",
    key: "country",
  },
  {
    id: 18,
    label: "Postal Code",
    type: "text",
    placeholder: "Postal Code",
    key: "postal_code",
  },
  {
    id: 19,
    label: "Website",
    type: "text",
    placeholder: "Website",
    key: "website",
  },
  {
    id: 20,
    label: "Incharge Employee",
    type: "select",
    placeholder: "Select Incharge Employee",
    key: "employee_id",
    options: [
      {
        label: "Employee 1",
        value: "emp1",
      },
      {
        label: "Employee 2",
        value: "emp2",
      },
    ],
  },
];

export const clientFormLayoutJap = [
  {
    id: 1,
    label: "ファーストネーム",
    type: "text",
    placeholder: "ファーストネーム",
    key: "first_name",
    required: true,
  },
  {
    id: 2,
    label: "日本語の名",
    type: "text",
    placeholder: "日本語の名",
    key: "first_name_in_japanese",
  },
  {
    id: 3,
    label: "苗字",
    type: "text",
    placeholder: "苗字",
    key: "last_name",
    required: true,
  },
  {
    id: 4,
    label: "日本語の姓",
    type: "text",
    placeholder: "日本語の姓",
    key: "last_name_in_japanese",
  },
  {
    id: 5,
    label: "性別",
    type: "select",
    placeholder: "性別を選択して下さい",
    key: "gender",
    options: [
      {
        label: "Male",
        value: "Male",
      },
      {
        label: "Female",
        value: "Female",
      },
      {
        label: "Other",
        value: "Other",
      },
    ],
  },
  {
    id: 6,
    label: "ポジション",
    type: "text",
    placeholder: "ポジション",
    key: "position",
  },
  {
    id: 7,
    label: "デパートメント",
    type: "text",
    placeholder: "デパートメント",
    key: "dept",
  },
  {
    id: 8,
    label: "電話 1",
    type: "text",
    placeholder: "電話 1",
    key: "tel1",
  },
  {
    id: 9,
    label: "電話 2",
    type: "text",
    placeholder: "電話 2",
    key: "tel2",
  },
  {
    id: 10,
    label: "ファックス",
    type: "text",
    placeholder: "ファックス",
    key: "fax",
  },
  {
    id: 11,
    label: "Eメール 1",
    type: "email",
    placeholder: "Eメール 1",
    key: "email1",
  },
  {
    id: 12,
    label: "Eメール 2",
    type: "email",
    placeholder: "Eメール 2",
    key: "email2",
  },
  {
    id: 13,
    label: "Eメール 3",
    type: "email",
    placeholder: "Eメール 3",
    key: "email3",
  },
  {
    id: 14,
    label: "会社",
    type: "select",
    placeholder: "会社",
    key: "company_id",
    options: [
      {
        label: "Company 1",
        value: "com1",
      },
      {
        label: "Company 2",
        value: "com2",
      },
    ],
  },
  {
    id: 15,
    label: "住所",
    type: "text",
    placeholder: "住所",
    key: "address",
  },
  {
    id: 16,
    label: "都市",
    type: "text",
    placeholder: "都市",
    key: "city",
  },
  {
    id: 17,
    label: "国",
    type: "text",
    placeholder: "国",
    key: "country",
  },
  {
    id: 18,
    label: "郵便番号",
    type: "text",
    placeholder: "郵便番号",
    key: "postal_code",
  },
  {
    id: 19,
    label: "Webサイト",
    type: "text",
    placeholder: "Webサイト",
    key: "website",
  },
  {
    id: 20,
    label: "担当社員",
    type: "select",
    placeholder: "選択する 担当社員",
    key: "employee_id",
    options: [
      {
        label: "Employee 1",
        value: "emp1",
      },
      {
        label: "Employee 2",
        value: "emp2",
      },
    ],
  },
];
