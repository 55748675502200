const initialState = { emails: [] };
export const emailReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "ADD_EMAIL":
      return { ...state, emails: payload };
    default:
      return state;
  }
};
