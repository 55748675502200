const initialState = { userDetails: {}, users: [], userRoles: [] };

function userReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case "STORE_USER_DETAILS":
      return { ...state, userDetails: payload };
    case "ADD_USERS":
      return { ...state, users: payload };
    case "ADD_USER_ROLE":
      return { ...state, userRoles: payload };
    default:
      return state;
  }
}

export { userReducer };
