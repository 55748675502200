import React, { useState, useContext, useEffect } from "react";

const LanguageContext = React.createContext();
export const useLanguageContext = () => useContext(LanguageContext);

export default function LanguageContextProvider({ children }) {
  const [language, changeLanguage] = useState("en");

  const get = localStorage.getItem("lan");

  useEffect(() => {
    if (get === "en") {
      changeLanguage("ja");
    }else{
      changeLanguage("en")
    }
  }, []);
  
  return (
    <LanguageContext.Provider
      value={{
        language,
        changeLanguage,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
}
