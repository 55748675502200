import { onSuccesshandler } from "../helpers/commonHelpers";
import { sendRequest } from "../services";
import { apipaths } from "../services/apiPaths";

export async function addTeamHandler(formdata, setModal, dispatch, renderlist) {
  if (formdata) {
    const form_data = formdata;
    form_data["employees"] = formdata["employee_id"];
    form_data["operation"] = "add";
    delete form_data["employee_id"];
    const { success, data } = await sendRequest(apipaths.addTeam, form_data);
    if (success) {
      onSuccesshandler(setModal, dispatch, renderlist, data?.message);
    }
  }
}
