import { sendRequest } from "../../services";
import { apipaths } from "../../services/apiPaths";

export const projectListAction = () => async (dispatch) => {
  const {
    success,
    data: { projects, companies, employees, clients },
  } = await sendRequest(apipaths.projectList);
  if (!success) return null;
  dispatch({ type: "ADD_PROJECTS", payload: projects });
  dispatch({ type: "ADD_COMAPNIES", payload: companies });
  dispatch({ type: "ADD_EMPLOYEES", payload: employees });
  dispatch({ type: "ADD_CLIENTS", payload: clients });
};
