import React, { useEffect, useState } from "react";
import Heading from "../Heading";
import TableCard from "../TableCard";
import CardContainer from "../Card/cardContainer";
import { taskColumns } from "../../Data/Columns/taskColumns";
import {
  meetingColumns,
  meetingColumnsHome,
} from "../../Data/Columns/meetingColumns";
import { useDispatch, useSelector } from "react-redux";
import { projectListAction } from "./../actions/projectAction";
import { meetingListAction } from "./../actions/meetingAction";
import { taskListAction } from "./../actions/taskListAction";
import {
  formatDateToday,
  modalInitialValue,
} from "../../helpers/commonHelpers";
import {
  companyColumns,
  companyColumnsHome,
} from "./../../Data/Columns/companyColumns";
import { companyListAction } from "./../actions/comanyAction";
import { addActionColumn } from "../../helpers/companyHelpers";
import Swal from "sweetalert2";
import { sendRequest } from "../../services";
import { apipaths } from "../../services/apiPaths";
import {
  projectColumnsHome,
  projectColumnsJa,
} from "../../Data/Columns/projectColumn";
import useTranslation from "../customHooks/translations";
import { taskColumnsHome } from "./../../Data/Columns/taskColumns";

function Home() {
  const [modal, setModal] = useState(modalInitialValue);
  const [taskData, setTaskData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [activeProject, setActiveProject] = useState([]);
  const [meeting, setMeeting] = useState([]);
  const [activeCompany, setActiveCompany] = useState([]);
  const selectedCompanyName = "";

  const { projects } = useSelector((state) => state.project);
  const { meet } = useSelector((state) => state.meet);
  const { tasks } = useSelector((state) => state.task);
  const { companies } = useSelector((state) => state.company);

  const translation = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    Data();
  }, [tasks]);

  useEffect(() => {
    projectDataFun();
  }, [projects]);

  useEffect(() => {
    activePro();
  }, [projects]);

  useEffect(() => {
    meetingData();
  }, [meet]);

  useEffect(() => {
    activeComp();
  }, [companies]);

  useEffect(() => {
    dispatch(meetingListAction());
    dispatch(taskListAction());
    dispatch(projectListAction());
    dispatch(companyListAction());
  }, []);

  let pendingTasklist = [];
  let pendingProjectList = [];
  let pendingActiveProject = [];
  let pendingMeetingList = [];
  let pendingActiveCompany = [];
  const today = new Date();

  const Data = () => {
    tasks &&
      tasks.map((task) => {
        if (task.status === "Pending") {
          pendingTasklist.push(task);
        }
        setTaskData(pendingTasklist);
      });
  };

  const projectDataFun = () => {
    projects &&
      projects.map((project) => {
        if (project.activity_status === "Prospective") {
          pendingProjectList.push(project);
        }
        setProjectData(pendingProjectList);
      });
  };

  const activePro = () => {
    projects &&
      projects.map((project) => {
        if (project.activity_status === "Active") {
          pendingActiveProject.push(project);
        }
        setActiveProject(pendingActiveProject);
      });
    projects &&
      projects.map((project) => {
        if (project.activity_status === "Super Active") {
          pendingActiveProject.push(project);
        }
        setActiveProject(pendingActiveProject);
      });
  };

  const meetingData = () => {
    meet &&
      meet.map((m) => {
        if (formatDateToday(m.startdate) >= formatDateToday(today)) {
          pendingMeetingList.push(m);
        }
        setMeeting(pendingMeetingList);
      });
  };

  const activeComp = () => {
    companies &&
      companies.map((company) => {
        if (company.activity_status === "Active") {
          pendingActiveCompany.push(company);
        }
        setActiveCompany(pendingActiveCompany);
      });
  };

  const deleteCompanyHandler = (companyId) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#1890ff",
    }).then(async ({ isConfirmed }) => {
      if (!isConfirmed) return null;
      const { data } = await sendRequest(apipaths.deleteCompany, {
        delete_id: companyId,
      });
      const { success, message } = data[0].original;
      if (success === 1) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: message,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch(companyListAction());
      }
    });
  };

  const deleteProjectHandler = (project_id) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#1890ff",
    }).then(async ({ isConfirmed }) => {
      if (!isConfirmed) return null;

      const { data } = await sendRequest(apipaths.deleteProject, {
        delete_id: project_id,
      });
      const { success, message } = data[0].original;
      if (success === 1) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: message,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch(projectListAction());
      }
    });
  };

  const deleteMeetingHandler = (meeting_id) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#1890ff",
    }).then(async ({ isConfirmed }) => {
      if (!isConfirmed) return null;

      const { data, success, message } = await sendRequest(
        apipaths.deleteMeeting,
        {
          delete_id: meeting_id,
        }
      );
      if (success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: message,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch(meetingListAction());
      }
    });
  };

  const deleteTaskHandler = (task_id) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#1890ff",
    }).then(async ({ isConfirmed }) => {
      if (!isConfirmed) return null;

      const { data } = await sendRequest(apipaths.deleteTask, {
        delete_id: task_id,
      });
      const { success, message } = data[0].original;
      if (success === 1) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: message,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch(taskListAction());
      }
    });
  };

  return (
    <div className="main-container">
      <Heading title={translation.dashboard} />
      <div className="content-wrapper px-5">
        <CardContainer />
      </div>

      <div className="px-5">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6">
            <TableCard
              count={taskData.length}
              key={1}
              columns={addActionColumn(
                { type: `dropdown`, name: `tasksHome-dropdown` }, 
                taskColumns,
                "",
                (task_id) => deleteTaskHandler(task_id),
                translation._language
              )}
              jaCol={addActionColumn(
                { type: `dropdown`, name: `tasksHome-dropdown` },
                taskColumnsHome,
                "",
                (task_id) => deleteTaskHandler(task_id),
                translation._language
              )}
              data={taskData}
              title={translation.active_task}
              rowPerPage={5}
            />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6">
            <TableCard
              count={meeting.length}
              columns={addActionColumn(
                { type: `dropdown`, name: `meetingHome-dropdown` },
                meetingColumns,
                "",
                (meeting_id) => deleteMeetingHandler(meeting_id),
                translation._language
              )}
              jaCol={addActionColumn(
                { type: `dropdown`, name: `meetingHome-dropdown` },
                meetingColumnsHome,
                "",
                (meeting_id) => deleteMeetingHandler(meeting_id),
                translation._language
              )}
              data={meeting}
              rowPerPage={5}
              title={translation.coming_meetings}
            />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6">
            <TableCard
              count={activeProject.length}
              columns={addActionColumn(
                { type: `dropdown`, name: `projectHome-dropdown` },
                projectColumnsHome,
                "",
                (project_id) => deleteProjectHandler(project_id),
                translation._language
              )}
              jaCol={addActionColumn(
                { type: `dropdown`, name: `projectHome-dropdown` },
                projectColumnsJa,
                "",
                (project_id) => deleteProjectHandler(project_id),
                translation._language
              )}
              data={activeProject}
              title={translation.active_projects}
              rowPerPage={5}
            />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6">
            <TableCard
              columns={addActionColumn(
                { type: `dropdown`, name: `projectHome-dropdown` },
                projectColumnsHome,
                "",
                (project_id) => deleteProjectHandler(project_id),
                translation._language
              )}
              jaCol={addActionColumn(
                { type: `dropdown`, name: `projectHome-dropdown` },
                projectColumnsJa,
                "",
                (project_id) => deleteProjectHandler(project_id),
                translation._language
              )}
              count={projectData.length}
              data={projectData}
              title={translation.prospective_projects}
              rowPerPage={5}
            />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12">
            <TableCard
              columns={addActionColumn(
                { type: "dropdown", name: "companyHome-dropdown" },
                companyColumns,
                "",
                (companyId) => deleteCompanyHandler(companyId),
                translation._language
              )}
              jaCol={addActionColumn(
                { type: "dropdown", name: "companyHome-dropdown" },
                companyColumnsHome,
                "",
                (companyId) => deleteCompanyHandler(companyId),
                translation._language
              )}
              count={activeCompany.length}
              data={activeCompany}
              title={translation.active_companies}
              rowPerPage={5}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
