import { Link } from "react-router-dom";
import { BsFillPencilFill, BsFillTrashFill } from "react-icons/bs";

export const quickMemosColumns = [
  {
    title: "ID",
    dataIndex: "memo_id",
    sorter: (a, b) => a.memo_id - b.memo_id,
  },
  {
    title: "Memo",
    dataIndex: "memo",
    sorter: (a, b) => a.memo.localeCompare(b.memo),
    render: (d, data) => {
      return (
        <>
          <label style={{ color: "#1890ff" }}>{data.memo}</label>
        </>
      );
    },
  },
  {
    title: "Date",
    dataIndex: "date",
    sorter: (a, b) => a.date - b.date,
  },
  {
    title: "Action",
    dataIndex: "action",
    render: (data) => (
      <div>
        {" "}
        <span>
          <Link to="/quickmemos/form/edit">
            <BsFillPencilFill className="table-icons edit-icon text-warning" />
          </Link>
          <BsFillTrashFill className="table-icons delete-icon text-danger" />
        </span>{" "}
      </div>
    ),
  },
];

export const quickMemosColumnsHome = [
  {
    title: "ID",
    dataIndex: "memo_id",
    sorter: (a, b) => a.memo_id - b.memo_id,
  },
  {
    title: "メモ",
    dataIndex: "memo",
    sorter: (a, b) => a.memo.localeCompare(b.memo),
    render: (d, data) => {
      return (
        <>
          <label style={{ color: "#1890ff" }}>{data.memo}</label>
        </>
      );
    },
  },
  {
    title: "日付",
    dataIndex: "date",
    sorter: (a, b) => a.date - b.date,
  },
];
