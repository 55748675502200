const initialState = { sectorboards: [] };
export const sectorBoardReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case "ADD_SECTOR_BOARD":
            return { ...state, sectorboards: payload };
        default:
            return state;
    }
};
