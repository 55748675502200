import { sendRequest } from "../../services";
import { apipaths } from "../../services/apiPaths";

export const addUserDetailsAction = () => async (dispatch) => {
  const {
    success,
    data: { user },
  } = await sendRequest(apipaths.userDetails);
  if (success) dispatch({ type: "STORE_USER_DETAILS", payload: user });
};
