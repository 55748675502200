export const clientFormLayout = [
  {
    id: 1,
    label: "First Name",
    type: "text",
    placeholder: "First Name",
    key: "first_name",
    required: true,
  },
  {
    id: 2,
    label: "First Name in japanese",
    type: "text",
    placeholder: "First Name in japanese",
    key: "first_name_in_japanese",
  },
  {
    id: 3,
    label: "Last Name",
    type: "text",
    placeholder: "Last Name",
    key: "last_name",
    required: true,
  },
  {
    id: 4,
    label: "Last name in japanese",
    type: "text",
    placeholder: "Last name in japanese",
    key: "last_name_in_japanese",
  },
  {
    id: 5,
    label: "Gender",
    type: "select",
    placeholder: "Gender",
    key: "gender",
    options: [
      {
        label: "Male",
        value: "Male",
      },
      {
        label: "Female",
        value: "Female",
      },
      {
        label: "Other",
        value: "Other",
      },
    ],
  },
  {
    id: 6,
    label: "Position",
    type: "text",
    placeholder: "Position",
    key: "position",
  },
  {
    id: 7,
    label: "Department",
    type: "text",
    placeholder: "Department",
    key: "dept",
  },
  {
    id: 8,
    label: "Telephone 1",
    type: "text",
    placeholder: "Telephone 1",
    key: "tel1",
  },
  {
    id: 9,
    label: "Telephone 2",
    type: "text",
    placeholder: "Telephone 2",
    key: "tel2",
  },
  {
    id: 10,
    label: "Fax",
    type: "text",
    placeholder: "Fax",
    key: "fax",
  },
  {
    id: 11,
    label: "Email 1",
    type: "email",
    placeholder: "Email 1",
    key: "email1",
  },
  {
    id: 12,
    label: "Email 2",
    type: "email",
    placeholder: "Email 2",
    key: "email2",
  },
  {
    id: 13,
    label: "Email 3",
    type: "email",
    placeholder: "Email 3",
    key: "email3",
  },
  {
    id: 15,
    label: "Address",
    type: "text",
    placeholder: "Address",
    key: "address",
  },
  {
    id: 16,
    label: "City",
    type: "text",
    placeholder: "City",
    key: "city",
  },
  {
    id: 17,
    label: "Country",
    type: "text",
    placeholder: "Country",
    key: "country",
  },
  {
    id: 18,
    label: "Postal Code",
    type: "text",
    placeholder: "Postal Code",
    key: "postal_code",
  },
  {
    id: 19,
    label: "Website",
    type: "text",
    placeholder: "Website",
    key: "website",
  },
  {
    id: 20,
    label: "Incharge Employee",
    type: "select",
    placeholder: "Select Incharge Employee",
    key: "employee_id",
    options: [
      {
        label: "Employee 1",
        value: "emp1",
      },
      {
        label: "Employee 2",
        value: "emp2",
      },
    ],
  },
];
