import React, { useEffect, useState } from "react";
import Heading from "../Heading";
import TableCard from "../TableCard";
import CardContainer from "../Card/cardContainer";
import {
  notesFormLayout,
  notesFormLayoutJap,
} from "../../Data/FormsLayout/notesFormLayout";
import { addActionColumn } from "../../helpers/companyHelpers";
import {
  companyFormLayoutHandler,
  formModalController,
  listingCommonDefaulter,
  modalInitialValue,
} from "../../helpers/commonHelpers";
import ModalContainer from "../Modal";
import { addNotehandler, updateNoteHandler } from "../../apiParsing/notes";
import { useDispatch, useSelector } from "react-redux";
import { notesListAction } from "../actions/notesAction";
import { deleteHandler, updateHandler } from "../../apiParsing/data";
import { apipaths } from "../../services/apiPaths";
import { employeesListAction } from "./../actions/employeesListAction";
import { clientListAction } from "./../actions/clientAction";
import { projectListAction } from "./../actions/projectAction";
import useTranslation from "../customHooks/translations";
import moment from "moment";

function Notes() {
  const [modal, setModal] = useState(modalInitialValue);
  const { notes } = useSelector((state) => state.notes);
  const { employees } = useSelector((state) => state.employee);
  const { projects } = useSelector((state) => state.project);
  const { clients } = useSelector((state) => state.client);
  const { companies } = useSelector((state) => state.company);

  const dispatch = useDispatch();
  const translation = useTranslation();

  useEffect(() => {
    if (clients && companies && employees) {
      employees.map((emp) => (emp.name = emp.first_name + " " + emp.last_name));
      let l1 = companyFormLayoutHandler(
        employees,
        notesFormLayout,
        "employee_id",
        "name"
      );
      let l2 = companyFormLayoutHandler(companies, l1, "company_id", "name");

      clients.map(
        (client) => (client.name = client.first_name + " " + client.last_name)
      );
      let l3 = companyFormLayoutHandler(clients, l2, "client_id", "name");
    }
    if (clients && companies && employees) {
      employees.map((emp) => (emp.name = emp.first_name + " " + emp.last_name));
      let l1 = companyFormLayoutHandler(
        employees,
        notesFormLayoutJap,
        "employee_id",
        "name"
      );
      let l2 = companyFormLayoutHandler(companies, l1, "company_id", "name");

      clients.map(
        (client) => (client.name = client.first_name + " " + client.last_name)
      );
      let l3 = companyFormLayoutHandler(clients, l2, "client_id", "name");
    }
  }, [clients, companies, employees]);

  useEffect(() => {
    if (employees && projects && clients && companies) {
      const arr = [employees, projects, clients, companies];
      const keyArr = ["employee_id", "project_id", "client_id", "company_id"];
      listingCommonDefaulter(arr, notesFormLayout, keyArr);
    }
    if (employees && projects && clients && companies) {
      const arr = [employees, projects, clients, companies];
      const keyArr = ["employee_id", "project_id", "client_id", "company_id"];
      listingCommonDefaulter(arr, notesFormLayoutJap, keyArr);
    }
  }, [employees, projects, clients, companies]);

  const notesColumns = [
    {
      title: "ID",
      dataIndex: "note_id",
      sorter: (a, b) => a.note_id - b.note_id,
    },
    {
      title: "Title",
      dataIndex: "title",
      sorter: (a, b) => a.title.localeCompare(b.title),
      render: (d, data) => {
        return (
          <>
            <label
              style={{ color: "#1890ff" }}
              onClick={() => {
                dataTest(data);
              }}
            >
              {data.title}
            </label>
          </>
        );
      },
    },
    {
      title: "Company",
      dataIndex: "company_id",
      sorter: (a, b) => a.company_id - b.company_id,
      render: (company_id, row) => {
        let company_name = "";
        const company = row.company;
        if (company_id && company) company_name = company.name;
        return company_name;
      },
    },
    {
      title: "Client",
      dataIndex: "client",
      sorter: (a, b) =>
        a?.client?.first_name.localeCompare(b?.client?.first_name),
      render: (d, data) => {
        return (
          <>
            {data?.client?.first_name && data?.client?.last_name
              ? data?.client?.first_name + " " + data?.client?.last_name
              : "-"}
          </>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
    },
  ];

  const notesColumnsHome = [
    {
      title: "ID",
      dataIndex: "note_id",
      sorter: (a, b) => a.note_id - b.note_id,
    },
    {
      title: "題名",
      dataIndex: "title",
      sorter: (a, b) => a.title.localeCompare(b.title),
      render: (d, data) => {
        return (
          <>
            <label
              style={{ color: "#1890ff" }}
              onClick={() => {
                dataTest(data);
              }}
            >
              {data.title}
            </label>
          </>
        );
      },
    },
    {
      title: "会社",
      dataIndex: "company_id",
      sorter: (a, b) => a.company_id - b.company_id,
      render: (company_id, row) => {
        let company_name = "";
        const company = row.company;
        if (company_id && company) company_name = company.name;
        return company_name;
      },
    },
    {
      title: "クライアント",
      dataIndex: "client",
      sorter: (a, b) =>
        a?.client?.first_name.localeCompare(b?.client?.first_name),
      render: (d, data) => {
        return (
          <>
            {data?.client?.first_name && data?.client?.last_name
              ? data?.client?.first_name + " " + data?.client?.last_name
              : "-"}
          </>
        );
      },
    },
    {
      title: "日付",
      dataIndex: "date",
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
    },
  ];

  const dataTest = (data) => {
    formModalController(
      setModal,
      notesFormLayout,
      notesFormLayoutJap,
      `${translation.edit_note}`,
      `edit-note-form`,
      data,
      (data) =>
        updateHandler(
          apipaths.addnotes,
          data,
          "note_id",
          setModal,
          dispatch,
          notesListAction
        )
    );
  };

  useEffect(() => {
    dispatch(notesListAction());
  }, []);

  return (
    <>
      <ModalContainer
        isOpen={modal.open}
        title={modal.title}
        closeIcon={() => setModal(modalInitialValue)}
        content={modal.content}
        footer={false}
      />
      <div className="main-container">
        <Heading title={translation.notes} />
        <div className="content-wrapper px-5">
          <CardContainer hideTotal={true} />
        </div>

        <div className="px-5">
          <TableCard
            button={{
              title: translation.add_note,
              onClick: () =>
                formModalController(
                  setModal,
                  notesFormLayout,
                  notesFormLayoutJap,
                  `${translation.add_note}`,
                  `add-note-form`,
                  {},
                  (data) =>
                    addNotehandler(data, setModal, dispatch, notesListAction)
                ),
            }}
            columns={addActionColumn(
              {},
              notesColumns,
              (data) => {
                formModalController(
                  setModal,
                  notesFormLayout,
                  notesFormLayoutJap,
                  `${translation.edit_note}`,
                  `edit-note-form`,
                  data,
                  (data) =>
                    updateNoteHandler(
                      apipaths.addnotes,
                      data,
                      "note_id",
                      setModal,
                      dispatch,
                      notesListAction
                    )
                );
              },
              (data) =>
                deleteHandler(
                  apipaths.deleteNote,
                  data?.["note_id"],
                  setModal,
                  dispatch,
                  notesListAction
                ),
              translation._language
            )}
            jaCol={addActionColumn(
              {},
              notesColumnsHome,
              (data) => {
                formModalController(
                  setModal,
                  notesFormLayout,
                  notesFormLayoutJap,
                  `${translation.edit_note}`,
                  `edit-note-form`,
                  data,
                  (data) =>
                    updateHandler(
                      apipaths.addnotes,
                      data,
                      "note_id",
                      setModal,
                      dispatch,
                      notesListAction
                    )
                );
              },
              (data) =>
                deleteHandler(
                  apipaths.deleteNote,
                  data?.["note_id"],
                  setModal,
                  dispatch,
                  notesListAction
                ),
              translation._language
            )}
            data={notes}
            title={translation.notes}
          />
        </div>
      </div>
    </>
  );
}

export default Notes;
