import React, { useEffect, useState } from "react";
import Heading from "../Heading";
import TableCard from "../TableCard";
import CardContainer from "../Card/cardContainer";
import { addActionColumn } from "../../helpers/companyHelpers";
import {
  formModalController,
  listingCommonDefaulter,
  modalInitialValue,
} from "../../helpers/commonHelpers";
import {
  emailFormLayout,
  emailFormLayoutJap,
} from "../../Data/FormsLayout/emailFormLayout";
import ModalContainer from "../Modal";
import {
  addFormDatahandler,
  deleteHandler,
  updateHandler,
} from "../../apiParsing/data";
import { apipaths } from "../../services/apiPaths";
import { emailListAction } from "../actions/emailAction";
import { useDispatch, useSelector } from "react-redux";
import useTranslation from "../customHooks/translations";
import moment from "moment";

function Email() {
  const [modal, setModal] = useState(modalInitialValue);
  const { emails } = useSelector((state) => state.emails);
  const { projects } = useSelector((state) => state.project);
  const { clients } = useSelector((state) => state.client);
  const { companies } = useSelector((state) => state.company);
  const { employees } = useSelector((state) => state.employee);
  const dispatch = useDispatch();
  const translation = useTranslation();

  useEffect(() => {
    dispatch(emailListAction());
  }, []);

  useEffect(() => {
    if (projects && clients && companies && employees) {
      const arr = [projects, clients, companies, employees];
      const kerArr = ["project_id", "client_id", "company_id", "employee_id"];
      listingCommonDefaulter(arr, emailFormLayout, kerArr);
    }
  }, [projects, clients, companies, employees]);

  useEffect(() => {
    if (projects && clients && companies && employees) {
      const arr = [projects, clients, companies, employees];
      const kerArr = ["project_id", "client_id", "company_id", "employee_id"];
      listingCommonDefaulter(arr, emailFormLayoutJap, kerArr);
    }
  }, [projects, clients, companies, employees]);

  const emailColumns = [
    {
      title: "ID",
      dataIndex: "email_id",
      sorter: (a, b) => a.email_id - b.email_id,
      key: "email_id",
    },
    {
      title: "Subject",
      dataIndex: "subject",
      sorter: (a, b) => a.subject.localeCompare(b.subject),
      render: (d, data) => {
        return (
          <>
            <label
              style={{ color: "#1890ff" }}
              onClick={() => {
                dataTest(data);
              }}
            >
              {data.subject}
            </label>
          </>
        );
      },
    },
    {
      title: "Sender",
      dataIndex: "sender",
      sorter: (a, b) => a.sender.localeCompare(b.sender),
      sorter: (a, b) => a.sender - b.sender,
    },
    {
      title: "Date",
      dataIndex: "send_date",
      sorter: (a, b) => moment(a.send_date).unix() - moment(b.send_date).unix(),
      key: "send_date",
    },
  ];

  const emailColumnsHome = [
    {
      title: "ID",
      dataIndex: "email_id",
      sorter: (a, b) => a.email_id - b.email_id,
      key: "email_id",
    },
    {
      title: "主題",
      dataIndex: "subject",
      sorter: (a, b) => a.subject.localeCompare(b.subject),
      render: (d, data) => {
        return (
          <>
            <label
              style={{ color: "#1890ff" }}
              onClick={() => {
                dataTest(data);
              }}
            >
              {data.subject}
            </label>
          </>
        );
      },
    },
    {
      title: "送信者",
      dataIndex: "sender",
      sorter: (a, b) => a.sender.localeCompare(b.sender),
    },
    {
      title: "日付",
      dataIndex: "send_date",
      sorter: (a, b) => moment(a.send_date).unix() - moment(b.send_date).unix(),

      key: "send_date",
    },
  ];

  const dataTest = (data) => {
    formModalController(
      setModal,
      emailFormLayout,
      emailFormLayoutJap,
      `${translation.edit_email}`,
      `edit-email-form`,
      data,
      (data) =>
        updateHandler(
          apipaths.addEmail,
          data,
          "email_id",
          setModal,
          dispatch,
          emailListAction
        )
    );
  };

  return (
    <>
      <ModalContainer
        isOpen={modal.open}
        title={modal.title}
        closeIcon={() => setModal(modalInitialValue)}
        content={modal.content}
        footer={false}
      />
      <div className="main-container">
        <Heading title={translation.email} />
        <div className="content-wrapper px-5">
          <CardContainer hideTotal={true} />
        </div>

        <div className="px-5">
          <TableCard
            button={{
              title: translation.add_email,
              onClick: () =>
                formModalController(
                  setModal,
                  emailFormLayout,
                  emailFormLayoutJap,
                  `${translation.add_email}`,
                  `add-email-form`,
                  {},
                  (data) =>
                    addFormDatahandler(
                      apipaths.addEmail,
                      data,
                      setModal,
                      dispatch,
                      emailListAction
                    )
                ),
            }}
            columns={addActionColumn(
              {},
              emailColumns,
              (data) =>
                formModalController(
                  setModal,
                  emailFormLayout,
                  emailFormLayoutJap,
                  `${translation.edit_email}`,
                  `edit-email-form`,
                  data,
                  (data) =>
                    updateHandler(
                      apipaths.addEmail,
                      data,
                      "email_id",
                      setModal,
                      dispatch,
                      emailListAction
                    )
                ),
              (data) =>
                deleteHandler(
                  apipaths.deleteEmail,
                  data?.["email_id"],
                  setModal,
                  dispatch,
                  emailListAction
                ),
              translation._language
            )}
            jaCol={addActionColumn(
              {},
              emailColumnsHome,
              (data) =>
                formModalController(
                  setModal,
                  emailFormLayout,
                  emailFormLayoutJap,
                  `${translation.edit_email}`,
                  `edit-email-form`,
                  data,
                  (data) =>
                    updateHandler(
                      apipaths.addEmail,
                      data,
                      "email_id",
                      setModal,
                      dispatch,
                      emailListAction
                    )
                ),
              (data) =>
                deleteHandler(
                  apipaths.deleteEmail,
                  data?.["email_id"],
                  setModal,
                  dispatch,
                  emailListAction
                ),
              translation._language
            )}
            data={emails}
            title={translation.email}
          />
        </div>
      </div>
    </>
  );
}

export default Email;
