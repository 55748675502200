import React from "react";
import $ from "jquery";
import { useSelector } from "react-redux";
function SelectFeild({
  formdata,
  setFormdata,
  data,
  inputClass = "form-select",
}) {
  const { label, placeholder, key, options, required = false } = data;

  const changeSelectMethod = (key, value) => {
    if (key == "company_id") {
      $("#client_id option").show();
      $("#client_id option")
        .not(".company_id_" + value)
        .hide();
    } else if (key == "client_id") {
      $("#project_id option").show();
      $("#project_id option")
        .not(".client_id_" + value)
        .hide();
    }
    setFormdata({ ...formdata, [key]: value });
  };

  return (
    <>
      <label>
        {label}
        {required && <span className="text-danger">*</span>}
      </label>
      <select
        defaultValue={formdata[key] ? formdata[key] : ""}
        className={inputClass}
        id={key}
        required={required}
        onChange={(e) => changeSelectMethod(key, e.target.value)}
      >
        <option value="">{placeholder}</option>
        {options?.map((opt, index) => {
          return (
            <option
              key={index}
              value={
                key == "client_id"
                  ? opt.client_id
                  : key == "company_id"
                  ? opt.company_id
                  : key == "project_id"
                  ? opt.project_id
                  : key == "employee_id"
                  ? opt.employee_id
                  : key == "task_id"
                  ? opt.task_id
                  : key == "role_id"
                  ? opt.role_namess
                  : key == "activity_status"
                  ? opt.activity_status
                  : key == "contract_status"
                  ? opt.contract_status
                  : key === "stage"
                  ? opt.stage
                  : opt.nda_status
              }
              data={JSON.stringify(opt)}
              class={
                key == "client_id"
                  ? "company_id_" + opt.company_id
                  : key == "project_id"
                  ? "client_id_" + opt.client_id
                  : ""
              }
            >
              {opt.name || opt.label}
            </option>
          );
        })}
      </select>
    </>
  );
}
export default SelectFeild;
