import React, { useEffect, useState } from "react";
import Heading from "../Heading";
import TableCard from "../TableCard";
import CardContainer from "../Card/cardContainer";
import { useDispatch, useSelector } from "react-redux";
import { companyListAction } from "../actions/comanyAction";
import { addActionColumn } from "../../helpers/companyHelpers";
import { sendRequest } from "../../services";
import { apipaths } from "../../services/apiPaths";
import Swal from "sweetalert2";
import {
  addPrefexToObjectProperty,
  formModalController,
  listingCommonDefaulter,
  modalInitialValue,
} from "../../helpers/commonHelpers";
import ModalContainer from "../Modal";
import {
  companyFormLayout,
  companyFormLayoutJap,
} from "../../Data/FormsLayout/companyFormLayout";
import { employeesListAction } from "../actions/employeesListAction";
import { Link, useNavigate } from "react-router-dom";
import useTranslation from "../customHooks/translations";

function Company() {
  const dispatch = useDispatch();

  const [modal, setModal] = useState(modalInitialValue);
  const { companies } = useSelector((state) => state.company);
  const { employees } = useSelector((state) => state.employee);

  const translation = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(employeesListAction());
  }, []);

  useEffect(() => {
    if (employees) {
      listingCommonDefaulter([employees], companyFormLayout, ["employee_id"]);
    }
  }, [employees]);

  useEffect(() => {
    if (employees) {
      listingCommonDefaulter([employees], companyFormLayoutJap, [
        "employee_id",
      ]);
    }
  }, [employees]);

  const companyColumns = [
    {
      title: "ID",
      dataIndex: "company_id",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.company_id - b.company_id,
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (company_id, data) => {
        return (
          <>
            <Link to={`/edit-company/${data.company_id}`}>
              <label>{data.name}</label>
            </Link>
          </>
        );
      },
    },
    {
      title: "Name in Japnese",
      dataIndex: "name_in_japanese",
      sorter: (a, b) => a.name_in_japanese.localeCompare(b.name_in_japanese),
    },
    {
      title: "Industry",
      dataIndex: "industry",
      sorter: (a, b) => a.industry.localeCompare(b.industry),
    },
    {
      title: "Telephone",
      dataIndex: "tel1",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.tel1 - b.tel1,
    },
    {
      title: "Email",
      dataIndex: "email1",
      sorter: (a, b) => a.email1.localeCompare(b.email1),
    },
    {
      title: "Country",
      dataIndex: "country",
      sorter: (a, b) => a.country.localeCompare(b.country),
    },
    {
      title: "Source",
      dataIndex: "source",
      sorter: (a, b) => a.source.localeCompare(b.source),
    },
  ];

  const companyColumnsHome = [
    {
      title: "ID",
      dataIndex: "company_id",
      sorter: (a, b) => a.company_id - b.company_id,
    },
    {
      title: "名前",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (company_id, data) => {
        return (
          <>
            <Link to={`/edit-company/${data.company_id}`}>
              <label>{data.name}</label>
            </Link>
          </>
        );
      },
    },
    {
      title: "日本語での名前",
      dataIndex: "name_in_japanese",
      sorter: (a, b) => a.name_in_japanese.localeCompare(b.name_in_japanese),
    },
    {
      title: "業種",
      dataIndex: "industry",
      sorter: (a, b) => a.industry.localeCompare(b.industry),
    },
    {
      title: "電話",
      dataIndex: "tel1",
      sorter: (a, b) => a.tel1 - b.tel1,
    },

    {
      title: "メール",
      dataIndex: "email1",
      sorter: (a, b) => a.email1.localeCompare(b.email1),
    },
    {
      title: "国",
      dataIndex: "country",
      sorter: (a, b) => a.country.localeCompare(b.country),
    },
    {
      title: "ソース",
      dataIndex: "source",
      sorter: (a, b) => a.source.localeCompare(b.source),
    },
  ];

  const onSubmit = async (formdata) => {
    let new_company_details = { ...formdata };
    new_company_details = addPrefexToObjectProperty(
      "Company_",
      new_company_details
    );
    new_company_details = {
      ...new_company_details,
      OPtype: "Company",
      operation: "add",
      company_type: "keep",
    };

    const {
      success,
      data: { message },
    } = await sendRequest(apipaths.addCompany, new_company_details);
    if (success) {
      setModal(modalInitialValue);
      dispatch(companyListAction());
      Swal.fire({
        position: "center",
        icon: "success",
        title: message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  useEffect(() => {
    dispatch(companyListAction());
  }, [dispatch]);

  const deleteCompanyHandler = (companyId) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#1890ff",
    }).then(async ({ isConfirmed }) => {
      if (!isConfirmed) return null;
      const { data } = await sendRequest(apipaths.deleteCompany, {
        delete_id: companyId,
      });
      const { success, message } = data[0].original;
      if (success === 1) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: message,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch(companyListAction());
      }
    });
  };

  const dataTest = (data) => {
    formModalController(
      setModal,
      companyFormLayout,
      `Edit Company`,
      `edit-company-form`,
      data,
      (data) => updateCompanyHandler(data)
    );
  };

  const updateCompanyHandler = async (company) => {
    company["id"] = company.company_id;
    delete company.project_id;
    delete company.enable;
    delete company.company;
    delete company.client;
    delete company.created_at;
    delete company.user_id;
    delete company.edate;
    delete company.sdate;
    delete company.status;
    delete company.employee;
    delete company.company_project_profile_id;

    company = addPrefexToObjectProperty("Company_", company);
    company["operation"] = "update";
    company["company_type"] = "update";
    company["OPtype"] = "Company";

    const {
      data: { flash_success },
      success,
    } = await sendRequest(apipaths.addCompany, company);
    if (success) {
      setModal(modalInitialValue);
      dispatch(companyListAction());
      Swal.fire({
        position: "center",
        icon: "success",
        title: flash_success,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  return (
    <>
      <ModalContainer
        isOpen={modal.open}
        title={modal.title}
        closeIcon={() => setModal(modalInitialValue)}
        content={modal.content}
        footer={false}
      />
      <div className="main-container">
        <Heading title={translation.company} />
        <div className="content-wrapper px-5">
          <CardContainer hideTotal={true} />
        </div>

        <div className="px-5">
          <TableCard
            button={{
              title: translation.add_company,
              onClick: () => navigate("/company/form/add"),
            }}
            columns={addActionColumn(
              { type: "dropdown", name: "company-dropdown" },
              companyColumns,
              "",
              (companyId) => deleteCompanyHandler(companyId),
              translation._language
            )}
            jaCol={addActionColumn(
              { type: "dropdown", name: "company-dropdown" },
              companyColumnsHome,
              companyFormLayoutJap,
              "",
              (companyId) => deleteCompanyHandler(companyId),
              translation._language
            )}
            data={companies}
            title={translation.company}
          />
        </div>
      </div>
    </>
  );
}

export default Company;
