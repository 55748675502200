import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { addProjectHandler } from "../../apiParsing/project";
import { projectFormLayout } from "../../Data/FormsLayout/projectFormLayout";
import {
  companyFormLayoutHandler,
  formModalController,
  modalInitialValue,
  regName,
} from "../../helpers/commonHelpers";
import { addActionColumn } from "../../helpers/companyHelpers";
import { sendRequest } from "../../services";
import { apipaths } from "../../services/apiPaths";
import { clientListAction } from "../actions/clientAction";
import { companyListAction } from "../actions/comanyAction";
import { projectListAction } from "../actions/projectAction";
import TableCard from "../TableCard";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Dropdown } from "antd";
import { BarsOutlined } from "@ant-design/icons";
import { menu } from "./../../Data/Columns/projectColumn";
import ModalContainer from "../Modal";
import Heading from "../Heading";
import CardContainer from "../Card/cardContainer";
import useTranslation from "../customHooks/translations";
import { projectFormLayoutJap } from "../../Data/FormsLayout/projectFormLayout";
import moment from "moment";

function CompanyProject() {
  const [formlayout, setFormLayout] = useState(projectFormLayout);
  const [modal, setModal] = useState(modalInitialValue);
  const [projectList, setProjectList] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { projects } = useSelector((state) => state.project);
  const { clients } = useSelector((state) => state.client);
  const { companies } = useSelector((state) => state.company);
  const { employees } = useSelector((state) => state.employee);
  const { id: companyId } = useParams();
  const translation = useTranslation();

  useEffect(() => {
    dispatch(clientListAction());
    dispatch(companyListAction());
    dispatch(projectListAction());
  }, [dispatch]);

  useEffect(() => {
    if (clients && companies && employees) {
      employees.map((emp) => (emp.name = emp.first_name + " " + emp.last_name));
      let l1 = companyFormLayoutHandler(
        employees,
        projectFormLayout,
        "employee_id",
        "name"
      );
      let l2 = companyFormLayoutHandler(companies, l1, "company_id", "name");
      clients.map(
        (client) => (client.name = client.first_name + " " + client.last_name)
      );
      let l3 = companyFormLayoutHandler(clients, l2, "client_id", "name");
      setFormLayout(l3);
    }
    if (clients && companies && employees) {
      employees.map((emp) => (emp.name = emp.first_name + " " + emp.last_name));
      let l1 = companyFormLayoutHandler(
        employees,
        projectFormLayoutJap,
        "employee_id",
        "name"
      );
      let l2 = companyFormLayoutHandler(companies, l1, "company_id", "name");

      clients.map(
        (client) => (client.name = client.first_name + " " + client.last_name)
      );
      let l3 = companyFormLayoutHandler(clients, l2, "client_id", "name");
    }
  }, [clients, companies, employees]);

  useEffect(() => {
    if (location.state.id == "company_id")
      setProjectList(
        projects?.filter(
          (project) => project?.company_id == parseInt(companyId)
        )
      );
    if (location.state.id == "client_id")
      setProjectList(
        projects?.filter((project) => project?.client_id == parseInt(companyId))
      );
  }, [projects]);

  const projectColumns = [
    {
      title: "ID",
      dataIndex: "project_id",
      sorter: {
        compare: (a, b) => a.id - b.id,
        multiple: 1,
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (name, data) => {
        return (
          <>
            <Link to={`/edit-project/${data.project_id}`}>
              <label>{name}</label>
            </Link>
          </>
        );
      },
    },
    {
      title: "Project Cost",
      dataIndex: "project_cost",
      sorter: (a, b) => a.project_cost - b.project_cost,
    },
    {
      title: "Stage",
      dataIndex: "stage",
      sorter: (a, b) => a.stage.localeCompare(b.stage),
    },
    {
      title: "Start Date",
      dataIndex: "startdate",
      sorter: (a, b) => moment(a.startdate).unix() - moment(b.startdate).unix(),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (data, index) => (
        <div key={index}>
          <Dropdown overlay={menu} placement="bottom" className="action-button">
            <BarsOutlined />
          </Dropdown>
        </div>
      ),
    },
  ];

  const projectColumnsHome = [
    {
      title: "ID",
      dataIndex: "project_id",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.project_id - b.project_id,
    },
    {
      title: "名前",
      dataIndex: "name",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (name, data) => {
        return (
          <>
            <Link to={`/edit-project/${data.project_id}`}>
              <label>{name}</label>
            </Link>
          </>
        );
      },
    },
    {
      title: "プロジェクト費用",
      dataIndex: "project_cost",
      sorter: (a, b) => a.project_cost - b.project_cost,
    },
    // {
    //   title: "会社",
    //   dataIndex: "company",
    //   sorter: (a, b) => a.company.name.localeCompare(b.company.name),
    //   render: (company) => {
    //     return (
    //       <p className="mb-0">{company && company.name ? company.name : ""}</p>
    //     );
    //   },
    // },
    // {
    //   title: "クライアント",
    //   dataIndex: "client",
    //   render: (client) => {
    //     if (!client) return null;
    //     const { first_name, last_name } = client;
    //     return <p className="mb-0">{first_name + " " + last_name}</p>;
    //   },
    // },
    // {
    //   title: "活動状態",
    //   dataIndex: "activity_status",
    //   sorter: (a, b) => a.activity_status.localeCompare(b.activity_status),
    // },
    {
      title: "ステージ",
      dataIndex: "stage",
      sorter: (a, b) => a.stage.localeCompare(b.stage),
    },
    {
      title: "日付",
      dataIndex: "startdate",
      sorter: (a, b) => moment(a.startdate).unix() - moment(b.startdate).unix(),
    },
  ];

  const updateProjectHandler = async (project) => {
    if (!regName.test(project.name)) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Please Fill Out Project Subject !",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      let new_project_details = { ...project };
      new_project_details = {
        Project_name: project.name,
        Project_startdate: project.startdate,
        Project_enddate: project.enddate,
        Project_project_cost: project.project_cost,
        Project_company_id: project.company_id,
        Project_client_id: project.client_id,
        Project_employee_id: project.Project_employee_id,
        Project_activity_status: project.Project_activity_status,
        Project_stage: project.Project_stage,
        Project_nda_status: project.Project_nda_status,
        Project_contract_status: project.Project_contract_status,
        Project_desc: project.Project_desc,
        Project_remarks: project.Project_remarks,
        Project_keypoints: project.Project_keypoints,
      };
      new_project_details = {
        ...new_project_details,
        OPtype: "Project",
        operation: "update",
        project_type: "keep",
        Project_id: project.project_id,
      };
      const {
        success,
        data: { message },
      } = await sendRequest(apipaths.addProject, new_project_details);

      if (success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Project Update Successfully!",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate(-1);
      }
    }
  };

  const deleteProjectHandler = (project_id) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#1890ff",
    }).then(async ({ isConfirmed }) => {
      if (!isConfirmed) return null;

      const { data } = await sendRequest(apipaths.deleteProject, {
        delete_id: project_id,
      });
      const { success, message } = data[0].original;
      if (success === 1) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: message,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch(projectListAction());
      }
    });
  };

  return (
    <>
      <ModalContainer
        isOpen={modal.open}
        title={modal.title}
        closeIcon={() => setModal(modalInitialValue)}
        content={modal.content}
        footer={false}
      />
      <div className="main-container">
        <Heading title={translation.project} />
        <div className="content-wrapper px-5">
          <CardContainer hideTotal={true} />
        </div>

        <div className="px-5">
          <TableCard
            button={{
              title: translation.add_project,
              onClick: () =>
                formModalController(
                  setModal,
                  projectFormLayout,
                  projectFormLayoutJap,
                  `${translation.add_project}`,
                  `Add-project-form`,
                  {},
                  (data) =>
                    addProjectHandler(
                      data,
                      setModal,
                      dispatch,
                      projectListAction
                    )
                ),
            }}
            columns={addActionColumn(
              { type: `dropdown`, name: `project-dropdown` },
              projectColumns,
              (data) => {
                formModalController(
                  setModal,
                  projectFormLayout,
                  projectFormLayoutJap,
                  `${translation.edit_project}`,
                  `edit-Project-form`,
                  data,
                  (data) => updateProjectHandler(data)
                );
              },
              (project_id) => deleteProjectHandler(project_id),
              translation._language
            )}
            jaCol={addActionColumn(
              { type: `dropdown`, name: `project-dropdown` },
              projectColumnsHome,
              (data) => {
                formModalController(
                  setModal,
                  projectFormLayout,
                  projectFormLayoutJap,
                  `${translation.edit_project}`,
                  `edit-Project-form`,
                  data,
                  (data) => updateProjectHandler(data)
                );
              },
              (project_id) => deleteProjectHandler(project_id),
              translation._language
            )}
            data={projectList}
            title={translation.project}
          />
        </div>
      </div>
    </>
  );
}

export default CompanyProject;
