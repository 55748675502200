import {
  addPrefexToObjectProperty,
  onSuccesshandler,
} from "../helpers/commonHelpers";
import { isCompanyId } from "../helpers/companyHelpers";
import { sendRequest } from "../services";
import { apipaths } from "../services/apiPaths";

export const addProjectHandler = async (
  formdata,
  setModal,
  dispatch,
  projectListAction,
  companyId
) => {
  
  let newProjectData = { ...formdata };
  newProjectData = addPrefexToObjectProperty("Project_", newProjectData);
  const form_data = isCompanyId(companyId, newProjectData);
  newProjectData["operation"] = "add";
  newProjectData["project_type"] = "add";
  newProjectData["OPtype"] = "Project";

  const {
    data: { message },
    success,
  } = await sendRequest(apipaths.addProject, form_data);
  if (success) {
    onSuccesshandler(setModal, dispatch, projectListAction, message);
  }
};
