import { combineReducers } from "redux";
import { clientReducer } from "./clientReducer";
import { companyReducer } from "./companyReducer";
import { employeeReducer } from "./employeeReducer";
import { industryReducer } from "./industryReducer";
import { projectReducer } from "./projectReducer";
import { sectorBoardReducer } from "./sectorBoardReducer";
import { taskReducer } from "./taskReducer";
import { userReducer } from "./userReducer";
import { notesReducer } from "./notesReducer";
import { meetReducer } from "./meetReducer";
import { emailReducer } from "./emailReducer";
import { memoReducer } from "./memoReducer";
import { teamReducer } from "./teamReducer";
import { projectProfileReducer } from "./projectProfileReducer";

const allReducers = combineReducers({
  user: userReducer,
  company: companyReducer,
  employee: employeeReducer,
  client: clientReducer,
  project: projectReducer,
  industry: industryReducer,
  sectorBoard: sectorBoardReducer,
  task: taskReducer,
  notes: notesReducer,
  meet: meetReducer,
  emails: emailReducer,
  memos: memoReducer,
  team: teamReducer,
  projectProfile : projectProfileReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOG_OUT") {
    state = undefined;
    sessionStorage.setItem("logout-status", "logout");
  }
  return allReducers(state, action);
};

export default rootReducer;
