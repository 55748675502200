export const companyColumns = [
  {
    title: "ID",
    dataIndex: "company_id",
    defaultSortOrder: "descend",
     sorter: (a, b) => a.company_id - b.company_id,
  },
  {
    title: "Name",
    dataIndex: "name",
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: "Name in Japnese",
    dataIndex: "name_in_japanese",
    sorter: (a, b) => a.name_in_japanese.localeCompare(b.name_in_japanese),
  },
  {
    title: "Industry",
    dataIndex: "industry",
    sorter: (a, b) => a.industry.localeCompare(b.industry),
  },
  {
    title: "Telephone",
    dataIndex: "tel1",
    defaultSortOrder: "descend",
    sorter: (a, b) => a.tel1 - b.tel1,
  },
  {
    title: "Email",
    dataIndex: "email1",
    sorter: (a, b) => a.email1.localeCompare(b.email1),
  },
  {
    title: "Country",
    dataIndex: "country",
    sorter: (a, b) => a.country.localeCompare(b.country),
  },
  {
    title: "Source",
    dataIndex: "source",
    sorter: (a, b) => a.source.localeCompare(b.source),
  },
];

export const companyColumnsHome = [
  {
    title: "ID",
    dataIndex: "company_id",
    sorter: (a, b) => a.company_id - b.company_id,
  },
  {
    title: "名前",
    dataIndex: "name",
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: "日本語での名前",
    dataIndex: "name_in_japanese",
    sorter: (a, b) => a.name_in_japanese.localeCompare(b.name_in_japanese),
  },
  {
    title: "業種",
    dataIndex: "industry",
    sorter: (a, b) => a.industry.localeCompare(b.industry),
  },
  {
    title: "電話",
    dataIndex: "tel1",
    sorter: (a, b) => a.tel1 - b.tel1,
  },

  {
    title: "メール",
    dataIndex: "email1",
    sorter: (a, b) => a.email1.localeCompare(b.email1),
  },
  {
    title: "国",
    dataIndex: "country",
    sorter: (a, b) => a.country.localeCompare(b.country),
  },
  {
    title: "ソース",
    dataIndex: "source",
    sorter: (a, b) => a.source.localeCompare(b.source),
  },
];
