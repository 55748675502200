// import {
//   FaHome,
//   FaBuilding,
//   FaUserFriends,
//   FaChartBar,
//   FaTasks,
//   FaClipboard,
//   FaClipboardList,
//   FaRegEnvelope,
//   FaRegListAlt,
//   FaUserAlt,
//   FaUsers,
//   FaRegFileExcel,
// } from "react-icons/fa";
// import React, { useState } from "react";
// import { Menu } from "antd";
// import { BiMenu } from "react-icons/bi";
// import "./style.css";
// import { useNavigate } from "react-router-dom";
// import useTranslation from "../customHooks/translations";
// import { MenuOutlined } from "@ant-design/icons";
// import { useEffect } from "react";
// import { IoMdSettings } from "react-icons/io";

// function Sidebar({ sidebarToggle, setSidebarToggle }) {
//   let navigate = useNavigate();

//   const translation = useTranslation();

//   const [items] = useState([
//     {
//       items: [
//         {
//           title: "Dashboard",
//           navTitle: "Dashboard",
//           icon: <FaHome size={20} />,
//         },
//         {
//           title: "Sector Board",
//           navTitle: "Sector Board",
//           icon: <FaHome size={20} />,
//         },
//       ],
//     },
//     {
//       title: "Menu 1",
//       items: [
//         {
//           title: "Company",
//           navTitle: "Company",
//           icon: <FaBuilding size={20} />,
//         },
//         {
//           title: "Client",
//           navTitle: "Client",
//           icon: <FaUserFriends size={20} />,
//         },
//         {
//           title: "Project",
//           navTitle: "Project",
//           icon: <FaChartBar size={20} />,
//         },
//       ],
//     },
//     {
//       title: "Menu 2",
//       items: [
//         {
//           title: "Tasks",
//           navTitle: "Tasks",
//           icon: <FaTasks size={20} />,
//         },
//         {
//           title: "Notes",
//           navTitle: "Notes",
//           icon: <FaClipboard size={20} />,
//         },
//         {
//           title: "Meetings",
//           navTitle: "Meetings",
//           icon: <FaClipboardList size={20} />,
//         },
//         {
//           title: "Email",
//           navTitle: "Email",
//           icon: <FaRegEnvelope size={20} />,
//         },
//         {
//           title: "Quick Memos",
//           navTitle: "Quick Memos",
//           icon: <FaRegListAlt size={20} />,
//         },
//       ],
//     },
//     {
//       title: "Menu 3",
//       items: [
//         {
//           title: "Users",
//           navTitle: "Users",
//           icon: <FaUserAlt size={20} />,
//         },
//         {
//           title: "Employees",
//           navTitle: "Employees",
//           icon: <FaUsers size={20} />,
//         },
//         {
//           title: "Teams",
//           navTitle: "Teams",
//           icon: <FaChartBar size={20} />,
//         },
//         {
//           title: "Import Contacts",
//           navTitle: "Import Contacts",
//           icon: <FaRegFileExcel size={20} />,
//         },
//       ],
//     },
//   ]);

//   const [items1] = useState([
//     {
//       items: [
//         {
//           title: "ダッシュボード",
//           icon: <FaHome size={20} />,
//           navTitle: "Dashboard",
//         },
//         {
//           title: "セクター掲示板",
//           icon: <FaHome size={20} />,
//           navTitle: "Sector Board",
//         },
//       ],
//     },
//     {
//       title: "Menu 1",
//       items: [
//         {
//           title: "会社",
//           icon: <FaBuilding size={20} />,
//           navTitle: "Company",
//         },
//         {
//           title: "クライアント",
//           icon: <FaUserFriends size={20} />,
//           navTitle: "Client",
//         },
//         {
//           title: "プロジェクト",
//           icon: <FaChartBar size={20} />,
//           navTitle: "Project",
//         },
//       ],
//     },
//     {
//       title: "Menu 2",
//       items: [
//         {
//           title: "タスク",
//           icon: <FaTasks size={20} />,
//           navTitle: "Tasks",
//         },
//         {
//           title: "備考",
//           icon: <FaClipboard size={20} />,
//           navTitle: "Notes",
//         },
//         {
//           title: "ミーティング",
//           icon: <FaClipboardList size={20} />,
//           navTitle: "Meetings",
//         },
//         {
//           title: "Eメール",
//           icon: <FaRegEnvelope size={20} />,
//           navTitle: "Email",
//         },
//         {
//           title: "クイックメモ",
//           icon: <FaRegListAlt size={20} />,
//           navTitle: "Quick Memos",
//         },
//       ],
//     },
//     {
//       title: "Menu 3",
//       items: [
//         {
//           title: "ユーザー",
//           icon: <FaUserAlt size={20} />,
//           navTitle: "Users",
//         },
//         {
//           title: "社員",
//           icon: <FaUsers size={20} />,
//           navTitle: "Employees",
//         },
//         {
//           title: "チーム",
//           icon: <FaChartBar size={20} />,
//           navTitle: "Teams",
//         },
//         {
//           title: "連絡先のインポート",
//           icon: <FaRegFileExcel size={20} />,
//           navTitle: "Import Contacts",
//         },
//       ],
//     },
//   ]);

//   const menuItemsHandler = (items) => {
//     return (
//       <Menu style={{ width: "100%" }} mode="vertical" theme="light">
//         {items?.map((item, i) => {
//           const { title, icon, navTitle } = item;
//           return (
//             <Menu.Item
//               key={i}
//               icon={icon}
//               onClick={() => linkHandler(navTitle)}
//               className="sidebar-item"
//             >
//               {title}
//             </Menu.Item>
//           );
//         })}
//       </Menu>
//     );
//   };

//   const linkHandler = (navTitle) => {
//     let path = "/" + navTitle.toLowerCase().replaceAll(" ", "");
//     navigate(path);
//   };
//   useEffect(() => {
//     let toggle = document.querySelector(".header-toggle");
//     let submenu = document.querySelector("#nav-bar");
//     let container = document.querySelector(".sidebar-container");

//     toggle.addEventListener("click", function () {
//       submenu.classList.toggle("hide");
//       container.classList.toggle("hide");

//     });
//   }, []);

//   return (
//     <div>
//       <div
//         className="sidebar-header hide bg-1572e8 d-flex align-items-center px-3 justify-content-between"
//         id="nav-bar"
//       >
//         <img
//           src="/images/jaim_logo.png"
//           className="img-logo"
//           style={{ padding: "5px 10px 10px 10px" }}
//           alt="logo"
//         />

//         <MenuOutlined
//           size={25}
//           cursor="pointer"
//           className="me-2 sidebar-menu-icon header-toggle"
//           id="header-toggle"
//           style={{ color: "#fff" }}
//         />
//         {/* <IoMdSettings className="collapse-icon header-toggle" /> */}
//       </div>

//       {translation._language === "en" ? (
//         <div className="sidebar-container hide">
//           {items?.map((item, index) => {
//             const { title, items } = item;
//             return (
//               <div key={index}>
//                 {" "}
//                 <p className="fs-14 pb-0 text-uppercase px-3 pt-2 mt-1 pb-1 mb-0">
//                   {title}
//                 </p>
//                 {menuItemsHandler(items)}
//               </div>
//             );
//           })}
//         </div>
//       ) : (
//         <div className="sidebar-container hide">
//           {items1?.map((item, index) => {
//             const { title, items } = item;
//             return (
//               <div key={index}>
//                 <p className="fs-14 pb-0 text-uppercase px-3 pt-4 mt-1 pb-1 mb-0">
//                   {title}
//                 </p>
//                 {menuItemsHandler(items)}
//               </div>
//             );
//           })}
//         </div>
//       )}
//     </div>
//   );
// }

// export default Sidebar;

import {
  FaHome,
  FaBuilding,
  FaUserFriends,
  FaChartBar,
  FaTasks,
  FaClipboard,
  FaClipboardList,
  FaRegEnvelope,
  FaRegListAlt,
  FaUserAlt,
  FaUsers,
  FaRegFileExcel,
} from "react-icons/fa";
import React, { useState } from "react";
import { Menu } from "antd";
import { BiMenu } from "react-icons/bi";
import "./style.css";
import { useNavigate } from "react-router-dom";
import useTranslation from "../customHooks/translations";
import { MenuOutlined } from "@ant-design/icons";

function Sidebar({ sidebarToggle, setSidebarToggle }) {
  let navigate = useNavigate();

  const translation = useTranslation();

  const [items] = useState([
    {
      items: [
        {
          title: "Dashboard",
          navTitle: "Dashboard",
          icon: <FaHome size={20} />,
        },
        {
          title: "Sector Board",
          navTitle: "Sector Board",
          icon: <FaHome size={20} />,
        },
      ],
    },
    {
      title: "Menu 1",
      items: [
        {
          title: "Company",
          navTitle: "Company",
          icon: <FaBuilding size={20} />,
        },
        {
          title: "Client",
          navTitle: "Client",
          icon: <FaUserFriends size={20} />,
        },
        {
          title: "Project",
          navTitle: "Project",
          icon: <FaChartBar size={20} />,
        },
      ],
    },
    {
      title: "Menu 2",
      items: [
        {
          title: "Tasks",
          navTitle: "Tasks",
          icon: <FaTasks size={20} />,
        },
        {
          title: "Notes",
          navTitle: "Notes",
          icon: <FaClipboard size={20} />,
        },
        {
          title: "Meetings",
          navTitle: "Meetings",
          icon: <FaClipboardList size={20} />,
        },
        {
          title: "Email",
          navTitle: "Email",
          icon: <FaRegEnvelope size={20} />,
        },
        {
          title: "Quick Memos",
          navTitle: "Quick Memos",
          icon: <FaRegListAlt size={20} />,
        },
      ],
    },
    {
      title: "Menu 3",
      items: [
        {
          title: "Users",
          navTitle: "Users",
          icon: <FaUserAlt size={20} />,
        },
        {
          title: "Employees",
          navTitle: "Employees",
          icon: <FaUsers size={20} />,
        },
        {
          title: "Teams",
          navTitle: "Teams",
          icon: <FaChartBar size={20} />,
        },
        {
          title: "Import Contacts",
          navTitle: "Import Contacts",
          icon: <FaRegFileExcel size={20} />,
        },
      ],
    },
  ]);

  const [items1] = useState([
    {
      items: [
        {
          title: "ダッシュボード",
          icon: <FaHome size={20} />,
          navTitle: "Dashboard",
        },
        {
          title: "セクター掲示板",
          icon: <FaHome size={20} />,
          navTitle: "Sector Board",
        },
      ],
    },
    {
      title: "Menu 1",
      items: [
        {
          title: "会社",
          icon: <FaBuilding size={20} />,
          navTitle: "Company",
        },
        {
          title: "クライアント",
          icon: <FaUserFriends size={20} />,
          navTitle: "Client",
        },
        {
          title: "プロジェクト",
          icon: <FaChartBar size={20} />,
          navTitle: "Project",
        },
      ],
    },
    {
      title: "Menu 2",
      items: [
        {
          title: "タスク",
          icon: <FaTasks size={20} />,
          navTitle: "Tasks",
        },
        {
          title: "備考",
          icon: <FaClipboard size={20} />,
          navTitle: "Notes",
        },
        {
          title: "ミーティング",
          icon: <FaClipboardList size={20} />,
          navTitle: "Meetings",
        },
        {
          title: "Eメール",
          icon: <FaRegEnvelope size={20} />,
          navTitle: "Email",
        },
        {
          title: "クイックメモ",
          icon: <FaRegListAlt size={20} />,
          navTitle: "Quick Memos",
        },
      ],
    },
    {
      title: "Menu 3",
      items: [
        {
          title: "ユーザー",
          icon: <FaUserAlt size={20} />,
          navTitle: "Users",
        },
        {
          title: "社員",
          icon: <FaUsers size={20} />,
          navTitle: "Employees",
        },
        {
          title: "チーム",
          icon: <FaChartBar size={20} />,
          navTitle: "Teams",
        },
        {
          title: "連絡先のインポート",
          icon: <FaRegFileExcel size={20} />,
          navTitle: "Import Contacts",
        },
      ],
    },
  ]);

  const menuItemsHandler = (items) => {
    return (
      <Menu style={{ width: "100%" }} mode="vertical" theme="light">
        {items?.map((item, i) => {
          const { title, icon, navTitle } = item;
          return (
            <Menu.Item
              key={i}
              icon={icon}
              onClick={() => linkHandler(navTitle)}
              className="sidebar-item"
            >
              {title}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  const linkHandler = (navTitle) => {
    let path = "/" + navTitle.toLowerCase().replaceAll(" ", "");
    navigate(path);
  };

  return (
    <div>
      <div
        className={`${
          sidebarToggle ? "show" : ""
        } sidebar-header bg-1572e8 d-flex align-items-center px-3 justify-content-between`}
      >
        <img
          src="/images/jaim_logo.png"
          className="img-logo"
          style={{ padding: "5px 10px 10px 10px" }}
          alt="logo"
        />

        <MenuOutlined
          size={25}
          cursor="pointer"
          className="me-2 sidebar-menu-icon"
          style={{ color: "#fff" }}
          onClick={() => setSidebarToggle((sidebarToggle) => !sidebarToggle)}
        />

        {/*         
        <BiMenu
          size={25}
          cursor="pointer"
          className="me-2 sidebar-menu-icon"
          fill="#fff"
          onClick={() => setSidebarToggle((sidebarToggle) => !sidebarToggle)}
        /> */}
      </div>

      {translation._language === "en" ? (
        <div
          className={`sidebar-container ${
            sidebarToggle ? "show" : ""
          } bg-white`}
        >
          {items?.map((item, index) => {
            const { title, items } = item;
            return (
              <div key={index}>
                <p className="fs-14 pb-0 text-uppercase px-3 pt-2 mt-1 pb-1 mb-0">
                  {title}
                </p>
                {menuItemsHandler(items)}
              </div>
            );
          })}
        </div>
      ) : (
        <div
          className={`sidebar-container ${
            sidebarToggle ? "show" : ""
          } bg-white`}
        >
          {items1?.map((item, index) => {
            const { title, items } = item;
            return (
              <div key={index}>
                <p className="fs-14 pb-0 text-uppercase px-3 pt-4 mt-1 pb-1 mb-0">
                  {title}
                </p>
                {menuItemsHandler(items)}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default Sidebar;
