export const quickMemosForm = [
  {
    id: 1,
    type: "text",
    key: "memo",
    label: "Memo",
    placeholder: "Memo",
    containerClass: "col-12",
    required: true,
  },
];

export const quickMemosFormJap = [
  {
    id: 1,
    type: "text",
    key: "memo",
    label: "メモ",
    placeholder: "メモ",
    containerClass: "col-12",
    required: true,
  },
];
