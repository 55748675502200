import React from "react";
import { useLanguageContext } from "./contexts/LanguageContext";
import useTranslation from "./customHooks/translations";

export default function LanguageHandler() {
  const { language, changeLanguage } = useLanguageContext();
  const translation = useTranslation();

  const handleSel = () => {
    localStorage.setItem("lan", "en");
    if (language === "ja") {
      localStorage.removeItem("lan");
    }

    if (language === "en") {
      changeLanguage("ja");
    } else {
      changeLanguage("en");
    }
  };

  return (
    <>
      <button
        style={{}}
        className="btn btn-outline-info add-btn language-btn"
        onClick={() => handleSel()}
      >
        {language === "en" ? translation.japanese : translation.english}
      </button>
    </>
  );
}
