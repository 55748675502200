import React, { useEffect, useState } from "react";
import Heading from "../Heading";
import TableCard from "../TableCard";
import CardContainer from "../Card/cardContainer";
import { addActionColumn } from "../../helpers/companyHelpers";
import {
  quickMemosForm,
  quickMemosFormJap,
} from "../../Data/FormsLayout/quickMemosForm";
import {
  formModalController,
  modalInitialValue,
} from "../../helpers/commonHelpers";
import ModalContainer from "../Modal";
import {
  addHandler,
  deleteHandler,
  updateHandler,
} from "../../apiParsing/data";
import { apipaths } from "../../services/apiPaths";
import { useDispatch, useSelector } from "react-redux";
import { memoListAction } from "../actions/memoListAction";
import { Link } from "react-router-dom";
import { BsFillPencilFill, BsFillTrashFill } from "react-icons/bs";
import useTranslation from "../customHooks/translations";
import moment from "moment";

function QuickMemos() {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(modalInitialValue);
  const { memos } = useSelector((state) => state.memos);
  const translation = useTranslation();

  useEffect(() => {
    dispatch(memoListAction());
  }, []);

  const quickMemosColumns = [
    {
      title: "ID",
      dataIndex: "memo_id",
      sorter: (a, b) => a.memo_id - b.memo_id,
    },
    {
      title: "Memo",
      dataIndex: "memo",
      sorter: (a, b) => a.memo.localeCompare(b.memo),
      render: (d, data) => {
        return (
          <>
            <label
              style={{ color: "#1890ff" }}
              onClick={() => {
                dataTest(data);
              }}
            >
              {data.memo}
            </label>
          </>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
    },
    {
      title: translation.action,
      dataIndex: "action",
      render: (data) => (
        <div>
          {" "}
          <span>
            <Link to="/quickmemos/form/edit">
              <BsFillPencilFill className="table-icons edit-icon text-warning" />
            </Link>
            <BsFillTrashFill className="table-icons delete-icon text-danger" />
          </span>{" "}
        </div>
      ),
    },
  ];

  const Ja = [
    {
      title: "ID",
      dataIndex: "memo_id",
      sorter: (a, b) => a.memo_id - b.memo_id,
    },
    {
      title: "メモ",
      dataIndex: "memo",
      sorter: (a, b) => a.memo.localeCompare(b.memo),
      render: (d, data) => {
        return (
          <>
            <label
              style={{ color: "#1890ff" }}
              onClick={() => {
                dataTest(data);
              }}
            >
              {data.memo}
            </label>
          </>
        );
      },
    },
    {
      title: "日付",
      dataIndex: "date",
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
    },
  ];

  const dataTest = (data) => {
    formModalController(
      setModal,
      quickMemosForm,
      quickMemosFormJap,
      `${translation.edit_quick_memos}`,
      `edit-quick-memo-form`,
      data,
      (data) =>
        updateHandler(
          apipaths.addMemo,
          data,
          "memo_id",
          setModal,
          dispatch,
          memoListAction
        )
    );
  };

  return (
    <>
      <ModalContainer
        isOpen={modal.open}
        title={modal.title}
        closeIcon={() => setModal(modalInitialValue)}
        content={modal.content}
        footer={false}
      />
      <div className="main-container">
        <Heading title={translation.quick_memos} />
        <div className="content-wrapper px-5">
          <CardContainer hideTotal={true} />
        </div>

        <div className="px-5">
          <TableCard
            button={{
              title: translation.add_memo,
              onClick: () =>
                formModalController(
                  setModal,
                  quickMemosForm,
                  quickMemosFormJap,
                  `${translation.add_quick_memos}`,
                  `add-quick-memo-form`,
                  {},
                  (data) =>
                    addHandler(
                      apipaths.addMemo,
                      data,
                      setModal(modalInitialValue),
                      dispatch,
                      memoListAction
                    )
                ),
            }}
            jaCol={addActionColumn(
              {},
              Ja,
              (data) =>
                formModalController(
                  setModal,
                  quickMemosForm,
                  quickMemosFormJap,
                  `${translation.edit_quick_memos}`,
                  `edit-quick-memo-form`,
                  data,
                  (data) =>
                    updateHandler(
                      apipaths.addMemo,
                      data,
                      "memo_id",
                      setModal,
                      dispatch,
                      memoListAction
                    )
                ),
              (data) =>
                deleteHandler(
                  apipaths.deleteMemo,
                  data?.["memo_id"],
                  setModal,
                  dispatch,
                  memoListAction
                ),
              translation._language
            )}
            columns={addActionColumn(
              {},
              quickMemosColumns,
              (data) =>
                formModalController(
                  setModal,
                  quickMemosForm,
                  quickMemosFormJap,
                  `${translation.edit_quick_memos}`,
                  `edit-quick-memo-form`,
                  data,
                  (data) =>
                    updateHandler(
                      apipaths.addMemo,
                      data,
                      "memo_id",
                      setModal,
                      dispatch,
                      memoListAction
                    )
                ),
              (data) =>
                deleteHandler(
                  apipaths.deleteMemo,
                  data?.["memo_id"],
                  setModal,
                  dispatch,
                  memoListAction
                ),
              translation._language
            )}
            data={memos}
            title={translation.quick_memos}
          />
        </div>
      </div>
    </>
  );
}

export default QuickMemos;
