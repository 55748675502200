import React, { useEffect, useState } from "react";
import Heading from "../Heading";
import TableCard from "../TableCard";
import { taskFormLayout } from "../../Data/FormsLayout/taskFormLayout";
import { SubTaskFormLayout } from "../../Data/FormsLayout/subTaskFormLayout";
import CardContainer from "../Card/cardContainer";
import { useDispatch, useSelector } from "react-redux";
import { taskListAction } from "../actions/taskListAction";
import ModalContainer from "../Modal";
import {
  formatDate,
  formatDateList,
  modalInitialValue,
  multiTabFormModalController,
} from "../../helpers/commonHelpers";
import { apipaths } from "../../services/apiPaths";
import { addActionColumn } from "../../helpers/companyHelpers";
import { sendRequest } from "../../services";
import Swal from "sweetalert2";
import { Dropdown, Menu } from "antd";
import { BarsOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { FaClipboardList, FaPen } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import useTranslation from "../customHooks/translations";
import moment from "moment";

function Task() {
  const [modal, setModal] = useState(modalInitialValue);

  const dispatch = useDispatch();
  const { tasks } = useSelector((state) => state.task);

  useEffect(() => {
    dispatch(taskListAction());
  }, []);

  const navigate = useNavigate();
  const translation = useTranslation();

  const selectedCompanyName = "";

  const onSubmit = async (formdata) => {
    let new_task_details = { ...formdata };
    new_task_details = {
      ...new_task_details,
      startdate: formatDateList(formdata.startdate),
      OPtype: "Task",
      operation: "add",
      task_type: "keep",
    };

    const {
      success,
      data: { message },
    } = await sendRequest(apipaths.addTask, new_task_details);
    if (success) {
      dispatch(taskListAction());
      Swal.fire({
        position: "center",
        icon: "success",
        title: message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  const taskColumns = [
    {
      title: "ID",
      dataIndex: "task_id",
      sorter: (a, b) => a.task_id - b.task_id,
      render: (id) => {
        return id;
      },
    },
    {
      title: "Subject",
      dataIndex: "subject",
      sorter: (a, b) => a?.subject?.localeCompare(b?.subject),
      render: (subject, data) => {
        return (
          <>
            <label
              style={{ color: "#1890ff" }}
              onClick={() => {
                navigate(`/edit-tasks/${data.task_id}`);
              }}
            >
              {subject}
            </label>
          </>
        );
      },
    },
    {
      title: "Start To End Date",
      dataIndex: "startdate",
      sorter: (a, b) =>
        moment(formatDateList(a.startdate)).unix() -
        moment(formatDateList(b.startdate)).unix(),
      render: (startdate, data) => {
        return (
          formatDateList(startdate) +
          " " +
          "to" +
          " " +
          formatDateList(data.enddate)
        );
      },
    },
    {
      title: "Priority",
      dataIndex: "priority",
      sorter: (a, b) => a?.priority?.localeCompare(b?.priority),
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
    },
    {
      title: "Company",
      dataIndex: "company_id",
      sorter: (a, b) => a.company_id - b.company_id,
      render: (company_id, row) => {
        let company_name = "";
        const company = row.company;
        if (company_id && company) company_name = company.name;
        return company_name;
      },
    },
    {
      title: "Client",
      dataIndex: "client_id",
      sorter: (a, b) => a.client_id - b.client_id,
      render: (client_id, row) => {
        const client = row["client"];
        if (client) return client.first_name + " " + client.last_name;
        else return "";
      },
    },
    {
      title: "Projects",
      dataIndex: "project_id",
      sorter: (a, b) => a.project_id - b.project_id,
      render: (project_id, row) => {
        const project = row["project"];
        if (project) return project.name;
        else return "";
      },
    },
    {
      title: "Assignee",
      dataIndex: "employee_id",
      sorter: (a, b) => a.employee_id - b.employee_id,
      render: (employee_id, row) => {
        const employee = row["employee"];
        if (employee) return employee.first_name + " " + employee.last_name;
        else return "";
      },
    },
  ];

  const taskColumnsHome = [
    {
      title: "ID",
      dataIndex: "task_id",
      sorter: (a, b) => a.task_id - b.task_id,
      render: (id) => {
        return id;
      },
    },
    {
      title: "主題",
      dataIndex: "subject",
      sorter: (a, b) => a?.subject?.localeCompare(b?.subject),
      render: (d, data) => {
        return (
          <>
            <label
              style={{ color: "#1890ff" }}
              onClick={() => {
                navigate(`/edit-tasks/${data.task_id}`);
              }}
            >
              {data.subject}
            </label>
          </>
        );
      },
    },
    {
      title: "開始日から終了日まで",
      dataIndex: "startdate",
      sorter: (a, b) =>
        moment(formatDateList(a.startdate)).unix() -
        moment(formatDateList(b.startdate)).unix(),
      render: (startdate, data) => {
        return (
          formatDateList(startdate) +
          " " +
          "to" +
          " " +
          formatDateList(data.enddate)
        );
      },
    },
    {
      title: "優先順位",
      dataIndex: "priority",
      sorter: (a, b) => a?.priority?.localeCompare(b?.priority),
    },
    {
      title: "状態",
      dataIndex: "status",
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
    },
    {
      title: "会社",
      dataIndex: "company_id",
      sorter: (a, b) => a.company_id - b.company_id,
      render: (company_id, row) => {
        let company_name = "";
        const company = row.company;
        if (company_id && company) company_name = company.name;
        return company_name;
      },
    },
    {
      title: "クライアント",
      dataIndex: "client_id",
      sorter: (a, b) => a.client_id - b.client_id,
      render: (client_id, row) => {
        const client = row["client"];
        if (client) return client.first_name + " " + client.last_name;
        else return "";
      },
    },
    {
      title: "プロジェクト",
      dataIndex: "project_id",
      sorter: (a, b) => a.project_id - b.project_id,
      render: (project_id, row) => {
        const project = row["project"];
        if (project) return project.name;
        else return "";
      },
    },
    {
      title: "譲受人",
      dataIndex: "employee_id",
      sorter: (a, b) => a.employee_id - b.employee_id,
      render: (employee_id, row) => {
        const employee = row["employee"];
        if (employee) return employee.first_name + " " + employee.last_name;
        else return "";
      },
    },
    {
      title: "Action",
      dataIndex: "task_id",
      render: (task_id, index) => (
        <div key={index}>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key={1}>
                  <Link to={`/edit-tasks/${task_id}`}>
                    <div className="d-flex align-items-center">
                      <FaPen className="text-warning" />
                      <span className="ms-2">Edit</span>
                    </div>
                  </Link>
                </Menu.Item>

                <Menu.Item key={2}>
                  {/* <Link to="/tasks"> */}
                  <div
                    className="d-flex align-items-center"
                    onClick={() => deleteTaskHandler(task_id)}
                  >
                    <FaClipboardList className="text-danger" />
                    <span className="ms-2">Delete</span>
                  </div>
                  {/* </Link> */}
                </Menu.Item>
              </Menu>
            }
            placement="bottom"
            className="action-button"
          >
            <BarsOutlined />
          </Dropdown>
        </div>
      ),
    },
  ];

  const updateTaskHandler = async (task) => {
    task["id"] = task.task_id;
    delete task.project_id;
    delete task.enable;
    delete task.company;
    delete task.client;
    delete task.created_at;
    delete task.updated_at;
    delete task.user_id;
    delete task.edate;
    delete formatDate(task.startdate);
    delete task.status;
    delete task.employee;
    delete task.company_project_profile_id;

    task["operation"] = "update";
    task["task_type"] = "update";
    task["OPtype"] = "Task";
    task["startdate"] = formatDate(task.startdate);
    const {
      data: { message },
      success,
    } = await sendRequest(apipaths.editTask, task);
    if (success) {
      dispatch(taskListAction());
      setModal(modalInitialValue);
      Swal.fire({
        position: "center",
        icon: "success",
        title: message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const deleteTaskHandler = (task_id) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#1890ff",
    }).then(async ({ isConfirmed }) => {
      if (!isConfirmed) return null;

      const { data } = await sendRequest(apipaths.deleteTask, {
        delete_id: task_id,
      });
      const { success, message } = data[0].original;
      if (success === 1) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: message,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch(taskListAction());
      }
    });
  };

  return (
    <>
      <ModalContainer
        isOpen={modal.open}
        title={modal.title}
        closeIcon={() => setModal(modalInitialValue)}
        content={modal.content}
        footer={false}
      />
      <div className="main-container">
        <Heading title={translation.task} />
        <div className="content-wrapper px-5">
          <CardContainer hideTotal={true} />
        </div>

        <div className="px-5">
          <TableCard
            button={{
              title: translation.add_task,
              onClick: () => navigate("/tasks/form/add"),
            }}
            columns={addActionColumn(
              { type: `dropdown`, name: `tasksHome-dropdown` },
              taskColumns,
              (data) =>
                multiTabFormModalController(
                  setModal,
                  selectedCompanyName,
                  { task: taskFormLayout, subTask: SubTaskFormLayout },
                  { title1: "Tasks", title2: "Sub Task" },
                  `add-task-form`,
                  data,
                  (data) => updateTaskHandler(data)
                ),
              (task_id) => deleteTaskHandler(task_id),
              translation._language
            )}
            jaCol={addActionColumn(
              { type: `dropdown`, name: `tasksHome-dropdown` },
              taskColumnsHome,
              (data) =>
                multiTabFormModalController(
                  setModal,
                  selectedCompanyName,
                  { task: taskFormLayout, subTask: SubTaskFormLayout },
                  { title1: "Tasks", title2: "Sub Task" },
                  `add-task-form`,
                  data,
                  (data) => updateTaskHandler(data)
                ),
              (task_id) => deleteTaskHandler(task_id),
              translation._language
            )}
            data={tasks}
            title={translation.task}
          />
        </div>
      </div>
    </>
  );
}

export default Task;
