import { PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import "./style.css";
import { useSelector } from "react-redux";
import {
  formatDateList,
  formatDateMeet,
  formatDateToday,
} from "../../helpers/commonHelpers";
import useTranslation from "../customHooks/translations";

function Card({
  color,
  title,
  total,
  hideTotal,
  onClick,
  mainTotal,
  mainTitle,
  mainNav,
  Icon,
}) {
  const [projectData, setProjectData] = useState([]);
  const [taskData, setTaskData] = useState([]);
  const [meeting, setMeeting] = useState([]);

  const { tasks } = useSelector((state) => state.task);
  const { meet } = useSelector((state) => state.meet);
  const { projects } = useSelector((state) => state.project);

  const translation = useTranslation();

  const tomorrow = new Date(Date.now() + 24 * 60 * 60 * 1000);

  useEffect(() => {
    TodaysProject();
  }, [projects]);

  useEffect(() => {
    tomTaskAdd();
  }, [tasks]);

  useEffect(() => {
    tomMeetAdd();
  }, [meet]);

  let todaysProject = [];
  const TodaysProject = () => {
    projects &&
      projects.map((t) => {
        if (t.activity_status === "Prospective") {
          todaysProject.push(t);
        }
        setProjectData(todaysProject);
      });
  };

  let tomTask = [];
  const tomTaskAdd = () => {
    tasks &&
      tasks.map((t) => {
        if (formatDateList(t.startdate) === formatDateList(tomorrow)) {
          tomTask.push(t);
        }
        setTaskData(tomTask);
      });
  };

  let tomMeet = [];
  const tomMeetAdd = () => {
    meet &&
      meet.map((t) => {
        if (formatDateMeet(t.startdate) === formatDateMeet(tomorrow)) {
          tomMeet.push(t);
        }
        setMeeting(tomMeet);
      });
  };

  return (
    <div className="card-container p-3 my-3">
      <div className="d-flex justify-content-between">
        <div className="card-icon d-flex align-items-center">
          <Icon
            className="fs-18 card-main-icon"
            style={{ backgroundColor: color }}
            onClick={onClick.list}
          />
          <PlusOutlined
            className="fw-700"
            style={{ color }}
            onClick={onClick.form}
          />
        </div>
        <div className="text-right">
          <p className="mb-0 fs-12 fw-500 text-muted">{title}</p>
          <p className="mb-0 fs-22 fw-700" style={{ color }}>
            {total}
          </p>
        </div>
      </div>

      {hideTotal !== true && (
        <>
          <hr className="mt-4 mb-1" style={{ backgroundColor: "#dfdfdf" }} />
          <div className="text-right mt-2">
            <p className="mb-1 fs-12 text-muted">
              {mainTitle === translation.total_tasks
                ? `${taskData.length}` + " " + `${translation.tomorrows_tasks}`
                : ""}
              {mainTitle === translation.meetings
                ? `${meeting.length}` + " " + `${translation.tomorrow_meeting}`
                : ""}
              {mainTitle === translation.total_projects
                ? `${projectData.length}` +
                  " " +
                  `${translation.prospective_projects}`
                : ""}
            </p>
            <p className="mb-0 fs-12 text-muted">
              {mainTitle == translation.meetings ? "" : mainTotal}{" "}
              {translation.total}{" "}
              {mainTitle == translation.meetings ? "" : mainTitle}
            </p>
          </div>
        </>
      )}
    </div>
  );
}

export default Card;
