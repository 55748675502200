import { Layout, Tabs } from "antd";
import Swal from "sweetalert2";
import { taskListAction } from "../components/actions/taskListAction";
import FormContainer from "../components/Form";
import { sendRequest } from "../services";
import { apipaths } from "./../services/apiPaths";
import { createContext } from "react";
import { createDispatchHook, createSelectorHook } from "react-redux";

export const modalInitialValue = {
  open: false,
  content: <></>,
  title: "Modal data",
  key: "",
};

export const formModalController = (
  setModal,
  formlayout,
  FormLayoutJap,
  title,
  key,
  defaultData = {},
  onModalSubmit = null
) => {
  let btnTitle = title.split(" ")[0] === "Edit" ? "Update" : "Add";
  setModal({
    open: true,
    content: (
      <FormContainer
        layout={formlayout}
        FormLayoutJap={FormLayoutJap}
        onCancel={() => setModal(modalInitialValue)}
        onSubmit={(data) => {
          if (data.first_name) {
            if (
              !regName.test(data.first_name) ||
              (data.first_name.trim() === "" &&
                !regName.test(data.last_name)) ||
              data.last_name.trim() === ""
            ) {
              Swal.fire({
                position: "center",
                icon: "error",
                title: "Please Fill Out Name !",
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              onModalSubmit
                ? onModalSubmit(data) && setModal(modalInitialValue)
                : console.log("");
            }
          } else if (data.title) {
            if (!regName.test(data.title) || data.title.trim() === "") {
              Swal.fire({
                position: "center",
                icon: "error",
                title: "Please Fill Out title Name !",
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              onModalSubmit
                ? onModalSubmit(data) && setModal(modalInitialValue)
                : console.log("");
            }
          } else if (data.subject) {
            if (!regName.test(data.subject) || data.subject.trim() === "") {
              Swal.fire({
                position: "center",
                icon: "error",
                title: "Please Fill Out subject Name !",
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              onModalSubmit
                ? onModalSubmit(data) && setModal(modalInitialValue)
                : console.log("");
            }
          } else if (data.memo) {
            if (!regName.test(data.memo) || data.memo.trim() === "") {
              Swal.fire({
                position: "center",
                icon: "error",
                title: "Please Fill Out Memo !",
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              onModalSubmit
                ? onModalSubmit(data) && setModal(modalInitialValue)
                : console.log("");
            }
          } else if (data.name) {
            if (!regName.test(data.name) || data.name.trim() === "") {
              Swal.fire({
                position: "center",
                icon: "error",
                title: "Please Fill Out Name !",
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              onModalSubmit
                ? onModalSubmit(data) && setModal(modalInitialValue)
                : console.log("");
            }
          }
          else if (data.team_name) {
            if (!regName.test(data.team_name) || data.team_name.trim() === "") {
              Swal.fire({
                position: "center",
                icon: "error",
                title: "Please Fill Out Name !",
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              onModalSubmit
                ? onModalSubmit(data) && setModal(modalInitialValue)
                : console.log("");
            }
          }
        }}
        submitBtnTitle={btnTitle}
        defaultData={defaultData}
      />
    ),
    title,
    key,
  });
};

export const multiTabFormModalController = (
  setModal,
  selectedCompanyName,
  formlayout,
  title,
  key,
  defaultData = {},
  onModalSubmit = {
    onModalSubmit1: null,
    onModalSubmit2: null,
    onModalSubmit3: null,
  },
  id
) => {
  let btnTitle1 = title.title1.split(" ")[0] === "Edit" ? "Update" : "Add";
  let btnTitle2 = title.title2.split(" ")[0] === "Edit" ? "Update" : "Add";
  let btnTitle3 = title.title2.split(" ")[0] === "Edit" ? "Update" : "Add";

  const { TabPane } = Tabs;
  setModal({
    open: true,
    content: (
      <div>
        <div className="p-3">
          <p style={{ float: "right" }} className="d-flex justify-content-end">
            {selectedCompanyName}
          </p>
          <Tabs defaultActiveKey="1">
            <TabPane tab={title.title1} key="1">
              <div className="px-2">
                <FormContainer
                  layout={formlayout.task}
                  onCancel={() => setModal(modalInitialValue)}
                  onSubmit={(data) =>
                    onModalSubmit.onModalSubmit1
                      ? onModalSubmit
                      : onModalSubmit(data) && setModal(modalInitialValue)
                  }
                  submitBtnTitle={btnTitle1}
                  defaultData={defaultData}
                />
              </div>
            </TabPane>
            <TabPane tab={title.title2} key="2">
              <div className="px-2">
                <FormContainer
                  layout={formlayout.subTask}
                  onCancel={() => setModal(modalInitialValue)}
                  onSubmit={(data) =>
                    onModalSubmit.onModalSubmit2
                      ? onModalSubmit
                      : onModalSubmit(data) && setModal(modalInitialValue)
                  }
                  submitBtnTitle={btnTitle2}
                  defaultData={defaultData}
                />
              </div>
            </TabPane>
            {title.title3 && (
              <TabPane tab={title.title3} key="3">
                <div className="px-2">
                  <FormContainer
                    layout={formlayout.subTask2}
                    onCancel={() => setModal(modalInitialValue)}
                    onSubmit={(data) =>
                      onModalSubmit.onModalSubmit3
                        ? onModalSubmit
                        : console.log("")
                    }
                    submitBtnTitle={btnTitle3}
                    defaultData={defaultData}
                  />
                </div>
              </TabPane>
            )}
          </Tabs>
        </div>
        <hr />
        <div className="pt-1 pb-4 px-3">{/* form container */}</div>
      </div>
    ),
    title: "",
    key,
  });
};

export const addPrefexToObjectProperty = (prefix, dataObject = []) => {
  Object.keys(dataObject).map((property) => {
    dataObject[prefix + property] = dataObject[property];
    delete dataObject[property];
    return property;
  });

  return dataObject;
};

export const getSelectOptions = (arr, property, value) => {
  let result = [];
  arr.map((arr_obj) => {
    return result.push({ label: arr_obj[property], value: arr_obj[value] });
  });
  return result;
};

export const companyFormLayoutHandler = (arr, formlayout, key, value) => {
  let layout = formlayout.map((lay) => {
    if (lay.key === key) lay.options = arr; //getSelectOptions(arr, value, key);

    return lay;
  });
  return layout;
};

export const companyFormLayoutHandlerDrop = (arr, formlayout, key, value) => {
  let layout = formlayout.map((lay) => {
    if (lay.key === key) lay.options = getSelectOptions(arr, value, key);
    return lay;
  });
  return layout;
};

export function onSuccesshandler(setModal, dispatch, renderList, message) {
  // setModal(modalInitialValue);
  dispatch(renderList());
  Swal.fire({
    position: "center",
    icon: "success",
    title: message,
    showConfirmButton: false,
    timer: 1500,
  });
}

export const filterClientsHandler = (
  companyId,
  listData,
  setCompanyClients
) => {
  if (companyId) {
    let data =
      listData &&
      listData?.filter((client) => client.company_id == parseInt(companyId));
    setCompanyClients(data);
  } else setCompanyClients(listData);
};

export function nameAdded(arr = []) {
  arr.map((item) => {
    if (item?.first_name && item?.last_name) {
      return (item.name = item?.first_name + " " + item?.last_name);
    } else {
      return null;
    }
  });
}

export async function listingCommonDefaulter(arr, layout, keyArr) {
  arr.map((item, index) => (item.key = keyArr[index]));
  arr.map((item) => {
    nameAdded(item);
    return companyFormLayoutHandler(item, layout, item?.key, "name");
  });
}

export async function listingCommonDefaulterUser(arr, layout, keyArr) {
  arr.map((item, index) => (item.key = keyArr[index]));
  arr.map((item) => {
    nameAdded(item);
    return companyFormLayoutHandler(item, layout, item?.key, "role_name");
  });
}

export async function listingCommon(arr, layout, keyArr) {
  arr.map((item, index) => (item.key = keyArr[index]));
  arr.map((item) => {
    nameAdded(item);
    return companyFormLayoutHandler(item, layout, item?.key, "subject");
  });
}

export async function listingCommonTitle(arr, layout, keyArr) {
  arr.map((item, index) => (item.key = keyArr[index]));
  arr.map((item) => {
    nameAdded(item);
    return companyFormLayoutHandler(item, layout, item?.key, "title");
  });
}

export const resetMainForm = () => {
  document.getElementById("dynamic-form-container").reset();
};

export const getUserRole = (roleId) => {
  roleId = parseInt(roleId);
  switch (roleId) {
    case 1:
      return "Admin";
    case 2:
      return "Manager";
    case 3:
      return "Associate";
    default:
      return "";
  }
};

export const formatDate = (date) => {
  let d = new Date(date),
    month = "" + d.getMonth(),
    day = "" + d.getDate(),
    year = d.getFullYear(),
    dayName = d.getDay(),
    hours = d.getHours() || "00",
    minutes = d.getMinutes() || "00";

  if (day.length < 2) day = "0" + day;
  <div className="3"></div>;
  const months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  if (months.length < 2) months = "0" + months;

  return `${year}/${months[month]}/${day} ${hours}:${minutes}`;
};

export const formatDateList = (date) => {
  let d = new Date(date),
    month = "" + d.getMonth(),
    day = "" + d.getDate(),
    year = d.getFullYear(),
    dayName = d.getDay(),
    hours = "0" + d.getHours() || "00",
    minutes = d.getMinutes() || "00";

  if (day.length < 2) day = "0" + day;

  const months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  if (months.length < 2) months = "0" + months;

  return `${year}/${months[month]}/${day}`;
};

export const formatMeetingDate = (date) => {
  let d = new Date(date),
    month = "" + d.getMonth(),
    day = "" + d.getDate(),
    year = d.getFullYear(),
    dayName = d.getDay(),
    hours = "0" + d.getHours() || "00",
    minutes = d.getMinutes() || "00";

  if (day.length < 2) day = "0" + day;

  const months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  if (months.length < 2) months = "0" + months;

  return `${year}/${months[month]}/${day}`;
};

export const formatDateMeet = (date) => {
  let d = new Date(date),
    month = "" + d.getMonth(),
    day = "" + d.getDate(),
    year = d.getFullYear(),
    dayName = d.getDay(),
    hours = "" + d.getHours(),
    minutes = d.getMinutes(),
    sec = d.getSeconds();

  if (day.length < 2) day = "0" + day;

  if (sec.length < 2) sec = "0" + sec;

  const months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  if (months.length < 2) months = "0" + months;

  return `${year}-${months[month]}-${day}`;
};

export const formatEndDate = (date) => {
  let d = new Date(date),
    month = "" + d.getMonth(),
    day = "" + d.getDate(),
    year = d.getFullYear(),
    dayName = d.getDay(),
    hours = "" + d.getHours(),
    minutes = d.getMinutes();

  if (day.length < 2) day = "0" + day;

  const months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  if (months.length < 2) months = "0" + months;

  return `${year}/${months[month]}/${day} ${hours}:${minutes}`;
};

export const formatDateToday = (date) => {
  let d = new Date(date),
    month = "" + d.getMonth(),
    day = "" + d.getDate(),
    year = d.getFullYear(),
    dayName = d.getDay(),
    hours = "" + d.getHours(),
    minutes = d.getMinutes(),
    sec = d.getSeconds();

  if (day.length < 2) day = "0" + day;

  if (sec.length < 2) sec = "0" + sec;

  const months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  if (months.length < 2) months = "0" + months;

  return `${year}-${months[month]}-${day} ${hours}:${minutes}:${sec}`;
};

export const deleteProjectHandler = (project_id) => {
  Swal.fire({
    title: "Are you sure?",
    icon: "question",
    showCancelButton: true,
    confirmButtonText: "Delete",
    confirmButtonColor: "#1890ff",
  }).then(async ({ isConfirmed }) => {
    if (!isConfirmed) return null;

    const { data } = await sendRequest(apipaths.deleteProject, {
      delete_id: project_id,
    });
    const { success, message } = data[0].original;
    if (success === 1) {
      Swal.fire({
        position: "center",
        icon: "success",
        title: message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  });
};

export const fetchData = () => {
  return fetch(apipaths.taskList)
    .then((response) => response.json())
    .then((data) => console.log(""));
};

export const deleteTaskHandler = (task_id) => {
  Swal.fire({
    title: "Are you sure?",
    icon: "question",
    showCancelButton: true,
    confirmButtonText: "Delete",
    confirmButtonColor: "#1890ff",
  }).then(async ({ isConfirmed }) => {
    if (!isConfirmed) return null;

    const { data } = await sendRequest(apipaths.deleteTask, {
      delete_id: task_id,
    });

    const { success, message } = data[0].original;
    if (success === 1) {
      Swal.fire({
        position: "center",
        icon: "success",
        title: message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  });
};

export function trim(StringtoTrim) {
  StringtoTrim.replace(/^\s\s*/, "");
}

export const regName = /^(?![\s-])[\w\s-]+$/;
