export const notesFormLayout = [
  {
    id: 1,
    label: "Title",
    type: "text",
    placeholder: "Title",
    key: "title",
    containerClass: "col-sm-12 col-md-4 col-lg-8",
    required: true,
  },
  {
    id: 2,
    label: "Document",
    type: "file",
    key: "document",
    containerClass: "col-sm-12 col-md-4 col-lg-4",
  },
  {
    id: 3,
    label: "Employee",
    type: "select",
    placeholder: "Select Employee",
    key: "employee_id",
    containerClass: "col-sm-12 col-md-4 col-lg-4",
    options: [
      {
        label: "Priority 1",
        value: "pri1",
      },
      {
        label: "Priority 2",
        value: "pri2",
      },
    ],
  },
  // {
  //   id: 4,
  //   label: "Company",
  //   type: "select",
  //   placeholder: "Select Company",
  //   key: "company_id",
  //   containerClass: "col-sm-12 col-md-4 col-lg-4",
  //   options: [
  //     {
  //       label: "Priority 1",
  //       value: "pri1",
  //     },
  //     {
  //       label: "Priority 2",
  //       value: "pri2",
  //     },
  //   ],
  // },
  {
    id: 5,
    label: "Client",
    type: "select",
    placeholder: "Select Client",
    key: "client_id",
    containerClass: "col-sm-12 col-md-4 col-lg-4",
    options: [
      {
        label: "Priority 1",
        value: "pri1",
      },
      {
        label: "Priority 2",
        value: "pri2",
      },
    ],
  },
  {
    id: 6,
    label: "Project",
    type: "select",
    placeholder: "Select Project",
    key: "project_id",
    containerClass: "col-sm-12 col-md-4 col-lg-4",
    options: [
      {
        label: "Priority 1",
        value: "pri1",
      },
      {
        label: "Priority 2",
        value: "pri2",
      },
    ],
  },

  {
    id: 7,
    label: "Entry Date",
    type: "date",
    placeholder: "Select Entry Date",
    key: "date",
    containerClass: "col-sm-12 col-md-4 col-lg-4",
  },
  {
    id: 8,
    label: "Description",
    type: "textarea",
    placeholder: "Description",
    rows: 12,
    key: "desc",
    containerClass: "col-sm-12 col-md-4 col-lg-12",
  },
];

export const notesFormLayoutJap = [
  {
    id: 1,
    label: "題名",
    placeholder: "題名",
    type: "text",
    key: "title",
    containerClass: "col-sm-12 col-md-4 col-lg-8",
    required: true,
  },
  {
    id: 2,
    label: "書類",
    placeholder: "書類",
    type: "file",
    key: "document",
    containerClass: "col-sm-12 col-md-4 col-lg-4",
  },
  {
    id: 3,
    label: "社員",
    placeholder: "社員",
    type: "select",
    key: "employee_id",
    containerClass: "col-sm-12 col-md-4 col-lg-4",
    options: [
      {
        label: "Priority 1",
        value: "pri1",
      },
      {
        label: "Priority 2",
        value: "pri2",
      },
    ],
  },
  // {
  //   id: 4,
  //   label: "会社",
  //   placeholder: "会社",
  //   type: "select",
  //   key: "company_id",
  //   containerClass: "col-sm-12 col-md-4 col-lg-4",
  //   options: [
  //     {
  //       label: "Priority 1",
  //       value: "pri1",
  //     },
  //     {
  //       label: "Priority 2",
  //       value: "pri2",
  //     },
  //   ],
  // },
  {
    id: 5,
    label: "クライアント",
    placeholder: "クライアント",
    type: "select",
    key: "client_id",
    containerClass: "col-sm-12 col-md-4 col-lg-4",
    options: [
      {
        label: "Priority 1",
        value: "pri1",
      },
      {
        label: "Priority 2",
        value: "pri2",
      },
    ],
  },
  {
    id: 6,
    label: "事業",
    placeholder: "事業",
    type: "select",
    key: "project_id",
    containerClass: "col-sm-12 col-md-4 col-lg-4",
    options: [
      {
        label: "Priority 1",
        value: "pri1",
      },
      {
        label: "Priority 2",
        value: "pri2",
      },
    ],
  },

  {
    id: 7,
    label: "入力日",
    placeholder: "入力日",
    type: "date",
    key: "date",
    containerClass: "col-sm-12 col-md-4 col-lg-4",
  },
  {
    id: 8,
    label: "説明",
    type: "textarea",
    placeholder: "説明",
    rows: 12,
    key: "desc",
    containerClass: "col-sm-12 col-md-4 col-lg-12",
  },
];
