import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { addProjectHandler } from "../../apiParsing/project";
import {
  addPrefexToObjectProperty,
  companyFormLayoutHandler,
  formModalController,
  modalInitialValue,
} from "../../helpers/commonHelpers";
import { addActionColumn } from "../../helpers/companyHelpers";
import { sendRequest } from "../../services";
import { apipaths } from "../../services/apiPaths";
import { clientListAction } from "../actions/clientAction";
import { companyListAction } from "../actions/comanyAction";
import { projectListAction } from "../actions/projectAction";
import TableCard from "../TableCard";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown, Menu } from "antd";
import { BarsOutlined } from "@ant-design/icons";
import { FaClipboardList, FaTasks, FaPen } from "react-icons/fa";
import { projectFormLayout } from "./../../Data/FormsLayout/projectProfileFormLayout";
import { projectFormLayoutJap } from "../../Data/FormsLayout/projectFormLayout";
import useTranslation from "../customHooks/translations";

function ProjectDataTable({ companyId, setModal }) {
  const dispatch = useDispatch();
  const translation = useTranslation();
  const navigate = useNavigate();

  const [formLayout, setFormLayout] = useState(projectFormLayout);
  const [projectList, setProjectList] = useState([]);
  const { projects } = useSelector((state) => state.project);
  const { clients } = useSelector((state) => state.client);
  const { companies } = useSelector((state) => state.company);
  const { employees } = useSelector((state) => state.employee);

  useEffect(() => {
    if (clients && companies && employees) {
      employees.map((emp) => (emp.name = emp.first_name + " " + emp.last_name));
      let l1 = companyFormLayoutHandler(
        employees,
        projectFormLayout,
        "employee_id",
        "name"
      );
      let l2 = companyFormLayoutHandler(companies, l1, "company_id", "name");
      clients.map(
        (client) => (client.name = client.first_name + " " + client.last_name)
      );
      let l3 = companyFormLayoutHandler(clients, l2, "client_id", "name");
      setFormLayout(l3);
    }
  }, [clients, companies, employees]);

  useEffect(() => {
    if (projects && companyId)
      setProjectList(
        projects.filter((project) => project.company_id == parseInt(companyId))
      );
  }, [projects]);

  useEffect(() => {
    dispatch(clientListAction());
    dispatch(companyListAction());
    dispatch(projectListAction());
  }, []);

  const projectColumns = [
    {
      title: "ID",
      dataIndex: "project_id",
      sorter: (a, b) => a.project_id - b.project_id,
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (name, data) => {
        return (
          <>
            <Link to={`/edit-project/${data.project_id}`}>
              <label>{name}</label>
            </Link>
          </>
        );
      },
    },
    {
      title: "Project Cost",
      dataIndex: "project_cost",
      sorter: (a, b) => a.project_cost.localeCompare(b.project_cost),
    },
    {
      title: "Status",
      dataIndex: "activity_status",
      sorter: (a, b) => a.activity_status.localeCompare(b.activity_status),
      render: (activity_status) => {
        return <p className="mb-0">{activity_status}</p>;
      },
    },
    {
      title: "Stage",
      dataIndex: "stage",
      sorter: (a, b) => a.stage.localeCompare(b.stage),
    },
    {
      title: "Start Date",
      dataIndex: "startdate",
      sorter: (a, b) => a.startdate.localeCompare(b.startdate),
      render: (startdate) => {
        return <p className="mb-0">{startdate}</p>;
      },
    },
  ];

  const projectColumnsHome = [
    {
      title: "ID",
      dataIndex: "project_id",
      // sorter: {
      //   compare: (a, b) => a.math - b.math,
      //   multiple: 1,
      // },
      sorter: (a, b) => a.project_id - b.project_id,
    },
    {
      title: "名前",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (name, data) => {
        return (
          <>
            <Link to={`/edit-project/${data.project_id}`}>
              <label>{name}</label>
            </Link>
          </>
        );
      },
    },
    {
      title: "プロジェクト費用",
      dataIndex: "project_cost",
      sorter: (a, b) => a.project_cost.localeCompare(b.project_cost),
    },
    {
      title: "状態",
      dataIndex: "activity_status",
      sorter: (a, b) => a.activity_status.localeCompare(b.activity_status),
      render: (activity_status) => {
        return <p className="mb-0">{activity_status}</p>;
      },
    },
    {
      title: "ステージ",
      dataIndex: "stage",
      sorter: (a, b) => a.stage.localeCompare(b.stage),
    },
    {
      title: "開始日",
      dataIndex: "startdate",
      sorter: (a, b) => a.startdate.localeCompare(b.startdate),
      render: (startdate) => {
        return <p className="mb-0">{startdate}</p>;
      },
    },
    {
      title: "Action",
      dataIndex: "project_id",
      render: (project_id, index) => (
        <div key={index}>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key={1}>
                  <Link to="/tasks">
                    <div className="d-flex align-items-center">
                      <FaTasks className="text-success" />
                      <span className="ms-2">View Tasks</span>
                    </div>
                  </Link>
                </Menu.Item>

                <Menu.Item key={2}>
                  <Link to="/meetings">
                    <div className="d-flex align-items-center">
                      <FaClipboardList className="text-secondary" />
                      <span className="ms-2">View Meetings</span>
                    </div>
                  </Link>
                </Menu.Item>

                <Menu.Item key={3}>
                  <Link to={`/edit-project/${project_id}`}>
                    <div className="d-flex align-items-center">
                      <FaPen className="text-warning" />
                      <span className="ms-2">Edit</span>
                    </div>
                  </Link>
                </Menu.Item>

                <Menu.Item key={4}>
                  <Link to="/project">
                    <div
                      className="d-flex align-items-center"
                      onClick={() => {
                        deleteProjectHandler(project_id);
                      }}
                    >
                      <FaClipboardList className="text-danger" />
                      <span className="ms-2">Delete</span>
                    </div>
                  </Link>
                </Menu.Item>
              </Menu>
            }
            placement="bottom"
            className="action-button"
          >
            <BarsOutlined />
          </Dropdown>
        </div>
      ),
    },
  ];

  const updateProjectHandler = async (project) => {
    project["id"] = project.project_id;
    delete project.project_id;
    delete project.enable;
    delete project.company;
    delete project.client;
    delete project.created_at;
    delete project.updated_at;
    delete project.user_id;
    delete project.edate;
    delete project.sdate;
    delete project.status;
    delete project.employee;
    delete project.company_project_profile_id;

    project = addPrefexToObjectProperty("Project_", project);
    project["operation"] = "update";
    project["project_type"] = "update";
    project["OPtype"] = "Project";

    const {
      data: { flash_success },
      success,
    } = await sendRequest(apipaths.addProject, project);
    if (success) {
      setModal(modalInitialValue);
      dispatch(projectListAction());
      Swal.fire({
        position: "center",
        icon: "success",
        title: flash_success,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  const deleteProjectHandler = (project_id) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#1890ff",
    }).then(async ({ isConfirmed }) => {
      if (!isConfirmed) return null;

      const { data } = await sendRequest(apipaths.deleteProject, {
        delete_id: project_id,
      });
      const { success, message } = data[0].original;
      if (success === 1) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: message,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch(projectListAction());
      }
    });
  };

  return (
    <TableCard
      button={{
        title: translation.add_project,
        onClick: () =>
          formModalController(
            setModal,
            formLayout,
            projectFormLayoutJap,
            `${translation.add_project}`,
            `Add-project-form`,
            {},
            (data) =>
              addProjectHandler(
                data,
                setModal,
                dispatch,
                projectListAction,
                companyId
              )
          ),
      }}
      columns={addActionColumn(
        {},
        projectColumns,
        (data) => navigate(`/edit-project/${data.project_id}`),
        ({ project_id }) => deleteProjectHandler(project_id),
        translation._language
      )}
      jaCol={addActionColumn(
        { type: `dropdown`, name: `projectHome-dropdown` },
        projectColumnsHome,
        (data) => navigate(`/edit-project/${data.project_id}`),
        ({ project_id }) => deleteProjectHandler(project_id),
        translation._language
      )}
      data={projectList}
      title={translation.project}
    />
  );
}

export default ProjectDataTable;
