import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteMeeting } from "../../apiParsing/meeting";
import { addActionColumn } from "../../helpers/companyHelpers";
import { meetingListAction } from "../actions/meetingAction";
import TableCard from "../TableCard";
import { Dropdown } from "antd";
import { BarsOutlined } from "@ant-design/icons";
import { menu } from "./../../Data/Columns/meetingColumns";
import useTranslation from "../customHooks/translations";
import CompanyMeetingForm from "./CompanyMeetingForm";
import { useLocation, useNavigate } from "react-router-dom";

const MeetingDataTable = ({ companyId, setModal, projectId }) => {
  const [meetingList, setMeetingList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formData, setFormData] = useState("");
  const [editData, setEditData] = useState({});
  const [navId, setNavId] = useState("");
  const location = useLocation();
  const translation = useTranslation();
  const dispatch = useDispatch();
  const { meet } = useSelector((state) => state.meet);
  const navigate = useNavigate();

  const mainLocation = location.pathname.slice(1, -4);

  useEffect(() => {
    if (meet && companyId)
      setMeetingList(meet.filter((m) => m.company_id == parseInt(companyId)));
    else if (meet && projectId)
      setMeetingList(meet.filter((m) => m.project_id == parseInt(projectId)));
  }, [meet]);

  const editMeetingHandler = (data) => {
    setIsModalVisible(true);
    setEditData(data);
  };

  const meetingColumns = [
    {
      title: "ID",
      dataIndex: "meeting_id",
      sorter: (a, b) => a.meeting_id - b.meeting_id,
      render: (id) => {
        return id;
      },
    },
    {
      title: "Subject",
      dataIndex: "subject",
      sorter: (a, b) => a?.subject?.localeCompare(b?.subject),
      render: (subject, data) => {
        return (
          <>
            <label
              style={{ color: "#1890ff" }}
              onClick={() => {
                navigate(`/edit-meetings/${data.meeting_id}`);
              }}
            >
              {subject}
            </label>
          </>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "startdate",
      sorter: (a, b) => a.startdate.localeCompare(b.startdate),
    },
    {
      title: "Start Time",
      dataIndex: "starttime",
      sorter: (a, b) => a.starttime.localeCompare(b.starttime),
    },

    {
      title: "Venue",
      dataIndex: "venue",
      sorter: (a, b) => a.venue.localeCompare(b.venue),
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      sorter: (a, b) => a.remarks.localeCompare(b.remarks),
    },

    {
      title: "Action",
      dataIndex: "action",
      render: (index) => (
        <div key={index}>
          <Dropdown overlay={menu} placement="bottom" className="action-button">
            <BarsOutlined />
          </Dropdown>
        </div>
      ),
    },
  ];

  const meetingColumnsHome = [
    {
      title: "ID",
      dataIndex: "meeting_id",
      sorter: (a, b) => a.meeting_id - b.meeting_id,
      render: (id) => {
        return id;
      },
    },
    {
      title: "主題",
      dataIndex: "subject",
      sorter: (a, b) => a?.subject?.localeCompare(b?.subject),
      render: (subject, data) => {
        return (
          <>
            <label
              style={{ color: "#1890ff" }}
              onClick={() => {
                navigate(`/edit-meetings/${data.meeting_id}`);
              }}
            >
              {subject}
            </label>
          </>
        );
      },
    },
    {
      title: "日付",
      dataIndex: "startdate",
      sorter: (a, b) => a.startdate.localeCompare(b.startdate),
    },
    {
      title: "開始時間",
      dataIndex: "starttime",
      sorter: (a, b) => a.starttime.localeCompare(b.starttime),
    },

    {
      title: "会場",
      dataIndex: "venue",
      sorter: (a, b) => a.venue.localeCompare(b.venue),
    },
    {
      title: "備考",
      dataIndex: "remarks",
      sorter: (a, b) => a.remarks.localeCompare(b.remarks),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (index) => (
        <div key={index}>
          <Dropdown overlay={menu} placement="bottom" className="action-button">
            <BarsOutlined />
          </Dropdown>
        </div>
      ),
    },
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };
  useEffect(() => {
    dispatch(meetingListAction());
    // locationCheck();
  }, []);

  return (
    <>
      <TableCard
        button={{
          title: translation.add_meeting,
          onClick: () => showModal(),
        }}
        columns={addActionColumn(
          {},
          meetingColumns,
          (data) => editMeetingHandler(data),
          (data) => deleteMeeting(data, setModal, dispatch, meetingListAction),
          translation._language
        )}
        jaCol={addActionColumn(
          {},
          meetingColumnsHome,
          (data) => editMeetingHandler(data),
          (data) => deleteMeeting(data, setModal, dispatch, meetingListAction),
          translation._language
        )}
        data={meetingList}
        title={translation.meetings}
      />
      <CompanyMeetingForm
        companyId={companyId}
        projectId={projectId}
        setIsModalVisible={setIsModalVisible}
        isModalVisible={isModalVisible}
        editData={editData}
        setEditData={setEditData}
      />
    </>
  );
};

export default MeetingDataTable;
