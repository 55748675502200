import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Client from "./components/Client";
import Company from "./components/Company";
import CompanyForm from "./components/Company/companyForm";
import EditCompany from "./components/Company/editCompany";
import Email from "./components/Email";
import EmailForm from "./components/Email/emailForm";
import Employees from "./components/Employees";
import EmployeesForm from "./components/Employees/employeeForm";
import Home from "./components/Home";
import ImportContacts from "./components/ImportContacts";
import Login from "./components/Login";
import Meetings from "./components/Meetings";
import MeetingForm from "./components/Meetings/meetingForm";
import ModalContainer from "./components/Modal";
import Navbar from "./components/Navbar";
import Notes from "./components/Notes";
import NoteForm from "./components/Notes/noteForm";
import Project from "./components/Project";
import EditProject from "./components/Project/editProject";
import ProjectForm from "./components/Project/ProjectForm";
import QuickMemos from "./components/QuickMemos";
import QuickMemosForm from "./components/QuickMemos/quickMemosForm";
import SectorBoard from "./components/SectorBoard";
import Sidebar from "./components/Sidebar";
import Task from "./components/Task";
import TaskFormContainer from "./components/Task/taskFormContainer";
import Teams from "./components/Teams";
import TeamForm from "./components/Teams/teamForm";
import Users from "./components/Users";
import UserForm from "./components/Users/userForm";
import Protected from "./Protected";
import UserProfile from "./components/Profile/UserProfile";
import EditTask from "./components/Task/editTask";
import EditMeeting from "./components/Meetings/editMeeting";
import UserCalendar from "./components/Calendar/Calendar";
import useTranslation from "./components/customHooks/translations";
import TaskCompanyTable from "./components/Task/TaskCompanyTable";
import NotesDataTable from "./components/Notes/NotesDataTable";
import CompanyNote from "./components/Notes/CompanyNote";
import CompanyProject from "./components/Project/CompnayProject";
import CompanyClient from "./components/Client/CompanyClient";
import MeetingParam from "./components/Meetings/MeetingParam";
import TodayTask from "./components/Task/TodayTask";
import TodayMeeting from "./components/Meetings/TodayMeeting";
import TaskCompanyForm from "./components/Task/TaskCompanyForm";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

function App() {
  const [sidebarToggle, setSidebarToggle] = useState(false);

  const translation = useTranslation();

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/dashboard"
            element={
              <>
                {" "}
                <Navbar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Sidebar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Protected Components={Home} />
              </>
            }
          />
          <Route
            path="/company"
            element={
              <>
                {" "}
                <Navbar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Sidebar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Protected Components={Company} />
              </>
            }
          />
          <Route
            path="/company/form/:id"
            element={
              <>
                {" "}
                <Navbar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Sidebar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Protected Components={CompanyForm} />
              </>
            }
          />
          <Route
            path="/edit-company/:id"
            element={
              <>
                {" "}
                <Navbar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Sidebar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Protected Components={EditCompany} />
              </>
            }
          />
          <Route
            path="/sectorboard"
            element={
              <>
                {" "}
                <Navbar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Sidebar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Protected Components={SectorBoard} />
              </>
            }
          />
          <Route
            path="/client"
            element={
              <>
                {" "}
                <Navbar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Sidebar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Protected Components={Client} />
              </>
            }
          />
          <Route
            path="/project"
            element={
              <>
                {" "}
                <Navbar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Sidebar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Protected Components={Project} />
              </>
            }
          />
          <Route
            path="/project/form/:id"
            element={
              <>
                {" "}
                <Navbar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Sidebar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Protected Components={ProjectForm} />
              </>
            }
          />
          <Route
            path="/edit-project/:id"
            element={
              <>
                {" "}
                <Navbar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Sidebar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Protected Components={EditProject} />
              </>
            }
          />
          <Route
            path="/tasks"
            element={
              <>
                {" "}
                <Navbar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Sidebar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Protected Components={Task} />
              </>
            }
          />
          <Route
            path="/tasks/form/:id"
            element={
              <>
                {" "}
                <Navbar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Sidebar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Protected Components={TaskFormContainer} />
              </>
            }
          />
          <Route
            path="/edit-tasks/:id"
            element={
              <>
                {" "}
                <Navbar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Sidebar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Protected Components={EditTask} />
              </>
            }
          />
          <Route
            path="/notes"
            element={
              <>
                {" "}
                <Navbar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Sidebar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Protected Components={Notes} />
              </>
            }
          />
          <Route
            path="/notes/form/:id"
            element={
              <>
                {" "}
                <Navbar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Sidebar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Protected Components={NoteForm} />
              </>
            }
          />
          <Route
            path="/meetings"
            element={
              <>
                {" "}
                <Navbar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Sidebar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Protected Components={Meetings} />
              </>
            }
          />
          <Route
            path="/meetings/form/:id"
            element={
              <>
                {" "}
                <Navbar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Sidebar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Protected Components={MeetingForm} />
              </>
            }
          />
          <Route
            path="/edit-meetings/:id"
            element={
              <>
                {" "}
                <Navbar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Sidebar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Protected Components={EditMeeting} />
              </>
            }
          />
          <Route
            path="/email"
            element={
              <>
                {" "}
                <Navbar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Sidebar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Protected Components={Email} />
              </>
            }
          />
          <Route
            path="/email/form/:id"
            element={
              <>
                {" "}
                <Navbar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Sidebar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Protected Components={EmailForm} />
              </>
            }
          />
          <Route
            path="/quickmemos"
            element={
              <>
                {" "}
                <Navbar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Sidebar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Protected Components={QuickMemos} />
              </>
            }
          />
          <Route
            path="/quickmemos/form/:id"
            element={
              <>
                {" "}
                <Navbar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Sidebar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Protected Components={QuickMemosForm} />
              </>
            }
          />
          <Route
            path="/users"
            element={
              <>
                {" "}
                <Navbar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Sidebar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Protected Components={Users} />
              </>
            }
          />
          <Route
            path="/users/form/:id"
            element={
              <>
                {" "}
                <Navbar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Sidebar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Protected Components={UserForm} />
              </>
            }
          />
          <Route
            path="/employees"
            element={
              <>
                {" "}
                <Navbar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Sidebar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Protected Components={Employees} />
              </>
            }
          />
          <Route
            path="/employees/form/:id"
            element={
              <>
                {" "}
                <Navbar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Sidebar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Protected Components={EmployeesForm} />
              </>
            }
          />
          <Route
            path="/teams"
            element={
              <>
                {" "}
                <Navbar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Sidebar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Protected Components={Teams} />
              </>
            }
          />
          <Route
            path="/teams/form/:id"
            element={
              <>
                {" "}
                <Navbar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Sidebar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Protected Components={TeamForm} />
              </>
            }
          />
          <Route
            path="/importcontacts"
            element={
              <>
                {" "}
                <Navbar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Sidebar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Protected Components={ImportContacts} />
              </>
            }
          />
          <Route
            path="/modal"
            element={
              <>
                {" "}
                <Navbar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Sidebar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Protected Components={ModalContainer} />
              </>
            }
          />
          <Route
            path="/userProfile"
            element={
              <>
                {" "}
                <Navbar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Sidebar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Protected Components={UserProfile} />
              </>
            }
          />
          <Route
            path="/calendar"
            element={
              <>
                {" "}
                <Navbar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Sidebar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Protected Components={UserCalendar} />
              </>
            }
          />
          <Route
            path="/tasks/:id"
            element={
              <>
                {" "}
                <Navbar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Sidebar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Protected Components={TaskCompanyTable} />
              </>
            }
          />
          <Route
            path="/notes/:id"
            element={
              <>
                {" "}
                <Navbar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Sidebar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Protected Components={CompanyNote} />
              </>
            }
          />
          <Route
            path="/project/:id"
            element={
              <>
                {" "}
                <Navbar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Sidebar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Protected Components={CompanyProject} />
              </>
            }
          />
          <Route
            path="/client/:id"
            element={
              <>
                {" "}
                <Navbar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Sidebar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Protected Components={CompanyClient} />
              </>
            }
          />
          <Route
            path="/meetings/:id"
            element={
              <>
                {" "}
                <Navbar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Sidebar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Protected Components={MeetingParam} />
              </>
            }
          />
          <Route
            path="/todayTasks"
            element={
              <>
                {" "}
                <Navbar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Sidebar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Protected Components={TodayTask} />
              </>
            }
          />
          <Route
            path="/todayMeetings"
            element={
              <>
                {" "}
                <Navbar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Sidebar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Protected Components={TodayMeeting} />
              </>
            }
          />
          <Route
            path="/company/task/add"
            element={
              <>
                {" "}
                <Navbar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Sidebar
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
                <Protected Components={TaskCompanyForm} />
              </>
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
