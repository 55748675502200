import { sendRequest } from "../../services";
import { apipaths } from "../../services/apiPaths";

export const emailListAction = () => async (dispatch) => {
  const { success, data } = await sendRequest(apipaths.emailListing);
  const { emails, companies, clients, projects, employees } = data;
  if (success) {
    dispatch({ type: "ADD_EMAIL", payload: emails });
    dispatch({ type: "ADD_EMPLOYEES", payload: employees });
    dispatch({ type: "ADD_COMAPNIES", payload: companies });
    dispatch({ type: "ADD_CLIENTS", payload: clients });
    dispatch({ type: "ADD_PROJECTS", payload: projects });
  }
};
