import { Menu, Dropdown } from "antd";
import { BarsOutlined } from "@ant-design/icons";
import { FaClipboardList, FaTasks, FaPen } from "react-icons/fa";
import { Link } from "react-router-dom";
import { deleteProjectHandler } from "../../helpers/commonHelpers";

export const menu = (
  <Menu>
    <Menu.Item>
      <Link to="/tasks">
        <div className="d-flex align-items-center">
          <FaTasks className="text-success" />
          <span className="ms-2">View Tasks</span>
        </div>
      </Link>
    </Menu.Item>

    <Menu.Item>
      <Link to="/meetings">
        <div className="d-flex align-items-center">
          <FaClipboardList className="text-secondary" />
          <span className="ms-2">View Meetings</span>
        </div>
      </Link>
    </Menu.Item>

    <Menu.Item>
      <Link to="/edit-project/7">
        <div className="d-flex align-items-center">
          <FaPen className="text-warning" />
          <span className="ms-2">Edit</span>
        </div>
      </Link>
    </Menu.Item>

    <Menu.Item>
      <Link to="/project">
        <div className="d-flex align-items-center">
          <FaClipboardList className="text-danger" />
          <span className="ms-2">Delete</span>
        </div>
      </Link>
    </Menu.Item>
  </Menu>
);

export const projectColumns = [
  {
    title: "ID",
    dataIndex: "project_id",
    sorter: {
      compare: (a, b) => a.id - b.id,
      multiple: 1,
    },
  },
  {
    title: "Name",
    dataIndex: "name",
    sorter: {
      compare: (a, b) => a.name - b.name,
      multiple: 1,
    },
  },

  {
    title: "Company",
    dataIndex: "company",
    sorter: {
      compare: (a, b) => a.company - b.company,
      multiple: 2,
    },
    render: (company) => {
      return (
        <p className="mb-0">{company && company.name ? company.name : ""}</p>
      );
    },
  },
  {
    title: "Action",
    dataIndex: "project_id",
    render: (project_id, index) => (
      <div key={index}>
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key={1}>
                <Link to="/tasks">
                  <div className="d-flex align-items-center">
                    <FaTasks className="text-success" />
                    <span className="ms-2">View Tasks</span>
                  </div>
                </Link>
              </Menu.Item>

              <Menu.Item key={2}>
                <Link to="/meetings">
                  <div className="d-flex align-items-center">
                    <FaClipboardList className="text-secondary" />
                    <span className="ms-2">View Meetings</span>
                  </div>
                </Link>
              </Menu.Item>

              <Menu.Item key={3}>
                <Link to={`/edit-project/${project_id}`}>
                  <div className="d-flex align-items-center">
                    <FaPen className="text-warning" />
                    <span className="ms-2">Edit</span>
                  </div>
                </Link>
              </Menu.Item>

              <Menu.Item key={4}>
                <Link to="/project">
                  <div
                    className="d-flex align-items-center"
                    onClick={() => {
                      deleteProjectHandler(project_id);
                    }}
                  >
                    <FaClipboardList className="text-danger" />
                    <span className="ms-2">Delete</span>
                  </div>
                </Link>
              </Menu.Item>
            </Menu>
          }
          placement="bottom"
          className="action-button"
        >
          <BarsOutlined />
        </Dropdown>
      </div>
    ),
  },
];

export const projectColumnsHome = [
  {
    title: "ID",
    dataIndex: "project_id",
    defaultSortOrder: "descend",
    sorter: (a, b) => a.project_id - b.project_id,
  },
  {
    title: "Name",
    dataIndex: "name",
    sorter: (a, b) => a.name.localeCompare(b.name),
    render: (project_id, data) => {
      return (
        <>
          <label>{data.name}</label>
        </>
      );
    },
  },
  {
    title: "Company",
    dataIndex: "company",
    sorter: (a, b) => a.company.name.localeCompare(b.company.name),
    render: (company) => {
      return <p className="mb-0">{company?.name}</p>;
    },
  },
  {
    title: "Action",
    dataIndex: "project_id",
    render: (project_id, index) => (
      <div key={index}>
        <Dropdown
          overlay={
            <Menu>
              {/* <Menu.Item key={1}>
                <Link to="/tasks">
                  <div className="d-flex align-items-center">
                    <FaTasks className="text-success" />
                    <span className="ms-2">View Tasks</span>
                  </div>
                </Link>
              </Menu.Item>

              <Menu.Item key={2}>
                <Link to="/meetings">
                  <div className="d-flex align-items-center">
                    <FaClipboardList className="text-secondary" />
                    <span className="ms-2">View Meetings</span>
                  </div>
                </Link>
              </Menu.Item> */}

              <Menu.Item key={3}>
                <Link to={`/edit-project/${project_id}`}>
                  <div className="d-flex align-items-center">
                    <FaPen className="text-warning" />
                    <span className="ms-2">Edit</span>
                  </div>
                </Link>
              </Menu.Item>

              <Menu.Item key={4}>
                <Link to="/project">
                  <div
                    className="d-flex align-items-center"
                    onClick={() => {
                      deleteProjectHandler(project_id);
                    }}
                  >
                    <FaClipboardList className="text-danger" />
                    <span className="ms-2">Delete</span>
                  </div>
                </Link>
              </Menu.Item>
            </Menu>
          }
          placement="bottom"
          className="action-button"
        >
          <BarsOutlined />
        </Dropdown>
      </div>
    ),
  },
];

export const projectColumnsJa = [
  {
    title: "ID",
    dataIndex: "project_id",
    defaultSortOrder: "descend",
    sorter: (a, b) => a.project_id - b.project_id,
  },
  {
    title: "名前",
    dataIndex: "name",
    sorter: (a, b) => a.name.localeCompare(b.name),
    render: (project_id, data) => {
      return (
        <>
          <label>{data.name}</label>
        </>
      );
    },
  },
  {
    title: "会社",
    dataIndex: "company",
    sorter: (a, b) => a.company.name.localeCompare(b.company.name),
    render: (company) => {
      return (
        <p className="mb-0">{company && company.name ? company.name : ""}</p>
      );
    },
  },
  {
    title: "Action",
    dataIndex: "project_id",
    render: (project_id, index) => (
      <div key={index}>
        <Dropdown
          overlay={
            <Menu>
              {/* <Menu.Item key={1}>
                <Link to="/tasks">
                  <div className="d-flex align-items-center">
                    <FaTasks className="text-success" />
                    <span className="ms-2">View Tasks</span>
                  </div>
                </Link>
              </Menu.Item>

              <Menu.Item key={2}>
                <Link to="/meetings">
                  <div className="d-flex align-items-center">
                    <FaClipboardList className="text-secondary" />
                    <span className="ms-2">View Meetings</span>
                  </div>
                </Link>
              </Menu.Item> */}

              <Menu.Item key={3}>
                <Link to={`/edit-project/${project_id}`}>
                  <div className="d-flex align-items-center">
                    <FaPen className="text-warning" />
                    <span className="ms-2">Edit</span>
                  </div>
                </Link>
              </Menu.Item>

              <Menu.Item key={4}>
                <Link to="/project">
                  <div
                    className="d-flex align-items-center"
                    onClick={() => {
                      deleteProjectHandler(project_id);
                    }}
                  >
                    <FaClipboardList className="text-danger" />
                    <span className="ms-2">Delete</span>
                  </div>
                </Link>
              </Menu.Item>
            </Menu>
          }
          placement="bottom"
          className="action-button"
        >
          <BarsOutlined />
        </Dropdown>
      </div>
    ),
  },
];
