import { sendRequest } from "../../services";
import { apipaths } from "../../services/apiPaths";

export const clientListAction = () => async (dispatch) => {
  const { success, data } = await sendRequest(apipaths.clientList);
  const { clients } = data[0];
  if (success) {
    dispatch({ type: "ADD_CLIENTS", payload: clients });
  }
};
