export const teamsFormLayout = [
  {
    id: 1,
    label: "Teams Name",
    key: "team_name",
    type: "text",
    placeholder: "Teams Name",
    containerClass: "col-sm-12 col-md-6 col-lg-8  ",
    required: true,
  },
  {
    id: 2,
    type: "selectdropdown",
    label: "Employee",
    key: "employee_id",
    containerClass: "col-sm-12 col-md-6 col-lg-4",
    placeholder: "Select Employee",
    options: [
      {
        label: "Option 1",
        value: "opt1",
      },
      {
        label: "Option 2",
        value: "opt2",
      },
      {
        label: "Option 3",
        value: "opt3",
      },
    ],
  },
];

export const teamsFormLayoutJap = [
  {
    id: 1,
    label: "チーム 名前",
    key: "team_name",
    type: "text",
    placeholder: "チーム 名前",
    containerClass: "col-sm-12 col-md-6 col-lg-8  ",
    required: true,
  },
  {
    id: 2,
    type: "selectdropdown",
    label: "社員",
    key: "employee_id",
    containerClass: "col-sm-12 col-md-6 col-lg-4",
    placeholder: "社員",
    options: [
      {
        label: "Option 1",
        value: "opt1",
      },
      {
        label: "Option 2",
        value: "opt2",
      },
      {
        label: "Option 3",
        value: "opt3",
      },
    ],
  },
];
