import Swal from "sweetalert2";
import { modalInitialValue, onSuccesshandler } from "../helpers/commonHelpers";
import { sendRequest } from "../services";
import { apipaths } from "../services/apiPaths";

export async function addMeetingHandler(
  formdata,
  setModal,
  dispatch,
  meetingListAction
) {
  try {
    const form_data = formdata;

    form_data["subject"] = formdata["meetings"];
    delete form_data["meetings"];
    form_data["operation"] = "add";
    const { data, success } = await sendRequest(apipaths.addMeeting, form_data);
    if (success) {
      onSuccesshandler(
        setModal,
        dispatch,
        meetingListAction,
        data?.flash_success
      );
    }
  } catch (error) {
    console.error(error);
  }
}

export async function deleteMeeting(
  data,
  setModal,
  dispatch,
  meetingListAction
) {
  Swal.fire({
    title: "Are you sure?",
    icon: "question",
    showCancelButton: true,
    confirmButtonText: "Delete",
    confirmButtonColor: "#1890ff",
  }).then(async ({ isConfirmed }) => {
    if (!isConfirmed) return null;

    const { success, message } = await sendRequest(apipaths.deleteMeeting, {
      delete_id: data?.["meeting_id"],
    });
    if (success) {
      onSuccesshandler(setModal, dispatch, meetingListAction, message);
    }
  });
}

export const updateMeetingHandler = async (
  formdata,
  setModal,
  dispatch,
  meetingListAction
) => {
  const form_data = { ...formdata };
  form_data["id"] = formdata.meeting_id;
  form_data["operation"] = "update";

  delete form_data.created_at;
  delete form_data.updated_at;
  delete form_data.company;
  delete form_data.user_id;
  delete form_data.employee;
  delete form_data.meeting_id;
  delete form_data.enable;

  const { success, message } = await sendRequest(
    apipaths.addMeeting,
    form_data
  );
  if (success) {
    setModal(modalInitialValue);
    dispatch(meetingListAction());
    Swal.fire({
      position: "center",
      icon: "success",
      title: message,
      showConfirmButton: false,
      timer: 1500,
    });
  }
};
