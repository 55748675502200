import { sendRequest } from "../../services";
import { apipaths } from "../../services/apiPaths";

export const usersListAction = () => async (dispatch) => {
  const { success, data } = await sendRequest(apipaths.listUser);
  const { users, user_roles } = data;
  if (success) {
    dispatch({ type: "ADD_USERS", payload: users });
    dispatch({ type: "ADD_USER_ROLE", payload: user_roles });
  }
};
