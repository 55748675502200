import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  addHandler,
  deleteHandler,
  updateHandler,
} from "../../apiParsing/data";
import { emailFormLayout } from "../../Data/FormsLayout/editFormLayout/emailFormLayout";
import { emailFormLayoutJap } from "../../Data/FormsLayout/emailFormLayout";
import {
  filterClientsHandler,
  formModalController,
  listingCommonDefaulter,
} from "../../helpers/commonHelpers";
import { addActionColumn } from "../../helpers/companyHelpers";
import { apipaths } from "../../services/apiPaths";
import { emailListAction } from "../actions/emailAction";
import useTranslation from "../customHooks/translations";
import TableCard from "../TableCard";
import moment from "moment";

export default function EmailDatatable({ setModal, companyId, projectId }) {
  const { emails } = useSelector((state) => state.emails);
  const { employees } = useSelector((state) => state.employee);
  const { projects } = useSelector((state) => state.project);
  const { clients } = useSelector((state) => state.client);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const location = useLocation();
  const translation = useTranslation();

  const mainLocation = location.pathname.slice(1, 13);

  useEffect(() => {
    filterClientsHandler(companyId, emails, setData);
  }, [emails]);

  const selClient = clients.filter((d) => {
    return d.company_id == companyId;
  });

  const selProject = projects.filter((d) => {
    return d.company_id == companyId;
  });

  useEffect(() => {
    if (employees && selProject && selClient) {
      const arr = [employees, selProject, selClient];
      const keyArr = ["employee_id", "project_id", "client_id"];
      listingCommonDefaulter(arr, emailFormLayout, keyArr);
    }
  }, []);

  const emailColumns = [
    {
      title: "ID",
      dataIndex: "email_id",
      sorter: (a, b) => a.email_id - b.email_id,
      key: "email_id",
    },
    {
      title: "Date",
      dataIndex: "send_date",
      sorter: (a, b) => moment(a.send_date).unix() - moment(b.send_date).unix(),
    },
    {
      title: "Subject",
      dataIndex: "subject",
      sorter: (a, b) => a.subject.localeCompare(b.subject),
      render: (d, data) => {
        return (
          <>
            <label
              style={{ color: "#1890ff" }}
              onClick={() => {
                dataTest(data);
              }}
            >
              {data.subject}
            </label>
          </>
        );
      },
    },
    {
      title: "Sender",
      dataIndex: "sender",
      sorter: (a, b) => a.sender.localeCompare(b.sender),
      key: "sender",
    },
    {
      title: "Company",
      dataIndex: "company_id",
      sorter: (a, b) => a.company_id - b.company_id,
      render: (company_id, row) => {
        let company_name = "";
        const company = row.company;
        if (company_id && company) company_name = company.name;
        return company_name;
      },
    },
    {
      title: "Client",
      dataIndex: "client",
      sorter: (a, b) =>
        a?.client?.first_name.localeCompare(b?.client?.first_name),
      render: (d, data) => {
        return (
          <>
            {data?.client?.first_name && data?.client?.last_name
              ? data?.client?.first_name + " " + data?.client?.last_name
              : "-"}
          </>
        );
      },
    },
  ];

  const emailColumnsHome = [
    {
      title: "ID",
      dataIndex: "email_id",
      sorter: (a, b) => a.email_id - b.email_id,
      key: "email_id",
    },
    {
      title: "主題",
      dataIndex: "subject",
      sorter: (a, b) => a.subject.localeCompare(b.subject),
      render: (d, data) => {
        return (
          <>
            <label
              style={{ color: "#1890ff" }}
              onClick={() => {
                dataTest(data);
              }}
            >
              {data.subject}
            </label>
          </>
        );
      },
    },
    {
      title: "送信者",
      dataIndex: "sender",
      sorter: (a, b) => a.sender.localeCompare(b.sender),
      key: "sender",
    },

    {
      title: "日付",
      dataIndex: "send_date",
      sorter: (a, b) => moment(a.send_date).unix() - moment(b.send_date).unix(),
    },
    {
      title: "会社",
      dataIndex: "company_id",
      sorter: (a, b) => a.company_id - b.company_id,
      render: (company_id, row) => {
        let company_name = "";
        const company = row.company;
        if (company_id && company) company_name = company.name;
        return company_name;
      },
    },
    {
      title: "クライアント",
      dataIndex: "client",
      sorter: (a, b) =>
        a?.client?.first_name.localeCompare(b?.client?.first_name),
      render: (d, data) => {
        return (
          <>
            {data?.client?.first_name && data?.client?.last_name
              ? data?.client?.first_name + " " + data?.client?.last_name
              : "-"}
          </>
        );
      },
    },
  ];

  const dataTest = (data) => {
    formModalController(
      setModal,
      emailFormLayout,
      emailFormLayoutJap,
      `${translation.edit_email}`,
      `edit-email-form`,
      data,
      (data) =>
        updateHandler(
          apipaths.addEmail,
          data,
          "email_id",
          setModal,
          dispatch,
          emailListAction
        )
    );
  };

  return (
    <TableCard
      button={{
        title: translation.add_email,
        onClick: () =>
          formModalController(
            setModal,
            emailFormLayout,
            emailFormLayoutJap,
            `${translation.add_email}`,
            `add-email-form`,
            {},
            (data) =>
              addHandler(
                apipaths.addEmail,
                data,
                setModal,
                dispatch,
                emailListAction,
                companyId
              )
          ),
      }}
      columns={addActionColumn(
        {},
        emailColumns,
        (data) =>
          formModalController(
            setModal,
            emailFormLayout,
            emailFormLayoutJap,
            `${translation.edit_email}`,
            `edit-email-form`,
            data,
            (data) =>
              updateHandler(
                apipaths.addEmail,
                data,
                "email_id",
                setModal,
                dispatch,
                emailListAction
              )
          ),
        (data) =>
          deleteHandler(
            apipaths.deleteEmail,
            data?.["email_id"],
            setModal,
            dispatch,
            emailListAction
          ),
        translation._language
      )}
      jaCol={addActionColumn(
        {},
        emailColumnsHome,
        (data) =>
          formModalController(
            setModal,
            emailFormLayout,
            emailFormLayoutJap,
            `Edit Email`,
            `edit-email-form`,
            data,
            (data) =>
              updateHandler(
                apipaths.addEmail,
                data,
                "email_id",
                setModal,
                dispatch,
                emailListAction
              )
          ),
        (data) =>
          deleteHandler(
            apipaths.deleteEmail,
            data?.["email_id"],
            setModal,
            dispatch,
            emailListAction
          ),
        translation._language
      )}
      title={translation.email}
      data={data}
    />
  );
}
