export const apipaths = {
  signIn: { method: "POST", url: "/login" },
  userDetails: { method: "POST", url: "/user/details" },
  companyList: { method: "GET", url: "/companies" },
  addCompany: { method: "POST", url: "/add-company" },
  clientList: { method: "GET", url: "/clients" },
  addClient: { method: "POST", url: "/add-client" },
  deleteClient: { method: "POST", url: "/delete-client" },
  deleteCompany: { method: "POST", url: "/delete-company" },
  deleteProject: { method: "POST", url: "/delete-project" },
  projectList: { method: "GET", url: "/projects" },
  addProject: { method: "POST", url: "/add-project" },
  addSectorBoard: { method: "POST", url: "/add-sector-board" },
  sectorBoardList: { method: "GET", url: "/sector-board" },
  taskList: { method: "GET", url: "/tasks" },
  addnotes: { method: "POST", url: "/add-note" },
  notesList: { method: "GET", url: "/notes" },
  deleteMeeting: { method: "POST", url: "/delete-meeting" },
  addMeeting: { method: "POST", url: "/add-meeting" },
  meetingList: { method: "GET", url: "/meetings" },
  addTask: { method: "POST", url: "/add-task" },
  editTask: { method: "GET", url: "/edit-task" },
  addSubTask: { method: "POST", url: "/add-task" },
  addEmail: { method: "POST", url: "/add-email" },
  deleteEmail: { method: "POST", url: "/delete-email" },
  emailListing: { method: "Get", url: "/emails" },
  deleteNote: { method: "POST", url: "/delete-note" },
  addMemo: { method: "POST", url: "/add-memo" },
  listMemo: { method: "GET", url: "/memos" },
  deleteMemo: { method: "POST", url: "/delete-memo" },
  listTeam: { method: "GET", url: "/teams" },
  listUser: { method: "GET", url: "/users" },
  listEmplo: { method: "GET", url: "/employees" },
  deleteTeam: { method: "POST", url: "/delete-team" },
  deleteTask: { method: "POST", url: "/delete-task" },
  deleteEmployee: { method: "POST", url: "/delete-employee" },
  addEmployee: { method: "POST", url: "/add-employee" },
  addUser: { method: "POST", url: "/add-user" },
  userRoleList: { method: "GET", url: "/roles-lists" },
  addTeam: { method: "POST", url: "/add-team" },
  contactImport: { method: "POST", url: "/import" },
  editProfile: { method: "POST", url: "/edit-profile" },
  userProfileImg: { method: "POST", url: "/user/update-image" },
  changePassword: { method: "POST", url: "/user/changepassword" },
  addCounterParty: { method: "POST", url: "/add-counterparties" },
  counterpartys: { method: "GET", url: "/counterparty" },
  updateCounterparty: { method: "POST", url: "/update-party" },
  deletecounterpartys: { method: "POST", url: "/delete-counterparties" },
  actionplan: { method: "POST", url: "/add-plan" },
  actionplanList: { method: "GET", url: "/actionplan" },
  actionplanUpdate: { method: "POST", url: "/update-plan" },
  deleteactionplan: { method: "POST", url: "/delete-plan" },
  projectProfileAdd: { method: "POST", url: "/add-project-profile" },
  projectProfileList: { method: "GET", url: "/project-profilelist" },
};
