import {
  formatDateMeet,
  formatMeetingDate,
  onSuccesshandler,
} from "../helpers/commonHelpers";
import { isCompanyId } from "../helpers/companyHelpers";
import { sendRequest } from "../services";
import { apipaths } from "../services/apiPaths";

export async function addNotehandler(
  formdata,
  setModal,
  dispatch,
  notesListAction,
  companyId
) {
  const docData = new FormData();
  docData.append("document", formdata?.document);

  console.log("file", formdata.document);

  console.log("dox", docData);
  const allData = {
    date: formdata.date || formatDateMeet(new Date()),
    client_id: formdata.client_id,
    company_id: formdata.company_id,
    desc: formdata.desc,
    document: docData,
    employee_id: formdata.employee_id,
    project_id: formdata.project_id,
    title: formdata.title,
  };

  allData["operation"] = "add";
  const form_data_withId = isCompanyId(companyId, allData);
  const { success, message } = await sendRequest(
    apipaths.addnotes,
    form_data_withId
  );
  if (success) {
    onSuccesshandler(setModal, dispatch, notesListAction, message);
  }
}

export async function updateNoteHandler(
  apiUrl,
  editData,
  id,
  setModal,
  dispatch,
  renderList
) {
  const formdata = editData;
  // delete formdata["date"];
  // formdata["date"] = editData.date;
  formdata["id"] = editData[`${id}`];
  delete formdata[`${id}`];
  formdata["operation"] = "update";
  const { success, message } = await sendRequest(apiUrl, formdata);
  if (success) {
    onSuccesshandler(setModal, dispatch, renderList, message);
  }
}
