import { BsFillPencilFill, BsFillTrashFill } from "react-icons/bs";
import { Link } from "react-router-dom";
export const teamColumns = [
  {
    title: "ID",
    dataIndex: "team_id",
    sorter: (a, b) => a.team_id - b.team_id,
  },
  {
    title: "Team Name",
    dataIndex: "team_name",
    sorter: (a, b) => a.team_name.localeCompare(b.team_name),
  },
  {
    title: "Action",
    dataIndex: "action",
    render: (data) => (
      <div>
        {" "}
        <span>
          <Link to="/teams/form/edit">
            <BsFillPencilFill className="table-icons edit-icon text-warning" />
          </Link>
          <BsFillTrashFill className="table-icons delete-icon text-danger" />
        </span>{" "}
      </div>
    ),
  },
];

export const teamColumnsHome = [
  {
    title: "ID",
    dataIndex: "team_id",
    sorter: (a, b) => a.team_id - b.team_id,
  },
  {
    title: "チームの名前",
    dataIndex: "team_name",
    sorter: (a, b) => a.team_name.localeCompare(b.team_name),
  },
  {
    title: "Action",
    dataIndex: "action",
    render: (data) => (
      <div>
        {" "}
        <span>
          <Link to="/teams/form/edit">
            <BsFillPencilFill className="table-icons edit-icon text-warning" />
          </Link>
          <BsFillTrashFill className="table-icons delete-icon text-danger" />
        </span>{" "}
      </div>
    ),
  },
];
