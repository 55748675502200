import { BarsOutlined, UserAddOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import { BarChartOutlined } from "@ant-design/icons";
import { FaClipboardList, FaTasks, FaRegEdit, FaPen } from "react-icons/fa";
import { BsFillPencilFill, BsFillTrashFill } from "react-icons/bs";
import { Link } from "react-router-dom";

function addActionColumn(
  { type, name },
  dataColumns,
  onEditCallback,
  onDeleteCallBack,
  pageLanguage
) {
  let columns = dataColumns && dataColumns.filter((d) => d.title !== "Action");
  if (type === "dropdown") {
    if (name === "project-dropdown") {
      columns.push({
        title: pageLanguage === "en" ? "Action" : "アクション",
        dataIndex: "project_id",
        render: (project_id, row) => {
          const menu = (
            <Menu>
              <Menu.Item>
                <Link
                  to={`/meetings/${project_id}`}
                  state={{ id: "project_id" }}
                >
                  <div className="d-flex align-items-center">
                    <FaClipboardList className="text-secondary" />
                    <span className="ms-2">
                      {pageLanguage === "en"
                        ? "View Meetings"
                        : "ミーティングを見る"}
                    </span>
                  </div>
                </Link>
              </Menu.Item>

              <Menu.Item>
                <Link to={`/tasks/${project_id}`} state={{ id: "project_id" }}>
                  <div className="d-flex align-items-center">
                    <FaTasks className="text-success" />
                    <span className="ms-2">
                      {pageLanguage === "en" ? "View Tasks" : "タスクを表示"}
                    </span>
                  </div>
                </Link>
              </Menu.Item>

              <Menu.Item key={6}>
                <Link to={`/edit-project/${project_id}`}>
                  <div className="d-flex align-items-center">
                    <FaPen className="text-warning" />
                    <span className="ms-2">
                      {pageLanguage === "en" ? "Edit" : "編集"}
                    </span>
                  </div>
                </Link>
              </Menu.Item>
              <Menu.Item>
                <div
                  className="d-flex align-items-center"
                  onClick={() => onDeleteCallBack(row.project_id)}
                >
                  <FaClipboardList className="text-danger" />
                  <span className="ms-2">
                    {pageLanguage === "en" ? "Delete" : "消去"}
                  </span>
                </div>
              </Menu.Item>
            </Menu>
          );
          return (
            <div key={row._id} style={{ textAlign: "center" }}>
              <Dropdown
                overlay={menu}
                placement="bottom"
                className="action-button"
              >
                <BarsOutlined />
              </Dropdown>
            </div>
          );
        },
      });
    } else if (name === "projectHome-dropdown") {
      columns.push({
        title: pageLanguage === "en" ? "Action" : "アクション",
        dataIndex: "project_id",
        render: (project_id) => (
          <div>
            <span>
              <Link to={`/edit-project/${project_id}`}>
                <BsFillPencilFill className="table-icons edit-icon text-warning" />
              </Link>
              <BsFillTrashFill
                onClick={() => onDeleteCallBack(project_id)}
                className="table-icons delete-icon text-danger"
              />
            </span>
          </div>
        ),
      });
    } else if (name === "meetingHome-dropdown") {
      columns.push({
        title: pageLanguage === "en" ? "Action" : "アクション",
        dataIndex: "meeting_id",
        render: (meeting_id) => (
          <div>
            <span>
              <Link to={`/edit-meetings/${meeting_id}`}>
                <BsFillPencilFill className="table-icons edit-icon text-warning" />
              </Link>
              <BsFillTrashFill
                onClick={() => onDeleteCallBack(meeting_id)}
                className="table-icons delete-icon text-danger"
              />
            </span>
          </div>
        ),
      });
    } else if (name === "tasksHome-dropdown") {
      columns.push({
        title: pageLanguage === "en" ? "Action" : "アクション",
        dataIndex: "task_id",
        render: (task_id) => (
          <div>
            <span>
              <Link to={`/edit-tasks/${task_id}`}>
                <BsFillPencilFill className="table-icons edit-icon text-warning" />
              </Link>
              <BsFillTrashFill
                onClick={() => onDeleteCallBack(task_id)}
                className="table-icons delete-icon text-danger"
              />
            </span>
          </div>
        ),
      });
    } else if (name === "client-dropdown")
      columns.push({
        title: pageLanguage === "en" ? "Action" : "アクション",
        dataIndex: "action",
        render: (data, row) => {
          const menu = (
            <Menu>
              <Menu.Item>
                <Link
                  to={`/project/${row.client_id}`}
                  state={{ id: "client_id" }}
                >
                  <div className="d-flex align-items-center">
                    <BarChartOutlined className="text-danger" />
                    <span className="ms-2">
                      {pageLanguage === "en"
                        ? "View Project"
                        : "プロジェクトを見る"}
                    </span>
                  </div>
                </Link>
              </Menu.Item>

              <Menu.Item>
                <Link
                  to={`/meetings/${row.client_id}`}
                  state={{ id: "client_id" }}
                >
                  <div className="d-flex align-items-center">
                    <FaClipboardList className="text-secondary" />
                    <span className="ms-2">
                      {" "}
                      {pageLanguage === "en"
                        ? "View Meetings"
                        : "ミーティングを見る"}
                    </span>
                  </div>
                </Link>
              </Menu.Item>

              <Menu.Item>
                <Link
                  to={`/tasks/${row.client_id}`}
                  state={{ id: "client_id" }}
                >
                  <div className="d-flex align-items-center">
                    <FaTasks className="text-success" />
                    <span className="ms-2">
                      {" "}
                      {pageLanguage === "en" ? "View Tasks" : "タスクを表示"}
                    </span>
                  </div>
                </Link>
              </Menu.Item>

              <Menu.Item>
                <Link
                  to={`/notes/${row.client_id}`}
                  state={{ id: "client_id" }}
                >
                  <div className="d-flex align-items-center">
                    <FaRegEdit className="text-success" />
                    <span className="ms-2">
                      {pageLanguage === "en" ? "View Notes" : "メモを見る"}
                    </span>
                  </div>
                </Link>
              </Menu.Item>

              <Menu.Item>
                <div
                  className="d-flex align-items-center"
                  onClick={() => onEditCallback(row)}
                >
                  <FaPen className="text-warning" />
                  <span className="ms-2">
                    {" "}
                    {pageLanguage === "en" ? "Edit" : "編集"}
                  </span>
                </div>
              </Menu.Item>

              <Menu.Item>
                <div
                  className="d-flex align-items-center"
                  onClick={() => onDeleteCallBack(row.client_id)}
                >
                  <FaClipboardList className="text-danger" />
                  <span className="ms-2">
                    {" "}
                    {pageLanguage === "en" ? "Delete" : "消去"}
                  </span>
                </div>
              </Menu.Item>
            </Menu>
          );
          return (
            <div key={row._id} style={{ textAlign: "center" }}>
              <Dropdown
                overlay={menu}
                placement="bottom"
                className="action-button"
              >
                <BarsOutlined />
              </Dropdown>
            </div>
          );
        },
      });
    else if (name === "company-dropdown") {
      columns.push({
        title: pageLanguage === "en" ? "Action" : "アクション",
        dataIndex: "company_id",
        render: (company_id) => {
          const menu = (
            <Menu>
              <Menu.Item key={1}>
                <Link to={`/client/${company_id}`}>
                  <div className="d-flex align-items-center">
                    <UserAddOutlined className="text-primary" />
                    <span className="ms-2">
                      {pageLanguage === "en"
                        ? "View Client"
                        : "クライアントを見る"}
                    </span>
                  </div>
                </Link>
              </Menu.Item>

              <Menu.Item key={2}>
                <Link
                  to={`/project/${company_id}`}
                  state={{ id: "company_id" }}
                >
                  <div className="d-flex align-items-center">
                    <BarChartOutlined className="text-danger" />
                    <span className="ms-2">
                      {" "}
                      {pageLanguage === "en"
                        ? "View Project"
                        : "プロジェクトを見る"}
                    </span>
                  </div>
                </Link>
              </Menu.Item>

              <Menu.Item key={3}>
                <Link
                  to={`/meetings/${company_id}`}
                  state={{ id: "company_id" }}
                >
                  <div className="d-flex align-items-center">
                    <FaClipboardList className="text-secondary" />
                    <span className="ms-2">
                      {" "}
                      {pageLanguage === "en"
                        ? "View Meetings"
                        : "ミーティングを見る"}
                    </span>
                  </div>
                </Link>
              </Menu.Item>

              <Menu.Item key={4}>
                <Link to={`/tasks/${company_id}`} state={{ id: "company_id" }}>
                  <div className="d-flex align-items-center">
                    <FaTasks className="text-success" />
                    <span className="ms-2">
                      {" "}
                      {pageLanguage === "en" ? "View Tasks" : "タスクを表示"}
                    </span>
                  </div>
                </Link>
              </Menu.Item>

              <Menu.Item key={5} state={{ id: "company_id" }}>
                <Link to={`/notes/${company_id}`} state={{ id: "company_id" }}>
                  <div className="d-flex align-items-center">
                    <FaRegEdit className="text-success" />
                    <span className="ms-2">
                      {pageLanguage === "en" ? "View Notes" : "メモを見る"}
                    </span>
                  </div>
                </Link>
              </Menu.Item>

              <Menu.Item key={6}>
                <Link to={`/edit-company/${company_id}`}>
                  <div className="d-flex align-items-center">
                    <FaPen className="text-warning" />
                    <span className="ms-2">
                      {" "}
                      {pageLanguage === "en" ? "Edit" : "編集"}
                    </span>
                  </div>
                </Link>
              </Menu.Item>

              <Menu.Item key={7}>
                <div
                  className="d-flex align-items-center"
                  onClick={() => onDeleteCallBack(company_id)}
                >
                  <FaClipboardList className="text-danger" />
                  <span className="ms-2">
                    {" "}
                    {pageLanguage === "en" ? "Delete" : "消去"}
                  </span>
                </div>
              </Menu.Item>
            </Menu>
          );
          return (
            <div key={company_id} style={{ textAlign: "center" }}>
              <Dropdown
                overlay={menu}
                placement="bottom"
                className="action-button"
              >
                <BarsOutlined />
              </Dropdown>
            </div>
          );
        },
      });
    } else if (name === "companyHome-dropdown") {
      columns.push({
        title: pageLanguage === "en" ? "Action" : "アクション",
        dataIndex: "company_id",
        render: (company_id) => (
          <div>
            <span>
              <Link to={`/edit-company/${company_id}`}>
                <BsFillPencilFill className="table-icons edit-icon text-warning" />
              </Link>
              <BsFillTrashFill
                onClick={() => onDeleteCallBack(company_id)}
                className="table-icons delete-icon text-danger"
              />
            </span>
          </div>
        ),
      });
    } else if (name === "meeting-dropdown") {
      columns.push({
        title: pageLanguage === "en" ? "Action" : "アクション",
        dataIndex: "meeting_id",
        render: (meeting_id) => {
          const menu = (
            <Menu>
              <Menu.Item key={6}>
                <Link to={`/edit-meetings/${meeting_id}`}>
                  <div className="d-flex align-items-center">
                    <FaPen className="text-warning" />
                    <span className="ms-2">
                      {" "}
                      {pageLanguage === "en" ? "Edit" : "編集"}
                    </span>
                  </div>
                </Link>
              </Menu.Item>

              <Menu.Item key={7}>
                <div
                  className="d-flex align-items-center"
                  onClick={() => onDeleteCallBack(meeting_id)}
                >
                  <FaClipboardList className="text-danger" />
                  <span className="ms-2">
                    {" "}
                    {pageLanguage === "en" ? "Delete" : "消去"}
                  </span>
                </div>
              </Menu.Item>
            </Menu>
          );
          return (
            <div key={meeting_id}>
              <Dropdown
                overlay={menu}
                placement="bottom"
                className="action-button"
              >
                <BarsOutlined />
              </Dropdown>
            </div>
          );
        },
      });
    }

    return columns;
  } else if (name === "user1-dropdown") {
    columns.push({
      title: pageLanguage === "en" ? "Action" : "アクション",
      dataIndex: "action",
      render: (data, row) => (
        <div>
          {" "}
          <span>
            <BsFillPencilFill
              className="table-icons edit-icon text-warning"
              onClick={() => onEditCallback(row)}
            />
          </span>{" "}
        </div>
      ),
    });
    return columns;
  } else {
    columns.push({
      title: pageLanguage == "en" ? "Action" : "アクション",
      dataIndex: "action",
      render: (data, row) => (
        <div>
          {" "}
          <span>
            <BsFillPencilFill
              className="table-icons edit-icon text-warning"
              onClick={() => onEditCallback(row)}
            />
            {name !== "user" && (
              <BsFillTrashFill
                onClick={() => onDeleteCallBack(row)}
                className="table-icons delete-icon text-danger"
              />
            )}
          </span>{" "}
        </div>
      ),
    });
    return columns;
  }
}

export function isCompanyId(companyId, formdata) {
  if (companyId) {
    formdata["company_id"] = companyId;
  }
  return formdata;
}

export function isProjectId(projectId, formdata) {
  if (projectId) {
    formdata["project_id"] = projectId;
  }
  return formdata;
}

export { addActionColumn };
