import React, { useEffect, useState } from "react";
import {
  Inject,
  ScheduleComponent,
  Day,
  Week,
  Month,
  Agenda,
  ViewDirective,
  ViewsDirective,
} from "@syncfusion/ej2-react-schedule";
import { useDispatch, useSelector } from "react-redux";
import { meetingListAction } from "../actions/meetingAction";
import { taskListAction } from "../actions/taskListAction";
import Heading from "../Heading";
import { formatDateList, formatDateMeet } from "../../helpers/commonHelpers";
import useTranslation from "../customHooks/translations";
import { IoHomeOutline } from "react-icons/io5";
import { useNavigate } from "react-router";
import "./style.css";

function UserCalender() {
  const dispatch = useDispatch();

  const [taskData, setTaskData] = useState([]);
  const [todayMeeting, setTodayMeeting] = useState([]);

  const { meet } = useSelector((state) => state.meet);
  const { tasks } = useSelector((state) => state.task);
  const today = new Date();
  const translation = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    Todaystask();
  }, [tasks]);

  useEffect(() => {
    TodayMeet();
  }, [meet]);

  useEffect(() => {
    dispatch(taskListAction());
    dispatch(meetingListAction());
  }, []);

  let todaystask = [];
  const Todaystask = () => {
    tasks &&
      tasks.map((t) => {
        if (formatDateList(t.startdate) === formatDateList(today)) {
          todaystask.push(t);
        }
        setTaskData(todaystask);
      });
  };

  let todayMeet = [];
  const TodayMeet = () => {
    meet &&
      meet.map((t) => {
        if (formatDateMeet(t.startdate) === formatDateMeet(today)) {
          todayMeet.push(t);
        }
        setTodayMeeting(todayMeet);
      });
  };

  const meeting = meet
    .map((m) => {
      return m.startdate;
    })
    .filter((d) => {
      return formatDateMeet(d) == formatDateMeet(new Date());
    });

  const task = tasks
    .map((m) => {
      return m.startdate;
    })
    .filter((d) => {
      return formatDateList(d)== formatDateList(new Date());
    });

    useEffect(() => {
      if(taskData.length == 0){
        setTaskData([])
      }
      if(todayMeeting.length == 0){
        setTodayMeeting([])
      }

    }, [])
    

  const data = [
    {
      Id: 1,
      Subject: "Meeting" + " " + "-" + " " + todayMeeting.length,
      StartTime: meeting[0],
      EndTime: meeting[0],
      IsAllDay: true,
    },
    {
      Id: 2,
      Subject: "Task" + " " + "-" + " " + taskData.length,
      StartTime: task[0],
      EndTime: task[0],
      IsAllDay: true,
    },
  ];

  return (
    <div className="main-container">
      <Heading title={translation.calendar} />

      <div className="px-5" style={{ marginTop: "30px" }}>
        <div className="row">
          <div className="col-lg-12 card h-100 px-0">
            <div className="card-header mt-3 d-flex flex-row">
              <div className="fw-500 fs-6">
                <p>{translation.calendar}</p>
              </div>
              <div>
                <h3
                  style={{ marginTop: "-2px", marginLeft: "10px" }}
                  className="fs-20 p-0 cursor-pointer"
                  onClick={() => navigate("/dashboard")}
                >
                  <IoHomeOutline
                    style={{ color: "rgb(90 86 86)", fontSize: "23px" }}
                  />
                </h3>
              </div>
            </div>
            <div className="card-body">
              <ScheduleComponent
                currentView="Month"
                timeScale={{ enable: true }}
                height="550px"
                selectedDate={new Date()}
                readOnly
                eventSettings={{
                  dataSource: data,
                }}
              >
                <ViewsDirective>
                  <ViewDirective option="Month" readonly={true} />
                  <ViewDirective option="Week" readonly={true} />
                  <ViewDirective option="Day" readonly={true} />
                  <ViewDirective option="Agenda" readonly={true} />
                </ViewsDirective>
                <Inject services={[Day, Week, Month, Agenda]} />
              </ScheduleComponent>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default UserCalender;
