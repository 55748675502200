const initialState = { companies: [] };
export const companyReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "ADD_COMAPNIES":
      return { ...state, companies: payload };
    default:
      return state;
  }
};
