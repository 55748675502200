import React, { useEffect, useState } from "react";
import Heading from "../Heading";
import TableCard from "../TableCard";
import CardContainer from "../Card/cardContainer";
import { addActionColumn } from "../../helpers/companyHelpers";
import {
  formModalController,
  listingCommonDefaulter,
  modalInitialValue,
} from "../../helpers/commonHelpers";
import {
  employeeFormLayout,
  employeeFormLayoutJap,
} from "../../Data/FormsLayout/employeeFormLayout";
import ModalContainer from "../Modal";
import { useDispatch, useSelector } from "react-redux";
import { employeesListAction } from "../actions/employeesListAction";
import {
  addHandler,
  deleteHandler,
  updateHandler,
} from "../../apiParsing/data";
import { apipaths } from "../../services/apiPaths";
import { Link } from "react-router-dom";
import { BsFillPencilFill } from "react-icons/bs";
import { BsFillTrashFill } from "react-icons/bs";
import useTranslation from "../customHooks/translations";

function Employees() {
  const [modal, setModal] = useState(modalInitialValue);
  const dispatch = useDispatch();
  const translation = useTranslation();

  const { employees } = useSelector((state) => state.employee);
  const { companies } = useSelector((state) => state.company);

  useEffect(() => {
    dispatch(employeesListAction());
  }, []);

  useEffect(() => {
    if (companies) {
      listingCommonDefaulter([companies], employeeFormLayout, ["company_id"]);
    }
  }, [companies]);

  useEffect(() => {
    if (companies) {
      listingCommonDefaulter([companies], employeeFormLayoutJap, [
        "company_id",
      ]);
    }
  }, [companies]);

  const employeeColumns = [
    {
      title: "ID",
      dataIndex: "employee_id",
      sorter: (a, b) => a.employee_id - b.employee_id,
    },
    {
      title: "Name",
      dataIndex: "first_name",
      sorter: (a, b) => a.first_name.localeCompare(b.first_name),
      render: (d, data) => {
        return (
          <>
            <label
              style={{ color: "#1890ff" }}
              onClick={() => {
                dataTest(data);
              }}
            >
              {data.first_name}
              {data.last_name}
            </label>
          </>
        );
      },
    },
    {
      title: "Telephone",
      dataIndex: "tel1",
      sorter: (a, b) => a.tel1 - b.tel1,
    },
    {
      title: "Fax",
      dataIndex: "fax",
      sorter: (a, b) => a.fax.localeCompare(b.fax),
    },
    {
      title: "Email",
      dataIndex: "email1",
      sorter: (a, b) => a.email1.localeCompare(b.email1),
    },
    {
      title: "Company",
      dataIndex: "company",
      sorter: (a, b) => a.company.name.localeCompare(b.company.name),
      render: (data) => {
        return <>{data?.name}</>;
      },
    },
    {
      title: "Address",
      dataIndex: "address",
      sorter: (a, b) => a.address.localeCompare(b.address),
    },
    {
      title: "City",
      dataIndex: "city",
      sorter: (a, b) => a.city.localeCompare(b.city),
    },
    {
      title: "Country",
      dataIndex: "country",
      sorter: (a, b) => a.country.localeCompare(b.country),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (data) => (
        <div>
          {" "}
          <span>
            <Link to="/employees/form/edit">
              <BsFillPencilFill className="table-icons edit-icon text-warning" />
            </Link>
            <BsFillTrashFill className="table-icons delete-icon text-danger" />
          </span>{" "}
        </div>
      ),
    },
  ];

  const employeeColumnsHome = [
    {
      title: "ID",
      dataIndex: "employee_id",
      sorter: (a, b) => a.employee_id - b.employee_id,
    },
    {
      title: "名前",
      dataIndex: "first_name",
      sorter: (a, b) => a.first_name.localeCompare(b.first_name),
      render: (d, data) => {
        return (
          <>
            <label
              style={{ color: "#1890ff" }}
              onClick={() => {
                dataTest(data);
              }}
            >
              {data.first_name}
            </label>
          </>
        );
      },
    },
    {
      title: "電話",
      dataIndex: "tel1",
      sorter: (a, b) => a.tel1 - b.tel1,
    },
    {
      title: "ファックス",
      dataIndex: "fax",
      sorter: (a, b) => a.fax.localeCompare(b.fax),
    },
    {
      title: "Eメール",
      dataIndex: "email1",
      sorter: (a, b) => a.email1.localeCompare(b.email1),
    },
    {
      title: "会社",
      dataIndex: "company",
      sorter: (a, b) => a.company.name.localeCompare(b.company.name),
      render: (data) => {
        return <>{data?.name}</>;
      },
    },
    {
      title: "住所",
      dataIndex: "address",
      sorter: (a, b) => a.address.localeCompare(b.address),
    },
    {
      title: "都市",
      dataIndex: "city",
      sorter: (a, b) => a.city.localeCompare(b.city),
    },
    {
      title: "国",
      dataIndex: "country",
      sorter: (a, b) => a.country.localeCompare(b.country),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (data) => (
        <div>
          {" "}
          <span>
            <Link to="/employees/form/edit">
              <BsFillPencilFill className="table-icons edit-icon text-warning" />
            </Link>
            <BsFillTrashFill className="table-icons delete-icon text-danger" />
          </span>{" "}
        </div>
      ),
    },
  ];

  const dataTest = (data) => {
    formModalController(
      setModal,
      employeeFormLayout,
      employeeFormLayoutJap,
      `${translation.edit_employee}`,
      `edit-employee-form`,
      data,
      (data) =>
        updateHandler(
          apipaths.addEmployee,
          data,
          "employee_id",
          setModal,
          dispatch,
          employeesListAction
        )
    );
  };

  return (
    <>
      <ModalContainer
        isOpen={modal.open}
        title={modal.title}
        closeIcon={() => setModal(modalInitialValue)}
        content={modal.content}
        footer={false}
      />
      <div className="main-container">
        <Heading title={translation.employee} />
        <div className="content-wrapper px-5">
          <CardContainer hideTotal={true} />
        </div>

        <div className="px-5">
          <TableCard
            button={{
              title: translation.add_Employee,
              onClick: () =>
                formModalController(
                  setModal,
                  employeeFormLayout,
                  employeeFormLayoutJap,
                  `${translation.add_Employee}`,
                  `add-employee-form`,
                  {},
                  (data) =>
                    addHandler(
                      apipaths.addEmployee,
                      data,
                      setModal,
                      dispatch,
                      employeesListAction
                    )
                ),
            }}
            columns={addActionColumn(
              {},
              employeeColumns,
              (data) =>
                formModalController(
                  setModal,
                  employeeFormLayout,
                  employeeFormLayoutJap,
                  `${translation.edit_employee}`,
                  `edit-employee-form`,
                  data,
                  (data) =>
                    updateHandler(
                      apipaths.addEmployee,
                      data,
                      "employee_id",
                      setModal,
                      dispatch,
                      employeesListAction
                    )
                ),
              (data) =>
                deleteHandler(
                  apipaths.deleteEmployee,
                  data?.["employee_id"],
                  setModal,
                  dispatch,
                  employeesListAction
                ),
              translation._language
            )}
            jaCol={addActionColumn(
              {},
              employeeColumnsHome,
              (data) =>
                formModalController(
                  setModal,
                  employeeFormLayout,
                  employeeFormLayoutJap,
                  `${translation.edit_employee}`,
                  `edit-employee-form`,
                  data,
                  (data) =>
                    updateHandler(
                      apipaths.addEmployee,
                      data,
                      "employee_id",
                      setModal,
                      dispatch,
                      employeesListAction
                    )
                ),
              (data) =>
                deleteHandler(
                  apipaths.deleteEmployee,
                  data?.["employee_id"],
                  setModal,
                  dispatch,
                  employeesListAction
                ),
              translation._language
            )}
            data={employees}
            title={translation.employee}
          />
        </div>
      </div>
    </>
  );
}

export default Employees;
