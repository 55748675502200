import { BarsOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { SubTaskFormLayout } from "../../Data/FormsLayout/subTaskFormLayout";
import { taskFormLayout } from "../../Data/FormsLayout/taskFormLayout";
import {
  formatDateList,
  listingCommonDefaulter,
  modalInitialValue,
  multiTabFormModalController,
} from "../../helpers/commonHelpers";
import { addActionColumn } from "../../helpers/companyHelpers";
import { sendRequest } from "../../services";
import { apipaths } from "../../services/apiPaths";
import { taskListAction } from "../actions/taskListAction";
import CardContainer from "../Card/cardContainer";
import useTranslation from "../customHooks/translations";
import Heading from "../Heading";
import ModalContainer from "../Modal";
import TableCard from "../TableCard";
import { menu } from "./../../Data/Columns/taskColumns";
import moment from "moment";

const TaskCompanyTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: companyId } = useParams();
  const [modal, setModal] = useState(modalInitialValue);
  const translation = useTranslation();
  const location = useLocation();
  const [taskList, setTaskList] = useState([]);
  const [product, setProduct] = useState("");

  const { tasks } = useSelector((state) => state.task);
  const { clients } = useSelector((state) => state.client);
  const { companies } = useSelector((state) => state.company);
  const { employees } = useSelector((state) => state.employee);
  const { projects } = useSelector((state) => state.project);

  const name = companies.filter((d) => {
    return d.company_id == parseInt(companyId);
  });

  const selectedCompanyName = name[0]?.name;

  const filterTaskHandler = () => {
    if (companyId) {
      let data = tasks.filter((task) => task.company_id == parseInt(companyId));
      setTaskList(data);
    } else setTaskList(tasks);
  };

  const filterProjectTask = () => {
    if (companyId) {
      let data = tasks.filter((task) => task.project_id == parseInt(companyId));
      setTaskList(data);
    } else setTaskList(tasks);
  };

  const filterClientTask = () => {
    if (companyId) {
      let data = tasks.filter((task) => task.client_id == parseInt(companyId));
      setTaskList(data);
    } else setTaskList(tasks);
  };

  const removeItem = () => {
    if (companyId) {
      let data = companies.filter(
        (task) => task.company_id !== parseInt(companyId)
      );
      setProduct(data);
    } else setProduct(companies);
  };

  useEffect(() => {
    if (projects && clients && companies && employees && product) {
      const arr = [projects, clients, companies, employees, product];
      const kerArr = [
        "project_id",
        "client_id",
        "company_id",
        "employee_id",
        "product",
      ];
      listingCommonDefaulter(arr, taskFormLayout, kerArr);
    }
  }, [projects, clients, companies, employees]);

  const taskColumns = [
    {
      title: "ID",
      dataIndex: "task_id",
      sorter: (a, b) => a.task_id - b.task_id,
      render: (id) => {
        return id;
      },
    },
    {
      title: "Subject",
      dataIndex: "subject",
      sorter: (a, b) => a?.subject?.localeCompare(b?.subject),
      render: (subject, data) => {
        return (
          <>
            <label
              style={{ color: "#1890ff" }}
              onClick={() => {
                navigate(`/edit-tasks/${data.task_id}`);
              }}
            >
              {subject}
            </label>
          </>
        );
      },
    },
    {
      title: "Priority",
      dataIndex: "priority",
      sorter: (a, b) => a?.priority?.localeCompare(b?.priority),
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
    },
    {
      title: "Start To End Date",
      dataIndex: "startdate",
      sorter: (a, b) =>
        moment(formatDateList(a.startdate)).unix() -
        moment(formatDateList(b.startdate)).unix(),
      render: (startdate, data) => {
        return (
          formatDateList(startdate) +
          " " +
          "to" +
          " " +
          formatDateList(data.enddate)
        );
      },
    },
    {
      title: "Assignee",
      dataIndex: "employee_id",
      sorter: (a, b) => a.employee_id - b.employee_id,
      render: (employee_id, row) => {
        const employee = row["employee"];
        if (employee) return employee.first_name + " " + employee.last_name;
        else return "";
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (data, index) => (
        <div key={index}>
          <Dropdown overlay={menu} placement="bottom" className="action-button">
            <BarsOutlined />
          </Dropdown>
        </div>
      ),
    },
  ];

  const taskColumnsHome = [
    {
      title: "ID",
      dataIndex: "task_id",
      sorter: (a, b) => a.task_id - b.task_id,
      render: (id) => {
        return id;
      },
    },
    {
      title: "主題",
      dataIndex: "subject",
      sorter: (a, b) => a?.subject?.localeCompare(b?.subject),
      render: (subject, data) => {
        return (
          <>
            <label
              style={{ color: "#1890ff" }}
              onClick={() => {
                navigate(`/edit-tasks/${data.task_id}`);
              }}
            >
              {subject}
            </label>
          </>
        );
      },
    },
    {
      title: "優先順位",
      dataIndex: "priority",
      sorter: (a, b) => a?.priority?.localeCompare(b?.priority),
    },
    {
      title: "状態",
      dataIndex: "status",
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
    },
    {
      title: "開始日から終了日まで",
      dataIndex: "startdate",
      sorter: (a, b) =>
        moment(formatDateList(a.startdate)).unix() -
        moment(formatDateList(b.startdate)).unix(),
      render: (startdate, data) => {
        return (
          formatDateList(startdate) +
          " " +
          "to" +
          " " +
          formatDateList(data.enddate)
        );
      },
    },
    {
      title: "譲受人",
      dataIndex: "employee_id",
      sorter: (a, b) => a.employee_id - b.employee_id,
      render: (employee_id, row) => {
        const employee = row["employee"];
        if (employee) return employee.first_name + " " + employee.last_name;
        else return "";
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (data, index) => (
        <div key={index}>
          <Dropdown overlay={menu} placement="bottom" className="action-button">
            <BarsOutlined />
          </Dropdown>
        </div>
      ),
    },
  ];

  const updateTaskHandler = async (task) => {
    task["id"] = task.task_id;
    delete task.project_id;
    delete task.enable;
    delete task.company;
    delete task.client;
    delete task.created_at;
    delete task.updated_at;
    delete task.user_id;
    delete task.edate;
    delete task.sdate;
    delete task.status;
    delete task.employee;
    delete task.company_project_profile_id;

    task["operation"] = "update";
    task["task_type"] = "update";
    task["OPtype"] = "Task";

    const {
      data: { message },
      success,
    } = await sendRequest(apipaths.addTask, task);
    if (success) {
      dispatch(taskListAction());
      setModal(modalInitialValue);
      Swal.fire({
        position: "center",
        icon: "success",
        title: message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const dataTest = (data) => {
    multiTabFormModalController(
      setModal,
      selectedCompanyName,
      { task: taskFormLayout, subTask: SubTaskFormLayout },
      { title1: "Tasks", title2: "Sub Task" },
      `add-task-form`,
      data,
      (data) => updateTaskHandler(data)
    );
  };

  useEffect(() => {
    if (companies.length > 0) removeItem();
  }, [companies]);

  useEffect(() => {
    if (location?.state?.id == "company_id") {
      setTaskList(tasks.filter((m) => m.company_id == parseInt(companyId)));
    } else if (location?.state?.id == "client_id") {
      setTaskList(tasks.filter((m) => m.client_id == parseInt(companyId)));
    } else {
      setTaskList(tasks.filter((m) => m.project_id == parseInt(companyId)));
    }
  }, [tasks]);

  const deleteTaskHandler = (task_id) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#1890ff",
    }).then(async ({ isConfirmed }) => {
      if (!isConfirmed) return null;

      const { data } = await sendRequest(apipaths.deleteTask, {
        delete_id: task_id,
      });
      const { success, message } = data[0].original;
      if (success === 1) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: message,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch(taskListAction());
      }
    });
  };

  useEffect(() => {
    dispatch(taskListAction());
  }, []);

  return (
    <>
      <ModalContainer
        isOpen={modal.open}
        title={modal.title}
        closeIcon={() => setModal(modalInitialValue)}
        content={modal.content}
        footer={false}
      />
      <div className="main-container">
        <Heading title={translation.task} />
        <div className="content-wrapper px-5">
          <CardContainer hideTotal={true} />
        </div>

        <div className="px-5">
          <TableCard
            button={{
              title: translation.add_task,
              onClick: () => navigate("/tasks/form/add"),
            }}
            columns={addActionColumn(
              { type: `dropdown`, name: `tasksHome-dropdown` },

              taskColumns,
              (data) =>
                multiTabFormModalController(
                  setModal,
                  selectedCompanyName,
                  { task: taskFormLayout, subTask: SubTaskFormLayout },
                  { title1: "Tasks", title2: "Sub Task" },
                  `add-task-form`,
                  data,
                  (data) => updateTaskHandler(data)
                ),
              (task_id) => deleteTaskHandler(task_id),

              translation._language
            )}
            jaCol={addActionColumn(
              { type: `dropdown`, name: `tasksHome-dropdown` },

              taskColumnsHome,
              (data) =>
                multiTabFormModalController(
                  setModal,
                  selectedCompanyName,
                  { task: taskFormLayout, subTask: SubTaskFormLayout },
                  { title1: "Tasks", title2: "Sub Task" },
                  `add-task-form`,
                  data,
                  (data) => updateTaskHandler(data)
                ),
              (task_id) => deleteTaskHandler(task_id),
              translation._language
            )}
            data={taskList}
            title={translation.task}
          />
        </div>
      </div>
    </>
  );
};

export default TaskCompanyTable;
