export const emailFormLayout = [
  {
    id: 1,
    label: "Subject",
    key: "subject",
    type: "text",
    required: true,

    placeholder: "Subject",
    containerClass: "col-sm-12 col-md-6 col-lg-6  ",
  },
  {
    id: 2,
    label: "Sender",
    key: "sender",
    type: "text",
    placeholder: "Sender",
    containerClass: "col-sm-12 col-md-6 col-lg-3  ",
  },
  {
    id: 3,
    label: "Send Date",
    key: "send_date",
    type: "date",
    containerClass: "col-sm-12 col-md-6 col-lg-3",
  },
  {
    id: 4,
    label: "Message",
    type: "textarea",
    placeholder: "Enter Message",
    key: "message",
    containerClass: "col-lg-12 col-md-12 col-sm-12",
    rows: 3,
  },
  {
    id: 5,
    label: "Conclusion",
    key: "conclusion",
    type: "text",
    placeholder: "Conclusion",
    containerClass: "col-sm-12 col-md-6 col-lg-12  ",
  },
  {
    id: 6,
    label: "Next Step",
    key: "next_step",
    type: "text",
    placeholder: "Next Step",
    containerClass: "col-sm-12 col-md-6 col-lg-8  ",
  },
  {
    id: 7,
    label: "Attachment File",
    type: "file",
    key: "attachmentFile",
    containerClass: "col-sm-12 col-md-4 col-lg-3",
  },
  {
    id: 9,
    label: "Client",
    type: "select",
    placeholder: "Select Client",
    key: "client_id",
    options: [
      {
        label: "Client 1",
        value: "client1",
      },
      {
        label: "Client 2",
        value: "client2",
      },
    ],
    containerClass: "col-sm-12 col-md-4 col-lg-4",
  },
  {
    id: 10,
    label: "Project",
    type: "select",
    placeholder: "Select Project",
    key: "project_id",
    options: [
      {
        label: "Project 1",
        value: "proj1",
      },
      {
        label: "Project 2",
        value: "proj2",
      },
    ],
    containerClass: "col-sm-12 col-md-4 col-lg-4",
  },
  {
    id: 11,
    label: "Employee",
    type: "select",
    placeholder: "Select Employee",
    key: "employee_id",
    options: [
      {
        label: "Employee 1",
        value: "emp1",
      },
      {
        label: "Employee 2",
        value: "emp2",
      },
    ],
    containerClass: "col-sm-12 col-md-4 col-lg-4",
  },
];
