const initialState = { tasks: [] };
export const taskReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "ADD_TASKS":
      return { ...state, tasks: payload };
    default:
      return state;
  }
};
