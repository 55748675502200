import React, { useEffect, useState } from "react";
import "./New.css";
import Heading from "./../Heading/index";
import CardContainer from "../Card/cardContainer";
import { getUserRole, regName } from "../../helpers/commonHelpers";
import { useDispatch, useSelector } from "react-redux";
import { apipaths } from "../../services/apiPaths";
import { addUserDetailsAction } from "../actions/userActions";
import { sendRequest } from "../../services";
import Swal from "sweetalert2";
import useTranslation from "../customHooks/translations";
import { IoHomeOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { render } from "@testing-library/react";

const UserProfile = () => {
  const [formData, setFormData] = useState("");
  const [passwordData, setPasswordData] = useState({
    oldpassword: "",
    password: "",
    cnfpassword: "",
  });

  const { userDetails } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const translation = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(addUserDetailsAction());
  }, []);

  useEffect(() => {
    setFormData(userDetails);
  }, [userDetails]);

  const onInputChange = (e) => {
    const target = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };

  const PasswordHandler = (e) => {
    const target = e.target;
    setPasswordData((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };

  const EditUserprofileHandler = async () => {
    if (formData.name.trim() == "") {
    } else {
      let { username, name, gender, contact_number, email } = formData;
      let apiData = { ...apipaths.editProfile };
      const {
        success,
        data: { message },
      } = await sendRequest(apiData, {
        username,
        name,
        gender,
        contact_number,
        email,
      });

      if (success === true) {
        dispatch(addUserDetailsAction());
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Profile update successfully",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  };

  const EditUserPasswordHandler = async () => {
    let { oldpassword, password, cnfpassword } = passwordData;
    if (oldpassword) {
      if (password != cnfpassword) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Password and Confirm Password do not match",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        let apiData = { ...apipaths.changePassword };
        const { success, message } = await sendRequest(apiData, {
          password,
          oldpassword,
        });
        setPasswordData({
          oldpassword: "",
          password: "",
          cnfpassword: "",
        });
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please enter valid password",
          showConfirmButton: false,
          timer: 1500,
        });
        if (success === true) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your Password has been changed",
            showConfirmButton: false,
            timer: 1500,
          });
          setPasswordData({
            oldpassword: "",
            password: "",
            cnfpassword: "",
          });
        }
      }
    } else {
      Swal.fire({
        position: "center",
        icon: "error",
        message: "Fill The Current Password!",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const fileUploadHandler = async (e, document) => {
    // if (!e.target.files[0]) {
    //   return null;
    // }
    // let formdata = new FormData();
    // formdata.append("image", e.target.files[0]);
    // formdata.append("path", document.path);
    // formdata.append("user_id", userDetails.user_id);

    // let extension = e.target.files[0].name.split(".");
    // extension = extension[extension.length - 1];

    // let filename = document.path;
    // filename = filename.substring(0, filename.length - 1);
    // filename = filename + "." + extension;
    // formdata.append("filename", e.target.files[0].name);

    // const { success, message } = await sendRequest(
    //   apipaths.userProfileImg,
    //   formdata
    // );
    // console.log("formdata", formdata);
    // if (success === true) {
    //   dispatch(addUserDetailsAction());
    // }
    if (!e.target.files[0]) {
      return null;
    }
    let formdata = new FormData();
    formdata.append("profile_image", e.target.files[0]);
    formdata.append("path", document.path);
    formdata.append("user_id", userDetails.user_id);

    let extension = e.target.files[0].name.split(".");
    extension = extension[extension.length - 1];

    let filename = document.path;
    filename = filename.substring(0, filename.length - 1);
    filename = filename + "." + extension;
    formdata.append("filename", e.target.files[0].name);

    const { success } = await sendRequest(apipaths.userProfileImg, formdata);
    if (success === true) {
      dispatch(addUserDetailsAction());
    }
  };
  console.log("userprofile", userDetails.profile_image);

  return (
    <>
      <div className="main-container">
        <Heading title={translation.my_profile} />
        <div className="content-wrapper px-5">
          <CardContainer hideTotal={true} />
        </div>

        <div className="px-5">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-12 mb-2">
              <div className="card h-100">
                <div className="card-header mt-3 d-flex flex-row">
                  <div className="fw-500 fs-20">
                    <p>{translation.my_profile}</p>
                  </div>
                  <div>
                    <h3
                      style={{ marginTop: "0px", marginLeft: "10px" }}
                      className="fs-20 p-0 cursor-pointer"
                      onClick={() => navigate("/dashboard")}
                    >
                      <IoHomeOutline
                        style={{ color: "rgb(90 86 86)", fontSize: "23px" }}
                      />
                    </h3>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className="card border-0 profile-update-card p-3 position-relative w-100 mx-3 mt-4">
                      <div className="profile-bg bg-primary">
                        <div className="user-image">
                          <img
                            id="img"
                            className="img"
                            src={
                              "https://jaimbackend.itaims.in" +
                              userDetails.profile_image
                            }
                            // src={profileImg}
                            // src={"./images/user-profile.jpg"}
                            alt=""
                          />

                          <div className="file btn btn-primary btn-lg change cursor-pointer">
                            <label>{translation.change_photo}</label>
                            <input
                              type="file"
                              accept="image/*"
                              name="image-upload"
                              onChange={(e) =>
                                fileUploadHandler(e, {
                                  name: "Profile Image",
                                  path: "profile_image/",
                                })
                              }
                              // onChange={imageHandler}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="card border-0 d-flex profile-update-card w-100 p-3">
                      <h3 className="card-title">
                        {translation.update_profile}
                      </h3>
                      <form>
                        <div className="row">
                          <div className="col-md-6 mb-2">
                            <div class="form-group">
                              <label htmlFor="input-field">
                                {translation.name}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="name"
                                placeholder={translation.name}
                                onChange={onInputChange}
                                value={formData.name}
                              />
                              {formData.name == 0 ||
                              formData?.name?.trim() == "" ? (
                                <p className="text-danger">
                                  Please fill out this field
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="col-md-6 mb-2">
                            <div class="form-group">
                              <label htmlFor="input-field">
                                {translation.username}
                              </label>
                              <input
                                type="text"
                                name="username"
                                className="form-control"
                                id="username"
                                placeholder={translation.name}
                                onChange={onInputChange}
                                value={formData.username}
                              />
                            </div>
                          </div>

                          <div className="col-md-6 mb-2">
                            <div class="form-group">
                              <label htmlFor="input-field">
                                {translation.email}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="email"
                                placeholder={translation.email}
                                onChange={onInputChange}
                                value={formData.email}
                              />
                            </div>
                          </div>
                          <div className="col-md-6 mb-2">
                            <div class="form-group">
                              <label htmlFor="input-field">
                                {translation.contact_number}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="contact_number"
                                key="contact_number"
                                placeholder={translation.contact_number}
                                onChange={onInputChange}
                                value={formData.contact_number}
                              />
                            </div>
                          </div>
                          <div className="col-md-6 mb-2">
                            <div class="form-group">
                              <label htmlFor="input-field">
                                {translation.gender}
                              </label>
                              <select
                                class="form-control form-select"
                                onChange={onInputChange}
                                value={formData.gender}
                                name="gender"
                              >
                                <option>{translation.select_gender}</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6 mb-2">
                            <div class="form-group">
                              <label htmlFor="input-field">
                                {translation.role}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="role_id"
                                placeholder={getUserRole(userDetails.role_id)}
                                disabled
                              />
                            </div>
                          </div>

                          <div className="col-lg-12 d-flex justify-content-end">
                            <button
                              className="btn btn-primary mt-4 border rounded-1"
                              type="button"
                              onClick={() => EditUserprofileHandler()}
                            >
                              {translation.update_profile}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card border-0 profile-update-card p-3">
                      <h3 className="card-title">
                        {translation.update_password}
                      </h3>
                      <form action="">
                        <div className="row profile-details">
                          <div className="col-lg-12">
                            <div class="mb-3 form-field">
                              <label htmlFor="input-field">
                                {translation.current_password}
                              </label>
                              <input
                                type="password"
                                className="form-control"
                                id="template-input"
                                placeholder=""
                                name="oldpassword"
                                onChange={PasswordHandler}
                                value={passwordData?.oldpassword}
                              />
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div class="mb-3 form-field">
                              <label htmlFor="input-field">
                                {translation.new_password}
                              </label>
                              <input
                                type="password"
                                className="form-control"
                                placeholder=""
                                name="password"
                                onChange={PasswordHandler}
                                value={passwordData?.password}
                              />
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div class="mb-3 form-field">
                              <label htmlFor="input-field">
                                {translation.confirm_password}
                              </label>
                              <input
                                type="password"
                                className="form-control"
                                placeholder=""
                                name="cnfpassword"
                                onChange={PasswordHandler}
                                value={passwordData?.cnfpassword}
                              />
                            </div>
                          </div>
                          <div className="col-lg-12 d-flex justify-content-end">
                            <button
                              className="btn btn-primary border rounded-1"
                              type="button"
                              onClick={() => EditUserPasswordHandler()}
                            >
                              {translation.change_password}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfile;
