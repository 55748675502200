import { sendRequest } from "../../services";
import { apipaths } from "../../services/apiPaths";

export const meetingListAction = () => async (dispatch) => {
  const { success, data } = await sendRequest(apipaths.meetingList);
  const { meetings } = data;
  if (success) {
    dispatch({ type: "ADD_MEET", payload: meetings });
  }
};
