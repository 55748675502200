import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { formatDateList, regName, trim } from "../../helpers/commonHelpers";
import { sendRequest } from "../../services";
import { apipaths } from "../../services/apiPaths";
import { companyListAction } from "../actions/comanyAction";
import { projectListAction } from "../actions/projectAction";
import { taskListAction } from "../actions/taskListAction";
import CardContainer from "../Card/cardContainer";
import Heading from "../Heading";
import { Tabs } from "antd";
import "./style.css";
import useTranslation from "../customHooks/translations";
import { BsFillTrashFill } from "react-icons/bs";
import { DatePicker, Space } from "antd";

const { RangePicker } = DatePicker;

function TaskForm() {
  const [formData, setFormData] = useState({});
  const [Error, setError] = useState(false);
  const [date, setDate] = useState("");
  const [value, setValue] = useState("");
  const [val, setVal] = useState([value]);
  const [activeKey, setActiveKey] = useState("item-1");
  const dispatch = useDispatch();
  const translation = useTranslation();

  const { clients } = useSelector((state) => state.client);
  const { companies } = useSelector((state) => state.company);
  const { employees } = useSelector((state) => state.employee);
  const { projects } = useSelector((state) => state.project);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(taskListAction());
    dispatch(companyListAction());
    dispatch(projectListAction());
  }, []);

  const handleEvent = (event, picker) => {
    setDate(
      formatDateList(event[0]) + " " + "-" + " " + formatDateList(event[1])
    );
  };

  const CPParty = companies.filter((d) => {
    return d.company_id != formData.company_id;
  });

  const fillClient = clients.filter((d) => {
    return d.company_id == formData.company_id;
  });

  const fillCPClient = clients.filter((d) => {
    return d.company_id == formData.cp_company_id;
  });

  const fillProject = projects.filter((d) => {
    return d.client_id == formData.client_id;
  });

  const fillCpProject = projects.filter((d) => {
    return d.client_id == formData.cp_client_id;
  });

  const onInputChange = (e) => {
    const target = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };
  const onCancel = () => {
    navigate(-1);
  };

  const dateChange = (date) => {
    handleEvent(date);
    setValue(date);
  };

  const onKeyChange = (key) => setActiveKey(key);

  const [inputList, setInputList] = useState([
    {
      sub_task: "",
      due_date: "",
      time: "",
      status: "",
      employee_id: "",
      operation: "add",
      task_type: "close",
    },
  ]);

  const submitHandler = async () => {
    if (
      !regName.test(formData.subject) ||
      formData?.subject?.length == undefined
    ) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Please Fill Out Task Subject !",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      let new_task_details = {
        ...formData,
        sub_task: inputList,
      };
      new_task_details = {
        ...new_task_details,
        startdate: date,
        OPtype: "Task",
        operation: "add",
        task_type: "keep",
      };
      console.log(
        "🚀 ~ file: taskForm.js:128 ~ submitHandler ~ new_task_details",
        new_task_details
      );
      const {
        success,
        data: { message },
      } = await sendRequest(apipaths.addTask, new_task_details);
      if (success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: message,
          showConfirmButton: false,
          timer: 1500,
        });
        navigate(-1);
      }
    }
  };

  const handleInputChange = (e, i) => {
    const values = [...inputList];

    if (e.target.name === "sub_task") {
      values[i].sub_task = e.target.value;
    } else if (e.target.name === "due_date") {
      values[i].due_date = e.target.value;
    } else if (e.target.name === "time") {
      values[i].time = e.target.value;
    } else if (e.target.name === "status") {
      values[i].status = e.target.value;
    } else if (e.target.name === "employee_id") {
      values[i].employee_id = e.target.value;
    }
    setInputList(values);
  };

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleAddClick = (i) => {
    setInputList([
      ...inputList,
      {
        sub_task: "",
        due_date: "",
        time: "",
        status: "",
        employee_id: "",
        operation: "add",
        task_type: "close",
      },
    ]);
  };

  return (
    <>
      <div className="main-container">
        <Heading title={translation.task} />
        <div className="content-wrapper px-5">
          <CardContainer hideTotal={true} />
          <div className="content-container">
            <div className="p-3">
              <Tabs
                className="row"
                activeKey={activeKey}
                onChange={onKeyChange}
              >
                <Tabs.TabPane
                  tab={translation.task}
                  key="item-1"
                  className="row"
                >
                  <>
                    <div class="mb-2 col-lg-8  mt-3">
                      <label for="subject" class="form-label">
                        {translation.subject}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="subject"
                        aria-describedby="emailHelp"
                        placeholder={translation.subject}
                        name="subject"
                        key="subject"
                        min="1"
                        max="3"
                        onChange={onInputChange}
                      />
                    </div>
                    <div className="mb-2 col-lg-4  mt-3 position-relative">
                      <label for="startdate" class="form-label">
                        {translation.startDate_to_endDate}
                      </label>
                      <RangePicker
                        onChange={(date) => dateChange(date)}
                        className="form-control border-none rounded-lg"
                        placeholder={[translation.start__end_date]}
                        format="YYYY/MM/DD"
                        position="bottom-left"
                      />
                    </div>
                    <div class="mb-2 col-lg-3  mt-3">
                      <label for="priority" class="form-label">
                        {translation.priority}
                      </label>
                      <select
                        class="form-select"
                        aria-label="Select Priority"
                        key="priority"
                        onChange={onInputChange}
                        name="priority"
                      >
                        {" "}
                        <option selected>{translation.select_priority}</option>
                        <option value="Urgent">Urgent</option>
                        <option value="High">High</option>
                        <option value="Medium">Medium</option>
                        <option value="Low">Low</option>
                      </select>
                    </div>
                    <div class="mb-2 col-lg-3  mt-3">
                      <label for="Client" class="form-label">
                        {translation.company}
                      </label>
                      <select
                        class="form-select"
                        aria-label=" Select Client"
                        key="company_id"
                        onChange={onInputChange}
                        name="company_id"
                      >
                        {" "}
                        <option selected>{translation.select_company}</option>
                        {companies.map((d, i) => (
                          <option key={d.company_id} value={d.company_id}>
                            {d.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div class="mb-2 col-lg-3  mt-3">
                      <label for="Client" class="form-label">
                        {translation.client}
                      </label>
                      <select
                        class="form-select"
                        aria-label=" Select Client"
                        key="client_id"
                        onChange={onInputChange}
                        name="client_id"
                      >
                        {" "}
                        <option selected>{translation.select_client}</option>
                        {fillClient.map((c, i) => (
                          <option key={c.client_id} value={c.client_id}>
                            {c.first_name + "" + c.last_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div class="mb-2 col-lg-3  mt-3">
                      <label for="Project" class="form-label">
                        {translation.project}
                      </label>
                      <select
                        class="form-select"
                        aria-label=" select Project"
                        id="project_id"
                        key="project_id"
                        onChange={onInputChange}
                        name="project_id"
                      >
                        <option selected>{translation.select_project}</option>
                        {fillProject.map((p, i) => (
                          <option key={p.project_id} value={p.project_id}>
                            {p?.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div class="mb-2 col-lg-3  mt-3">
                      <label for="Counter Party" class="form-label">
                        {translation.counterparty}
                      </label>
                      <select
                        class="form-select"
                        aria-label="Select Counter Party"
                        key="cp_company_id"
                        onChange={onInputChange}
                        id="counterParty"
                        name="cp_company_id"
                      >
                        <option selected>
                          {translation.select_counter_party}
                        </option>

                        {CPParty.map((d, i) => (
                          <option key={d.company_id} value={d.company_id}>
                            {d.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div class="mb-2 col-lg-3  mt-3">
                      <label for="CP Client" class="form-label">
                        {translation.cpclient}
                      </label>
                      <select
                        class="form-select"
                        aria-label="Select CP Client"
                        key="cp_client_id"
                        onChange={onInputChange}
                        id="cp_client_id"
                        name="cp_client_id"
                      >
                        <option selected>{translation.select_cp_client}</option>
                        {fillCPClient.map((c, i) => (
                          <option key={c.client_id} value={c.client_id}>
                            {c.first_name + "" + c.last_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div class="mb-2 col-lg-3  mt-3">
                      <label for="CP Project" class="form-label">
                        {translation.cpproject}
                      </label>
                      <select
                        class="form-select"
                        aria-label="Select CP Project"
                        key="cp_project_id"
                        onChange={onInputChange}
                        id="cp_project_id"
                        name="cp_project_id"
                      >
                        <option selected>
                          {translation.select_cp_project}
                        </option>
                        {fillCpProject &&
                          fillCpProject.map((p, i) => (
                            <option key={p.project_id} value={p.project_id}>
                              {p.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div class="mb-2 col-lg-3  mt-3">
                      <label for="status" class="form-label">
                        {translation.status}
                      </label>
                      <select
                        class="form-select"
                        aria-label=" select Activity Status"
                        key="status"
                        id="status"
                        name="status"
                        onChange={onInputChange}
                      >
                        <option selected>{translation.select_status}</option>
                        <option value="Pending">Pending</option>
                        <option value="Started">Started</option>
                        <option value="Inprogress">Inprogress</option>
                        <option value="Completed">Completed</option>
                      </select>
                    </div>
                    <div class="mb-2 col-lg-3  mt-3">
                      <label for="Task Assigned To" class="form-label">
                        {translation.task_assigned_to}
                      </label>
                      <select
                        class="form-select"
                        aria-label=" Select Task Assigned To"
                        key="employee_id"
                        onChange={onInputChange}
                        id="employee_id"
                        name="employee_id"
                      >
                        <option selected>
                          {translation.select_task_asigned_to}
                        </option>
                        {employees.map((e, i) => (
                          <option key={e.employee_id} value={e.employee_id}>
                            {e.first_name + " " + e.last_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div class="mb-2 col-lg-3  mt-3">
                      <label for="Employee" class="form-label">
                        {translation.employee}
                      </label>
                      <select
                        class="form-select"
                        aria-label=" Select Employee"
                        key="employee_id"
                        onChange={onInputChange}
                        id="employee_id"
                        name="employee_id"
                      >
                        <option selected>{translation.select_employee}</option>
                        {employees.map((e, i) => (
                          <option key={e.employee_id} value={e.employee_id}>
                            {e.first_name + " " + e.last_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div class="mb-2 col-lg-12  mt-3">
                      <label for="Description" class="form-label">
                        {translation.description}
                      </label>
                      <textarea
                        class="form-control"
                        id="desc"
                        placeholder={translation.description}
                        rows="3"
                        name="desc"
                        key="desc"
                        onChange={onInputChange}
                      ></textarea>
                    </div>
                    <div class="mb-2 col-lg-12  mt-3">
                      <label for="statsu" class="form-label">
                        {translation.remarks}
                      </label>
                      <textarea
                        class="form-control"
                        id="remarks"
                        rows="3"
                        key="remarks"
                        name="remarks"
                        placeholder={translation.remarks}
                        onChange={onInputChange}
                      ></textarea>
                    </div>
                  </>
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab={translation.sub_task}
                  key="item-2"
                  className="row"
                >
                  {inputList.map((x, i) => {
                    return (
                      <>
                        <div class="mb-2 col-lg-2">
                          <label for="Sub Task" class="form-label">
                            {translation.sub_task}
                          </label>

                          <input
                            name="sub_task"
                            key="sub_task"
                            class="form-control"
                            placeholder={translation.sub_task}
                            value={x.sub_task}
                            onChange={(e) => handleInputChange(e, i)}
                          />
                        </div>
                        <div class="mb-2 col-lg-2">
                          <label for="Due Date" class="form-label">
                            {translation.due_date}
                          </label>

                          <input
                            className="ml10"
                            name="due_date"
                            key="due_date"
                            class="form-control"
                            placeholder={translation.due_date}
                            value={x.due_date}
                            onChange={(e) => handleInputChange(e, i)}
                          />
                        </div>
                        <div class="mb-2 col-lg-2">
                          <label for="Time" class="form-label">
                            {translation.time}
                          </label>

                          <input
                            className="ml10"
                            name="time"
                            key="time"
                            class="form-control"
                            placeholder={translation.time}
                            value={x.time}
                            onChange={(e) => handleInputChange(e, i)}
                          />
                        </div>
                        <div class="mb-2 col-lg-3">
                          <label for="Status" class="form-label">
                            {translation.status}
                          </label>
                          <select
                            class="form-select"
                            name="status"
                            key="status"
                            value={x.status}
                            onChange={(e) => handleInputChange(e, i)}
                          >
                            <option selected>
                              {translation.select_status}
                            </option>
                            <option value="Pending">Pending</option>
                            <option value="Started">Started</option>
                            <option value="Inprogress">Inprogress</option>
                            <option value="Completed">Completed</option>
                          </select>
                        </div>

                        <div class="mb-2 col-lg-2">
                          <label for="Assignee" class="form-label">
                            {translation.assignee}
                          </label>
                          <select
                            class="form-select"
                            aria-label=" Select Assignee"
                            key="employee_id"
                            onChange={(e) => handleInputChange(e, i)}
                            name="employee_id"
                          >
                            <option selected>
                              {translation.select_employee}
                            </option>
                            {employees.map((e, i) => (
                              <option key={e.employee_id} value={e.employee_id}>
                                {e.first_name + " " + e.last_name}
                              </option>
                            ))}
                          </select>
                        </div>

                        {i == 0 ? (
                          ""
                        ) : (
                          <BsFillTrashFill
                            onClick={() => handleRemoveClick(i)}
                            style={{ marginTop: "35px" }}
                            className="table-icons delete-icon text-danger col-lg-1"
                          />
                        )}
                        {inputList.length - 1 === i && (
                          <div className="ps-2">
                            <button
                              class="btn btn-primary px-3 py-2 me-2 mt-1 d-block"
                              onClick={() => handleAddClick(i)}
                            >
                              {translation.add}
                            </button>
                          </div>
                        )}
                      </>
                    );
                  })}
                </Tabs.TabPane>
              </Tabs>
              <div className="mt-2 d-flex justify-content-between w-100">
                <div class="d-flex justify-content-end pe-5 w-100">
                  <button
                    type="button"
                    class="btn btn-danger px-3 py-2 me-2"
                    onClick={() => onCancel()}
                  >
                    {translation.cancel}
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary px-3 py-2"
                    onClick={() => submitHandler()}
                  >
                    {translation.add}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TaskForm;
