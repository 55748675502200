import React, { useEffect, useState } from "react";
import Heading from "../Heading";
import TableCard from "../TableCard";
import CardContainer from "../Card/cardContainer";
import {
  formatDateMeet,
  listingCommonDefaulter,
  modalInitialValue,
  multiTabFormModalController,
} from "../../helpers/commonHelpers";
import ModalContainer from "../Modal";
import { meetingFormLayout } from "../../Data/FormsLayout/meetingFormLayout";
import { useDispatch, useSelector } from "react-redux";
import { meetingListAction } from "../actions/meetingAction";
import { deleteMeeting, updateMeetingHandler } from "../../apiParsing/meeting";
import { addActionColumn } from "../../helpers/companyHelpers";
import { apipaths } from "./../../services/apiPaths";
import { Dropdown } from "antd";
import { BarsOutlined } from "@ant-design/icons";
import { menu } from "./../../Data/Columns/meetingColumns";
import { sendRequest } from "../../services";
import Swal from "sweetalert2";
import { companyListAction } from "../actions/comanyAction";
import { projectListAction } from "../actions/projectAction";
import useTranslation from "../customHooks/translations";
import { useNavigate } from "react-router-dom";

function Meetings() {
  const [modal, setModal] = useState(modalInitialValue);
  const [todayMeeting, setTodayMeeting] = useState([]);
  const translation = useTranslation();

  const dispatch = useDispatch();
  const { meet } = useSelector((state) => state.meet);
  const { employees } = useSelector((state) => state.employee);
  const { projects } = useSelector((state) => state.project);
  const { clients } = useSelector((state) => state.client);
  const { companies } = useSelector((state) => state.company);
  const selectedCompanyName = "";
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(meetingListAction());
    dispatch(companyListAction());
    dispatch(projectListAction());
  }, []);

  useEffect(() => {
    if (projects && clients && companies && employees) {
      const arr = [projects, clients, companies, employees];
      const kerArr = ["project_id", "client_id", "company_id", "employee_id"];
      listingCommonDefaulter(arr, meetingFormLayout.meeting, kerArr);
    }
  }, [projects, clients, companies, employees]);

  useEffect(() => {
    TodayMeet();
  }, [meet]);

  const today = new Date();

  let todayMeet = [];
  const TodayMeet = () => {
    meet &&
      meet.map((t) => {
        if (formatDateMeet(t.startdate) === formatDateMeet(today)) {
          todayMeet.push(t);
        }
        setTodayMeeting(todayMeet);
      });
  };

  const meetingColumns = [
    {
      title: "ID",
      dataIndex: "meeting_id",
      sorter: {
        compare: (a, b) => (a, b),
        multiple: 1,
      },
    },
    {
      title: "Subject",
      render: (data) => {
        return (
          <>
            <label
              style={{ color: "#1890ff" }}
              onClick={() => {
                dataTest(data);
              }}
            >
              {data.subject}
            </label>
          </>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "startdate",
      sorter: {
        compare: (a, b) => a.math - b.math,
        multiple: 2,
      },
    },
    {
      title: "Start Time",
      dataIndex: "starttime",
      sorter: {
        compare: (a, b) => a.math - b.math,
        multiple: 2,
      },
    },
    {
      title: "End Time",
      dataIndex: "endtime",
      sorter: {
        compare: (a, b) => a.math - b.math,
        multiple: 2,
      },
    },
    {
      title: "Venue",
      dataIndex: "venue",
      sorter: {
        compare: (a, b) => a.math - b.math,
        multiple: 2,
      },
    },
    {
      title: "Client",
      dataIndex: "employee_id",
      render: (client_id, row) => {
        const client = row["client"];
        if (client) return client.first_name + " " + client.last_name;
        else return "";
      },
    },
    {
      title: "Company",
      dataIndex: "company_id",
      render: (company_id, row) => {
        let company_name = "";
        const company = row.company;
        if (company_id && company) company_name = company.name;
        return company_name;
      },
    },
    {
      title: "Projects",
      dataIndex: "project_id",
      render: (project_id, row) => {
        const project = row["project"];
        if (project) return project.name;
        else return "";
      },
    },
  ];

  const meetingColumnsHome = [
    {
      title: "ID",
      dataIndex: "meeting_id",
      sorter: {
        compare: (a, b) => (a, b),
        multiple: 1,
      },
    },
    {
      title: "主題",
      render: (data) => {
        return (
          <>
            <label
              style={{ color: "#1890ff" }}
              onClick={() => {
                navigate(`/edit-meetings/${data.meeting_id}`);
              }}
            >
              {data.subject}
            </label>
          </>
        );
      },
    },
    {
      title: "日付",
      dataIndex: "startdate",
      sorter: {
        compare: (a, b) => a.math - b.math,
        multiple: 2,
      },
    },
    {
      title: "開始時間",
      dataIndex: "starttime",
      sorter: {
        compare: (a, b) => a.math - b.math,
        multiple: 2,
      },
    },
    {
      title: "終了時間",
      dataIndex: "endtime",
      sorter: {
        compare: (a, b) => a.math - b.math,
        multiple: 2,
      },
    },
    {
      title: "会場",
      dataIndex: "venue",
      sorter: {
        compare: (a, b) => a.math - b.math,
        multiple: 2,
      },
    },
    {
      title: "クライアント",
      dataIndex: "employee_id",
      render: (client_id, row) => {
        const client = row["client"];
        if (client) return client.first_name + " " + client.last_name;
        else return "";
      },
    },
    {
      title: "会社",
      dataIndex: "company_id",
      render: (company_id, row) => {
        let company_name = "";
        const company = row.company;
        if (company_id && company) company_name = company.name;
        return company_name;
      },
    },
    {
      title: "プロジェクト",
      dataIndex: "project_id",
      render: (project_id, row) => {
        const project = row["project"];
        if (project) return project.name;
        else return "";
      },
    },
  ];

  const dataTest = (data) => {
    multiTabFormModalController(
      setModal,
      selectedCompanyName,
      {
        task: meetingFormLayout.meeting,
        subTask: meetingFormLayout.task,
        subTask2: meetingFormLayout.task,
      },
      {
        title1: `Edit Meetings`,
        title2: "Pre Task",
        title3: "Post Task",
      },
      `edit-meetings-form`,
      data,
      (data) =>
        updateMeetingHandler(data, setModal, dispatch, meetingListAction)
    );
  };

  const onSubmit = async (formdata) => {
    let new_meeting_details = { ...formdata };
    new_meeting_details = {
      ...new_meeting_details,
      OPtype: "Meeting",
      operation: "add",
      task_type: "keep",
    };

    const {
      success,
      data: { message },
    } = await sendRequest(apipaths.addMeeting, new_meeting_details);
    if (success) {
      dispatch(meetingListAction());
      Swal.fire({
        position: "center",
        icon: "success",
        title: message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const deleteMeetingHandler = (meeting_id) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#1890ff",
    }).then(async ({ isConfirmed }) => {
      if (!isConfirmed) return null;

      const { data } = await sendRequest(apipaths.deleteMeeting, {
        delete_id: meeting_id,
      });
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Meeting is deleted successfully!",
        showConfirmButton: false,
        timer: 1500,
      });
      dispatch(meetingListAction());
    });
  };

  return (
    <>
      <ModalContainer
        isOpen={modal.open}
        title={modal.title}
        closeIcon={() => setModal(modalInitialValue)}
        content={modal.content}
        footer={false}
      />
      <div className="main-container">
        <Heading title={translation.meetings} />
        <div className="content-wrapper px-5">
          <CardContainer hideTotal={true} />
        </div>

        <div className="px-5">
          <TableCard
            button={{
              title: translation.add_meeting,
              onClick: () =>
                multiTabFormModalController(
                  setModal,
                  selectedCompanyName,
                  {
                    task: meetingFormLayout.meeting,
                    subTask: meetingFormLayout.task,
                    subTask2: meetingFormLayout.task,
                  },
                  {
                    title1: `${translation.add_meeting}`,
                    title2: `${translation.pre_meeting_task}`,
                    title3: `${translation.post_meeting_task}`,
                  },
                  `add-meetings-form`,
                  {},
                  (data) => onSubmit(data)
                ),
            }}
            columns={addActionColumn(
              { type: `dropdown`, name: `meetingHome-dropdown` },

              meetingColumns,
              (data) =>
                multiTabFormModalController(
                  setModal,
                  selectedCompanyName,
                  {
                    task: meetingFormLayout.meeting,
                    subTask: meetingFormLayout.task,
                    subTask2: meetingFormLayout.task,
                  },
                  {
                    title1: `${translation.edit_meetings}`,
                    title2: `${translation.pre_meeting_task}`,
                    title3: `${translation.post_meeting_task}`,
                  },
                  `edit-meetings-form`,
                  data,
                  (data) =>
                    updateMeetingHandler(
                      data,
                      setModal,
                      dispatch,
                      meetingListAction
                    )
                ),
              (data) =>
                deleteMeeting(data, setModal, dispatch, meetingListAction),
              translation._language
            )}
            jaCol={addActionColumn(
              { type: `dropdown`, name: `meetingHome-dropdown` },
              meetingColumnsHome,
              (data) =>
                multiTabFormModalController(
                  setModal,
                  selectedCompanyName,
                  {
                    task: meetingFormLayout.meeting,
                    subTask: meetingFormLayout.task,
                    subTask2: meetingFormLayout.task,
                  },
                  {
                    title1: `${translation.edit_meetings}`,
                    title2: `${translation.pre_meeting_task}`,
                    title3: `${translation.post_meeting_task}`,
                  },
                  `edit-meetings-form`,
                  data,
                  (data) => navigate(`edit-meeting${data.meeting_id}`)
                ),
              (meeting_id) => deleteMeetingHandler(meeting_id),
              translation._language
            )}
            data={todayMeeting}
            title={translation.meetings}
          />
        </div>
      </div>
    </>
  );
}

export default Meetings;
