import Swal from "sweetalert2";
import { modalInitialValue, onSuccesshandler, regName } from "../helpers/commonHelpers";
import { sendRequest } from "../services";
import { apipaths } from "../services/apiPaths";

export const addClienthandler = async (
  formdata,
  setModal,
  dispatch,
  clientListAction,
  companyId
) => {
  formdata["operation"] = "add";
  if (companyId) {
    formdata["company_id"] = companyId;
  }
  // if (!regName.test(formdata.first_name) || formdata.first_name.trim() === "" && !regName.test(formdata.last_name) || formdata.last_name.trim() === "") {
  //   Swal.fire({
  //     position: "center",
  //     icon: "error",
  //     title: "Please Fill Out client Name !",
  //     showConfirmButton: false,
  //     timer: 1500,
  //   });
  // } else {
    const { success, message } = await sendRequest(
      apipaths.addClient,
      formdata
    );
    if (success) {
      onSuccesshandler(setModal, dispatch, clientListAction, message);
    }
  // }
};

export const updateClienthandler = async (
  formdata,
  setModal,
  dispatch,
  clientListAction
) => {
  const form_data = { ...formdata };
  form_data["id"] = formdata.client_id;
  form_data["operation"] = "update";

  delete form_data.created_at;
  delete form_data.updated_at;
  delete form_data.company;
  delete form_data.user_id;
  delete form_data.employee;
  delete form_data.client_id;
  delete form_data.enable;

  const { success, message } = await sendRequest(apipaths.addClient, form_data);
  if (success) {
    setModal(modalInitialValue);
    dispatch(clientListAction());
    Swal.fire({
      position: "center",
      icon: "success",
      title: message,
      showConfirmButton: false,
      timer: 1500,
    });
  }
};
