import React, { useEffect, useState } from "react";
import Heading from "../Heading";
import TableCard from "../TableCard";
import CardContainer from "../Card/cardContainer";
import { userColumns, userColumnsHome } from "../../Data/Columns/userColumns";
import { addActionColumn } from "../../helpers/companyHelpers";
import {
  companyFormLayoutHandler,
  companyFormLayoutHandlerDrop,
  formModalController,
  listingCommonDefaulterUser,
  modalInitialValue,
} from "../../helpers/commonHelpers";
import {
  userFormLayout,
  userFormLayoutJap,
} from "../../Data/FormsLayout/userFormLayout";
import ModalContainer from "../Modal";
import { useDispatch, useSelector } from "react-redux";
import { usersListAction } from "../actions/usersListAction";
import {
  addHandler,
  deleteHandler,
  updateHandler,
} from "../../apiParsing/data";
import { apipaths } from "../../services/apiPaths";
import useTranslation from "../customHooks/translations";

function Users() {
  const [modal, setModal] = useState(modalInitialValue);
  const dispatch = useDispatch();
  const { users, userRoles } = useSelector((state) => state.user);

  const translation = useTranslation();

  useEffect(() => {
    dispatch(usersListAction());
  }, []);

  useEffect(() => {
    if (userRoles) {
      listingCommonDefaulterUser([userRoles], userFormLayout, [
        "role_id",
      ]);
    }
  }, [userRoles]);

  // useEffect(() => {
  //   if (employees) {
  //     listingCommonDefaulter([employees], companyFormLayoutJap, [
  //       "employee_id",
  //     ]);
  //   }
  // }, [employees]);

  return (
    <>
      <ModalContainer
        isOpen={modal.open}
        title={modal.title}
        closeIcon={() => setModal(modalInitialValue)}
        content={modal.content}
        footer={false}
      />
      <div className="main-container">
        <Heading title={translation.users} />
        <div className="content-wrapper px-5">
          <CardContainer hideTotal={true} />
        </div>

        <div className="px-5">
          <TableCard
            button={{
              title: translation.add_user,
              onClick: () =>
                formModalController(
                  setModal,
                  userFormLayout,
                  userFormLayoutJap,
                  `${translation.add_user}`,
                  `add-user-form`,
                  {},
                  (data) =>
                    addHandler(
                      apipaths.addUser,
                      data,
                      setModal,
                      dispatch,
                      usersListAction
                    )
                ),
            }}
            columns={addActionColumn(
              { name: "user1-dropdown" },
              userColumns,
              (data) => {
                formModalController(
                  setModal,
                  userFormLayout,
                  userFormLayoutJap,
                  `${translation.edit_user}`,
                  `edit-user-form`,
                  data,
                  (data) =>
                    updateHandler(
                      apipaths.addUser,
                      data,
                      "user_id",
                      setModal,
                      dispatch,
                      usersListAction
                    )
                );
              },
              () => deleteHandler(""),
              translation._language
            )}
            jaCol={addActionColumn(
              { name: "user1-dropdown" },
              userColumnsHome,
              (data) => {
                formModalController(
                  setModal,
                  userFormLayout,
                  userFormLayoutJap,
                  `${translation.edit_user}`,
                  `edit-user-form`,
                  data,
                  (data) =>
                    updateHandler(
                      apipaths.addUser,
                      data,
                      "user_id",
                      setModal,
                      dispatch,
                      usersListAction
                    )
                );
              },
              () => deleteHandler(""),
              translation._language
            )}
            data={users}
            title={translation.users}
          />
        </div>
      </div>
    </>
  );
}

export default Users;
