export const employeeFormLayout = [
  {
    id: 1,
    label: "First Name",
    placeholder: "Firstname",
    key: "first_name",
    type: "text",
    required: true,
  },
  {
    id: 2,
    label: "Last Name",
    placeholder: "Lastname",
    key: "last_name",
    type: "text",
    required: true,
  },
  {
    id: 3,
    label: "Gender",
    placeholder: "Select Gender",
    key: "gender",
    type: "select",
    options: [
      {
        label: "Male",
        key: "male",
      },
      {
        label: "Female",
        key: "female",
      },
    ],
  },
  {
    id: 4,
    label: "Telephone 1",
    type: "text",
    placeholder: "Telephone",
    key: "tel1",
    required: false,
  },
  {
    id: 5,
    label: "Telephone 2",
    type: "text",
    placeholder: "Telephone",
    key: "tel2",
    required: false,
  },
  {
    id: 6,
    label: "Fax",
    type: "text",
    placeholder: "Fax",
    key: "fax",
    required: false,
  },
  {
    id: 7,
    label: "Email 1",
    type: "email",
    placeholder: "Email",
    key: "email1",
    required: false,
  },
  {
    id: 8,
    label: "Email 2",
    type: "email",
    placeholder: "Email",
    key: "email2",
    required: false,
  },
  {
    id: 9,
    label: "Email 3",
    type: "email",
    placeholder: "Email",
    key: "email3",
    required: false,
  },
  {
    id: 10,
    label: "Company",
    type: "select",
    placeholder: "Select Company",
    key: "company_id",
    options: [
      {
        label: "Company A",
        value: "coma",
      },
      {
        label: "Company B",
        value: "comb",
      },
    ],
    required: false,
  },
  {
    id: 11,
    label: "Address",
    type: "text",
    placeholder: "Address",
    key: "address",
    required: false,
  },
  {
    id: 12,
    label: "City",
    type: "text",
    placeholder: "city",
    key: "city",
    required: false,
  },
  {
    id: 13,
    label: "Country",
    type: "text",
    placeholder: "Country",
    key: "country",
    required: false,
  },
  {
    id: 14,
    label: "Postal Code",
    type: "text",
    placeholder: "Postal Code",
    key: "postal_code",
    required: false,
  },
  {
    id: 15,
    label: "Website",
    type: "text",
    placeholder: "Website",
    key: "website",
    required: false,
  },
];

export const employeeFormLayoutJap = [
  {
    id: 1,
    label: "ファーストネーム",
    placeholder: "ファーストネーム",
    key: "first_name",
    type: "text",
    required: true,
  },
  {
    id: 2,
    label: "苗字",
    placeholder: "苗字",
    key: "last_name",
    type: "text",
    required: true,
  },
  {
    id: 3,
    label: "性別",
    placeholder: "性別を選択して下さい",
    key: "gender",
    type: "select",
    options: [
      {
        label: "Male",
        key: "male",
      },
      {
        label: "Female",
        key: "female",
      },
    ],
  },
  {
    id: 4,
    label: "電話 1",
    placeholder: "電話 1",
    key: "tel1",
    type: "text",
    required: false,
  },
  {
    id: 5,
    label: "電話 2",
    placeholder: "電話 2",
    type: "text",
    key: "tel2",
    required: false,
  },
  {
    id: 6,
    type: "text",
    label: "ファックス",
    placeholder: "ファックス",
    key: "fax",
    required: false,
  },
  {
    id: 7,
    type: "email",
    label: "メール 1",
    placeholder: "メール 1",
    key: "email1",
    required: false,
  },
  {
    id: 8,
    type: "email",
    label: "メール 2",
    placeholder: "メール 2",
    key: "email2",
    required: false,
  },
  {
    id: 9,
    type: "email",
    label: "Eメール 3",
    placeholder: "Eメール 3",
    key: "email3",
    required: false,
  },
  {
    id: 10,
    type: "select",
    label: "選択する",
    placeholder: "選択する",
    key: "company_id",
    options: [
      {
        label: "Company A",
        value: "coma",
      },
      {
        label: "Company B",
        value: "comb",
      },
    ],
    required: false,
  },
  {
    id: 11,
    type: "text",
    label: "住所",
    placeholder: "住所",
    key: "address",
    required: false,
  },
  {
    id: 12,
    type: "text",
    label: "都市",
    placeholder: "都市",
    key: "city",
    required: false,
  },
  {
    id: 13,
    type: "text",
    label: "国",
    placeholder: "国",
    key: "country",
    required: false,
  },
  {
    id: 14,
    type: "text",
    label: "郵便番号",
    placeholder: "郵便番号",
    key: "postal_code",
    required: false,
  },
  {
    id: 15,
    type: "text",
    label: "Webサイト",
    placeholder: "Webサイト",
    key: "website",
    required: false,
  },
];
