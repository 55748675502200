import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addHandler,
  deleteHandler,
  updateHandler,
} from "../../apiParsing/data";
import { notesColumns } from "../../Data/Columns/notesColumn";
import { notesFormLayout } from "../../Data/FormsLayout/notesFormLayout";
import {
  filterClientsHandler,
  formModalController,
  listingCommonDefaulter,
} from "../../helpers/commonHelpers";
import { addActionColumn } from "../../helpers/companyHelpers";
import { apipaths } from "../../services/apiPaths";
import { notesListAction } from "../actions/notesAction";
import TableCard from "../TableCard";
import { useLocation, useParams } from "react-router-dom";
import { BsFillPencilFill, BsFillTrashFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { modalInitialValue } from "./../../helpers/commonHelpers";
import ModalContainer from "../Modal";
import Heading from "../Heading";
import CardContainer from "../Card/cardContainer";
import useTranslation from "../customHooks/translations";
import { notesFormLayoutJap } from "../../Data/FormsLayout/notesFormLayout";
import { addNotehandler } from "../../apiParsing/notes";
import moment from "moment";

export default function CompanyNote() {
  const dispatch = useDispatch();
  const { notes } = useSelector((state) => state.notes);
  const { employees } = useSelector((state) => state.employee);
  const { projects } = useSelector((state) => state.project);
  const { clients } = useSelector((state) => state.client);
  const { companies } = useSelector((state) => state.company);
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(modalInitialValue);

  const { id: companyId } = useParams();
  const translation = useTranslation();
  const location = useLocation();

  useEffect(() => {
    if (location?.state?.id == "company_id") {
      setData(notes.filter((m) => m.company_id == parseInt(companyId)));
    }

    // if (location.state.id == "compnay_id")
    //   setData(notes.filter((m) => m.project_id == parseInt(companyId)));
    if (location.state.id == "client_id")
      setData(notes.filter((m) => m.client_id == parseInt(companyId)));
  }, [notes]);

  useEffect(() => {
    if (employees && projects && clients && companies) {
      const arr = [employees, projects, clients, companies];
      const keyArr = ["employee_id", "project_id", "client_id", "company_id"];
      listingCommonDefaulter(arr, notesFormLayout, keyArr);
    }
  }, [employees, projects, clients, companies]);

  const notesColumns = [
    {
      title: "ID",
      dataIndex: "note_id",
      sorter: (a, b) => a.note_id - b.note_id,
    },
    {
      title: "Title",
      dataIndex: "title",
      sorter: (a, b) => a.title.localeCompare(b.title),
      render: (d, data) => {
        return (
          <>
            <label
              style={{ color: "#1890ff" }}
              onClick={() => {
                dataTest(data);
              }}
            >
              {data.title}
            </label>
          </>
        );
      },
    },
    {
      title: "Entry Date",
      dataIndex: "date",
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
    },

    {
      title: "Employee",
      dataIndex: "employee_id",
      sorter: (a, b) => a.employee_id - b.employee_id,
      render: (employee_id, row) => {
        const employee = row["employee"];
        if (employee) return employee.first_name + " " + employee.last_name;
        else return "";
      },
    },
    {
      title: "Projects",
      dataIndex: "project",
      sorter: (a, b) => a.project.name.localeCompare(b.project.name),

      render: (d, data) => {
        return <>{data?.project?.name}</>;
      },
    },
    {
      title: "Client",
      dataIndex: "client",
      sorter: (a, b) =>
        a?.client?.first_name.localeCompare(b?.client?.first_name),
      render: (d, data) => {
        return (
          <>
            {data?.client?.first_name && data?.client?.last_name
              ? data?.client?.first_name + " " + data?.client?.last_name
              : "-"}
          </>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (data) => (
        <div>
          {" "}
          <span>
            <Link to="/notes/form/edit">
              <BsFillPencilFill className="table-icons edit-icon text-warning" />
            </Link>
            <BsFillTrashFill className="table-icons delete-icon text-danger" />
          </span>{" "}
        </div>
      ),
    },
  ];

  const notesColumnsHome = [
    {
      title: "ID",
      dataIndex: "note_id",
      sorter: (a, b) => a.note_id - b.note_id,
    },
    {
      title: "題名",
      dataIndex: "title",
      sorter: (a, b) => a.title.localeCompare(b.title),
      render: (d, data) => {
        return (
          <>
            <label
              style={{ color: "#1890ff" }}
              onClick={() => {
                dataTest(data);
              }}
            >
              {data.title}
            </label>
          </>
        );
      },
    },

    {
      title: "日付",
      dataIndex: "date",
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
    },
    {
      title: "社員",
      dataIndex: "employee_id",
      sorter: (a, b) => a.employee_id - b.employee_id,
      render: (employee_id, row) => {
        const employee = row["employee"];
        if (employee) return employee.first_name + " " + employee.last_name;
        else return "";
      },
    },
    {
      title: "プロジェクト",
      dataIndex: "project",
      sorter: (a, b) => a.project.name.localeCompare(b.project.name),

      render: (d, data) => {
        return <>{data?.project?.name}</>;
      },
    },
    {
      title: "クライアント",
      dataIndex: "client",
      sorter: (a, b) =>
        a?.client?.first_name.localeCompare(b?.client?.first_name),
      render: (d, data) => {
        return (
          <>
            {data?.client?.first_name && data?.client?.last_name
              ? data?.client?.first_name + " " + data?.client?.last_name
              : "-"}
          </>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (data) => (
        <div>
          {" "}
          <span>
            <Link to="/notes/form/edit">
              <BsFillPencilFill className="table-icons edit-icon text-warning" />
            </Link>
            <BsFillTrashFill className="table-icons delete-icon text-danger" />
          </span>{" "}
        </div>
      ),
    },
  ];

  const dataTest = (data) => {
    formModalController(
      setModal,
      notesFormLayout,
      notesFormLayoutJap,
      `${translation.edit_note}`,
      `edit-note-form`,
      data,
      (data) =>
        updateHandler(
          apipaths.addnotes,
          data,
          "note_id",
          setModal,
          dispatch,
          notesListAction
        )
    );
  };

  useEffect(() => {
    dispatch(notesListAction());
  }, []);

  return (
    <>
      <ModalContainer
        isOpen={modal.open}
        title={modal.title}
        closeIcon={() => setModal(modalInitialValue)}
        content={modal.content}
        footer={false}
      />
      <div className="main-container">
        <Heading title={translation.notes} />
        <div className="content-wrapper px-5">
          <CardContainer hideTotal={true} />
        </div>

        <div className="px-5">
          <TableCard
            button={{
              title: translation.add_note,
              onClick: () =>
                formModalController(
                  setModal,
                  notesFormLayout,
                  notesFormLayoutJap,
                  `${translation.add_note}`,
                  `add-note-form`,
                  {},
                  (data) =>
                    addNotehandler(data, setModal, dispatch, notesListAction)
                ),
            }}
            columns={addActionColumn(
              {},
              notesColumns,
              (data) => {
                formModalController(
                  setModal,
                  notesFormLayout,
                  notesFormLayoutJap,
                  `${translation.edit_note}`,
                  `edit-note-form`,
                  data,
                  (data) =>
                    updateHandler(
                      apipaths.addnotes,
                      data,
                      "note_id",
                      setModal,
                      dispatch,
                      notesListAction
                    )
                );
              },
              (data) =>
                deleteHandler(
                  apipaths.deleteNote,
                  data?.["note_id"],
                  setModal,
                  dispatch,
                  notesListAction
                ),
              translation._language
            )}
            jaCol={addActionColumn(
              {},
              notesColumnsHome,
              (data) => {
                formModalController(
                  setModal,
                  notesFormLayout,
                  notesFormLayoutJap,
                  `${translation.edit_note}`,
                  `edit-note-form`,
                  data,
                  (data) =>
                    updateHandler(
                      apipaths.addnotes,
                      data,
                      "note_id",
                      setModal,
                      dispatch,
                      notesListAction
                    )
                );
              },
              (data) =>
                deleteHandler(
                  apipaths.deleteNote,
                  data?.["note_id"],
                  setModal,
                  dispatch,
                  notesListAction
                ),
              translation._language
            )}
            data={data}
            title={translation.notes}
          />
        </div>
      </div>
    </>
  );
}
