import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { meetingListAction } from "../actions/meetingAction";
import { Form, Modal } from "antd";
import Swal from "sweetalert2";
import { sendRequest } from "../../services";
import { apipaths } from "../../services/apiPaths";
import useTranslation from "../customHooks/translations";
import { DatePicker, Space } from "antd";
import moment from "moment";
import { formatDateList, regName } from "../../helpers/commonHelpers";
import { useLocation } from "react-router-dom";
import "./style.css";

function CompanyMeetingForm(props) {
  const {
    projectId,
    companyId,
    setIsModalVisible,
    isModalVisible,
    editData,
    setEditData,
  } = props;

  const [meetingList, setMeetingList] = useState([]);
  const [formData, setFormData] = useState({});
  const [date, setDate] = useState("");
  const { companies } = useSelector((state) => state.company);
  const { clients } = useSelector((state) => state.client);
  const { employees } = useSelector((state) => state.employee);
  const { projects } = useSelector((state) => state.project);
  const { meet } = useSelector((state) => state.meet);

  const translation = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const mainLocation = location.pathname.slice(1, -4);

  const name = companies.filter((d) => {
    return d.company_id === parseInt(companyId);
  });

  const companyClientData = projects.filter((d) => {
    return d.project_id == projectId;
  });
  const nameProject = projects.filter((d) => {
    return d.project_id == parseInt(projectId);
  });

  const selectedProjectName =
    mainLocation === "edit-company" ? name[0]?.name : nameProject[0]?.name;

  const fillClient = clients.filter((d) => {
    return d.company_id == companyId;
  });

  const fillProject = projects.filter((d) => {
    return d.client_id == formData.client_id;
  });

  const handleCancel = () => {
    setIsModalVisible(false);
    setFormData("");
    setEditData("");
  };

  const onInputChange = (e) => {
    const target = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };

  const onSubmit = async () => {
    if (!regName.test(formData.subject) || formData.subject == undefined) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Please Fill Out Meeting Subject !",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      let new_meeting_details = {
        ...formData,
        company_id: companyId,
        startdate: date,
      };
      new_meeting_details = {
        ...new_meeting_details,
        OPtype: "Meeting",
        operation: "add",
        task_type: "keep",
      };
      const {
        success,
        // data: { message },
      } = await sendRequest(apipaths.addMeeting, new_meeting_details);
      setFormData("");
      if (success) {
        dispatch(meetingListAction());
        handleCancel();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Meeting Added Successfully!",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  };

  const updateMeetingHandler = async () => {
    if (!regName.test(formData.subject)) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Please Fill Out Meeting Subject !",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      const form_data = {
        startdate: date,
        company_id: companyId,
        attendees: formData.attendees,
        client_id: formData.client_id,
        desc: formData.desc,
        employee_id: formData.employee_id,
        endtime: formData.endtime,
        keypoints: formData.keypoints,
        project_id: formData.project_id,
        remarks: formData.remarks,
        starttime: formData.starttime,
        subject: formData.subject,
        venue: formData.venue,
      };
      form_data["operation"] = "update";
      form_data["id"] = editData.meeting_id;

      const { success, message } = await sendRequest(
        apipaths.addMeeting,
        form_data
      );
      if (success) {
        dispatch(meetingListAction());
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Meeting Update Successfully!",
          showConfirmButton: false,
          timer: 1500,
        });
        handleCancel();
      }
    }
  };

  const updateProjectHandler = async () => {
    const form_data = {
      startdate: date,
      project_id: projectId,
      attendees: formData.attendees,
      client_id: formData.client_id,
      desc: formData.desc,
      employee_id: formData.employee_id,
      endtime: formData.endtime,
      keypoints: formData.keypoints,
      project_id: formData.project_id,
      remarks: formData.remarks,
      starttime: formData.starttime,
      subject: formData.subject,
      venue: formData.venue,
    };
    form_data["operation"] = "update";
    form_data["id"] = editData.meeting_id;

    const { success, message } = await sendRequest(
      apipaths.addMeeting,
      form_data
    );
    if (success) {
      dispatch(meetingListAction());
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Meeting Update Successfully!",
        showConfirmButton: false,
        timer: 1500,
      });
      handleCancel();
    }
  };

  const submitHandlerProject = async () => {
    if (!regName.test(formData.subject) || formData.subject == undefined) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Please Fill Out Meeting Subject !",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      let new_meeting_details = {
        ...formData,
        project_id: projectId,
        startdate: date,
        company_id: companyClientData[0]?.company_id,
        client_id: companyClientData[0]?.client_id,
      };
      new_meeting_details = {
        ...new_meeting_details,
        OPtype: "Meeting",
        operation: "add",
        task_type: "keep",
      };
      const {
        success,
        data: { message },
      } = await sendRequest(apipaths.addMeeting, new_meeting_details);
      setFormData("");
      if (success) {
        dispatch(meetingListAction());
        handleCancel();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Meeting Added Successfully!",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  };

  const mainHandler = () => {
    if (editData.meeting_id) {
      if (mainLocation === "edit-company") {
        updateMeetingHandler();
      } else {
        updateProjectHandler();
      }
    } else {
      if (mainLocation === "edit-company") {
        onSubmit();
      } else {
        submitHandlerProject();
      }
    }
  };

  const dateChange = (date) => {
    setDate(formatDateList(date));
  };

  return (
    <>
      <Modal
        destroyOnClose
        centered
        visible={isModalVisible}
        onCancel={handleCancel}
        width={1000}
        footer={false}
        maskClosable={false}
        className="model"
      >
        <div
          className="line px-3 d-flex justify-content-between align-items-center"
          style={{
            clear: "both",
            width: "100%",
            borderBottom: " 1px solid #f0f0f0",
          }}
        >
          <h5>{translation.meetings}</h5>
          <h5 className="text-success pe-5">{selectedProjectName}</h5>
        </div>
        <form>
          <div className="row mt-4 px-4">
            <div class="col-12 col-md-6 col-lg-9">
              <label for="Meetings" class="form-label">
                {translation.meetings}
                <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                class="form-control"
                id="subject"
                aria-describedby="emailHelp"
                placeholder={translation.subject}
                name="subject"
                key="subject"
                defaultValue={editData?.subject}
                onChange={onInputChange}
              />
            </div>
            <div class="col-12 col-md-6 col-lg-3">
              <label for="Date" class="form-label">
                {translation.date}
              </label>

              {editData.startdate ? (
                <DatePicker
                  className="w-100"
                  name="startdate"
                  key="startdate"
                  placeholder={translation.select_date}
                  onChange={(date) => dateChange(date)}
                  style={{ borderRadius: "0.375rem" }}
                  defaultValue={moment(formatDateList(editData.startdate))}
                />
              ) : (
                <DatePicker
                  className="w-100"
                  name="startdate"
                  key="startdate"
                  placeholder={translation.select_date}
                  onChange={(date) => dateChange(date)}
                  style={{ borderRadius: "0.375rem" }}
                />
              )}
            </div>
            <div class="col-12 col-md-6 col-lg-3 mt-3">
              <label for="Start Time" class="form-label">
                {translation.start_time}
              </label>
              <input
                type="text"
                class="form-control"
                id="starttime"
                aria-describedby="emailHelp"
                placeholder={translation.time}
                name="starttime"
                key="starttime"
                onChange={onInputChange}
                defaultValue={editData.starttime}
              />
            </div>
            <div class="col-12 col-md-6 col-lg-3 mt-3">
              <label for="End Time" class="form-label">
                {translation.end_time}
              </label>
              <input
                type="text"
                class="form-control"
                id="endtime"
                aria-describedby="emailHelp"
                placeholder={translation.time}
                name="endtime"
                key="endtime"
                onChange={onInputChange}
                defaultValue={editData.endtime}
              />
            </div>
            <div class="col-12 col-md-6 col-lg-3 mt-3">
              <label for="Venue" class="form-label">
                {translation.venue}
              </label>
              <input
                type="text"
                class="form-control"
                id="venue"
                aria-describedby="emailHelp"
                placeholder={translation.venue}
                name="venue"
                key="venue"
                onChange={onInputChange}
                defaultValue={editData.venue}
              />
            </div>

            {/* <div class="col-12 col-md-6 col-lg-3 mt-3">
              <label for="Company" class="form-label">
                {translation.company}
              </label>
              <select
                class="form-select"
                aria-label="Select Company"
                key="company_id"
                onChange={onInputChange}
                name="company_id"
              >
                {" "}
                <option selected>{translation.select_company}</option>
                {companies.map((d, i) => (
                  <option key={d.company_id} value={d.company_id}>
                    {d.name}
                  </option>
                ))}
              </select>
            </div>

            <div class="col-12 col-md-6 col-lg-3 mt-3">
              <label for="Client" class="form-label">
                {translation.client}
              </label>
              <select
                class="form-select"
                aria-label=" Select Client"
                key="client_id"
                onChange={onInputChange}
                defaultValue={editData.client_id}
                name="client_id"
              >
                {" "}
                <option selected>{translation.select_client}</option>
                {fillClient.map((c, i) => (
                  <option key={c.client_id} value={c.client_id}>
                    {c.first_name + "" + c.last_name}
                  </option>
                ))}
              </select>
            </div>
            <div class="col-12 col-md-6 col-lg-3 mt-3">
              <label for="Project" class="form-label">
                {translation.project}
              </label>
              <select
                class="form-select"
                aria-label=" select Project"
                id="project_id"
                key="project_id"
                onChange={onInputChange}
                defaultValue={editData.project_id}
                name="project_id"
              >
                <option selected>{translation.select_project}</option>
                {fillProject.map((p, i) => (
                  <option key={p.project_id} value={p.project_id}>
                    {p.name}
                  </option>
                ))}
              </select>
            </div> */}
            <div class="mb-2 col-lg-3 mt-3">
              <label for="Incharge Employee" class="form-label">
                {translation.incharge_employee}
              </label>
              <select
                class="form-select"
                aria-label="Select Incharge Employee"
                key="employee_id"
                onChange={onInputChange}
                defaultValue={editData.employee_id}
                id="employee_id"
                name="employee_id"
              >
                <option selected>{translation.select_incharge_employee}</option>
                {employees.map((e, i) => (
                  <option key={e.employee_id} value={e.employee_id}>
                    {e.first_name + " " + e.last_name}
                  </option>
                ))}
              </select>
            </div>
            <div class="col-12 col-md-6 col-lg-9 mt-3">
              <label for="Attendees" class="form-label">
                {translation.attendees}
              </label>
              <input
                type="text"
                class="form-control"
                id="attendees"
                aria-describedby="emailHelp"
                placeholder={translation.attendees}
                name="attendees"
                key="attendees"
                onChange={onInputChange}
                defaultValue={editData.attendees}
              />
            </div>

            <div class="col-12  mt-3">
              <label for="Description" class="form-label">
                {translation.description}
              </label>
              <textarea
                class="form-control"
                id="desc"
                placeholder={translation.description}
                name="desc"
                key="desc"
                onChange={onInputChange}
                defaultValue={editData.desc}
              ></textarea>
            </div>
            <div class="col-12 col-md-6 col-lg-6 mt-3">
              <label for="Remarks" class="form-label">
                {translation.remarks}
              </label>
              <textarea
                class="form-control"
                placeholder={translation.remarks}
                id="remarks"
                key="remarks"
                onChange={onInputChange}
                defaultValue={editData.remarks}
                name="remarks"
              ></textarea>
            </div>
            <div class="col-12 col-md-6 col-lg-6 mt-3">
              <label for="Keypoints" class="form-label">
                {translation.keypoints}
              </label>
              <textarea
                class="form-control"
                id="keypoints"
                key="keypoints"
                name="keypoints"
                placeholder={translation.keypoints}
                onChange={onInputChange}
                defaultValue={editData.keypoints}
              ></textarea>
            </div>
          </div>
          <div class="col-lg-12 mb-2 d-flex justify-content-end mt-3 px-4">
            <button
              type="button"
              class="btn btn-danger px-3 py-2 me-2"
              onClick={() => handleCancel()}
            >
              {translation.cancel}
            </button>
            <button
              type="button"
              class="btn btn-primary px-3 py-2"
              onClick={() => mainHandler()}
            >
              {editData.meeting_id ? translation.update : translation.add}
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default CompanyMeetingForm;
