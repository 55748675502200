import moment from "moment";
import Swal from "sweetalert2";
import { formatMeetingDate, onSuccesshandler } from "../helpers/commonHelpers";
import { isCompanyId, isProjectId } from "../helpers/companyHelpers";
import { sendRequest } from "../services";

export async function addHandler(
  apiUrl,
  formdata,
  setModal,
  dispatch,
  renderlist,
  companyId
) {
  const form_data = formdata;
  form_data["operation"] = "add";
  const form_data_withId = isCompanyId(companyId, form_data);
  const { success, message } = await sendRequest(apiUrl, form_data_withId);
  if (success) {
    onSuccesshandler(setModal, dispatch, renderlist, message);
  }
}

export async function addProjectHandler(
  apiUrl,
  formdata,
  setModal,
  dispatch,
  renderlist,
  projectId
) {
  const form_data = formdata;
  form_data["operation"] = "add";
  const form_data_withId = isProjectId(projectId, form_data);
  const { success, message } = await sendRequest(apiUrl, form_data_withId);
  if (success) {
    onSuccesshandler(setModal, dispatch, renderlist, message);
  }
}

export async function addFormDatahandler(
  apiUrl,
  formdata,
  setModal,
  dispatch,
  renderlist,
  companyId
) {
  const form_data = new FormData();
  Object.keys(formdata).forEach((item) => {
    form_data.append(item, formdata[item]);
  });
  form_data.append("operation", "add");
  const { success, message } = await sendRequest(apiUrl, form_data);
  if (success) {
    onSuccesshandler(setModal, dispatch, renderlist, message);
  }
}

export async function deleteHandler(
  apiUrl,
  id,
  setModal,
  dispatch,
  renderList
) {
  Swal.fire({
    title: "Are you sure?",
    icon: "question",
    showCancelButton: true,
    confirmButtonText: "Delete",
    confirmButtonColor: "#1890ff",
  }).then(async ({ isConfirmed }) => {
    if (!isConfirmed) return null;
    const { success, message, data } = await sendRequest(apiUrl, {
      delete_id: id,
    });

    if (success) {
      onSuccesshandler(
        setModal,
        dispatch,
        renderList,
        message !== "" ? message : data?.[0]?.["original"]?.["message"]
      );
    }
  });
}

export async function updateHandler(
  apiUrl,
  editData,
  id,
  setModal,
  dispatch,
  renderList
) {
  const formdata = editData;
  formdata["id"] = editData[`${id}`];
  delete formdata[`${id}`];
  formdata["operation"] = "update";
  const { success, message } = await sendRequest(apiUrl, formdata);
  if (success) {
    onSuccesshandler(setModal, dispatch, renderList, message);
  }
}

export async function addProjectDataTableHandler(
  apiUrl,
  formdata,
  setModal,
  dispatch,
  renderlist,
  projectId,
  clientId,
  companyId
) {
  formdata["operation"] = "add";
  formdata["project_id"] = projectId;
  formdata["client_id"] = clientId;
  formdata["company_id"] = companyId;

  const { success, message } = await sendRequest(apiUrl, formdata);
  if (success) {
    onSuccesshandler(setModal, dispatch, renderlist, message);
  }
}
