import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { sendRequest } from "../../services";
import useTranslation from "../customHooks/translations";
import { apipaths } from "../../services/apiPaths";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { projectProfileAction } from "../actions/projectProfileAction";

const FundForm = (props) => {
  const {
    projectId,
    isModalVisible,
    setIsModalVisible,
    editData,
    setEditData,
  } = props;
  const [formData, setFormData] = useState({});
  const translation = useTranslation();
  const dispatch = useDispatch();

  const { projectProfiles } = useSelector((state) => state.projectProfile);
  console.log("projectProfiles", projectProfiles);

  useEffect(() => {
    dispatch(projectProfileAction());
  }, []);

  const onInputChange = (e) => {
    const target = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const mainHandler = async () => {
    const allData = {
      ...formData,
      company_id: null,
      foundation_year: null,
      industry: null,
      json_data: null,
      listed_type: null,
      profile_type: "fund",
      project_id: projectId,
      sub_industry: null,
      website: null,
    };

    const {
      success,
      data: { message },
    } = await sendRequest(apipaths.projectProfileAdd, allData);
    if (success) {
      Swal.fire({
        position: "center",
        icon: "success",
        title: message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  return (
    <>
      <div
        classNameName="line px-3 d-flex justify-content-between align-items-center"
        style={{ clear: "both", borderBottom: "1px solid #f0f0f0" }}
      >
        <form>
          <div classNameName="w-100 p-2 row px-4">
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.fund_name} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.fund_name}
                  name="fund_name"
                  key="fund_name"
                  value={formData.fund_name}
                  //   defaultValue={editData.fund_name}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.fund_type} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.fund_type}
                  name="fund_type"
                  key="fund_type"
                  value={formData.fund_type}
                  //   defaultValue={editData.fund_type}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.fund_area} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.fund_area}
                  name="fund_area"
                  key="fund_area"
                  value={formData.fund_area}
                  //   defaultValue={editData.fund_area}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.fund_series} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.fund_series}
                  name="fund_series"
                  key="fund_series"
                  value={formData.fund_series}
                  //   defaultValue={editData.fund_series}
                  onChange={onInputChange}
                />
              </div>
            </div>{" "}
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.amc_location} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.amc_location}
                  name="amc_location"
                  key="amc_location"
                  value={formData.amc_location}
                  //   defaultValue={editData.amc_location}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.investment_area} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.investment_area}
                  name="investment_area"
                  key="investment_area"
                  value={formData.investment_area}
                  //   defaultValue={editData.investment_area}
                  onChange={onInputChange}
                />
              </div>
            </div>{" "}
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.investment_country} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.investment_country}
                  name="investment_country"
                  key="investment_country"
                  value={formData.investment_country}
                  //   defaultValue={editData.investment_country}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.target_fund_size} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.target_fund_size}
                  name="target_fund_size"
                  key="target_fund_size"
                  value={formData.target_fund_size}
                  //   defaultValue={editData.target_fund_size}
                  onChange={onInputChange}
                />
              </div>
            </div>{" "}
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.fund_arranged} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.fund_arranged}
                  name="fund_arranged"
                  key="fund_arranged"
                  value={formData.fund_arranged}
                  //   defaultValue={editData.fund_arranged}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.fund_required} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.fund_required}
                  name="fund_required"
                  key="fund_required"
                  value={formData.fund_required}
                  //   defaultValue={editData.fund_required}
                  onChange={onInputChange}
                />
              </div>
            </div>{" "}
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.fund_closing_date} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.fund_closing_date}
                  name="fund_closing_date"
                  key="fund_closing_date"
                  value={formData.fund_closing_date}
                  //   defaultValue={editData.fund_closing_date}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.investment_profile_size} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.investment_profile_size}
                  name="investment_profile_size"
                  key="investment_profile_size"
                  value={formData.investment_profile_size}
                  //   defaultValue={editData.investment_profile_size}
                  onChange={onInputChange}
                />
              </div>
            </div>{" "}
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.current_profile_size} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.current_profile_size}
                  name="current_profile_size"
                  key="current_profile_size"
                  value={formData.current_profile_size}
                  //   defaultValue={editData.current_profile_size}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.fund_tenure} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.fund_tenure}
                  name="fund_tenure"
                  key="fund_tenure"
                  value={formData.fund_tenure}
                  //   defaultValue={editData.fund_tenure}
                  onChange={onInputChange}
                />
              </div>
            </div>{" "}
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.current_moic} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.current_moic}
                  name="current_moic"
                  key="current_moic"
                  value={formData.current_moic}
                  //   defaultValue={editData.current_moic}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.expected_moic} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.expected_moic}
                  name="expected_moic"
                  key="expected_moic"
                  value={formData.expected_moic}
                  //   defaultValue={editData.expected_moic}
                  onChange={onInputChange}
                />
              </div>
            </div>{" "}
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.expected_IRR} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.expected_IRR}
                  name="expected_IRR"
                  key="expected_IRR"
                  value={formData.expected_IRR}
                  //   defaultValue={editData.expected_IRR}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.commitment_period} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.commitment_period}
                  name="commitment_period"
                  key="commitment_period"
                  value={formData.commitment_period}
                  //   defaultValue={editData.commitment_period}
                  onChange={onInputChange}
                />
              </div>
            </div>{" "}
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.minimum_commitment_amount} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.minimum_commitment_amount}
                  name="minimum_commitment_amount"
                  key="minimum_commitment_amount"
                  value={formData.minimum_commitment_amount}
                  //   defaultValue={editData.minimum_commitment_amount}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.management_fee} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.management_fee}
                  name="management_fee"
                  key="management_fee"
                  value={formData.management_fee}
                  //   defaultValue={editData.management_fee}
                  onChange={onInputChange}
                />
              </div>
            </div>{" "}
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.hurdule_rate} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.hurdule_rate}
                  name="hurdule_rate"
                  key="hurdule_rate"
                  value={formData.hurdule_rate}
                  //   defaultValue={editData.hurdule_rate}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.performance_fee} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.performance_fee}
                  name="performance_fee"
                  key="performance_fee"
                  value={formData.performance_fee}
                  //   defaultValue={editData.performance_fee}
                  onChange={onInputChange}
                />
              </div>
            </div>{" "}
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.other_fees} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.other_fees}
                  name="other_fees"
                  key="other_fees"
                  value={formData.other_fees}
                  //   defaultValue={editData.other_fees}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.sponser_name} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.sponser_name}
                  name="sponser_name"
                  key="sponser_name"
                  value={formData.sponser_name}
                  //   defaultValue={editData.sponser_name}
                  onChange={onInputChange}
                />
              </div>
            </div>{" "}
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.sponser_commitment} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.sponser_commitment}
                  name="sponser_commitment"
                  key="sponser_commitment"
                  value={formData.sponser_commitment}
                  //   defaultValue={editData.sponser_commitment}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.current_Lps} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.current_Lps}
                  name="current_Lps"
                  key="current_Lps"
                  value={formData.current_Lps}
                  //   defaultValue={editData.current_Lps}
                  onChange={onInputChange}
                />
              </div>
            </div>{" "}
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.deal_status} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.deal_status}
                  name="deal_status"
                  key="deal_status"
                  value={formData.deal_status}
                  //   defaultValue={editData.deal_status}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.briefly_describe} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.briefly_describe}
                  name="briefly_describe"
                  key="briefly_describe"
                  value={formData.briefly_describe}
                  //   defaultValue={editData.briefly_describe}
                  onChange={onInputChange}
                />
              </div>
            </div>{" "}
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.brief_slogan} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.brief_slogan}
                  name="brief_slogan"
                  key="brief_slogan"
                  value={formData.brief_slogan}
                  //   defaultValue={editData.brief_slogan}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.brief_USP} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.brief_USP}
                  name="brief_USP"
                  key="brief_USP"
                  value={formData.brief_USP}
                  //   defaultValue={editData.brief_USP}
                  onChange={onInputChange}
                />
              </div>
            </div>{" "}
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.growth_strategy} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.growth_strategy}
                  name="growth_strategy"
                  key="growth_strategy"
                  value={formData.growth_strategy}
                  //   defaultValue={editData.growth_strategy}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.fund_manager} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.fund_manager}
                  name="fund_manager"
                  key="fund_manager"
                  value={formData.fund_manager}
                  //   defaultValue={editData.fund_manager}
                  onChange={onInputChange}
                />
              </div>
            </div>{" "}
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.incharge_position} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.incharge_position}
                  name="incharge_position"
                  key="incharge_position"
                  value={formData.incharge_position}
                  //   defaultValue={editData.incharge_position}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.contact_number} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.contact_number}
                  name="contact_number"
                  key="contact_number"
                  value={formData.contact_number}
                  //   defaultValue={editData.contact_number}
                  onChange={onInputChange}
                />
              </div>
            </div>{" "}
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.email_address} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.email_address}
                  name="email_address"
                  key="email_address"
                  value={formData.email_address}
                  //   defaultValue={editData.email_address}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.brief_background} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.brief_background}
                  name="brief_background"
                  key="brief_background"
                  value={formData.brief_background}
                  //   defaultValue={editData.brief_background}
                  onChange={onInputChange}
                />
              </div>
            </div>{" "}
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.FM_track_record} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.FM_track_record}
                  name="FM_track_record"
                  key="FM_track_record"
                  value={formData.FM_track_record}
                  //   defaultValue={editData.FM_track_record}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.Comment_if_any} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.Comment_if_any}
                  name="Comment_if_any"
                  key="Comment_if_any"
                  value={formData.Comment_if_any}
                  //   defaultValue={editData.Comment_if_any}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div class="col-lg-12 mb-2 d-flex justify-content-end px-2.5 mt-3">
              <button
                type="button"
                class="btn btn-danger px-3 py-2 me-2"
                onClick={() => handleCancel()}
              >
                {translation.cancel}
              </button>
              <button
                type="button"
                class="btn btn-primary px-3 py-2"
                onClick={() => mainHandler()}
              >
                Add
                {/* {editData.task_id || editData.project_id
                  ? `${translation.update}`
                  : `${translation.add}`} */}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default FundForm;
