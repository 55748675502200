import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from ".";
import { useSelector } from "react-redux";
import { formatDateList, formatDateMeet } from "./../../helpers/commonHelpers";
import useTranslation from "../customHooks/translations";
import { FaRegBuilding, FaTasks } from "react-icons/fa";
import { HiUserGroup } from "react-icons/hi";
import { BsGraphUp } from "react-icons/bs";

function CardContainer({ hideTotal }) {
  const [taskData, setTaskData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [activeProject, setActiveProject] = useState([]);
  const [todayMeeting, setTodayMeeting] = useState([]);

  const { tasks } = useSelector((state) => state.task);
  const { meet } = useSelector((state) => state.meet);
  const { companies } = useSelector((state) => state.company);
  const { projects } = useSelector((state) => state.project);

  const translation = useTranslation();

  const navigate = useNavigate();
  const getTitleHandler = (title) => {
    const string = title.split(" ");
    title = string?.[1];
    title = title.toLowerCase();
    if (title === "task" || title === "employee") {
      title += "s";
    } else if (title === "companies") title = "company";
    else if (title === "projects") title = "project";
    return title;
  };

  const today = new Date();

  useEffect(() => {
    Todaystask();
  }, [tasks]);

  useEffect(() => {
    TodayMeet();
  }, [meet]);

  useEffect(() => {
    TodaysProject();
  }, [projects]);

  useEffect(() => {
    activePro();
  }, [companies]);

  let todaystask = [];
  const Todaystask = () => {
    tasks &&
      tasks.map((t) => {
        if (formatDateList(t.startdate) == formatDateList(today)) {
          todaystask.push(t);
        }
        setTaskData(todaystask);
      });
  };

  let todayMeet = [];
  const TodayMeet = () => {
    meet &&
      meet.map((t) => {
        if (formatDateMeet(t.startdate) === formatDateMeet(today)) {
          todayMeet.push(t);
        }
        setTodayMeeting(todayMeet);
      });
  };

  let todaysProject = [];
  const TodaysProject = () => {
    projects &&
      projects.map((t) => {
        if (t.activity_status === "Active") {
          todaysProject.push(t);
        }
        setProjectData(todaysProject);
      });
  };

  let pendingActiveCompany = [];
  const activePro = () => {
    companies &&
      companies.map((project) => {
        if (project.activity_status === "Active") {
          pendingActiveCompany.push(project);
        }
        setActiveProject(pendingActiveCompany);
      });
  };

  const cardData = [
    {
      color: "#48abf7",
      title: translation.todays_task,
      total: taskData.length,
      path: "/todayTasks",
      mainTotal: tasks.length,
      mainTitle: translation.total_tasks,
      mainNav: "today's task",
      icon: FaTasks,
    },
    {
      color: "#31ce36",
      title: translation.todays_meeting,
      total: todayMeeting.length,
      path: "/todayMeetings",
      mainTotal: meet.length,
      mainTitle: translation.meetings,
      mainNav: "today's Meetings",
      icon: HiUserGroup,
    },
    {
      color: "#f25961",
      title: translation.active_companies,
      total: activeProject.length,
      path: "/company",
      mainTotal: companies.length,
      mainTitle: translation.total_company,
      mainNav: "Active Companies",
      icon: FaRegBuilding,
    },
    {
      color: "#ffad46",
      title: translation.active_projects,
      total: projectData.length,
      path: "/project",
      mainTotal: projects.length,
      mainTitle: translation.total_projects,
      mainNav: "Active Projects",
      icon: BsGraphUp,
    },
  ];

  return (
    <div className="row">
      {cardData?.map((card, index) => {
        const {
          color,
          title,
          total,
          path,
          mainTotal,
          mainTitle,
          mainNav,
          icon,
        } = card;
        return (
          <div key={index} className="col-12 col-md-6 col-lg-4 col-xl-3">
            <Card
              color={color}
              hideTotal={hideTotal}
              total={total}
              mainTotal={mainTotal}
              title={title}
              mainNav={mainNav}
              mainTitle={mainTitle}
              Icon={icon}
              onClick={{
                list: () => navigate(path),
                form: () => navigate(`/${getTitleHandler(mainNav)}/form/add`),
              }}
            />
          </div>
        );
      })}
    </div>
  );
}

export default CardContainer;
