import React, { useEffect, useState } from "react";
import Heading from "../Heading";
import CardContainer from "../Card/cardContainer";
import FormContainer from "../Form";
import { Tabs } from "antd";
import ModalContainer from "../Modal";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addPrefexToObjectProperty,
  companyFormLayoutHandler,
  getSelectOptions,
  modalInitialValue,
  regName,
} from "../../helpers/commonHelpers";
import { useDispatch, useSelector } from "react-redux";
import { sendRequest } from "../../services";
import { apipaths } from "../../services/apiPaths";
import { projectListAction } from "./../actions/projectAction";
import Swal from "sweetalert2";

import {
  projectFormLayout,
  projectFormLayoutJap,
} from "./../../Data/FormsLayout/projectFormLayout";
import MeetingDataTable from "../Meetings/MeetingDataTable";
import TaskDataTable from "../Task/TaskDataTable";
import CounterParty from "../CounterParty/CounterParty";
import ProjectProfileDataTable from "../ProjectProfile/projectProfileDataTable";
import useTranslation from "../customHooks/translations";
import NotesProjectTable from "../Notes/NotesProjectTable";
import ActionPlan from "../ActionPlan/index";
import EmailProjectTable from "../Email/EmailProjectTable";

function EditProject() {
  const [modal, setModal] = useState(modalInitialValue);
  const [formlayout, setFormlayout] = useState(projectFormLayout);
  const [selectedProject, setSelectedProject] = useState({});

  const [key, setKey] = useState("");

  const { TabPane } = Tabs;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const translation = useTranslation();
  const location = useLocation();
  const { id: projectId } = useParams();
  const { projects } = useSelector((state) => state.project);
  const { clients } = useSelector((state) => state.client);
  const { companies } = useSelector((state) => state.company);
  const { employees } = useSelector((state) => state.employee);

  useEffect(() => {
    dispatch(projectListAction());
  }, [dispatch]);

  useEffect(() => {
    if (employees.length > 0) projectFormLayoutHandle();
  }, [employees]);

  useEffect(() => {
    if (projects.length > 0) {
      let project = projects.filter(
        (com) => parseInt(com.project_id) === parseInt(projectId)
      );
      setSelectedProject(project[0]);
    }
  }, [projects]);

  useEffect(() => {
    if (clients && companies && employees) {
      employees.map((emp) => (emp.name = emp.first_name + " " + emp.last_name));
      let l1 = companyFormLayoutHandler(
        employees,
        projectFormLayout,
        "employee_id",
        "name"
      );
      let l2 = companyFormLayoutHandler(companies, l1, "company_id", "name");

      clients.map(
        (client) => (client.name = client.first_name + " " + client.last_name)
      );
      let l3 = companyFormLayoutHandler(clients, l2, "client_id", "name");
    }
    if (clients && companies && employees) {
      employees.map((emp) => (emp.name = emp.first_name + " " + emp.last_name));
      let l1 = companyFormLayoutHandler(
        employees,
        projectFormLayoutJap,
        "employee_id",
        "name"
      );
      let l2 = companyFormLayoutHandler(companies, l1, "company_id", "name");

      clients.map(
        (client) => (client.name = client.first_name + " " + client.last_name)
      );
      let l3 = companyFormLayoutHandler(clients, l2, "client_id", "name");
    }
  }, [clients, companies, employees]);

  const updateProjectHandler = async (data) => {
    if (!regName.test(data.name)) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Please Fill Out Project Subject !",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      let new_project_details = { ...data };
      new_project_details = addPrefexToObjectProperty(
        "Project_",
        new_project_details
      );
      new_project_details = {
        ...new_project_details,
        OPtype: "Project",
        operation: "update",
        project_type: "keep",
        Project_id: projectId,
      };

      const {
        success,
        data: { message },
      } = await sendRequest(apipaths.addProject, new_project_details);
      if (success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Project Update Successfully!",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate(-1);
      }
    }
  };

  const projectFormLayoutHandle = () => {
    let layout = formlayout.map((lay) => {
      if (lay.key === "employee_id") {
        employees.map(
          (emp) => (emp.name = emp.first_name + " " + emp.last_name)
        );
        lay.options = getSelectOptions(employees, "name", "employee_id");
      }
      return lay;
    });
    setFormlayout(layout);
  };

  const [tabs] = useState([
    {
      id: 1,
      key: "project",
      title: "Project",
      titleJap: "プロジェクト",
      content: (
        <FormContainer
          FormLayoutJap={projectFormLayoutJap}
          layout={formlayout}
          onCancel={() => navigate(-1)}
          onSubmit={updateProjectHandler}
          submitBtnTitle={"Submit"}
          cancelBtnTitle={translation.cancel}
          defaultData={selectedProject}
        />
      ),
    },
    {
      id: 2,
      key: "counter-party",
      title: "Counter Party",
      titleJap: "相手方",
      content: <CounterParty setModal={setModal} projectId={projectId} />,
    },
    {
      id: 3,
      key: "action-plan",
      title: "Action Plan",
      titleJap: "行動計画",
      content: <ActionPlan setModal={setModal} projectId={projectId} />,
    },
    {
      id: 4,
      key: "task",
      title: "Tasks",
      titleJap: "タスク",
      content: <TaskDataTable setModal={setModal} projectId={projectId} />,
    },

    {
      id: 5,
      key: "meetings",
      title: "Meetings",
      titleJap: "ミーティング",
      content: <MeetingDataTable setModal={setModal} projectId={projectId} />,
    },
    {
      id: 6,
      key: "notes",
      title: "Notes",
      titleJap: "備考",
      content: <NotesProjectTable setModal={setModal} projectId={projectId} />,
    },
    {
      id: 7,
      key: "emails",
      title: "Emails",
      titleJap: "メール",
      content: <EmailProjectTable setModal={setModal} projectId={projectId} />,
    },
    {
      id: 8,
      key: "projectProfile",
      title: "Profile",
      titleJap: "プロフィール",
      content: (
        <ProjectProfileDataTable setModal={setModal} projectId={projectId} />
      ),
    },
  ]);

  const onChange = (key) => {
    setKey(key);
  };

  return (
    <>
      <ModalContainer
        isOpen={modal.open}
        title={modal.title}
        closeIcon={() => setModal(modalInitialValue)}
        content={modal.content}
        footer={false}
      />

      <div className="main-container">
        <Heading title={translation.edit_project} />
        <div className="content-wrapper px-5">
          <CardContainer hideTotal={true} />
        </div>

        <div className="main-content-wrapper">
          {Object.keys(selectedProject).length > 0 && (
            <>
              {key === "Project" ? (
                ""
              ) : (
                <h3
                  style={{
                    float: "right",
                    marginLeft: "-25%",
                    marginTop: -"7px",
                  }}
                  className="me-5 fs-20"
                >
                  {selectedProject.name}
                </h3>
              )}
              <Tabs defaultActiveKey="Project" onChange={onChange}>
                {tabs?.map((tab) => {
                  if (tab.id === 1)
                    tab.content = (
                      <FormContainer
                        FormLayoutJap={projectFormLayoutJap}
                        layout={formlayout}
                        onCancel={() => navigate(-1)}
                        onSubmit={updateProjectHandler}
                        defaultData={selectedProject}
                        submitBtnTitle={translation.update}
                      />
                    );
                  return (
                    <TabPane
                      tab={
                        translation._language === "en"
                          ? tab.title
                          : tab.titleJap
                      }
                      key={tab.title}
                    >
                      {tab.content}
                    </TabPane>
                  );
                })}
              </Tabs>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default EditProject;
