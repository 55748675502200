import { sendRequest } from "../../services";
import { apipaths } from "../../services/apiPaths";

export const taskListAction = () => async (dispatch) => {
  const {
    success,
    data: { clients, companies, employees, projects, tasks },
  } = await sendRequest(apipaths.taskList);
  if (success) {
    dispatch({ type: "ADD_CLIENTS", payload: clients });
    dispatch({ type: "ADD_COMAPNIES", payload: companies });
    dispatch({ type: "ADD_EMPLOYEES", payload: employees });
    dispatch({ type: "ADD_PROJECTS", payload: projects });
    dispatch({ type: "ADD_TASKS", payload: tasks });
  }
};
