import { Link } from "react-router-dom";
import { Dropdown, Menu } from "antd";
import { FaClipboardList, FaPen } from "react-icons/fa";
import { BarsOutlined } from "@ant-design/icons";
import { deleteTaskHandler } from "../../helpers/commonHelpers";

export const menu = (
  <Menu>
    <Menu.Item key={1}>
      <Link to="/tasks/form/update">
        <div className="d-flex align-items-center">
          <FaPen className="text-warning" />
          <span className="ms-2">Edit</span>
        </div>
      </Link>
    </Menu.Item>

    <Menu.Item key={2}>
      <Link to="/tasks">
        <div className="d-flex align-items-center">
          <FaClipboardList className="text-danger" />
          <span className="ms-2">Delete</span>
        </div>
      </Link>
    </Menu.Item>
  </Menu>
);

export const taskColumns = [
  {
    title: "ID",
    dataIndex: "task_id",
    defaultSortOrder: "descend",
    sorter: (a, b) => a.task_id - b.task_id,
    render: (id) => {
      return id;
    },
  },
  {
    title: "Subject",
    dataIndex: "subject",
    defaultSortOrder: "descend",
    sorter: (a, b) => a.subject.localeCompare(b.subject),
  },

  {
    title: "Projects",
    dataIndex: "project",
    defaultSortOrder: "descend",
    sorter: (a, b) => a.project.name.localeCompare(b.project.name),
    render: (project_id, row) => {
      const project = row["project"];
      if (project) return project.name;
      else return "";
    },
  },

  {
    title: "Action",
    dataIndex: "task_id",
    render: (task_id, index) => (
      <div key={index}>
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key={1}>
                <Link to={`/edit-tasks/${task_id}`}>
                  <div className="d-flex align-items-center">
                    <FaPen className="text-warning" />
                    <span className="ms-2">Edit</span>
                  </div>
                </Link>
              </Menu.Item>

              <Menu.Item key={2}>
                {/* <Link to="/tasks"> */}
                <div
                  className="d-flex align-items-center"
                  onClick={() => deleteTaskHandler(task_id)}
                >
                  <FaClipboardList className="text-danger" />
                  <span className="ms-2">Delete</span>
                </div>
                {/* </Link> */}
              </Menu.Item>
            </Menu>
          }
          placement="bottom"
          className="action-button"
        >
          <BarsOutlined />
        </Dropdown>
      </div>
    ),
  },
];

export const taskColumnsHome = [
  {
    title: "ID",
    dataIndex: "task_id",
    defaultSortOrder: "descend",
    sorter: (a, b) => a.task_id - b.task_id,
    render: (id) => {
      return id;
    },
  },
  {
    title: "主題",
    dataIndex: "subject",
    defaultSortOrder: "descend",
    sorter: (a, b) => a.subject.localeCompare(b.subject),
  },

  {
    title: "事業",
    dataIndex: "project_id",
    sorter: (a, b) => a.project.name.localeCompare(b.project.name),

    render: (project_id, row) => {
      const project = row["project"];
      if (project) return project.name;
      else return "";
    },
  },
];
