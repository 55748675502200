import { BsFillPencilFill } from "react-icons/bs";
import { Link } from "react-router-dom";
export const userColumns = [
  {
    title: "ID",
    dataIndex: "user_id",
    sorter: (a, b) => a.user_id - b.user_id,
  },
  {
    title: "Name",
    dataIndex: "name",
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: "Email",
    dataIndex: "email",
    sorter: (a, b) => a.email.localeCompare(b.email),
  },
  {
    title: "Status",
    dataIndex: "status",
    sorter: (a, b) => a.status.localeCompare(b.status),
    render: (status) => (status ? "Active" : "Pending"),
  },
  {
    title: "Role",
    dataIndex: "role_id",
    sorter: (a, b) =>
      a?.user_roles?.role_name.localeCompare(b?.user_roles?.role_name),
    render: (d, data) => {
      return (
        <>
          {data && data?.user_roles?.role_name
            ? data?.user_roles?.role_name
            : "-"}
        </>
      );
    },
  },
  {
    title: "Action",
    dataIndex: "action",
    render: (data) => (
      <div>
        {" "}
        <span>
          <Link to="/users/form/edit">
            <BsFillPencilFill className="table-icons edit-icon text-warning" />
          </Link>
        </span>{" "}
      </div>
    ),
  },
];

export const userColumnsHome = [
  {
    title: "ID",
    dataIndex: "user_id",
    sorter: (a, b) => a.user_id - b.user_id,
  },
  {
    title: "名前",
    dataIndex: "name",
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: "メール",
    dataIndex: "email",
    sorter: (a, b) => a.email.localeCompare(b.email),
  },
  {
    title: "状態",
    dataIndex: "status",
    sorter: (a, b) => a.status.localeCompare(b.status),
    render: (status) => (status ? "Active" : "Pending"),
  },
  {
    title: "役割",
    dataIndex: "role_id",
    sorter: (a, b) =>
      a?.user_roles?.role_name.localeCompare(b?.user_roles?.role_name),
    render: (d, data) => {
      return (
        <>
          {data && data?.user_roles?.role_name
            ? data?.user_roles?.role_name
            : "-"}
        </>
      );
    },
  },
  {
    title: "Action",
    dataIndex: "action",
    render: (data) => (
      <div>
        {" "}
        <span>
          <Link to="/users/form/edit">
            <BsFillPencilFill className="table-icons edit-icon text-warning" />
          </Link>
        </span>{" "}
      </div>
    ),
  },
];
