import { sendRequest } from "../../services";
import { apipaths } from "../../services/apiPaths";

export const employeesListAction = () => async (dispatch) => {
  const { success, data } = await sendRequest(apipaths.listEmplo);
  const { employees, companies } = data;
  if (success) {
    dispatch({ type: "ADD_EMPLOYEES", payload: employees });
    dispatch({ type: "ADD_COMAPNIES", payload: companies });
  }
};
