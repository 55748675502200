export const actionColumn = [
  {
    title: "ID",
    dataIndex: "action_plan_id",
    sorter: {
      compare: (a, b) => (a, b),
      multiple: 1,
    },
  },
  {
    title: "Action Title",
    dataIndex: "action_title",
    sorter: {
      compare: (a, b) => (a, b),
      multiple: 1,
    },
  },
  {
    title: "Priority",
    dataIndex: "priority",
    sorter: {
      compare: (a, b) => (a, b),
      multiple: 1,
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    sorter: {
      compare: (a, b) => (a, b),
      multiple: 1,
    },
  },
  {
    title: "Tasks",
    dataIndex: "task_id",
    sorter: {
      compare: (a, b) => (a, b),
      multiple: 1,
    },
    render: (task_id, row) => {
      return row?.task?.subject;
    },
  },
  {
    title: "Notes",
    dataIndex: "note_id",
    sorter: {
      compare: (a, b) => (a, b),
      multiple: 1,
    },
    render: (counter_party_id, row) => {
      return row?.note?.title;
    },
    // render: (note_id, row) => {
    //   const note = row["note"];
    //   if (note) return note.title;
    //   else return "";
    // },
  },
];
export const actionColumnHome = [
  {
    title: "ID",
    dataIndex: "action_plan_id",
    sorter: {
      compare: (a, b) => (a, b),
      multiple: 1,
    },
  },
  {
    title: "アクションタイトル",
    dataIndex: "action_title",
    sorter: {
      compare: (a, b) => (a, b),
      multiple: 1,
    },
  },
  {
    title: "優先順位",
    dataIndex: "priority",
    sorter: {
      compare: (a, b) => (a, b),
      multiple: 1,
    },
  },
  {
    title: "スターテス",
    dataIndex: "status",
    sorter: {
      compare: (a, b) => (a, b),
      multiple: 1,
    },
  },
  {
    title: "タスク",
    dataIndex: "task_id",
    sorter: {
      compare: (a, b) => (a, b),
      multiple: 1,
    },
    render: (task_id, row) => {
      const task = row["task"];
      if (task) return task.subject;
      else return "";
    },
  },
  {
    title: "ノート",
    dataIndex: "note_id",
    sorter: {
      compare: (a, b) => (a, b),
      multiple: 1,
    },
    render: (note_id, row) => {
      const note = row["note"];
      if (note) return note.title;
      else return "";
    },
  },
];
