export default {
  dashboard: "Dashboard",
  add_company: "Add Company",
  active_task: "Active task",
  todays_task: "Today's task",
  todays_meeting: "Today's meetings",
  active_companies: "Active Companies",
  active_projects: "Active Projects",
  coming_meetings: "Upcoming Meetings",
  prospective_projects: "Prospective Projects",
  active_company: "Active Company",
  show: "Show",
  entries: "Entries",
  search: "Search",
  tomorrows_tasks: "Tomorrow's Tasks",
  total_tasks: "Total Tasks",
  tomorrow_meeting: "Tomorrow Meeting",
  total_companies: "Total Companies",
  japanese: "Japanese",
  english: "English",
  total_projects: "Total projects",
  subject: "Subject",
  action: "Action",
  project: "Project",
  showing: "Showing",
  previous: "Previous",
  next: "Next",
  task: "Tasks",
  notes: "Notes",
  meetings: "Meetings",
  emails: "Emails",
  quick_memo: "Quick Memo",
  sector_board: "Sector Board",
  company: "Company",
  total_company: "Total Company",
  client: "Client",
  projects: "Projects",
  users: "Users",
  employees: "Employees",
  teams: "Teams",
  importcontacts: "Import Contacts",
  id: "ID",
  name: "Name",
  name_in_japanese: "Name in japanese",
  industry: "Industry",
  telephone: "Telephone",
  fax: "Fax",
  email: "Email",
  country: "Country",
  counterparty: "Counter Party",
  source: "Source",
  cpclient: "CP Client",
  action: "Action",
  name: "Name",
  cpproject: "CP Project",
  sub_industry: "Sub Industry",
  telephone: "Telephone",
  telephone1: "Telephone 1",
  telephone2: "Telephone 2",
  email: "Email",
  email1: "Email 1",
  email2: "Email 2",
  email3: "Email 3",
  address: "Address",
  city: "City",
  country: "Country",
  postal_code: "Postal Code",
  web_site: "Web Site",
  website: "Website",
  incharge_employee: "Incharge Employee",
  source: "Source",
  activity_status: "Activity Status",
  select_activity_status: "Select Activity Status",
  engagement_status: "Engagement Status",
  select_engagement_status: "Select Engagement Status",
  nda_status: "NDA Status",
  select_nda_status: "Select NDA Status",
  contract_status: "Contract Status",
  select_contract_status: "Select Contract Status",
  first_name: "First Name*",
  first_name: "First Name",
  first_name_in_japanese: "First name in japanese",
  last_name: "Last Name*",
  last_name: "Last Name",
  last_name_in_japanese: "Last name in japanese",
  gender: "Gender",
  start_date: "Start Date",
  end_date: "End Date",
  project_cost: "Project Cost",
  select_company: "Select Company",
  select_Client: "Select Client",
  company_project_profile: "Company Project Profile",
  activity_status: "Activity Status",
  select_status: "Select Status",
  stage: "Stage",
  todays_date: "Start To End Date",
  select_stage: "Select Stage",
  nda_status: "NDA Status",
  select_nda_status: "Select NDA Status",
  contract_status: "Contract Status",
  select_contract_status: "Select Contract Status",
  description: "Description",
  remarks: "Remarks",
  keypoints: "Keypoints",
  department: "Department",
  position: "Position",
  subject: "Subject",
  start__end_date: "Start & End Date",
  priority: "Priority",
  select_priority: "Select Priority",
  select_project: "Select Project",
  status: "Status",
  pending: "Pending",
  task_assigned_to: "Task Assigned To",
  select_employee: "Select Employee",
  employee: "Employee",
  sub_task: "Sub Task",
  due_date: "Due Date",
  dd_mm_yyyy: "dd-mm-yyyy",
  time: "Time",
  select_time: "Select Time",
  waiting: "Waiting",
  assignee: "Assignee",
  select_employee: "Select Employee",
  acceptance: "Acceptance",
  title: "Title",
  document: "Document",
  no_file_chosen: "No file chosen",
  entry_date: "Entry Date",
  subject: "Subject",
  date: "Date",
  start_time: "Start Time",
  select_Time: "Select Time",
  end_time: "End Time",
  venue: "Venue",
  attendees: "Attendees",
  pre_meeting_task: "Pre Meeting Task",
  post_meeting_task: "Post Meeting Task",
  sender: "Sender",
  send_date: "Send Date",
  message: "Message",
  conclusion: "Conclusion",
  next_step: "Next Step",
  quick_memos: "Quick Memos",
  email: "Email",
  password: "Password",
  first_name: "First Name",
  last_name: "Last Name",
  import_contacts: "Import Contacts",
  add: "Add",
  delete: "Delete",
  submit: "Submit",
  login: "Login",
  forgot_password: "Forgot Password",
  edit: "Edit",
  add_memo: "Add Memo",
  memo: "Memo",
  view_all_memos: "View All Memos",
  select_client: "Select Client",
  add_client: "Add Client",
  edit_client: "Edit Client",
  add_project: "Add Project",
  edit_project: "Edit Project",
  add_note: "Add Note",
  edit_note: "Edit Note",
  add_email: "Add Email",
  edit_email: "Edit Email",
  add_quick_memos: "Add Quick Memo",
  edit_quick_memos: "Edit Quick Memo",
  add_Employee: "Add Employee",
  edit_employee: "Edit Employee",
  add_team: "Add Team",
  edit_team: "Edit Team",
  add_user: "Add User",
  edit_user: "Edit User",
  update_profile: "Update Profile",
  username: "Username",
  update_password: "Update Password",
  role: "Role",
  new_password: "New Password",
  current_password: "Current Password",
  confirm_password: "Confirm Password",
  change_password: "Change Password",
  edit_company: "Edit Company",
  update_company: "Update Company",
  cancel: "Cancel",
  add_meeting: "Add Meeting",
  edit_meetings: "Edit Meeting",
  add_task: "Add Task",
  view_meetings: "View Meetings",
  contact_number: "Phone Number",
  welcome: "Welcome",
  admin: "Admin",
  logout: "Logout",
  settings: "Settings",
  my_profile: "My Profile",
  change_photo: "Change Photo",
  today_date: "Today's Date",
  location: "Location",
  partnership_mode: "Partnership Mode",
  expected_amount: "Expected Amount",
  currency: "Currency",
  confidence_level: "Confidence Level",
  source_of_introduction: "Source Of Introduction",
  stage_of_proceeding: "Stage Of Proceeding",
  last_action: "Last Action",
  is_introduced: "Is Introduced",
  action_title: "Action Title",
  attachment: "Attachment",
  add_counter_party: "Add Counter Party",
  select_currency: "Select Currency",
  select_confidence_level: "Select Confidence Level",
  select_stage_of_proceeding: "Select Stage Of Proceeding",
  select_is_introduced: "Select Is Introduced",
  select_counter_party: "Select Counter Party",
  select_notes: "Select Notes",
  select_assignee: "Select Assignee",
  select_task: "Select Task",
  add_action_plan: "Add Action Plan",
  action_plan: "Action Plan",
  startDate_to_endDate: "Start Date To End Date",
  select_date: "Select Date",
  startDate_to_endDate: "Start Date To End Date",
  calendar: "Calender",
  select_incharge_employee: "Select Incharge Employee",
  pre_task: "Pre Task",
  post_task: "Post Task",
  select_task_asigned_to: "Select Task Assigned To",
  update: "Update",
  select_cp_project: "Select CP Project",
  select_cp_client: "Select CP Client",
  select_date: "Select Date",
  add_profile: "Add Profile",
  profile: "Profile",
  select_gender: "Select Gender",
  fund_name: "Fund Name",
  fund_type: "Fund Type",
  fund_area: "Fund Area",
  fund_series: "Fund Series",
  amc_location: "AMC and Location",
  investment_area: "Investment Areas",
  investment_country: "Investment Countries",
  target_fund_size: "Target Fund Size (Mil USD)",
  fund_arranged: "Fund Arranged (Mil USD)",
  fund_required: "Fund Required (Mil USD)",
  fax: "Fax",
  fund_closing_date: "Fund Closing Date",
  investment_profile_size: "Investment Portfolio Size",
  current_profile_size: "Current Investment portfolio size",
  fund_tenure: "Fund Tenure",
  current_moic: "Current MOIC",
  expected_moic: "Expected MOIC",
  current_IRR: "Current IRR",
  expected_IRR: "Expected IRR",
  commitment_period: "Commitment Period",
  minimum_commitment_amount: "Minimum Commitment Amount",
  management_fee: "Management Fee",
  hurdule_rate: "Hurdule Rate",
  performance_fee: "Performance Fee",
  other_fees: "Other Fees",
  sponser_name: "Sponsor Name",
  sponser_commitment: "Sponsor Commitment (Mil USD)",
  current_Lps: "Current LPs",
  deal_status: "Deal Status / Amounts and Rounds of funding. (up to 10 words)",
  briefly_describe: "Briefly describe funding situation (up to 10 words)",
  brief_slogan: "Brief slogan (upto 10 words)",
  brief_USP: "Brief USP (upto 10 words)",
  growth_strategy: "Growth Strategy (10-30 words)",
  fund_manager: "Fund Manager",
  incharge_position: "Incharge Position",
  fund_contact_number: "Contact Number",
  email_address: "Email address",
  brief_background: "Brief Background (up to 10 words)",
  FM_track_record: "FM Track Record",
  Comment_if_any: "Comments if any (up to 10 words)",
  project_name: "Project Name",
  energy_type: "Energy Type",
  project_capacity: "Project Capacity (MW)",
  project_location: "Project Location",
  project_cost: "Project Cost",
  debt_finance: "Debt Finanace (Mil USD)",
  interest_rate_duration: "Interest Rate &  Duration",
  project_credit_rating_1: "Project Credit Rating -1",
  project_credit_rating_2: "Project Credit Rating -2",
  project_credit_rating_3: "Project Credit Rating -3",
  project_commissioning_date: "Project Commissioning Date",
  project_status: "Project Status",
  green_brown_field: "Green/Brown Field",
  PPA_status: "PPA Status",
  PPA_counter_party: "PPA Counter Party",
  PPA_rate_tenure: "PPA Rate and Tenure ",
  last_official_valuation: "Last offcial valuation in Million USD",
  last_valuation_date: "Last valuation date",
  valuation_applicable: "Valuation applicable for the below deal (Mil$)",
  fund_form: "Fund Form",
  energy_form: "Energy Form",
  select_industries: "Select Industries",
  requested_transaction: "Requested Transaction (Debt/Equity/CCD etc.)",
  equity_offered: "% of equity offered.",
  amount_asked: "Amount asked for in Million USD",
  investment_horizon: "Investment Horizon in Number of Years",
  exit_type: "Exit type (IPO/Sellout/Buyback etc.)",
  exit_strategy: "Exit strategy (20-30 words)",
  projected_IRR: "Projected IRR",
  projected_ROIC: "Projected ROIC",
  projected_ROE: "Projected ROE",
  purpose_utilization_fund: "Purpose/ utlization of Fund",
  deal_status_amount:
    "Deal Status / Amounts and Rounds of funding. (up to 10 words)",
  briefly_describe_funding_situation:
    "Briefly describe funding situation (up to 10 words)",
  business_model_service: "Business Model/ Services (10-30 words)",
  revenue_model: "Revenue model (10-30 words)",
  cost_model: "Cost model (10-30 words)",
  clients: "Clients",
  competitors: "Competitors",
  indian_strategic_partners: "Indian Strategic Partners",
  japan_strategic_partners: "Japan Strategic Partners",
  golbal_presence: "Global Presence/ Collaborations",
  sales_last_year: "Sales of Last Year (in Mil US$) Actual",
  sales_1y: "Sales of +1Y (in Mil US$) (Projected)",
  sales_2Y: "Sales of +2Y (in Mil US$) (Projected)",
  sales_3Y: "Sales of +3Y (in Mil US$) (Projected)",
  sales_4Y: "Sales of +4Y (in Mil US$) (Projected)",
  sales_5Y: "Sales of +5Y (in Mil US$) (Projected)",
  sales_CAGR: "Sales CAGR",
  EBITDA_last_year: "EBITDA of Last Year (in Mil US$) Actual",
  EBITDA_1y_projected: "EBITDA of +1Y (in Mil US$) (Projected)",
  EBITDA_2y_projected: "EBITDA of +2Y (in Mil US$) (Projected)",
  EBITDA_3y_projected: "EBITDA of +3Y (in Mil US$) (Projected)",
  EBITDA_4y_projected: "EBITDA of +4Y (in Mil US$) (Projected)",
  EBITDA_5y_projected: "EBITDA of +5Y (in Mil US$) (Projected)",
  EBITDA_CAGR: "EBITDA CAGR",
  net_profit_loss_year: "Net Profit/Loss of Last Year (in Mil US$) Actual",
  net_profit_loss_1y: "Net Profit/Loss of +1Y (in Mil US$) Projected",
  project_incharge: "Project Incharge",
  current_investor_1: "Currnet Investor 1",
  current_investor_2: "Current Investor 2 ",
  total_funding_requirements: "Total Funding Requirements",
  equity_funding_asked: "Equity Funding  Asked (Mil USD)",
  debt_funding: "Debt Funding(Mil USD)",
  prefered_intrest_rate: "Preffered Interest rate",
  project_credit_rating: "Project Credit Rating & Rating Company",
  project_start_year: "Project start year",
  commissioning_date: "Commissioning date",
  exit_type_IPO: "Exit type (IPO/Sellout/Buyback etc.)",
  target_IRR: "Target IRR ",
  purpose_utilization: "Purpose/ utlization of Fund",
  business_model: "Business Model/ Services (10-30 words)",
  industry_served: "Industry(ies) Served",
  indian_strategic_partners: "Indian Strategic Partners",
  of_employees: "# of Employees",
  sales_of_3yr: "Sales of -3 yr (in Million USD)",
  sales_of_2yr: "Sales of -2 yr (in Million USD)",
  sales_of_1yr: "Sales of -1 yr (in Million USD)",
  sales_of_last_year: "Sales of last year (in Million USD) ",
  sales_1y_projected: "Sales of +1 yr  (in Million USD) (Projected)",
  sales_2y_projected: "Sales of +2 yr  (in Million USD) (Projected)",
  sales_3y_projected: "Sales of +3 yr  (in Million USD) (Projected)",
  sales_4y_projected: "Sales of +4 yr  (in Million USD) (Projected)",
  sales_5y_projected: "Sales of +5 yr  (in Million USD) (Projected)",
  sales_6y_projected: "Sales of +6 yr  (in Million USD) (Projected)",
  total_assets: "Total Assets",
  net_debt: "Net Debt",
  EBTDA_of_3yr: "EBTDA of -3 yr (in Million USD) ",
  EBTDA_of_2yr: "EBTDA of -2 yr (in Million USD) ",
  EBTDA_of_1yr: "EBTDA of -1 yr (in Million USD) ",
  EBTDA_last_year_million: "EBTDA of last year (in Million USD) ",
  EBTDA_of_1yr_project: "EBTDA of +1 yr  (in Million USD) (Projected)",
  EBTDA_of_2yr_project: "EBTDA of +1 yr  (in Million USD) (Projected)",
  EBTDA_of_3yr_project: "EBTDA of +1 yr  (in Million USD) (Projected)",
  EBTDA_of_4yr_project: "EBTDA of +1 yr  (in Million USD) (Projected)",
  EBTDA_of_5yr_project: "EBTDA of +1 yr  (in Million USD) (Projected)",
  EBTDA_of_6yr_project: "EBTDA of +1 yr  (in Million USD) (Projected)",
  promoters: "Promoters",
  ceo: "CEO",
  cfo: "CFO",
  investor_1: "Investor 1 ",
  investor_2: "Investor 2 ",
  close_significant_advisor: "Close Significant Advisor",
  company_name: "Company Name",
  foundation_year: "Foundation Year",
  listed_non_listed: "Listed/Non-Listed",
};
