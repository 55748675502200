export const projectProfileColumn = [
  {
    title: "ID",
    dataIndex: "profile_id",
    sorter: {
      compare: (a, b) => (a, b),
      multiple: 1,
    },
  },
  {
    title: "Projects",
    dataIndex: "project",
    defaultSortOrder: "descend",
    sorter: (a, b) => a.project.name.localeCompare(b.project.name),
    render: (project_id, row) => {
      console.log("row", row);
      const project = row["project"];
      if (project) return project.name;
      else return "";
    },
  },
  {
    title: "Website",
    dataIndex: "website",
    sorter: {
      compare: (a, b) => (a, b),
      multiple: 1,
    },
  },
  {
    title: "Location",
    dataIndex: "location",
    sorter: {
      compare: (a, b) => (a, b),
      multiple: 1,
    },
  },
  {
    title: "Partnership Mode",
    dataIndex: "partnershipMode",
    sorter: {
      compare: (a, b) => (a, b),
      multiple: 1,
    },
  },
  {
    title: "Currency",
    dataIndex: "currency",
    sorter: {
      compare: (a, b) => (a, b),
      multiple: 1,
    },
  },
];
