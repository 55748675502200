import { PlusOutlined } from "@ant-design/icons";
import { Button, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useTranslation from "../customHooks/translations";
import "./style.css";
import { IoHomeOutline } from "react-icons/io5";

function TableCard({
  jaCol,
  columns,
  data,
  title,
  count,
  button,
  rowPerPage,
  onlyData = false,
  archiveProperty,
}) {
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(rowPerPage ? rowPerPage : 10);
  const [tableColumns, setTableColumns] = useState([]);
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const translation = useTranslation();
  const location = useLocation();
  const pathname = location.pathname;
  const mainLocation = location.pathname.slice(1, -4);

  const createColumnsKey = () => {
    let modifiedColumns = columns.map((col, index) => {
      col["table_key"] = index;
      return col;
    });
    setTableColumns(modifiedColumns);
  };

  const createColumnsKeyJa = () => {
    let modifiedColumns = jaCol?.map((col, index) => {
      col["table_key"] = index;
      return col;
    });
    setTableColumns(modifiedColumns);
  };
  useEffect(() => {
    if (translation._language === "en") {
      createColumnsKey();
    } else {
      createColumnsKeyJa();
    }
  }, [translation._language]);

  useEffect(() => {
    if (search !== "") {
      const filteredData = data.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(search.toLowerCase());
      });
      setSearchResults(filteredData);
    } else {
      setSearchResults(data);
    }
  }, [search, data]);

  const getTitleHandler = (title) => {
    title = title.toLowerCase();
    if (title === "task" || title === "employee") {
      title += "s";
    } else if (title === "quick memos") title = "quickmemos";
    else if (title === "counter plan") title = "counterplan";
    return title;
  };

  return (
    <div
      className={`table-card-container bg-white  py-4 ${
        onlyData ? "no-shadow" : ""
      }`}
    >
      {onlyData === false && (
        <>
          <div
            style={{ borderBottom: "1px solid rgb(240,240,240)" }}
            className="d-flex justify-content-between align-items-center pb-2 px-3"
          >
            <div className="d-flex align-items-center justify-content-between w-100">
              <h2
                className="fs-20 d-flex align-items-center justify-content-between mb-0"
                style={{ float: "left", marginRight: "12px" }}
              >
                {title}
              </h2>
              {pathname == "/dashboard" || title == translation.memo ? (
                ""
              ) : (
                <h3
                  // style={{ marginTop: "-6px" }}
                  className="fs-20 justify-content-center align-items-center p-0 cursor-pointer mb-0"
                  onClick={() => navigate("/dashboard")}
                >
                  <IoHomeOutline
                    style={{ color: "rgb(90 86 86)", fontSize: "23px" }}
                  />
                </h3>
              )}
              <h3></h3>
              <h3
                className="fs-22"
                style={{
                  color:
                    title == "Active task"
                      ? "#48ABF7"
                      : title == "Upcoming Meetings"
                      ? "#F25961"
                      : title == "Active Projects"
                      ? "#FFAD46"
                      : title == "Prospective Projects"
                      ? "#31CE36"
                      : "",
                  marginLeft: "auto",
                }}
              >
                {count}
              </h3>
            </div>
            {button && button.title ? (
              <Button
                className="fs-14"
                type="primary"
                icon={<PlusOutlined />}
                size="large"
                shape="round"
                onClick={
                  button.onClick
                    ? button.onClick
                    : () => navigate(`/${getTitleHandler(title)}/form/add`)
                }
              >
                {button.title}
              </Button>
            ) : (
              <></>
            )}
          </div>
        </>
      )}
      {/* search bar */}
      <div>
        {pathname == "/dashboard" ||
        title == translation.memo ||
        location.pathname.slice(1, -4) == "edit-project" ||
        location.pathname.slice(1, -4) == "edit-company" ? (
          ""
        ) : (
          <div className="row mx-3 justify-content-end align-items-center">
            <label className="col-lg-1 px-2 text-end">
              {translation.search}:
            </label>
            <input
              placeholder={translation.search}
              type="text"
              name="search"
              class="form-control my-2 px-3 col-lg-3"
              style={{ width: "auto", float: "right" }}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        )}
      </div>
      <div className="px-3">
        <Table
          rowKey={"table_key"}
          pagination={
            location.pathname == "/dashboard"
              ? { pageSize, pageSizeOptions: [5] }
              : { pageSize, pageSizeOptions: [5, 10, 50, 100, 500] }
          }
          onChange={({ size }) => setPageSize(size)}
          columns={tableColumns}
          jaCol={tableColumns}
          dataSource={searchResults}
        />
      </div>
    </div>
  );
}
export default TableCard;
