import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { apipaths } from "../../services/apiPaths";
import CardContainer from "../Card/cardContainer";
import Heading from "../Heading";
import { meetingListAction } from "./../actions/meetingAction";
import { useDispatch, useSelector } from "react-redux";
import {
  formatDate,
  formatDateList,
  regName,
} from "../../helpers/commonHelpers";
import { clientListAction } from "../actions/clientAction";
import { companyListAction } from "../actions/comanyAction";
import { projectListAction } from "../actions/projectAction";
import { employeesListAction } from "./../actions/employeesListAction";
import "bootstrap/dist/css/bootstrap.css";
import useTranslation from "../customHooks/translations";
import { sendRequest } from "../../services";
import { DatePicker, Space } from "antd";
const { RangePicker } = DatePicker;

function MeetingForm() {
  const { id } = useParams();
  const [formData, setFormData] = useState({});
  const [preStartDate, setPreStartDate] = useState("");
  const [preEndDate, setPreEndDate] = useState("");
  const [date, setDate] = useState("");
  const [postStartDate, setPostStartDate] = useState("");
  const [postEndDate, setPostEndDate] = useState("");

  const translation = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { employees } = useSelector((state) => state.employee);
  const { projects } = useSelector((state) => state.project);
  const { clients } = useSelector((state) => state.client);
  const { companies } = useSelector((state) => state.company);
  const { meet } = useSelector((state) => state.meet);

  const { TabPane } = Tabs;

  useEffect(() => {
    dispatch(meetingListAction());
    dispatch(clientListAction());
    dispatch(companyListAction());
    dispatch(projectListAction());
    dispatch(employeesListAction());
  }, []);

  const onSubmit = async () => {
    if (formData.subject == undefined || !regName.test(formData.subject)) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Please Fill Out Meeting Subject !",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      let new_meeting_details = { ...formData };
      new_meeting_details = {
        ...new_meeting_details,
        startdate: date,
        Pre_startdate: preStartDate,
        Pre_enddate: preEndDate,
        Post_startdate: postStartDate,
        Post_enddate: postEndDate,
        OPtype: "Meeting",
        operation: "add",
        task_type: "keep",
      };

      const {
        success,
        data: { message },
      } = await sendRequest(apipaths.addMeeting, new_meeting_details);
      if (success) {
        dispatch(meetingListAction());
        Swal.fire({
          position: "center",
          icon: "success",
          title: message,
          showConfirmButton: false,
          timer: 1500,
        });
        navigate(-1);
      }
    }
  };

  const onCancel = () => {
    navigate(-1);
  };
  const fillClient = clients.filter((d) => {
    return d.company_id == formData.company_id;
  });

  const fillProject = projects.filter((d) => {
    return d.client_id == formData.client_id;
  });

  const handleEvent = (event, picker) => {
    setPreStartDate(formatDate(event[0]));
    setPreEndDate(formatDate(event[1]));
  };

  const handleEventPost = (event, picker) => {
    setPostStartDate(formatDate(event[0]));
    setPostEndDate(formatDate(event[1]));
  };

  const onInputChange = (e) => {
    const target = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };
  const preDateChange = (date) => {
    handleEvent(date);
  };
  const postDateChange = (date) => {
    handleEventPost(date);
  };

  const dateChange = (date) => {
    setDate(formatDateList(date));
  };

  const dateFormatList = ["YYYY/MM/DD", "YYYY/MM/DD"];

  return (
    <>
      <div className="main-container">
        <Heading title={translation.add_meeting} />
        <div className="content-wrapper px-5">
          <CardContainer hideTotal={true} />
          <div className="content-container">
            <div class=" p-3 ">
              <Tabs class="row">
                <Tabs.TabPane
                  tab={translation.meetings}
                  key="item-1"
                  className="row"
                >
                  <div class="col-12 col-md-6 col-lg-9">
                    <label for="Meetings" class="form-label">
                      {translation.meetings}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="subject"
                      aria-describedby="emailHelp"
                      placeholder={translation.meetings}
                      name="subject"
                      key="subject"
                      onChange={onInputChange}
                      required
                    />
                  </div>
                  <div class="col-12 col-md-6 col-lg-3">
                    <label for="Date" class="form-label">
                      {translation.date}
                    </label>

                    <DatePicker
                      className="w-100"
                      name="startdate"
                      key="startdate"
                      placeholder={translation.select_date}
                      onChange={(date) => dateChange(date)}
                      style={{ borderRadius: "0.375rem" }}
                    />
                  </div>
                  <div class="col-12 col-md-6 col-lg-3 mt-3">
                    <label for="Start Time" class="form-label">
                      {translation.start_time}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="starttime"
                      aria-describedby="emailHelp"
                      placeholder={translation.start_time}
                      name="starttime"
                      key="starttime"
                      onChange={onInputChange}
                    />
                  </div>
                  <div class="col-12 col-md-6 col-lg-3 mt-3">
                    <label for="End Time" class="form-label">
                      {translation.end_time}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="endtime"
                      aria-describedby="emailHelp"
                      placeholder={translation.end_time}
                      name="endtime"
                      key="endtime"
                      onChange={onInputChange}
                    />
                  </div>
                  <div class="col-12 col-md-6 col-lg-3 mt-3">
                    <label for="Venue" class="form-label">
                      {translation.venue}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="venue"
                      aria-describedby="emailHelp"
                      placeholder={translation.venue}
                      name="venue"
                      key="venue"
                      onChange={onInputChange}
                    />
                  </div>
                  <div class="col-12 col-md-6 col-lg-3 mt-3">
                    <label for="Company" class="form-label">
                      {translation.company}
                    </label>
                    <select
                      class="form-select"
                      aria-label="Select Company"
                      key="company_id"
                      onChange={onInputChange}
                      name="company_id"
                    >
                      {" "}
                      <option selected>{translation.select_company}</option>
                      {companies.map((d, i) => (
                        <option key={d.company_id} value={d.company_id}>
                          {d.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div class="col-12 col-md-6 col-lg-3 mt-3">
                    <label for="Client" class="form-label">
                      {translation.client}
                    </label>
                    <select
                      class="form-select"
                      aria-label=" Select Client"
                      key="client_id"
                      onChange={onInputChange}
                      name="client_id"
                    >
                      {" "}
                      <option selected>{translation.select_client}</option>
                      {fillClient.map((c, i) => (
                        <option key={c.client_id} value={c.client_id}>
                          {c.first_name + "" + c.last_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div class="col-12 col-md-6 col-lg-3 mt-3">
                    <label for="Project" class="form-label">
                      {translation.project}
                    </label>
                    <select
                      class="form-select"
                      aria-label=" select Project"
                      id="project_id"
                      key="project_id"
                      onChange={onInputChange}
                      name="project_id"
                    >
                      <option selected>{translation.select_project}</option>
                      {fillProject.map((p, i) => (
                        <option key={p.project_id} value={p.project_id}>
                          {p.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div class="mb-2 col-lg-3 mt-3">
                    <label for="Incharge Employee" class="form-label">
                      {translation.incharge_employee}
                    </label>
                    <select
                      class="form-select"
                      aria-label="Select Incharge Employee"
                      key="employee_id"
                      onChange={onInputChange}
                      id="employee_id"
                      name="employee_id"
                    >
                      <option selected>
                        {translation.select_incharge_employee}
                      </option>
                      {employees.map((e, i) => (
                        <option key={e.employee_id} value={e.employee_id}>
                          {e.first_name + " " + e.last_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div class="col-12 col-md-6 col-lg-9 mt-3">
                    <label for="Attendees" class="form-label">
                      {translation.attendees}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="attendees"
                      aria-describedby="emailHelp"
                      placeholder={translation.attendees}
                      name="attendees"
                      key="attendees"
                      onChange={onInputChange}
                    />
                  </div>

                  <div class="col-12  mt-3">
                    <label for="Description" class="form-label">
                      {translation.description}
                    </label>
                    <textarea
                      class="form-control"
                      id="desc"
                      placeholder={translation.description}
                      name="desc"
                      key="desc"
                      onChange={onInputChange}
                    ></textarea>
                  </div>
                  <div class="col-12 col-md-6 col-lg-6 mt-3">
                    <label for="Remarks" class="form-label">
                      {translation.remarks}
                    </label>
                    <textarea
                      class="form-control"
                      placeholder={translation.remarks}
                      id="remarks"
                      key="remarks"
                      onChange={onInputChange}
                      name="remarks"
                    ></textarea>
                  </div>
                  <div class="col-12 col-md-6 col-lg-6 mt-3">
                    <label for="Keypoints" class="form-label">
                      {translation.keypoints}
                    </label>
                    <textarea
                      class="form-control"
                      id="keypoints"
                      key="keypoints"
                      name="keypoints"
                      placeholder={translation.keypoints}
                      onChange={onInputChange}
                    ></textarea>
                  </div>
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab={translation.pre_task}
                  key="item-2"
                  className="row"
                >
                  <div class="col-12 col-md-6 col-lg-8">
                    <label for="Subject" class="form-label">
                      {translation.subject}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="Pre_subject"
                      aria-describedby="emailHelp"
                      placeholder={translation.subject}
                      name="Pre_subject"
                      key="Pre_subject"
                      onChange={onInputChange}
                    />
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <label for="Due Date" class="form-label">
                      {translation.startDate_to_endDate}
                    </label>
                    <RangePicker
                      dateFormat="yyyy-MM-dd"
                      onChange={(date) => preDateChange(date)}
                      placeholder={[translation.start__end_date]}
                      format={dateFormatList}
                      className="form-control border-none rounded-lg"
                    />
                  </div>
                  <div class="mb-2 col-lg-3 mt-3">
                    <label for="Priority" class="form-label">
                      {translation.priority}
                    </label>
                    <select
                      class="form-select"
                      aria-label="Select Priority"
                      key="Pre_priority"
                      onChange={onInputChange}
                      id="Pre_priority"
                      name="Pre_priority"
                    >
                      <option selected>{translation.select_priority}</option>
                      <option value="Urgent">Urgent</option>
                      <option value="High">High</option>
                      <option value="Medium">Medium</option>
                      <option value="Low">Low</option>
                    </select>
                  </div>
                  <div class="mb-2 col-lg-3 mt-3">
                    <label for="Status" class="form-label">
                      {translation.status}
                    </label>
                    <select
                      class="form-select"
                      aria-label=" Select Status"
                      key="Pre_status"
                      onChange={onInputChange}
                      name="Pre_status"
                    >
                      {" "}
                      <option selected>{translation.select_status}</option>
                      <option value="Pending">Pending</option>
                      <option value="Started">Started</option>
                      <option value="Inprogress">Inprogress</option>
                      <option value="Completed">Completed</option>
                    </select>
                  </div>
                  <div class="mb-2 col-lg-3 mt-3">
                    <label for="Task Assigned To" class="form-label">
                      {translation.task_assigned_to}
                    </label>
                    <select
                      class="form-select"
                      aria-label=" Select Task Assigned To"
                      key="Pre_task_assign_to"
                      onChange={onInputChange}
                      name="Pre_task_assign_to"
                    >
                      {" "}
                      <option selected>{translation.task_assigned_to}</option>
                      {employees.map((e, i) => (
                        <option key={e.employee_id} value={e.employee_id}>
                          {e.first_name + " " + e.last_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div class="mb-2 col-lg-3 mt-3">
                    <label for="Employee" class="form-label">
                      {translation.employee}
                    </label>
                    <select
                      class="form-select"
                      aria-label=" Select Employee"
                      key="Pre_employee_id"
                      onChange={onInputChange}
                      name="Pre_employee_id"
                    >
                      {" "}
                      <option selected>{translation.employee}</option>
                      {employees.map((e, i) => (
                        <option key={e.employee_id} value={e.employee_id}>
                          {e.first_name + " " + e.last_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div class="col-12  mt-2">
                    <label for="Description" class="form-label">
                      {translation.description}
                    </label>
                    <textarea
                      class="form-control"
                      id="Pre_desc"
                      rows="3"
                      placeholder={translation.description}
                      name="Pre_desc"
                      key="Pre_desc"
                      onChange={onInputChange}
                    ></textarea>
                  </div>
                  <div class="col-12  mt-2">
                    <label for="Remarks" class="form-label">
                      {translation.remarks}
                    </label>
                    <textarea
                      class="form-control"
                      id="Pre_remarks"
                      rows="3"
                      placeholder={translation.remarks}
                      name="Pre_remarks"
                      key="Pre_remarks"
                      onChange={onInputChange}
                    ></textarea>
                  </div>
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab={translation.post_task}
                  key="item-3"
                  className="row"
                >
                  <div class="col-12 col-md-6 col-lg-8">
                    <label for="Subject" class="form-label">
                      {translation.subject}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="Post_subject"
                      aria-describedby="emailHelp"
                      placeholder={translation.subject}
                      name="Post_subject"
                      key="Post_subject"
                      onChange={onInputChange}
                    />
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <label for="Due Date" class="form-label">
                      {translation.startDate_to_endDate}
                    </label>
                    <RangePicker
                      onChange={(date) => postDateChange(date)}
                      placeholder={[translation.start__end_date]}
                      className="form-control border-none rounded-lg"
                    />
                  </div>
                  <div class="mb-2 col-lg-3 mt-3">
                    <label for="Priority" class="form-label">
                      {translation.priority}
                    </label>
                    <select
                      class="form-select"
                      aria-label="Select Priority"
                      key="Post_priority"
                      onChange={onInputChange}
                      id="Post_priority"
                      name="Post_priority"
                    >
                      <option selected>{translation.select_priority}</option>
                      <option value="Urgent">Urgent</option>
                      <option value="High">High</option>
                      <option value="Medium">Medium</option>
                      <option value="Low">Low</option>
                    </select>
                  </div>
                  <div class="mb-2 col-lg-3 mt-3">
                    <label for="Status" class="form-label">
                      {translation.status}
                    </label>
                    <select
                      class="form-select"
                      aria-label=" Select Status"
                      key="Post_status"
                      onChange={onInputChange}
                      name="Post_status"
                    >
                      {" "}
                      <option selected>{translation.select_status}</option>
                      <option value="Pending">Pending</option>
                      <option value="Started">Started</option>
                      <option value="Inprogress">Inprogress</option>
                      <option value="Completed">Completed</option>
                    </select>
                  </div>
                  <div class="mb-2 col-lg-3 mt-3">
                    <label for="Task Assigned To" class="form-label">
                      {translation.task_assigned_to}
                    </label>
                    <select
                      class="form-select"
                      aria-label=" Select Task Assigned To"
                      key="Post_task_assign_to"
                      onChange={onInputChange}
                      name="Post_task_assign_to"
                    >
                      {" "}
                      <option selected>{translation.task_assigned_to}</option>
                      {employees.map((e, i) => (
                        <option key={e.employee_id} value={e.employee_id}>
                          {e.first_name + " " + e.last_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div class="mb-2 col-lg-3 mt-3">
                    <label for="Employee" class="form-label">
                      {translation.employee}
                    </label>
                    <select
                      class="form-select"
                      aria-label=" Select Employee"
                      key="Post_employee_id"
                      onChange={onInputChange}
                      name="Post_employee_id"
                    >
                      {" "}
                      <option selected>{translation.employee}</option>
                      {employees.map((e, i) => (
                        <option key={e.employee_id} value={e.employee_id}>
                          {e.first_name + " " + e.last_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div class="col-12  mt-2">
                    <label for="Description" class="form-label">
                      {translation.description}
                    </label>
                    <textarea
                      class="form-control"
                      id="Post_desc"
                      rows="3"
                      placeholder={translation.description}
                      name="Post_desc"
                      key="Post_desc"
                      onChange={onInputChange}
                    ></textarea>
                  </div>
                  <div class="col-12  mt-2">
                    <label for="Remarks" class="form-label">
                      {translation.remarks}
                    </label>
                    <textarea
                      class="form-control"
                      id="Post_remarks"
                      rows="3"
                      placeholder={translation.remarks}
                      name="Post_remarks"
                      key="Post_remarks"
                      onChange={onInputChange}
                    ></textarea>
                  </div>
                </Tabs.TabPane>
              </Tabs>
              <div class="col-lg-12 mb-2 d-flex justify-content-end mt-3">
                <button
                  type="button"
                  class="btn btn-danger px-3 py-2 me-2"
                  onClick={() => onCancel()}
                >
                  {translation.cancel}
                </button>
                <button
                  type="button"
                  class="btn btn-primary px-3 py-2"
                  onClick={() => onSubmit()}
                >
                  {translation.add}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MeetingForm;
