import { BsFillPencilFill, BsFillTrashFill } from "react-icons/bs";
import { Link } from "react-router-dom";

export const notesColumns = [
  {
    title: "ID",
    dataIndex: "note_id",
    sorter: {
      compare: (a, b) => (a, b),
      multiple: 1,
    },
  },
  {
    title: "Title",
    dataIndex: "title",
    sorter: {
      compare: (a, b) => a.english - b.english,
      multiple: 1,
    },
  },
  {
    title: "Company",
    dataIndex: "company",
    sorter: {
      compare: (a, b) => a.company - b.company,
      multiple: 2,
    },
    render: (company) => {
      return (
        <p className="mb-0">{company && company.name ? company.name : ""}</p>
      );
    },
    title: "Client",
    dataIndex: "client",
    sorter: {
      compare: (a, b) => a.math - b.math,
      multiple: 2,
    },
    render: (data) => {
      return (
        <>
          {data?.first_name && data?.last_name
            ? data?.first_name + " " + data?.last_name
            : "-"}
        </>
      );
    },
  },
  {
    title: "Date",
    dataIndex: "date",
    sorter: {
      compare: (a, b) => a.math - b.math,
      multiple: 2,
    },
  },
  {
    title: "Action",
    dataIndex: "action",
    render: (data) => (
      <div>
        {" "}
        <span>
          <Link to="/notes/form/edit">
            <BsFillPencilFill className="table-icons edit-icon text-warning" />
          </Link>
          <BsFillTrashFill className="table-icons delete-icon text-danger" />
        </span>{" "}
      </div>
    ),
  },
];
