export const SubTaskFormLayout = [
  {
    id: 1,
    label: "Sub Task",
    type: "text",
    placeholder: "Sub Task",
    key: "sub_task_1",
    containerClass: "col-sm-12 col-md-4 col-lg-2",
  },
  {
    id: 2,
    label: "Due Date",
    key: "due_date_1",
    type: "date",
    containerClass: "col-sm-12 col-md-4 col-lg-2",
  },
  {
    id: 3,
    label: "Time",
    type: "text",
    placeholder: "Time",
    key: "time_1",
    containerClass: "col-sm-12 col-md-4 col-lg-2",
  },
  {
    id: 4,
    label: "Status",
    type: "select",
    placeholder: "Select Status",
    key: "status_1",
    options: [
      {
        label: "Pending",
        value: "Pending",
      },
      {
        label: "Started",
        value: "Started",
      },
      {
        label: "Inprogress",
        value: "Inprogress",
      },
      {
        label: "Completed",
        value: "Completed",
      },
    ],
  },
  {
    id: 5,
    label: "Assignee",
    type: "select",
    placeholder: "Select Assignee",
    key: "employee_id_1",
    containerClass: "col-sm-12 col-md-4 col-lg-2",
    options: [
      {
        label: "Priority 1",
        value: "pri1",
      },
      {
        label: "Priority 2",
        value: "pri2",
      },
    ],
  },
  // {
  //   id: 6,
  //   label: "Acceptance",
  //   type: "checkbox",
  //   placeholder: "Select Acceptance",
  //   key: "acceptance",
  //   containerClass: "col-sm-12 col-md-4 col-lg-2",
  //   options: [
  //     {
  //       label: "",
  //       value: "pri1",
  //     },
  //   ],
  // },
];
