import React, { useState } from "react";
import useTranslation from "../customHooks/translations";

const EnergyForm = (props) => {
  const { navId, isModalVisible, setIsModalVisible, editData, setEditData } =
    props;
  const [formData, setFormData] = useState({});
  const translation = useTranslation();

  const onInputChange = (e) => {
    const target = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };

  return (
    <>
      <div
        classNameName="line px-3 d-flex justify-content-between align-items-center"
        style={{ clear: "both", borderBottom: "1px solid #f0f0f0" }}
      >
        <form>
          <div classNameName="w-100 p-2 row px-4">
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.company_name} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.company_name}
                  name="company_name"
                  key="company_name"
                  value={formData.company_name}
                  //   defaultValue={editData.fund_name}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.website} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.website}
                  name="website"
                  key="website"
                  value={formData.website}
                  //   defaultValue={editData.fund_name}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.industry} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.industry}
                  name="industry"
                  key="industry"
                  value={formData.industry}
                  //   defaultValue={editData.fund_name}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.sub_industry} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.sub_industry}
                  name="sub_industry"
                  key="sub_industry"
                  value={formData.sub_industry}
                  //   defaultValue={editData.fund_name}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.foundation_year} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.foundation_year}
                  name="foundation_year"
                  key="foundation_year"
                  value={formData.foundation_year}
                  //   defaultValue={editData.fund_name}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.listed_non_listed} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.listed_non_listed}
                  name="listed_non_listed"
                  key="listed_non_listed"
                  value={formData.listed_non_listed}
                  //   defaultValue={editData.fund_name}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.energy_type} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.energy_type}
                  name="energy_type"
                  key="energy_type"
                  value={formData.energy_type}
                  //   defaultValue={editData.fund_type}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.project_capacity} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.project_capacity}
                  name="project_capacity"
                  key="project_capacity"
                  value={formData.project_capacity}
                  //   defaultValue={editData.fund_area}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.project_location} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.project_location}
                  name="project_location"
                  key="project_location"
                  value={formData.project_location}
                  //   defaultValue={editData.fund_series}
                  onChange={onInputChange}
                />
              </div>
            </div>{" "}
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.project_cost} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.project_cost}
                  name="project_cost"
                  key="project_cost"
                  value={formData.project_cost}
                  //   defaultValue={editData.amc_location}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.total_funding_requirements} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.total_funding_requirements}
                  name="total_funding_requirements"
                  key="total_funding_requirements"
                  value={formData.project_cost}
                  //   defaultValue={editData.investment_area}
                  onChange={onInputChange}
                />
              </div>
            </div>{" "}
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.equity_funding_asked} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.equity_funding_asked}
                  name="equity_funding_asked"
                  key="equity_funding_asked"
                  value={formData.equity_funding_asked}
                  //   defaultValue={editData.equity_funding_asked}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.equity_offered} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.equity_offered}
                  name="equity_offered"
                  key="equity_offered"
                  value={formData.equity_offered}
                  //   defaultValue={editData.equity_offered}
                  onChange={onInputChange}
                />
              </div>
            </div>{" "}
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.last_valuation_date} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.last_valuation_date}
                  name="last_valuation_date"
                  key="last_valuation_date"
                  value={formData.last_valuation_date}
                  //   defaultValue={editData.last_valuation_date}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.valuation_applicable} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.valuation_applicable}
                  name="valuation_applicable"
                  key="valuation_applicable"
                  value={formData.valuation_applicable}
                  //   defaultValue={editData.valuation_applicable}
                  onChange={onInputChange}
                />
              </div>
            </div>{" "}
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.debt_funding} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.debt_funding}
                  name="debt_funding"
                  key="debt_funding"
                  value={formData.debt_funding}
                  //   defaultValue={editData.debt_funding}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.prefered_intrest_rate} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.prefered_intrest_rate}
                  name="prefered_intrest_rate"
                  key="prefered_intrest_rate"
                  value={formData.prefered_intrest_rate}
                  //   defaultValue={editData.prefered_intrest_rate}
                  onChange={onInputChange}
                />
              </div>
            </div>{" "}
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.project_credit_rating} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.project_credit_rating}
                  name="project_credit_rating"
                  key="project_credit_rating"
                  value={formData.project_credit_rating}
                  //   defaultValue={editData.project_credit_rating}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.project_start_year} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.project_start_year}
                  name="project_start_year"
                  key="project_start_year"
                  value={formData.project_start_year}
                  //   defaultValue={editData.project_start_year}
                  onChange={onInputChange}
                />
              </div>
            </div>{" "}
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.commissioning_date} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.commissioning_date}
                  name="commissioning_date"
                  key="commissioning_date"
                  value={formData.commissioning_date}
                  //   defaultValue={editData.commissioning_date}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.exit_type_IPO} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.exit_type_IPO}
                  name="exit_type_IPO"
                  key="exit_type_IPO"
                  value={formData.exit_type_IPO}
                  //   defaultValue={editData.exit_type_IPO}
                  onChange={onInputChange}
                />
              </div>
            </div>{" "}
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.exit_strategy} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.exit_strategy}
                  name="exit_strategy"
                  key="exit_strategy"
                  value={formData.exit_strategy}
                  //   defaultValue={editData.exit_strategy}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.target_IRR} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.target_IRR}
                  name="target_IRR"
                  key="target_IRR"
                  value={formData.target_IRR}
                  //   defaultValue={editData.target_IRR}
                  onChange={onInputChange}
                />
              </div>
            </div>{" "}
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.purpose_utilization_fund} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.purpose_utilization_fund}
                  name="purpose_utilization_fund"
                  key="purpose_utilization_fund"
                  value={formData.purpose_utilization_fund}
                  //   defaultValue={editData.purpose_utilization_fund}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.deal_status_amount} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.deal_status_amount}
                  name="deal_status_amount"
                  key="deal_status_amount"
                  value={formData.deal_status_amount}
                  //   defaultValue={editData.management_fee}
                  onChange={onInputChange}
                />
              </div>
            </div>{" "}
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.briefly_describe_funding_situation} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.briefly_describe_funding_situation}
                  name="briefly_describe_funding_situation"
                  key="briefly_describe_funding_situation"
                  value={formData.briefly_describe_funding_situation}
                  //   defaultValue={editData.briefly_describe_funding_situation}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.brief_slogan} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.brief_slogan}
                  name="brief_slogan"
                  key="brief_slogan"
                  value={formData.brief_slogan}
                  //   defaultValue={editData.brief_slogan}
                  onChange={onInputChange}
                />
              </div>
            </div>{" "}
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.brief_USP} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.brief_USP}
                  name="brief_USP"
                  key="brief_USP"
                  value={formData.brief_USP}
                  //   defaultValue={editData.brief_USP}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.business_model} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.business_model}
                  name="business_model"
                  key="business_model"
                  value={formData.business_model}
                  //   defaultValue={editData.business_model}
                  onChange={onInputChange}
                />
              </div>
            </div>{" "}
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.revenue_model} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.revenue_model}
                  name="revenue_model"
                  key="revenue_model"
                  value={formData.revenue_model}
                  //   defaultValue={editData.revenue_model}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.growth_strategy} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.growth_strategy}
                  name="growth_strategy"
                  key="growth_strategy"
                  value={formData.growth_strategy}
                  //   defaultValue={editData.growth_strategy}
                  onChange={onInputChange}
                />
              </div>
            </div>{" "}
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.cost_model} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.cost_model}
                  name="cost_model"
                  key="cost_model"
                  value={formData.cost_model}
                  //   defaultValue={editData.cost_model}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.clients} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.clients}
                  name="clients"
                  key="clients"
                  value={formData.clients}
                  //   defaultValue={editData.clients}
                  onChange={onInputChange}
                />
              </div>
            </div>{" "}
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.competitors} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.competitors}
                  name="competitors"
                  key="competitors"
                  value={formData.competitors}
                  //   defaultValue={editData.competitors}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.indian_strategic_partners} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.indian_strategic_partners}
                  name="indian_strategic_partners"
                  key="indian_strategic_partners"
                  value={formData.indian_strategic_partners}
                  //   defaultValue={editData.indian_strategic_partners}
                  onChange={onInputChange}
                />
              </div>
            </div>{" "}
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.japan_strategic_partners} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.japan_strategic_partners}
                  name="japan_strategic_partners"
                  key="japan_strategic_partners"
                  value={formData.japan_strategic_partners}
                  //   defaultValue={editData.japan_strategic_partners}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.golbal_presence} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.golbal_presence}
                  name="golbal_presence"
                  key="golbal_presence"
                  value={formData.golbal_presence}
                  //   defaultValue={editData.golbal_presence}
                  onChange={onInputChange}
                />
              </div>
            </div>{" "}
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.of_employees} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.of_employees}
                  name="of_employees"
                  key="of_employees"
                  value={formData.of_employees}
                  //   defaultValue={editData.of_employees}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.sales_of_1yr} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.sales_of_1yr}
                  name="sales_of_1yr"
                  key="sales_of_1yr"
                  value={formData.sales_of_1yr}
                  //   defaultValue={editData.sales_of_1yr}
                  onChange={onInputChange}
                />
              </div>
            </div>{" "}
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.sales_of_2yr} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.sales_of_2yr}
                  name="sales_of_2yr"
                  key="sales_of_2yr"
                  value={formData.sales_of_2yr}
                  //   defaultValue={editData.sales_of_2yr}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.sales_of_3yr} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.sales_of_3yr}
                  name="sales_of_3yr"
                  key="sales_of_3yr"
                  value={formData.sales_of_3yr}
                  //   defaultValue={editData.sales_of_3yr}
                  onChange={onInputChange}
                />
              </div>
            </div>{" "}
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.sales_1y_projected} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.sales_1y_projected}
                  name="sales_1y_projected"
                  key="sales_1y_projected"
                  value={formData.sales_1y_projected}
                  //   defaultValue={editData.sales_1y_projected}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.sales_2y_projected} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.sales_2y_projected}
                  name="sales_2y_projected"
                  key="sales_2y_projected"
                  value={formData.sales_2y_projected}
                  //   defaultValue={editData.sales_2y_projected}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.sales_3y_projected} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.sales_3y_projected}
                  name="sales_3y_projected"
                  key="sales_3y_projected"
                  value={formData.sales_3y_projected}
                  //   defaultValue={editData.sales_3y_projected}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.sales_4y_projected} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.sales_4y_projected}
                  name="sales_4y_projected"
                  key="sales_4y_projected"
                  value={formData.sales_4y_projected}
                  //   defaultValue={editData.sales_4y_projected}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.sales_5y_projected} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.sales_5y_projected}
                  name="sales_5y_projected"
                  key="sales_5y_projected"
                  value={formData.sales_5y_projected}
                  //   defaultValue={editData.sales_5y_projected}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.sales_6y_projected} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.sales_6y_projected}
                  name="sales_6y_projected"
                  key="sales_6y_projected"
                  value={formData.sales_6y_projected}
                  //   defaultValue={editData.sales_6y_projected}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.total_assets} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.total_assets}
                  name="total_assets"
                  key="total_assets"
                  value={formData.total_assets}
                  //   defaultValue={editData.total_assets}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.net_debt} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.net_debt}
                  name="net_debt"
                  key="net_debt"
                  value={formData.net_debt}
                  //   defaultValue={editData.net_debt}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.EBTDA_of_1yr} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.EBTDA_of_1yr}
                  name="EBTDA_of_1yr"
                  key="EBTDA_of_1yr"
                  value={formData.EBTDA_of_1yr}
                  //   defaultValue={editData.EBTDA_of_1yr}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.EBTDA_of_2yr} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.EBTDA_of_2yr}
                  name="EBTDA_of_2yr"
                  key="EBTDA_of_2yr"
                  value={formData.EBTDA_of_2yr}
                  //   defaultValue={editData.EBTDA_of_2yr}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.EBTDA_of_3yr} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.EBTDA_of_3yr}
                  name="EBTDA_of_3yr"
                  key="EBTDA_of_3yr"
                  value={formData.EBTDA_of_3yr}
                  //   defaultValue={editData.EBTDA_of_3yr}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.EBTDA_last_year_million} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.EBTDA_last_year_million}
                  name="EBTDA_last_year_million"
                  key="EBTDA_last_year_million"
                  value={formData.EBTDA_last_year_million}
                  //   defaultValue={editData.EBTDA_last_year_million}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.EBTDA_of_1yr_project} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.EBTDA_of_1yr_project}
                  name="EBTDA_of_1yr_project"
                  key="EBTDA_of_1yr_project"
                  value={formData.EBTDA_of_1yr_project}
                  //   defaultValue={editData.EBTDA_of_1yr_project}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.EBTDA_of_2yr_project} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.EBTDA_of_2yr_project}
                  name="EBTDA_of_2yr_project"
                  key="EBTDA_of_2yr_project"
                  value={formData.EBTDA_of_2yr_project}
                  //   defaultValue={editData.EBTDA_of_2yr_project}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.EBTDA_of_3yr_project} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.EBTDA_of_3yr_project}
                  name="EBTDA_of_3yr_project"
                  key="EBTDA_of_3yr_project"
                  value={formData.EBTDA_of_3yr_project}
                  //   defaultValue={editData.EBTDA_of_3yr_project}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.EBTDA_of_4yr_project} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.EBTDA_of_4yr_project}
                  name="EBTDA_of_4yr_project"
                  key="EBTDA_of_4yr_project"
                  value={formData.EBTDA_of_4yr_project}
                  //   defaultValue={editData.EBTDA_of_4yr_project}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.EBTDA_of_5yr_project} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.EBTDA_of_5yr_project}
                  name="EBTDA_of_5yr_project"
                  key="EBTDA_of_5yr_project"
                  value={formData.EBTDA_of_5yr_project}
                  //   defaultValue={editData.EBTDA_of_5yr_project}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.EBTDA_of_6yr_project} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.EBTDA_of_6yr_project}
                  name="EBTDA_of_6yr_project"
                  key="EBTDA_of_6yr_project"
                  value={formData.EBTDA_of_6yr_project}
                  //   defaultValue={editData.EBTDA_of_6yr_project}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.promoters} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.promoters}
                  name="promoters"
                  key="promoters"
                  value={formData.promoters}
                  //   defaultValue={editData.promoters}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.ceo} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.ceo}
                  name="ceo"
                  key="ceo"
                  value={formData.ceo}
                  //   defaultValue={editData.ceo}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.brief_background} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.brief_background}
                  name="brief_background"
                  key="brief_background"
                  value={formData.brief_background}
                  //   defaultValue={editData.brief_background}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.cfo} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.cfo}
                  name="cfo"
                  key="cfo"
                  value={formData.cfo}
                  //   defaultValue={editData.cfo}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.investor_1} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.investor_1}
                  name="investor_1"
                  key="investor_1"
                  value={formData.investor_1}
                  //   defaultValue={editData.investor_1}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.investor_2} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.investor_2}
                  name="investor_2"
                  key="investor_2"
                  value={formData.investor_2}
                  //   defaultValue={editData.investor_2}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.close_significant_advisor} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.close_significant_advisor}
                  name="close_significant_advisor"
                  key="close_significant_advisor"
                  value={formData.close_significant_advisor}
                  //   defaultValue={editData.close_significant_advisor}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label className="col-sm-4 col-form-label">
                {translation.Comment_if_any} :
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.Comment_if_any}
                  name="Comment_if_any"
                  key="Comment_if_any"
                  value={formData.Comment_if_any}
                  //   defaultValue={editData.Comment_if_any}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div class="col-lg-12 mb-2 d-flex justify-content-end px-2.5 mt-3">
              <button
                type="button"
                class="btn btn-danger px-3 py-2 me-2"
                // onClick={() => handleCancel()}
              >
                {translation.cancel}
              </button>
              <button
                type="button"
                class="btn btn-primary px-3 py-2"
                // onClick={() => mainHandler()}
              >
                Add
                {/* {editData.task_id || editData.project_id
                  ? `${translation.update}`
                  : `${translation.add}`} */}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default EnergyForm;
