import React from "react";
import { Modal } from "antd";

function ModalContainer({
  title = "Modal",
  content = <></>,
  onOk,
  onCancel,
  isOpen = false,
  footer = undefined,
  closeIcon = () => {},
}) {
  return (
    <>
      <Modal
        destroyOnClose
        width={1000}
        title={title}
        visible={isOpen}
        onOk={onOk}
        onCancel={onCancel}
        footer={footer}
        closeIcon={closeIcon}
      >
        <div>{content}</div>
      </Modal>
    </>
  );
}

export default ModalContainer;
