import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { addClienthandler, updateClienthandler } from "../../apiParsing/Client";
import { clientFormLayout } from "../../Data/FormsLayout/clientFormLayout";
import {
  companyFormLayoutHandler,
  formModalController,
  modalInitialValue,
} from "../../helpers/commonHelpers";
import { addActionColumn } from "../../helpers/companyHelpers";
import { sendRequest } from "../../services";
import { apipaths } from "../../services/apiPaths";
import { clientListAction } from "../actions/clientAction";
import TableCard from "../TableCard";
import { Dropdown } from "antd";
import { menu } from "../../Data/Columns/clientColumn";
import { BarsOutlined } from "@ant-design/icons";
import { updateHandler } from "../../apiParsing/data";
import { useLocation, useParams } from "react-router-dom";
import useTranslation from "../customHooks/translations";
import ModalContainer from "../Modal";
import Heading from "../Heading";
import CardContainer from "../Card/cardContainer";
import { clientFormLayoutJap } from "../../Data/FormsLayout/clientFormLayout";
import { companyListAction } from "../actions/comanyAction";

const CompanyClient = () => {
  const [modal, setModal] = useState(modalInitialValue);
  const { id: companyId } = useParams();
  const translation = useTranslation();

  const dispatch = useDispatch();
  const { clients } = useSelector((state) => state.client);
  const { companies } = useSelector((state) => state.company);
  const { employees } = useSelector((state) => state.employee);

  const [companyClients, setCompanyClients] = useState([]);
  const [formLayout, setFormLayout] = useState(clientFormLayout);

  const filterClientsHandler = () => {
    if (companyId) {
      let data = clients.filter(
        (client) => client.company_id == parseInt(companyId)
      );
      setCompanyClients(data);
    }
  };

  useEffect(() => {
    if (employees && companies) {
      employees.map((emp) => (emp.name = emp.first_name + " " + emp.last_name));
      companyFormLayoutHandler(
        employees,
        clientFormLayout,
        "employee_id",
        "name"
      );
      companyFormLayoutHandler(
        companies,
        clientFormLayout,
        "company_id",
        "name"
      );
    }
  }, [employees, companies]);

  useEffect(() => {
    if (employees && companies) {
      employees.map((emp) => (emp.name = emp.first_name + " " + emp.last_name));
      companyFormLayoutHandler(
        employees,
        clientFormLayoutJap,
        "employee_id",
        "name"
      );
      companyFormLayoutHandler(
        companies,
        clientFormLayoutJap,
        "company_id",
        "name"
      );
    }
  }, [employees, companies]);

  useEffect(() => {
    if (clients.length > 0) filterClientsHandler();
    filterClientsHandler();
  }, [clients]);

  useEffect(() => {
    // dispatch(companyListAction());
    dispatch(clientListAction());
  }, []);

  const clientColumns = [
    {
      title: "ID",
      dataIndex: "client_id",
      sorter: (a, b) => a.client_id - b.client_id,
      render: (id) => {
        return id;
      },
    },
    {
      title: "Name",
      dataIndex: "first_name",
      sorter: (a, b) => a.first_name.localeCompare(b.first_name),
      render: (d, data) => {
        return (
          <>
            <label
              style={{ color: "#1890ff" }}
              onClick={() => {
                dataTest(data);
              }}
            >
              {data.first_name}
            </label>
          </>
        );
      },
    },

    {
      title: "Telephone",
      dataIndex: "tel1",
      sorter: (a, b) => a.tel1 - b.tel1,
      render: (data, { tel1, tel2 }) => {
        return <>{tel1 ? tel1 : tel2}</>;
      },
    },

    {
      title: "Email",
      dataIndex: "email1",
      sorter: (a, b) => a.email1.localeCompare(b.email1),
    },
    {
      title: "Country",
      dataIndex: "country",
      sorter: (a, b) => a.country.localeCompare(b.country),
    },

    {
      title: "Position",
      dataIndex: "position",
      sorter: (a, b) => a.position.localeCompare(b.position),
    },

    {
      title: "Action",
      dataIndex: "action",
      render: (data, index) => (
        <div key={index}>
          <Dropdown overlay={menu} placement="bottom" className="action-button">
            <BarsOutlined />
          </Dropdown>
        </div>
      ),
    },
  ];

  const clientColumnsHome = [
    {
      title: "ID",
      dataIndex: "client_id",
      sorter: (a, b) => a.client_id - b.client_id,
      render: (id) => {
        return id;
      },
    },
    {
      title: "名前",
      dataIndex: "first_name",
      sorter: (a, b) => a.first_name.localeCompare(b.first_name),
      render: (d, data) => {
        return (
          <>
            <label
              style={{ color: "#1890ff" }}
              onClick={() => {
                dataTest(data);
              }}
            >
              {data.first_name}
            </label>
          </>
        );
      },
    },

    {
      title: "電話",
      dataIndex: "tel1",
      sorter: (a, b) => a.tel1 - b.tel1,
      render: (data, { tel1, tel2 }) => {
        return <>{tel1 ? tel1 : tel2}</>;
      },
    },
    
    {
      title: "Eメール",
      dataIndex: "email1",
      sorter: (a, b) => a.email1.localeCompare(b.email1),
    },
    {
      title: "国",
      dataIndex: "country",
      sorter: (a, b) => a.country.localeCompare(b.country),
    },
 
    {
      title: "ポジション",
      dataIndex: "position",
      sorter: (a, b) => a.position.localeCompare(b.position),
    },
  
    {
      title: "Action",
      dataIndex: "action",
      render: (data, index) => (
        <div key={index}>
          <Dropdown overlay={menu} placement="bottom" className="action-button">
            <BarsOutlined />
          </Dropdown>
        </div>
      ),
    },
  ];

  const dataTest = (data) => {
    formModalController(
      setModal,
      clientFormLayout,
      clientFormLayoutJap,
      `${translation.edit_client}`,
      `edit-client-form`,
      data,
      (data) =>
        updateHandler(
          apipaths.addClient,
          data,
          "client_id",
          setModal,
          dispatch,
          clientListAction
        )
    );
  };

  const clientDeleteHandler = (client_id) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#1890ff",
    }).then(async ({ isConfirmed }) => {
      if (!isConfirmed) return null;

      const { data } = await sendRequest(apipaths.deleteClient, {
        delete_id: client_id,
      });
      const { success, message } = data[0].original;
      if (success === 1) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: message,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch(clientListAction());
      }
    });
  };

  useEffect(() => {
    if (employees.length > 0 && companies.length > 0) {
      employees.map((emp) => (emp.name = emp.first_name + " " + emp.last_name));
      companyFormLayoutHandler(
        employees,
        clientFormLayout,
        "employee_id",
        "name"
      );
      companyFormLayoutHandler(
        companies,
        clientFormLayout,
        "company_id",
        "name"
      );
    }
  }, [employees, companies]);

  return (
    <>
      <ModalContainer
        isOpen={modal.open}
        title={modal.title}
        closeIcon={() => setModal(modalInitialValue)}
        content={modal.content}
        footer={false}
      />
      <div className="main-container">
        <Heading title={translation.client} />

        <div className="content-wrapper px-5">
          <CardContainer hideTotal={true} />
        </div>

        <div className="px-5">
          <TableCard
            button={{
              title: translation.add_client,
              onClick: () =>
                formModalController(
                  setModal,
                  clientFormLayout,
                  clientFormLayoutJap,
                  `${translation.add_client}`,
                  `add-client-form`,
                  {},
                  (data) =>
                    addClienthandler(data, setModal, dispatch, clientListAction)
                ),
            }}
            columns={addActionColumn(
              { type: `dropdown`, name: `client-dropdown` },
              clientColumns,
              (data) => {
                formModalController(
                  setModal,
                  clientFormLayout,
                  clientFormLayoutJap,
                  `${translation.edit_client}`,
                  `edit-client-form`,
                  data,
                  (data) =>
                    updateClienthandler(
                      data,
                      setModal,
                      dispatch,
                      clientListAction
                    )
                );
              },
              (data) => clientDeleteHandler(data),

              translation._language
            )}
            jaCol={addActionColumn(
              { type: `dropdown`, name: `client-dropdown` },
              clientColumnsHome,
              (data) => {
                formModalController(
                  setModal,
                  clientFormLayout,
                  clientFormLayoutJap,
                  `${translation.edit_client}`,
                  `edit-client-form`,
                  data,
                  (data) =>
                    updateClienthandler(
                      data,
                      setModal,
                      dispatch,
                      clientListAction
                    )
                );
              },
              (data) => clientDeleteHandler(data),
              translation._language
            )}
            data={companyClients}
            title={translation.client}
          />
        </div>
      </div>
    </>
  );
};

export default CompanyClient;
