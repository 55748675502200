import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteMeeting, updateMeetingHandler } from "../../apiParsing/meeting";
import { meetingFormLayout } from "../../Data/FormsLayout/meetingFormLayout";
import {
  listingCommonDefaulter,
  multiTabFormModalController,
} from "../../helpers/commonHelpers";
import { addActionColumn } from "../../helpers/companyHelpers";
import { emailListAction } from "../actions/emailAction";
import { meetingListAction } from "../actions/meetingAction";
import { notesListAction } from "../actions/notesAction";
import { projectListAction } from "../actions/projectAction";
import TableCard from "../TableCard";
import { Dropdown } from "antd";
import { BarsOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import { menu } from "./../../Data/Columns/meetingColumns";
import { sendRequest } from "../../services";
import { apipaths } from "../../services/apiPaths";
import { modalInitialValue } from "./../../helpers/commonHelpers";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ModalContainer from "../Modal";
import Heading from "../Heading";
import CardContainer from "../Card/cardContainer";
import useTranslation from "../customHooks/translations";
import moment from "moment";

const MeetingParam = () => {
  const [meetingList, setMeetingList] = useState([]);
  const [modal, setModal] = useState(modalInitialValue);
  const { clients } = useSelector((state) => state.client);
  const { companies } = useSelector((state) => state.company);
  const { employees } = useSelector((state) => state.employee);
  const { projects } = useSelector((state) => state.project);
  const { meet } = useSelector((state) => state.meet);
  const { id: companyId } = useParams();

  const selectedCompanyName = "";

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const translation = useTranslation();
  const location = useLocation();

  useEffect(() => {
    if (projects && clients && companies && employees) {
      const arr = [projects, clients, companies, employees];
      const kerArr = ["project_id", "client_id", "company_id", "employee_id"];
      listingCommonDefaulter(arr, meetingFormLayout.meeting, kerArr);
    }
  }, [projects, clients, companies, employees]);

  useEffect(() => {
    if (location.state.id == "company_id") {
      setMeetingList(meet.filter((m) => m.company_id == parseInt(companyId)));
    }
    if (location.state.id == "client_id") {
      setMeetingList(meet.filter((m) => m.client_id == parseInt(companyId)));
    }
    if (location.state.id == "project_id") {
      setMeetingList(meet.filter((m) => m.project_id == parseInt(companyId)));
    }
  }, [meet]);

  const meetingColumns = [
    {
      title: "ID",
      dataIndex: "meeting_id",
      sorter: (a, b) => a.meeting_id - b.meeting_id,
    },
    {
      title: "Subject",
      dataIndex: "subject",
      sorter: (a, b) => a?.subject?.localeCompare(b?.subject),
      render: (subject, data) => {
        return (
          <>
            <label
              style={{ color: "#1890ff" }}
              onClick={() => {
                navigate(`/edit-meetings/${data.meeting_id}`);
              }}
            >
              {subject}
            </label>
          </>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "startdate",
      sorter: (a, b) => moment(a.startdate).unix() - moment(b.startdate).unix(),
    },
    {
      title: "Start Time",
      dataIndex: "starttime",
      sorter: (a, b) => a.starttime.localeCompare(b.starttime),
    },
    {
      title: "End Time",
      dataIndex: "endtime",
      sorter: (a, b) => a.endtime.localeCompare(b.endtime),
    },
    {
      title: "Venue",
      dataIndex: "venue",
      sorter: (a, b) => a.venue.localeCompare(b.venue),
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      sorter: (a, b) => a.remarks.localeCompare(b.remarks),
    },

    {
      title: "Action",
      dataIndex: "action",
      render: (index) => (
        <div key={index}>
          <Dropdown overlay={menu} placement="bottom" className="action-button">
            <BarsOutlined />
          </Dropdown>
        </div>
      ),
    },
  ];

  const meetingColumnsHome = [
    {
      title: "ID",
      dataIndex: "meeting_id",
      sorter: (a, b) => a.meeting_id - b.meeting_id,
    },
    {
      title: "主題",
      dataIndex: "subject",
      sorter: (a, b) => a?.subject?.localeCompare(b?.subject),
      render: (subject, data) => {
        return (
          <>
            <label
              style={{ color: "#1890ff" }}
              onClick={() => {
                navigate(`/edit-meetings/${data.meeting_id}`);
              }}
            >
              {subject}
            </label>
          </>
        );
      },
    },
    {
      title: "日付",
      dataIndex: "startdate",
      sorter: (a, b) => moment(a.startdate).unix() - moment(b.startdate).unix(),
    },
    {
      title: "開始時間",
      dataIndex: "starttime",
      sorter: (a, b) => a.starttime.localeCompare(b.starttime),
    },
    {
      title: "終了時間",
      dataIndex: "endtime",
      sorter: (a, b) => a.endtime.localeCompare(b.endtime),
    },
    {
      title: "会場",
      dataIndex: "venue",
      sorter: (a, b) => a.venue.localeCompare(b.venue),
    },
    // {
    //   title: "クライアント",
    //   dataIndex: "employee_id",
    //   render: (client_id, row) => {
    //     const client = row["client"];
    //     if (client) return client.first_name + " " + client.last_name;
    //     else return "";
    //   },
    // },
    // {
    //   title: "会社",
    //   dataIndex: "company_id",
    //   render: (company_id, row) => {
    //     let company_name = "";
    //     const company = row.company;
    //     if (company_id && company) company_name = company.name;
    //     return company_name;
    //   },
    // },
    // {
    //   title: "プロジェクト",
    //   dataIndex: "project_id",
    //   render: (project_id, row) => {
    //     const project = row["project"];
    //     if (project) return project.name;
    //     else return "";
    //   },
    // },
    {
      title: "備考",
      dataIndex: "remarks",
      sorter: (a, b) => a.remarks.localeCompare(b.remarks),
    },
  ];

  const dataTest = (data) => {
    multiTabFormModalController(
      setModal,
      selectedCompanyName,
      {
        task: meetingFormLayout.meeting,
        subTask: meetingFormLayout.task,
        subTask2: meetingFormLayout.task,
      },
      {
        title1: `Edit Meetings`,
        title2: "Pre Task",
        title3: "Post Task",
      },
      `edit-meetings-form`,
      data,
      (data) =>
        updateMeetingHandler(data, setModal, dispatch, meetingListAction)
    );
  };

  const deleteMeetingHandler = (meeting_id) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#1890ff",
    }).then(async ({ isConfirmed }) => {
      if (!isConfirmed) return null;

      const { data } = await sendRequest(apipaths.deleteMeeting, {
        delete_id: meeting_id,
      });
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Meeting is deleted successfully!",
        showConfirmButton: false,
        timer: 1500,
      });
      dispatch(meetingListAction());
    });
  };

  useEffect(() => {
    dispatch(meetingListAction());
  }, [dispatch]);

  return (
    <>
      <ModalContainer
        isOpen={modal.open}
        title={modal.title}
        closeIcon={() => setModal(modalInitialValue)}
        content={modal.content}
        footer={false}
      />
      <div className="main-container">
        <Heading title={translation.meetings} />
        <div className="content-wrapper px-5">
          <CardContainer hideTotal={true} />
        </div>

        <div className="px-5">
          <TableCard
            button={{
              title: translation.add_meeting,
              onClick: () => navigate("/meetings/form/add"),
            }}
            columns={addActionColumn(
              { type: `dropdown`, name: `meetingHome-dropdown` },

              meetingColumns,
              (data) =>
                multiTabFormModalController(
                  selectedCompanyName,
                  setModal,
                  {
                    task: meetingFormLayout.meeting,
                    subTask: meetingFormLayout.task,
                    subTask2: meetingFormLayout.task,
                  },
                  {
                    title1: `Edit Meetings`,
                    title2: "Pre Task",
                    title3: "Post Task",
                  },
                  `edit-meetings-form`,
                  data,
                  (data) => navigate(`edit-meeting${data.meeting_id}`)
                ),
              (meeting_id) => deleteMeetingHandler(meeting_id),

              translation._language
            )}
            jaCol={addActionColumn(
              { type: `dropdown`, name: `meetingHome-dropdown` },
              meetingColumnsHome,
              (data) =>
                multiTabFormModalController(
                  setModal,
                  selectedCompanyName,
                  {
                    task: meetingFormLayout.meeting,
                    subTask: meetingFormLayout.task,
                    subTask2: meetingFormLayout.task,
                  },
                  {
                    title1: `${translation.edit_meetings}`,
                    title2: `${translation.pre_meeting_task}`,
                    title3: `${translation.post_meeting_task}`,
                  },
                  `edit-meetings-form`,
                  data,
                  (data) => navigate(`edit-meeting${data.meeting_id}`)
                ),
              (meeting_id) => deleteMeetingHandler(meeting_id),
              translation._language
            )}
            data={meetingList}
            title={translation.meetings}
          />
        </div>
      </div>
    </>
  );
};

export default MeetingParam;
