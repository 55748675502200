import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  addHandler,
  addProjectDataTableHandler,
  addProjectHandler,
  deleteHandler,
  updateHandler,
} from "../../apiParsing/data";
import {
  emailFormDataLayout,
  emailFormDataLayoutJap,
  emailFormLayout,
} from "../../Data/FormsLayout/emailFormLayout";
import { emailFormLayoutJap } from "../../Data/FormsLayout/emailFormLayout";
import {
  filterClientsHandler,
  formModalController,
  listingCommonDefaulter,
} from "../../helpers/commonHelpers";
import { addActionColumn } from "../../helpers/companyHelpers";
import { apipaths } from "../../services/apiPaths";
import { emailListAction } from "../actions/emailAction";
import useTranslation from "../customHooks/translations";
import TableCard from "../TableCard";

export default function EmailProjectTable({ setModal, projectId }) {
  const { emails } = useSelector((state) => state.emails);
  const { employees } = useSelector((state) => state.employee);
  const { projects } = useSelector((state) => state.project);
  const { clients } = useSelector((state) => state.client);
  const { companies } = useSelector((state) => state.company);

  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const location = useLocation();
  const translation = useTranslation();

  const mainLocation = location.pathname.slice(1, 13);

  useEffect(() => {
    setData(emails.filter((m) => m.project_id == parseInt(projectId)));
  }, [emails]);

  const nameProject = projects.filter((d) => {
    return d.project_id === parseInt(projectId);
  });
  const companyId = nameProject[0]?.company_id;
  const clientId = nameProject[0]?.client_id;

  useEffect(() => {
    if (employees) {
      const arr = [employees];
      const kerArr = ["employee_id"];
      listingCommonDefaulter(arr, emailFormDataLayout, kerArr);
    }
  }, [employees]);

  useEffect(() => {
    if (employees) {
      const arr = [employees];
      const kerArr = ["employee_id"];
      listingCommonDefaulter(arr, emailFormDataLayoutJap, kerArr);
    }
  }, [employees]);

  useEffect(() => {
    dispatch(emailListAction());
  }, []);

  const emailColumns = [
    {
      title: "ID",
      dataIndex: "email_id",
      sorter: (a, b) => a.email_id - b.email_id,
      key: "email_id",
    },
    {
      title: "Date",
      dataIndex: "send_date",
      sorter: (a, b) => a.send_date.localeCompare(b.send_date),
    },
    {
      title: "Subject",
      dataIndex: "subject",
      sorter: (a, b) => a.subject.localeCompare(b.subject),
      render: (d, data) => {
        return (
          <>
            <label
              style={{ color: "#1890ff" }}
              onClick={() => {
                dataTest(data);
              }}
            >
              {data.subject}
            </label>
          </>
        );
      },
    },
    {
      title: "Sender",
      dataIndex: "sender",
      sorter: (a, b) => a.sender.localeCompare(b.sender),
      key: "sender",
    },
    {
      title: "Company",
      dataIndex: "company_id",
      sorter: (a, b) => a.company_id - b.company_id,
      render: (company_id, row) => {
        let company_name = "";
        const company = row.company;
        if (company_id && company) company_name = company.name;
        return company_name;
      },
    },
    {
      title: "Client",
      dataIndex: "client",
      sorter: (a, b) =>
        a?.client?.first_name.localeCompare(b?.client?.first_name),
      render: (d, data) => {
        return (
          <>
            {data?.client?.first_name && data?.client?.last_name
              ? data?.client?.first_name + " " + data?.client?.last_name
              : "-"}
          </>
        );
      },
    },
  ];

  const emailColumnsHome = [
    {
      title: "ID",
      dataIndex: "email_id",
      sorter: (a, b) => a.email_id - b.email_id,
      key: "email_id",
    },
    {
      title: "主題",
      dataIndex: "subject",
      sorter: (a, b) => a.subject.localeCompare(b.subject),
      render: (data, allData) => {
        return (
          <>
            <label
              style={{ color: "#1890ff" }}
              onClick={() => {
                dataTest(allData);
              }}
            >
              {data}
            </label>
          </>
        );
      },
    },
    {
      title: "会社",
      dataIndex: "company_id",
      sorter: (a, b) => a.company_id - b.company_id,
      render: (company_id, row) => {
        let company_name = "";
        const company = row.company;
        if (company_id && company) company_name = company.name;
        return company_name;
      },
    },
    {
      title: "クライアント",
      dataIndex: "client",
      sorter: (a, b) =>
        a?.client?.first_name.localeCompare(b?.client?.first_name),
      render: (d, data) => {
        return (
          <>
            {data?.client?.first_name && data?.client?.last_name
              ? data?.client?.first_name + " " + data?.client?.last_name
              : "-"}
          </>
        );
      },
    },
    {
      title: "送信者",
      dataIndex: "sender",
      sorter: (a, b) => a.sender.localeCompare(b.sender),
    },
    {
      title: "日付",
      dataIndex: "send_date",
      sorter: (a, b) => a.send_date.localeCompare(b.send_date),
    },
  ];

  const dataTest = (data) => {
    formModalController(
      setModal,
      emailFormDataLayout,
      emailFormDataLayoutJap,
      `${translation.edit_email}`,
      `edit-email-form`,
      data,
      (data) =>
        updateHandler(
          apipaths.addEmail,
          data,
          "email_id",
          setModal,
          dispatch,
          emailListAction
        )
    );
  };

  return (
    <TableCard
      button={{
        title: translation.add_email,
        onClick: () =>
          formModalController(
            setModal,
            emailFormDataLayout,
            emailFormDataLayoutJap,
            `${translation.add_email}`,
            `add-email-form`,
            {},
            (data) =>
              addProjectDataTableHandler(
                apipaths.addEmail,
                data,
                setModal,
                dispatch,
                emailListAction,
                projectId,
                clientId,
                companyId
              )
          ),
      }}
      columns={addActionColumn(
        {},
        emailColumns,
        (data) =>
          formModalController(
            setModal,
            emailFormDataLayout,
            emailFormDataLayoutJap,
            `${translation.edit_email}`,
            `edit-email-form`,
            data,
            (data) =>
              updateHandler(
                apipaths.addEmail,
                data,
                "email_id",
                setModal,
                dispatch,
                emailListAction
              )
          ),
        (data) =>
          deleteHandler(
            apipaths.deleteEmail,
            data?.["email_id"],
            setModal,
            dispatch,
            emailListAction
          ),
        translation._language
      )}
      jaCol={addActionColumn(
        {},
        emailColumnsHome,
        (data) =>
          formModalController(
            setModal,
            emailFormDataLayout,
            emailFormDataLayoutJap,
            `Edit Email`,
            `edit-email-form`,
            data,
            (data) =>
              updateHandler(
                apipaths.addEmail,
                data,
                "email_id",
                setModal,
                dispatch,
                emailListAction
              )
          ),
        (data) =>
          deleteHandler(
            apipaths.deleteEmail,
            data?.["email_id"],
            setModal,
            dispatch,
            emailListAction
          ),
        translation._language
      )}
      title={translation.email}
      data={data}
    />
  );
}
