import React, { useState } from "react";
import { IoHomeOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  ContactsForm,
  ContactsFormJap,
} from "../../Data/FormsLayout/ContactForm";
import { resetMainForm } from "../../helpers/commonHelpers";
import { sendRequest } from "../../services";
import { apipaths } from "../../services/apiPaths";
import CardContainer from "../Card/cardContainer";
import useTranslation from "../customHooks/translations";
import FormContainer from "../Form";
import Heading from "../Heading";

function ImportContacts() {
  const [formlayout] = useState(ContactsForm);
  const translation = useTranslation();
  const navigate = useNavigate();

  const onCancel = () => {};

  const onSubmit = async (file) => {
    console.log("file", file);
    const formdata = new FormData();
    formdata.append("contact_import", file?.contacts);    
    console.log("🚀 ~ file: index.js:27 ~ onSubmit ~ file?.contacts", file?.contacts)
    const { message, success } = await sendRequest(
      apipaths.contactImport,
      formdata
    );
    if (success) {
      Swal.fire({
        position: "center",
        icon: "success",
        title: message,
        showConfirmButton: false,
        timer: 1500,
      });
      resetMainForm();
    }
  };

  return (
    <>
      <div className="main-container">
        <Heading title={translation.import_contacts} />
        <div className="content-wrapper px-5">
          <CardContainer hideTotal={true} />
          <div className="mt-5 bg-white shadow-1">
            <div className="card">
              <div className="card-header mt-3 d-flex flex-row">
                <div className="">
                  <h2 className="fs-20" style={{ marginLeft: "20px" }}>
                    {translation.import_contacts}
                  </h2>
                </div>
                <div className="">
                  <h3
                    className="fs-20 p-0 cursor-pointer"
                    style={{ marginTop: "-2px", marginLeft: "10px" }}
                    onClick={() => navigate("/dashboard")}
                  >
                    <IoHomeOutline
                      style={{ color: "rgb(90 86 86)", fontSize: "23px" }}
                    />
                  </h3>
                </div>
              </div>
              <hr />
              <div className="pt-1 pb-4 px-3 mt-3">
                <FormContainer
                  FormLayoutJap={ContactsFormJap}
                  layout={formlayout}
                  submitBtnTitle={"Submit"}
                  showCancelBtn={false}
                  onCancel={onCancel}
                  onSubmit={onSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ImportContacts;
