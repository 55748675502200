export const meetingFormLayout = {
  meeting: [
    {
      id: 1,
      label: "Meetings",
      type: "text",
      placeholder: "Meetings",
      key: "subject",
      required: true,
      containerClass: "col-12 col-md-6 col-lg-9",
    },
    {
      id: 2,
      label: "Date",
      type: "date",
      key: "startdate",
      required: false,
      containerClass: "col-12 col-md-6 col-lg-3",
    },
    {
      id: 3,
      label: "Start Time",
      type: "text",
      placeholder: "Time",
      key: "starttime",
      required: false,
    },
    {
      id: 4,
      label: "End Time",
      type: "text",
      placeholder: "Time",
      key: "endtime",
      required: false,
    },
    {
      id: 5,
      label: "Venue",
      type: "text",
      placeholder: "Venue",
      key: "venue",
      required: false,
    },
    {
      id: 6,
      label: "Company",
      type: "select",
      placeholder: "Select Company",
      key: "company_id",
      options: [
        {
          label: "Company A",
          value: "coma",
        },
        {
          label: "Company B",
          value: "comb",
        },
      ],
      required: false,
    },
    {
      id: 7,
      label: "Client",
      type: "select",
      placeholder: "Select Client",
      key: "client_id",
      options: [
        {
          label: "Client A",
          value: "clienta",
        },
        {
          label: "Client B",
          value: "clientb",
        },
      ],
      required: false,
    },
    {
      id: 8,
      label: "Project",
      type: "select",
      placeholder: "Select Project",
      key: "project_id",
      options: [
        {
          label: "Project A",
          value: "proa",
        },
        {
          label: "Project B",
          value: "prob",
        },
      ],
      required: false,
    },
    {
      id: 9,
      label: "Incharge Employee",
      type: "select",
      placeholder: "Select Incharge Employee",
      key: "employee_id",
      options: [
        {
          label: "Incharge Employee A",
          value: "InchargeEmpA",
        },
        {
          label: "Incharge Employee B",
          value: "InchargeEmpB",
        },
      ],
      required: false,
    },
    {
      id: 10,
      label: "Attendees",
      type: "text",
      placeholder: "Attendees",
      key: "attendees",
      required: false,
      containerClass: "col-12 col-md-6 col-lg-9",
    },
    {
      id: 11,
      label: "Description",
      type: "textarea",
      placeholder: "Description",
      key: "desc",
      required: false,
      containerClass: "col-12",
    },
    {
      id: 12,
      label: "Remarks",
      type: "textarea",
      placeholder: "Remarks",
      key: "remarks",
      required: false,
      containerClass: "col-12 col-md-6 col-lg-6",
    },
    {
      id: 13,
      label: "Keypoints",
      type: "textarea",
      placeholder: "Keypoints",
      key: "keypoints",
      required: false,
      containerClass: "col-12 col-md-6 col-lg-6",
    },
  ],
  task: [
    {
      id: 1,
      label: "Subject",
      type: "text",
      placeholder: "Subject",
      key: "Pre_subject",
      required: true,
      containerClass: "col-12 col-md-6 col-lg-8",
    },
    {
      id: 2,
      label: "Start To End Date",
      type: "date",
      key: "Pre_startdate",
      required: true,
      containerClass: "col-12 col-md-6 col-lg-4",
    },
    {
      id: 3,
      label: "Priority",
      type: "select",
      placeholder: "Select Priority",
      key: "Pre_priority  ",
      options: [
        {
          label: "Urgent",
          value: "Urgent",
        },
        {
          label: "High",
          value: "High",
        },
        {
          label: "Medium",
          value: "Medium",
        },
        {
          label: "Low",
          value: "Low",
        },
      ],
    },
    {
      id: 4,
      label: "Status",
      type: "select",
      placeholder: "Select Status",
      key: "Pre_status",
      options: [
        {
          label: "Pending",
          value: "Pending",
        },
        {
          label: "Started",
          value: "Started",
        },
        {
          label: "Inprogress",
          value: "Inprogress",
        },
        {
          label: "Completed",
          value: "Completed",
        },
      ],
    },

    {
      id: 5,
      label: "Task Assigned To",
      type: "select",
      placeholder: "Select Task Assigned To",
      key: "Pre_task_assign_to",
      required: false,
      containerClass: "col-12 col-md-6 col-lg-3",
      options: [
        {
          label: "Username 1",
          value: "user1",
        },
        {
          label: "Username 2",
          value: "user2",
        },
      ],
    },
    {
      id: 6,
      label: "Employee",
      type: "select",
      placeholder: "Select Employee",
      key: "Pre_employee_id",
      required: false,
      containerClass: "col-12 col-md-6 col-lg-3",
      options: [
        {
          label: "Employee 1",
          value: "emp1",
        },
        {
          label: "Employee 2",
          value: "emp2",
        },
      ],
    },
    {
      id: 7,
      label: "Description",
      type: "textarea",
      containerClass: "col-12",
      key: "Pre_desc",
      placeholder: "Describe Here",
      rows: 3,
    },
    {
      id: 8,
      label: "Remarks",
      type: "textarea",
      containerClass: "col-12",
      key: "Pre_remarks",
      placeholder: "Remarks Here",
      rows: 3,
    },
  ],
  postTask: [
    {
      id: 1,
      label: "Subject",
      type: "text",
      placeholder: "Subject",
      key: "Post_subject",
      required: true,
      containerClass: "col-12 col-md-6 col-lg-8",
    },
    {
      id: 2,
      label: "Start To End Date",
      type: "date",
      key: "Post_startdate",
      required: true,
      containerClass: "col-12 col-md-6 col-lg-4",
    },
    {
      id: 3,
      label: "Priority",
      type: "select",
      placeholder: "Select Priority",
      key: "Post_priority",
      options: [
        {
          label: "Urgent",
          value: "Urgent",
        },
        {
          label: "High",
          value: "High",
        },
        {
          label: "Medium",
          value: "Medium",
        },
        {
          label: "Low",
          value: "Low",
        },
      ],
    },
    {
      id: 4,
      label: "Status",
      type: "select",
      placeholder: "Select Status",
      key: "Post_status",
      options: [
        {
          label: "Pending",
          value: "Pending",
        },
        {
          label: "Started",
          value: "Started",
        },
        {
          label: "Inprogress",
          value: "Inprogress",
        },
        {
          label: "Completed",
          value: "Completed",
        },
      ],
    },

    {
      id: 5,
      label: "Task Assigned To",
      type: "select",
      placeholder: "Select Task Assigned To",
      key: "Post_task_assign_to",
      required: false,
      containerClass: "col-12 col-md-6 col-lg-3",
      options: [
        {
          label: "Username 1",
          value: "user1",
        },
        {
          label: "Username 2",
          value: "user2",
        },
      ],
    },
    {
      id: 6,
      label: "Employee",
      type: "select",
      placeholder: "Select Employee",
      key: "Post_employee_id",
      required: false,
      containerClass: "col-12 col-md-6 col-lg-3",
      options: [
        {
          label: "Employee 1",
          value: "emp1",
        },
        {
          label: "Employee 2",
          value: "emp2",
        },
      ],
    },
    {
      id: 7,
      label: "Description",
      type: "textarea",
      containerClass: "col-12",
      key: "Post_desc",
      placeholder: "Describe Here",
      rows: 3,
    },
    {
      id: 8,
      label: "Remarks",
      type: "textarea",
      containerClass: "col-12",
      key: "Post_remarks",
      placeholder: "Remarks Here",
      rows: 3,
    },
  ],
};
