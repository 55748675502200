export const taskFormLayout = [
  {
    id: 1,
    label: "Subject",
    key: "subject",
    type: "text",
    required: true,
    containerClass: "col-sm-12 col-md-6 col-lg-8",
  },
  {
    id: 2,
    label: "Start To End Date",
    key: "startdate",
    type: "date",
    containerClass: "col-sm-12 col-md-6 col-lg-4",
    // required: true,
  },
  {
    id: 3,
    label: "Priority",
    type: "select",
    placeholder: "Select Priority",
    key: "priority",
    options: [
      {
        label: "Urgent",
        value: "Urgent",
      },
      {
        label: "High",
        value: "High",
      },
      {
        label: "Medium",
        value: "Medium",
      },
      {
        label: "Low",
        value: "Low",
      },
    ],
  },
  {
    id: 4,
    label: "Company",
    type: "select",
    placeholder: "Select Company",
    key: "company_id",
    options: [
      {
        label: "Company A",
        value: "coma",
      },
      {
        label: "Company B",
        value: "comb",
      },
    ],
  },
  {
    id: 5,
    label: "Client",
    type: "select",
    placeholder: "Select Client",
    key: "client_id",
    options: [
      {
        label: "Client A",
        value: "client-a",
      },
      {
        label: "Client B",
        value: "client-b",
      },
    ],
  },
  {
    id: 6,
    label: "Project",
    type: "select",
    placeholder: "Select Project",
    key: "project_id",
    options: [
      {
        label: "Company A",
        value: "company-a",
      },
      {
        label: "Company B",
        value: "company-b",
      },
    ],
  },

  {
    id: 7,
    label: "Counter Party",
    type: "select",
    placeholder: "Select Counter Party",
    key: "counterParty",
    options: [
      {
        label: "Priority 1",
        value: "pri1",
      },
      {
        label: "Priority 2",
        value: "pri2",
      },
    ],
  },
  {
    id: 8,
    label: "CP Client",
    type: "select",
    placeholder: "Select CP Client",
    key: "product",
  },
  {
    id: 9,
    label: "CP Project",
    type: "select",
    placeholder: "Select CP Project",
    key: "cp_project_id",
  },
  {
    id: 10,
    label: "Status",
    type: "select",
    placeholder: "Select Status",
    key: "status",
    options: [
      {
        label: "Pending",
        value: "Pending",
      },
      {
        label: "Started",
        value: "Started",
      },
      {
        label: "Inprogress",
        value: "Inprogress",
      },
      {
        label: "Completed",
        value: "Completed",
      },
    ],
  },
  {
    id: 11,
    label: "Task Assigned To",
    type: "select",
    placeholder: "Select Task Assigned To",
    key: "employee_id",
    options: [
      {
        label: "Priority 1",
        value: "pri1",
      },
      {
        label: "Priority 2",
        value: "pri2",
      },
    ],
  },
  {
    id: 12,
    label: "Employee",
    type: "select",
    placeholder: "Select Employee",
    key: "employee_id",
    options: [
      {
        label: "Priority 1",
        value: "pri1",
      },
      {
        label: "Priority 2",
        value: "pri2",
      },
    ],
  },
  {
    id: 13,
    label: "Description",
    type: "textarea",
    placeholder: "Enter Description",
    key: "desc",
    containerClass: "col-lg-12 col-md-12 col-sm-12",
    rows: 3,
  },
  {
    id: 14,
    label: "Remarks",
    type: "textarea",
    placeholder: "Enter Remarks",
    key: "remarks",
    containerClass: "col-lg-12 col-md-12 col-sm-12",
    rows: 3,
  },
];
