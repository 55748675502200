const initialState = { clients: [] }

export const clientReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case "ADD_CLIENTS":
            return { ...state, clients: payload }
        default:
            return state;

    }
}