import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";

function Heading({ title }) {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  return (
    <div className="heading-container px-5 py-4 ">
      <h3
        className="fs-20 fw-600 text-white  text-capitalize"
        onClick={() => (pathname == "/dashboard" ? " " : navigate(-1))}
      >
        {pathname === "/dashboard" ? (
          ""
        ) : (
          <FiArrowLeft style={{ marginRight: "4px", marginBottom: "3px" }} />
        )}
        {title}
      </h3>
    </div>
  );
}

export default Heading;
