import React, { useEffect, useState } from "react";
import Heading from "../Heading";
import CardContainer from "../Card/cardContainer";
import { useNavigate, useParams } from "react-router-dom";
import FormContainer from "../Form";
import {
  companyFormLayout,
  companyFormLayoutJap,
} from "../../Data/FormsLayout/companyFormLayout";
import { useDispatch } from "react-redux";
import { companyListAction } from "../actions/comanyAction";
import { sendRequest } from "../../services";
import { apipaths } from "../../services/apiPaths";
import {
  addPrefexToObjectProperty,
  regName,
} from "../../helpers/commonHelpers";
import Swal from "sweetalert2";
import useTranslation from "../customHooks/translations";

function CompanyForm() {
  const [formLayout] = useState(companyFormLayout);

  const translation = useTranslation();

  const { id } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onCancel = () => {
    navigate(-1);
  };
  const onSubmit = async (formdata) => {
    let new_company_details = { ...formdata };
    new_company_details = addPrefexToObjectProperty(
      "Company_",
      new_company_details
    );
    new_company_details = {
      ...new_company_details,
      OPtype: "Company",
      operation: "add",
      company_type: "keep",
    };
    if (!regName.test(formdata.name) || formdata.name.trim() === "") {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Please Fill Out Company Name !",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      const {
        success,
        data: { message },
      } = await sendRequest(apipaths.addCompany, new_company_details);
      if (success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: message,
          showConfirmButton: false,
          timer: 1500,
        });
        navigate(-1);
      }
    }
  };

  useEffect(() => {
    dispatch(companyListAction());
  }, [dispatch]);

  return (
    <>
      <div className="main-container">
        <Heading title={`${id}  ${translation.company}`} />
        <div className="content-wrapper px-5">
          <CardContainer hideTotal={true} />
          <div className="content-container">
            <div className="p-3">
              <button className="btn btn-primary btn-sm px-5 py-2">
                {translation.company}
              </button>
            </div>
            <hr />
            <div className="pt-1 pb-4 px-3">
              <FormContainer
                layout={formLayout}
                FormLayoutJap={companyFormLayoutJap}
                onCancel={onCancel}
                onSubmit={onSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanyForm;
