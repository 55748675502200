import { Menu, Dropdown } from "antd";
import { BarChartOutlined, BarsOutlined } from "@ant-design/icons";
import { FaClipboardList, FaTasks, FaRegEdit, FaPen } from "react-icons/fa";
import { Link } from "react-router-dom";

export const menu = (
  <Menu>
    <Menu.Item>
      <Link to="/project">
        <div className="d-flex align-items-center">
          <BarChartOutlined className="text-danger" />
          <span className="ms-2">View Project</span>
        </div>
      </Link>
    </Menu.Item>

    <Menu.Item>
      <Link to="/meetings">
        <div className="d-flex align-items-center">
          <FaClipboardList className="text-secondary" />
          <span className="ms-2">View Meetings</span>
        </div>
      </Link>
    </Menu.Item>

    <Menu.Item>
      <Link to="/tasks">
        <div className="d-flex align-items-center">
          <FaTasks className="text-success" />
          <span className="ms-2">View Tasks</span>
        </div>
      </Link>
    </Menu.Item>

    <Menu.Item>
      <Link to="/notes">
        <div className="d-flex align-items-center">
          <FaRegEdit className="text-success" />
          <span className="ms-2">View Notes</span>
        </div>
      </Link>
    </Menu.Item>

    <Menu.Item>
      <div className="d-flex align-items-center">
        <FaPen className="text-warning" />
        <span className="ms-2">Edit</span>
      </div>
    </Menu.Item>

    <Menu.Item>
      <Link to="/client">
        <div className="d-flex align-items-center">
          <FaClipboardList className="text-danger" />
          <span className="ms-2">Delete</span>
        </div>
      </Link>
    </Menu.Item>
  </Menu>
);

export const clientColumns = [
  {
    title: "ID",
    dataIndex: "client_id",
    sorter: {
      compare: (a, b) => a.math - b.math,
      multiple: 1,
    },
  },
  {
    title: "Name",
    dataIndex: "first_name",
  },

  {
    title: "Telephone",
    dataIndex: "tel1",
    render: (data, { tel1, tel2 }) => {
      return <>{tel1 ? tel1 : tel2}</>;
    },
  },
  {
    title: "Company",
    dataIndex: "company",
    render: (company) => {
      return <>{company && company.name ? company.name : ""}</>;
    },
  },
  {
    title: "Email",
    dataIndex: "email1",
  },
  {
    title: "Country",
    dataIndex: "country",
  },
  {
    title: "Incharge Employee",
    dataIndex: "employee",
    render: (employee) => {
      return (
        <>
          {employee &&
            employee.first_name + " " + employee &&
            employee.last_name}
        </>
      );
    },
  },
  {
    title: "Position",
    dataIndex: "position",
  },
  {
    title: "Department",
    dataIndex: "dept",
  },
  {
    title: "Action",
    dataIndex: "action",
    render: (data, index) => (
      <div key={index}>
        <Dropdown overlay={menu} placement="bottom" className="action-button">
          <BarsOutlined />
        </Dropdown>
      </div>
    ),
  },
];
