// import React from "react";
// import { DatePicker } from "antd";
// import moment from "moment";
// import { getTodaysDate } from "../../services/commonServices";

// function DateFeild({ formdata, setFormdata, data, required = false }) {
//   const {
//     label,
//     key,
//     placeholder,
//     defaultValue = formdata[key] ? formdata[key] : getTodaysDate(),
//   } = data;
//   return (
//     <div>
//       <label className="d-block">
//         {label}
//         {required && <span className="text-danger">*</span>}
//       </label>
//       <DatePicker
//         placeholder={placeholder || label}
//         defaultValue={moment(defaultValue, "YYYY/MM/DD")}
//         className="w-100"
//         onChange={(date, dateString) =>
//           setFormdata({ ...formdata, [key]: dateString })
//         }
//       />
//     </div>
//   );
// }

// export default DateFeild;
import React from "react";
import { DatePicker, Space } from "antd";
import moment from "moment";
import { getTodaysDate } from "../../services/commonServices";
import { formatDateMeet, formatMeetingDate } from "../../helpers/commonHelpers";

function DateField({ formdata, setFormdata, data, required = false }) {
  const {
    label,
    key,
    placeholder,
    defaultValue = formdata[key] ? formdata[key] : getTodaysDate(),
  } = data;
  return (
    <div>
      <label className="d-block">
        {label}
        {required && <span className="text-danger">*</span>}{" "}
      </label>
      {formdata.startdate ? (
        <DatePicker
          className="w-100"
          style={{ borderRadius: "0.375rem" }}
          defaultValue={
            key == "startdate"
              ? moment(formdata.startdate)
              : key == "enddate"
              ? moment(formdata.enddate)
              : key == "date"
              ? moment(formdata.date)
              : key == "sendDate"
              ? moment(formdata.sendDate)
              : key == "Project_startdate"
          }
          placeholder={placeholder || label}
          onChange={(date, dateString) =>
            setFormdata({ ...formdata, [key]: dateString })
          }
        />
      ) : (
        <DatePicker
          style={{ borderRadius: "0.375rem" }}
          className="w-100"
          placeholder={placeholder || label}
          defaultValue={moment(defaultValue, "YYYY/MM/DD")}
          onChange={(date, dateString) =>
            setFormdata({ ...formdata, [key]: dateString })
          }
        />
      )}
    </div>
  );
}

export default DateField;
