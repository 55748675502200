import { sendRequest } from "../../services";
import { apipaths } from "../../services/apiPaths";

export const projectProfileAction = () => async (dispatch) => {
  const {
    success,
    data: { ProjectProfile },
  } = await sendRequest(apipaths.projectProfileList);
  if (success) {
    dispatch({ type: "ADD_PROJECT_PROFILE", payload: ProjectProfile });
  }
};
