export default {
  dashboard: "ダッシュボード",
  add_company: "会社を追加",
  active_task: "アクティブタスク",
  todays_task: "今日の仕事",
  todays_meeting: "今日の会議",
  active_companies: "アクティブな会社",
  active_projects: "アクティブなプロジェクト",
  coming_meetings: "今後の会議",
  prospective_projects: "前向きなプロジェクト",
  show: "表示",
  entries: "入力",
  search: "検索",
  task: "タスク",
  tomorrows_tasks: "明日のタスク",
  total_tasks: "全てのタスク",
  tomorrow_meeting: "明日の会議",
  total_companies: ":",
  total_projects: "全てのプロジェクト",
  subject: "主題",
  action: "アクション",
  showing: ":",
  previous: "前",
  todays_date: "今日の日付",
  next: "次",
  task: "タスク",
  notes: "備考",
  meetings: "ミーティング",
  emails: "メール",
  sector_board: "セクター掲示板",
  company: "会社",
  total_company: "全社",
  client: "クライアント",
  project: "プロジェクト",
  users: "ユーザー",
  employees: "社員",
  teams: "チーム",
  importcontacts: "連絡先のインポート",
  id: ":",
  name: "名前",
  name_in_japanese: "日本語での名前",
  japanese: "日本",
  english: "英語",
  industry: "業種",
  fax: "ファックス",
  source: "ソース",
  action: "アクション",
  sub_industry: "副業種",
  telephone: "電話",
  telephone1: "電話 1",
  telephone2: "電話 2",
  email: "Eメール",
  email1: "メール 1",
  email2: ": 2",
  email3: ":",
  address: "住所",
  city: "都市",
  country: "国",
  postal_code: "郵便番号",
  website: "Webサイト",
  incharge_employee: "担当社員",
  source: "ソース",
  activity_status: "活動状態",
  select_activity_status: "活動状態を選択",
  engagement_status: "エンゲージメン状態",
  select_engagement_status: "Select Engagem: Status",
  nda_status: "NDAの状態",
  select_nda_status: "NDAの状態を選択",
  contract_status: "契約の状態",
  select_contract_status: "契約の状態を選択",
  first_name: "名*",
  first_name: "名",
  first_name_in_japanese: "日本語の名",
  last_name: "姓*",
  last_name: "姓",
  last_name_in_japanese: "日本語の姓",
  gender: "性別",
  department: "デパートメント",
  counterparty: "相手方",
  cpclient: "CP クライアント",
  cpproject: "CPプロジェクト",
  position: "ポジション",
  start_date: "開始日",
  end_date: "終了日",
  project_cost: "プロジェクト費用",
  select_company: "会社を選択",
  select_client: "クライアントを選択",
  company_project_profile: "会社のプロジェクトプロフィール",
  activity_status: "活動状態",
  select_status: "状態を選択",
  stage: "ステージ",
  select_stage: "ステージを選択",
  nda_status: "NDAの状態",
  select_nda_status: "NDAの状態を選択",
  contract_status: "契約の状態",
  select_contract_status: "契約の状態を選択",
  description: "説明",
  remarks: "備考",
  keypoints: "キーポイント",
  subject: "主題",
  priority: "優先順位",
  select_priority: "優先度を選択",
  select_project: "プロジェクトを選択",
  status: "状態",
  pending: "保留中",
  task_assigned_to: "タスクの割当先",
  select_employee: "社員を選択",
  employee: "社員",
  sub_task: "サブタスク",
  due_date: "締切日",
  dd_mm_yyyy: "日-月-年",
  time: "時間",
  select_time: "時間を選択",
  waiting: "待機中",
  assignee: "譲受人",
  select_employee: "社員を選択",
  acceptance: "受諾",
  title: "題名",
  document: "書類",
  no_file_chosen: "ファイルが選択されていません",
  entry_date: "入力日",
  subject: "主題",
  date: "日付",
  start_time: "開始時間",
  select_Time: "時間を選択",
  end_time: "終了時間",
  venue: "会場",
  attendees: "参加者",
  pre_meeting_task: "予備の会議タスク",
  post_meeting_task: "会議後のタスク",
  sender: "送信者",
  send_date: "送信日",
  message: "メッセージ",
  conclusion: "結論",
  next_step: "次のステップ",
  quick_memos: "クイックメモ",
  email: "メール",
  password: "パスワード",
  first_name: "名",
  last_name: "姓",
  import_contacts: "連絡先をインポートする",
  add: "追加",
  delete: "消去",
  submit: "申請",
  login: "ログイン",
  forgot_password: "パスワードを忘れた",
  edit: "編集",
  add_memo: "メモを追加",
  memo: "メモ",
  view_all_memos: "すべてのメモを表示",
  select_client: "クライアントを選択",
  role: "役割",
  add_client: "クライアントを追加",

  edit_client: "クライアントを編集",
  edit_company: "会社を編集",
  add_project: "プロジェクトを追加",
  edit_project: "プロジェクトを編集",
  add_note: "メモを追加",
  edit_note: "編集メモ",
  add_email: "メールを追加",
  edit_email: "メールを編集",
  add_quick_memos: "クイックメモを追加",
  edit_quick_memos: "クイックメモを編集",
  add_Employee: "従業員を追加",
  edit_employee: "従業員の編集",
  add_team: "チームを追加",
  edit_team: "チームを編集",
  add_user: "ユーザーを追加する",
  edit_user: "ユーザーを編集",
  update_profile: "プロフィールを更新",
  username: "ユーザー名",
  update_password: "パスワードの更新",
  new_password: "新しいパスワード",
  current_password: "現在のパスワード",
  confirm_password: "パスワードを認証する",
  change_password: "パスワードを変更する",
  update_company: "会社の更新",
  cancel: "キャンセル",
  add_meeting: "会議を追加",
  add_task: "タスクを追加",
  view_meetings: "ミーティングを見る",
  contact_number: "連絡先番号",
  welcome: "ようこそ",
  admin: "管理者",
  logout: "ログアウト",
  settings: "設定",
  my_profile: "私のプロフィール",
  change_photo: "写真を変更する",
  today_date: "今日の日付",
  location: "位置",
  partnership_mode: "パートナーシップ モード",
  expected_amount: "予定金額",
  currency: "通貨",
  confidence_level: "信頼水準",
  source_of_introduction: "紹介元",
  stage_of_proceeding: "進行段階",
  last_action: "最後のアクション",
  is_introduced: "紹介されています",
  action_title: "アクションタイトル",
  attachment: "添付ファイル",
  add_counter_party: "相手方追加",
  select_currency: "通貨を選択",
  select_confidence_level: "信頼度を選択",
  select_stage_of_proceeding: "進行段階の選択",
  select_is_introduced: "選択が導入されました",
  select_counter_party: "相手方を選択",
  select_notes: "ノートを選択",
  select_assignee: "担当者を選択",
  select_task: "タスクを選択",
  add_action_plan: "アクションプランを追加",
  action_plan: "行動計画",
  startDate_to_endDate: "土日から終了日まで",
  start__end_date: "開始日と終了日",
  calendar: "カレンダー",
  select_company: "会社を選択",
  select_incharge_employee: "担当者を選択",
  pre_task: "プレタスク",
  post_task: "ポストタスク",
  select_task_asigned_to: "割り当てられたタスクを選択",
  update: "アップデート",
  select_cp_project: "CP プロジェクトを選択",
  select_cp_client: "CP クライアントの選択",
  select_date: "日付を選択",
  add_profile: "プロフィールを追加",
  profile: "プロフィール",
  select_gender: "性別を選択して下さい",
  edit_meetings: "会議の編集",
  fund_name: "ファンド名",
  fund_type: "ファンドの種類",
  fund_area: "ファンドエリア",
  fund_series: "ファンドシリーズ",
  amc_location: "AMCと場所",
  investment_area: "投資分野",
  investment_country: "投資国",
  target_fund_size: "目標資金規模 (100万米ドル)",
  fund_arranged: "ファンドアレンジメント (100万米ドル)",
  fund_required: "必要資金 (百万米ドル)",
  fax: "ファックス",
  fund_closing_date: "ファンドの締め日",
  investment_profile_size: "投資ポートフォリオの規模",
  current_profile_size: "現在の投資ポートフォリオの規模",
  fund_tenure: "ファンド保有期間",
  current_moic: "現在のMOIC",
  expected_moic: "期待されるMOIC",
  current_IRR: "現在のIRR",
  expected_IRR: "予想IRR",
  commitment_period: "コミットメント期間",
  minimum_commitment_amount: "最低コミットメント額",
  management_fee: "管理手数料",
  hurdule_rate: "ハードルレート",
  performance_fee: "パフォーマンス料金",
  other_fees: "その他の料金",
  sponser_name: "スポンサー名",
  sponser_commitment: "スポンサーコミットメント (100万米ドル)",
  current_Lps: "「現在のLP」",
  deal_status: "ディール ステータス / 資金調達の金額とラウンド。 (10単語まで)",
  briefly_describe: "「資金調達状況を簡単に説明してください (10 語以内)」、",
  brief_slogan: "「簡単なスローガン（10語まで）」、",
  brief_USP: "「簡単な USP (10 語まで)",
  growth_strategy: "「成長戦略（10～30語）」、",
  fund_manager: "ファンドマネージャー",
  incharge_position: "担当ポジション",
  fund_contact_number: "連絡先番号",
  email_address: "電子メールアドレス",
  brief_background: "簡単な背景 (10 語まで)",
  FM_track_record: "「FM実績",
  Comment_if_any: "「コメントがあれば（10文字まで）」",
  project_name: "プロジェクト名",
  energy_type: "「エネルギータイプ」",
  project_capacity: "プロジェクト容量 (MW)",
  project_location: "プロジェクトの場所",
  project_cost: "プロジェクトコスト",
  debt_finance: "債務融資 (100 万米ドル)",
  interest_rate_duration: "金利とデュレーション",
  project_credit_rating_1: "プロジェクトの信用格付け -1",
  project_credit_rating_2: "プロジェクトの信用格付け -2",
  project_credit_rating_3: "Project Credit Rating -3",
  project_commissioning_date: "プロジェクトの試運転日",
  project_status: "プロジェクトのステータス",
  green_brown_field: "「グリーン/ブラウンフィールド",
  PPA_status: "PPAステータス",
  PPA_counter_party: "「PPAカウンターパーティー」",
  PPA_rate_tenure: "PPAレートと在職期間 ",
  last_official_valuation: "100万米ドルでの最終公式評価",
  last_valuation_date: "最終評価日",
  valuation_applicable: "以下の取引に適用される評価額 (Mil$)",
  fund_form: "資金フォーム",
  energy_form: "エネルギー形態",
  select_industries: "業種を選択",
  requested_transaction:
    "要求されたトランザクション (デット/エクイティ/CCD など)",
  equity_offered: "提供されるエクイティの%。",
  amount_asked: "100万米ドルで要求された金額",
  investment_horizon: "投資期間の年数",
  exit_type: "「エグジットタイプ（IPO/セルアウト/バイバックなど）」",
  exit_strategy: "「出口戦略（20～30ワード）」",
  projected_IRR: "予測IRR",
  projected_ROIC: "予測 ROIC",
  projected_ROE: "「予想ROE」",
  purpose_utiization_fund: "「ファンドの目的・活用」",
  deal_status_amount:
    "ディールステータス / 資金調達の金額とラウンド. (10 ワードまで)",
  briefly_describe_funding_situation:
    "「資金調達状況を簡単に説明してください（10文字以内）」",
  business_model_service: "ビジネスモデル/サービス (10-30 ワード)",
  revenue_model: "収益モデル (10-30 ワード)",
  cost_model: "コスト モデル (10 ～ 30 ワード)",
  clients: "クライアント",
  competitors: "競合他社",
  indian_strategic_partners: "インドの戦略的パートナー",
  japan_strategic_partners: "日本戦略パートナー",
  global_presence: "グローバルプレゼンス/コラボレーション",
  sales_last_year: "昨年の売上 (ミリ US$) 実績",
  sales_1y: "+1Y の売上 (Mil US$) (予測)",
  sales_2Y: "+2Y の売上 (Mil US$) (予測)",
  sales_3Y: "+3Y の売上 (Mil US$) (予測)",
  sales_4Y: "+4Y の売上 (Mil US$) (予測)",
  sales_5Y: "+5Y の売上 (Mil US$) (予測)",
  sales_CAGR: "売上CAGR",
  EBITDA_last_year: "昨年の EBITDA (ミリ US$) 実績",
  EBITDA_1y_projected: "+1Y の EBITDA (Mil US$) (予測)",
  EBITDA_2y_projected: "+2Y の EBITDA (Mil US$) (予測)",
  EBITDA_3y_projected: "+3Y の EBITDA (Mil US$) (予測)",
  EBITDA_4y_projected: "+4Y の EBITDA (Mil US$) (予測)",
  EBITDA_5y_projected: "+5Y の EBITDA (Mil US$) (予測)",
  EBITDA_CAGR: "EBITDA CAGR",
  net_profit_loss_year: "昨年の純利益/損失 (100 万米ドル) 実績",
  net_profit_loss_1y: "+1Y の純利益/損失 (Mil US$) 予測",
  project_incharge: "プロジェクト担当",
  current_investor_1: "現在の投資家 1",
  current_investor_2: "現在の投資家 2",
  total_funding_requirements: "総資金要件",
  equity_funding_asked: "求められたエクイティ資金調達 (Mil USD)",
  debt_funding: "負債による資金調達(Mil USD)",
  prefered_intrest_rate: "プロジェクトの信用格付けおよび格付け会社",
  project_start_year: "プロジェクト開始年",
  commissioning_date: "試運転日",
  exit_type_IPO: " エグジットタイプ（IPO/セルアウト/バイバックなど）",
  target_IRR: "目標IRR",
  purpose_utilization: "基金の目的・使途",
  business_model: "ビジネスモデル・サービス（10～30ワード）",
  industry_served: "対象業種",
  of_employees: "従業員数",
  sales_of_3yr: "-3 年間の売上 (100 万ドル)",
  sales_of_2yr: "-2 年間の売上 (100 万ドル)",
  sales_of_1yr: "-1 年間の売上 (100 万ドル)",
  sales_of_last_year: "昨年の売上 (百万米ドル)",
  sales_1y_projected: "+1 年間の売上 (100 万米ドル) (予測)",
  sales_2y_projected: "+2 年間の売上 (100 万米ドル) (予測)",
  sales_3y_projected: "+3 年間の売上 (100 万米ドル) (予測)",
  sales_4y_projected: "+4 年間の売上 (100 万米ドル) (予測)",
  sales_5y_projected: "+5 年間の売上 (100 万米ドル) (予測)",
  sales_6y_projected: "+6 年間の売上 (100 万米ドル) (予測)",
  golbal_presence: "グローバルプレゼンス/コラボレーション",
  project_credit_rating: "プロジェクトの信用格付けおよび格付け会社",
  purpose_utilization_fund: "基金の目的・使途",
  total_assets: "総資産",
  net_debt: "純負債",
  EBTDA_of_3yr: "-3年のEBTDA（100万米ドル） ",
  EBTDA_of_2yr: "-2年のEBTDA（100万米ドル）",
  EBTDA_of_1yr: "- 1年のEBTDA（100万米ドル）",
  EBTDA_last_year_million: "昨年の EBTDA (100 万米ドル) ",
  EBTDA_of_1yr_project: "+1 年の EBTDA (100 万米ドル) (予測)",
  EBTDA_of_2yr_project: "+2 年の EBTDA (100 万米ドル) (予測)",
  EBTDA_of_3yr_project: "+3 年の EBTDA (100 万米ドル) (予測)",
  EBTDA_of_4yr_project: "+4 年の EBTDA (100 万米ドル) (予測)",
  EBTDA_of_5yr_project: "+5 年の EBTDA (100 万米ドル) (予測)",
  EBTDA_of_6yr_project: "+6 年の EBTDA (100 万米ドル) (予測)",
  promoters: "プロモーター",
  ceo: "最高経営責任者（CEO）",
  cfo: "最高財務責任者",
  investor_1: "投資家 1 ",
  investor_2: "投資家 2 ",
  close_significant_advisor: "重要なアドバイザーを閉じる",
  company_name: "会社名",
  foundation_year: "創立年",
  listed_non_listed: "上場・非上場",
};
