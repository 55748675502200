export const emailFormLayout = [
  {
    id: 1,
    label: "Subject",
    key: "subject",
    type: "text",
    placeholder: "Subject",
    required: true,
    containerClass: "col-sm-12 col-md-6 col-lg-6  ",
  },
  {
    id: 2,
    label: "Sender",
    key: "sender",
    type: "text",
    placeholder: "Sender",
    containerClass: "col-sm-12 col-md-6 col-lg-3  ",
  },
  {
    id: 3,
    label: "Send Date",
    key: "send_date",
    type: "date",
    containerClass: "col-sm-12 col-md-6 col-lg-3",
  },
  {
    id: 4,
    label: "Message",
    type: "textarea",
    placeholder: "Enter Message",
    key: "message",
    containerClass: "col-lg-12 col-md-12 col-sm-12",
    rows: 3,
  },
  {
    id: 5,
    label: "Conclusion",
    key: "conclusion",
    type: "text",
    placeholder: "Conclusion",
    containerClass: "col-sm-12 col-md-6 col-lg-12  ",
  },
  {
    id: 6,
    label: "Next Step",
    key: "next_step",
    type: "text",
    placeholder: "Next Step",
    containerClass: "col-sm-12 col-md-6 col-lg-8  ",
  },
  {
    id: 7,
    label: "Attachment File",
    type: "file",
    key: "attachment_file",
    containerClass: "col-sm-12 col-md-4 col-lg-3",
  },
  {
    id: 8,
    label: "Company",
    type: "select",
    placeholder: "Select Company",
    key: "company_id",
    options: [
      {
        label: "Company 1",
        value: "cmp1",
      },
      {
        label: "Company 2",
        value: "cmp2",
      },
    ],
    containerClass: "col-sm-12 col-md-4 col-lg-4",
  },
  {
    id: 9,
    label: "Client",
    type: "select",
    placeholder: "Select Client",
    key: "client_id",
    options: [
      {
        label: "Client 1",
        value: "client1",
      },
      {
        label: "Client 2",
        value: "client2",
      },
    ],
    containerClass: "col-sm-12 col-md-4 col-lg-4",
  },
  {
    id: 10,
    label: "Project",
    type: "select",
    placeholder: "Select Project",
    key: "project_id",
    options: [
      {
        label: "Project 1",
        value: "proj1",
      },
      {
        label: "Project 2",
        value: "proj2",
      },
    ],
    containerClass: "col-sm-12 col-md-4 col-lg-4",
  },
  {
    id: 11,
    label: "Employee",
    type: "select",
    placeholder: "Select Employee",
    key: "employee_id",
    options: [
      {
        label: "Employee 1",
        value: "emp1",
      },
      {
        label: "Employee 2",
        value: "emp2",
      },
    ],
    containerClass: "col-sm-12 col-md-4 col-lg-4",
  },
];

export const emailFormLayoutJap = [
  {
    id: 1,
    label: "主題",
    key: "subject",
    placeholder: "主題",
    type: "text",
    containerClass: "col-sm-12 col-md-6 col-lg-6  ",
    required: true,
  },
  {
    id: 2,
    label: "送信者",
    key: "sender",
    type: "text",
    placeholder: "送信者",
    containerClass: "col-sm-12 col-md-6 col-lg-3  ",
  },
  {
    id: 3,
    label: "送信日",
    type: "date",
    key: "send_date",
    placeholder: "送信日",
    containerClass: "col-sm-12 col-md-6 col-lg-3",
  },
  {
    id: 4,
    label: "メッセージ",
    type: "textarea",
    placeholder: "メッセージ",
    key: "message",
    containerClass: "col-lg-12 col-md-12 col-sm-12",
    rows: 3,
  },
  {
    id: 5,
    label: "結論",
    key: "conclusion",
    type: "text",
    placeholder: "結論",
    containerClass: "col-sm-12 col-md-6 col-lg-12  ",
  },
  {
    id: 6,
    label: "次のステップ",
    key: "next_step",
    type: "text",
    placeholder: "次のステップ",
    containerClass: "col-sm-12 col-md-6 col-lg-8  ",
  },
  {
    id: 7,
    label: "添付ファイル",
    type: "file",
    placeholder: "添付ファイル",
    containerClass: "col-sm-12 col-md-4 col-lg-3",
  },
  {
    id: 8,
    label: "会社",
    type: "select",
    placeholder: "選択する 会社",
    key: "company_id",
    options: [
      {
        label: "Company 1",
        value: "cmp1",
      },
      {
        label: "Company 2",
        value: "cmp2",
      },
    ],
    containerClass: "col-sm-12 col-md-4 col-lg-4",
  },
  {
    id: 9,
    label: "クライアント",
    type: "select",
    placeholder: "選択する クライアント",
    key: "client_id",
    options: [
      {
        label: "Client 1",
        value: "client1",
      },
      {
        label: "Client 2",
        value: "client2",
      },
    ],
    containerClass: "col-sm-12 col-md-4 col-lg-4",
  },
  {
    id: 10,
    label: "事業",
    type: "select",
    placeholder: "選択する 事業",
    key: "project_id",
    options: [
      {
        label: "Project 1",
        value: "proj1",
      },
      {
        label: "Project 2",
        value: "proj2",
      },
    ],
    containerClass: "col-sm-12 col-md-4 col-lg-4",
  },
  {
    id: 11,
    label: "社員",
    type: "select",
    placeholder: "選択する 社員",
    key: "employee_id",
    options: [
      {
        label: "Employee 1",
        value: "emp1",
      },
      {
        label: "Employee 2",
        value: "emp2",
      },
    ],
    containerClass: "col-sm-12 col-md-4 col-lg-4",
  },
];


export const emailFormDataLayout = [
  {
    id: 1,
    label: "Subject",
    key: "subject",
    type: "text",
    placeholder: "Subject",
    required: true,
    containerClass: "col-sm-12 col-md-6 col-lg-6  ",
  },
  {
    id: 2,
    label: "Sender",
    key: "sender",
    type: "text",
    placeholder: "Sender",
    containerClass: "col-sm-12 col-md-6 col-lg-3  ",
  },
  {
    id: 3,
    label: "Send Date",
    key: "send_date",
    type: "date",
    containerClass: "col-sm-12 col-md-6 col-lg-3",
  },
  {
    id: 4,
    label: "Message",
    type: "textarea",
    placeholder: "Enter Message",
    key: "message",
    containerClass: "col-lg-12 col-md-12 col-sm-12",
    rows: 3,
  },
  {
    id: 5,
    label: "Conclusion",
    key: "conclusion",
    type: "text",
    placeholder: "Conclusion",
    containerClass: "col-sm-12 col-md-6 col-lg-12  ",
  },
  {
    id: 6,
    label: "Next Step",
    key: "next_step",
    type: "text",
    placeholder: "Next Step",
    containerClass: "col-sm-12 col-md-6 col-lg-8  ",
  },
  {
    id: 7,
    label: "Attachment File",
    type: "file",
    key: "attachment_file",
    containerClass: "col-sm-12 col-md-4 col-lg-3",
  },
  // {
  //   id: 8,
  //   label: "Company",
  //   type: "select",
  //   placeholder: "Select Company",
  //   key: "company_id",
  //   options: [
  //     {
  //       label: "Company 1",
  //       value: "cmp1",
  //     },
  //     {
  //       label: "Company 2",
  //       value: "cmp2",
  //     },
  //   ],
  //   containerClass: "col-sm-12 col-md-4 col-lg-4",
  // },
  // {
  //   id: 9,
  //   label: "Client",
  //   type: "select",
  //   placeholder: "Select Client",
  //   key: "client_id",
  //   options: [
  //     {
  //       label: "Client 1",
  //       value: "client1",
  //     },
  //     {
  //       label: "Client 2",
  //       value: "client2",
  //     },
  //   ],
  //   containerClass: "col-sm-12 col-md-4 col-lg-4",
  // },
  // {
  //   id: 10,
  //   label: "Project",
  //   type: "select",
  //   placeholder: "Select Project",
  //   key: "project_id",
  //   options: [
  //     {
  //       label: "Project 1",
  //       value: "proj1",
  //     },
  //     {
  //       label: "Project 2",
  //       value: "proj2",
  //     },
  //   ],
  //   containerClass: "col-sm-12 col-md-4 col-lg-4",
  // },
  {
    id: 11,
    label: "Employee",
    type: "select",
    placeholder: "Select Employee",
    key: "employee_id",
    options: [
      {
        label: "Employee 1",
        value: "emp1",
      },
      {
        label: "Employee 2",
        value: "emp2",
      },
    ],
    containerClass: "col-sm-12 col-md-4 col-lg-4",
  },
];

export const emailFormDataLayoutJap = [
  {
    id: 1,
    label: "主題",
    key: "subject",
    placeholder: "主題",
    type: "text",
    containerClass: "col-sm-12 col-md-6 col-lg-6  ",
    required: true,
  },
  {
    id: 2,
    label: "送信者",
    key: "sender",
    type: "text",
    placeholder: "送信者",
    containerClass: "col-sm-12 col-md-6 col-lg-3  ",
  },
  {
    id: 3,
    label: "送信日",
    type: "date",
    key: "send_date",
    placeholder: "送信日",
    containerClass: "col-sm-12 col-md-6 col-lg-3",
  },
  {
    id: 4,
    label: "メッセージ",
    type: "textarea",
    placeholder: "メッセージ",
    key: "message",
    containerClass: "col-lg-12 col-md-12 col-sm-12",
    rows: 3,
  },
  {
    id: 5,
    label: "結論",
    key: "conclusion",
    type: "text",
    placeholder: "結論",
    containerClass: "col-sm-12 col-md-6 col-lg-12  ",
  },
  {
    id: 6,
    label: "次のステップ",
    key: "next_step",
    type: "text",
    placeholder: "次のステップ",
    containerClass: "col-sm-12 col-md-6 col-lg-8  ",
  },
  {
    id: 7,
    label: "添付ファイル",
    type: "file",
    placeholder: "添付ファイル",
    containerClass: "col-sm-12 col-md-4 col-lg-3",
  },
  // {
  //   id: 8,
  //   label: "会社",
  //   type: "select",
  //   placeholder: "選択する 会社",
  //   key: "company_id",
  //   options: [
  //     {
  //       label: "Company 1",
  //       value: "cmp1",
  //     },
  //     {
  //       label: "Company 2",
  //       value: "cmp2",
  //     },
  //   ],
  //   containerClass: "col-sm-12 col-md-4 col-lg-4",
  // },
  // {
  //   id: 9,
  //   label: "クライアント",
  //   type: "select",
  //   placeholder: "選択する クライアント",
  //   key: "client_id",
  //   options: [
  //     {
  //       label: "Client 1",
  //       value: "client1",
  //     },
  //     {
  //       label: "Client 2",
  //       value: "client2",
  //     },
  //   ],
  //   containerClass: "col-sm-12 col-md-4 col-lg-4",
  // },
  // {
  //   id: 10,
  //   label: "事業",
  //   type: "select",
  //   placeholder: "選択する 事業",
  //   key: "project_id",
  //   options: [
  //     {
  //       label: "Project 1",
  //       value: "proj1",
  //     },
  //     {
  //       label: "Project 2",
  //       value: "proj2",
  //     },
  //   ],
  //   containerClass: "col-sm-12 col-md-4 col-lg-4",
  // },
  {
    id: 11,
    label: "社員",
    type: "select",
    placeholder: "選択する 社員",
    key: "employee_id",
    options: [
      {
        label: "Employee 1",
        value: "emp1",
      },
      {
        label: "Employee 2",
        value: "emp2",
      },
    ],
    containerClass: "col-sm-12 col-md-4 col-lg-4",
  },
];
