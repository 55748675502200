import { sendRequest } from "../../services";
import { apipaths } from "../../services/apiPaths";

export const memoListAction = () => async (dispatch) => {
  const { success, data } = await sendRequest(apipaths.listMemo);
  const { quick_memo } = data;
  if (success) {
    dispatch({ type: "ADD_MEMO", payload: quick_memo });
  }
};
