import { sendRequest } from "../../services";
import { apipaths } from "../../services/apiPaths";

export const companyListAction = () => async (dispatch) => {
  const {
    success,
    data: { companies, employees },
  } = await sendRequest(apipaths.companyList);
  if (!success) return null;
  dispatch({ type: "ADD_COMAPNIES", payload: companies });
  dispatch({ type: "ADD_EMPLOYEES", payload: employees });
};
