import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  projectFormLayout, 
  projectFormLayoutJap,
} from "../../Data/FormsLayout/projectFormLayout";
import {
  addPrefexToObjectProperty,
  companyFormLayoutHandler,     
  regName,
} from "../../helpers/commonHelpers";
import { sendRequest } from "../../services";
import { apipaths } from "../../services/apiPaths";
import { clientListAction } from "../actions/clientAction";
import { companyListAction } from "../actions/comanyAction";
import CardContainer from "../Card/cardContainer";
import useTranslation from "../customHooks/translations";
import FormContainer from "../Form";
import Heading from "../Heading";

function ProjectForm() {
  const [formlayout, setFormLayout] = useState(projectFormLayout);

  const { clients } = useSelector((state) => state.client);
  const { companies } = useSelector((state) => state.company);
  const { employees } = useSelector((state) => state.employee);

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const translation = useTranslation();

  useEffect(() => {
    dispatch(companyListAction());
    dispatch(clientListAction());
  }, []);

  useEffect(() => {
    if (clients && companies && employees) {
      employees.map((emp) => (emp.name = emp.first_name + " " + emp.last_name));
      let l1 = companyFormLayoutHandler(
        employees,
        projectFormLayout,
        "employee_id",
        "name"
      );
      let l2 = companyFormLayoutHandler(companies, l1, "company_id", "name");

      clients.map(
        (client) => (client.name = client.first_name + " " + client.last_name)
      );
      let l3 = companyFormLayoutHandler(clients, l2, "client_id", "name");
    }
    if (clients && companies && employees) {
      employees.map((emp) => (emp.name = emp.first_name + " " + emp.last_name));
      let l1 = companyFormLayoutHandler(
        employees,
        projectFormLayoutJap,
        "employee_id",
        "name"
      );
      let l2 = companyFormLayoutHandler(companies, l1, "company_id", "name");

      clients.map(
        (client) => (client.name = client.first_name + " " + client.last_name)
      );
      let l3 = companyFormLayoutHandler(clients, l2, "client_id", "name");
    }
  }, [clients, companies, employees]);

  const onSubmit = async (formdata) => {
    if (!regName.test(formdata.name)) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Please Fill Out Project Subject !",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      let newProjectData = { ...formdata };
      newProjectData = addPrefexToObjectProperty("Project_", newProjectData);

      newProjectData["operation"] = "add";
      newProjectData["project_type"] = "add";
      newProjectData["OPtype"] = "Project";

      const {
        data: { message },
        success,
      } = await sendRequest(apipaths.addProject, newProjectData);
      if (success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: message,
          showConfirmButton: false,
          timer: 1500,
        });
        navigate(-1);
      }
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="main-container">
        <Heading title={translation.add_project} />
        <div className="content-wrapper px-5">
          <CardContainer hideTotal={true} />
          <div className="content-container">
            <div className="p-3">
              <button className="btn btn-primary btn-sm px-5 py-2">
                {translation.project}
              </button>
            </div>
            <hr />
            <div className="pt-1 pb-4 px-3">
              <FormContainer
                FormLayoutJap={projectFormLayoutJap}
                layout={formlayout}
                onCancel={onCancel}
                onSubmit={onSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProjectForm;
