const initialState = { employees: [] };
const employeeReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case "ADD_EMPLOYEES":
      return { ...state, employees: payload };
    default:
      return state;
  }
};

export { employeeReducer };
