import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { sendRequest } from "../../services";
import { apipaths } from "../../services/apiPaths";
import { companyListAction } from "../actions/comanyAction";
import { SectorBoardAction } from "../actions/sectorBoardAction";
import CardContainer from "../Card/cardContainer";
import useTranslation from "../customHooks/translations";
import FormContainer from "../Form";
import Heading from "../Heading";
import SectorCard from "./SectorCard";
import "./style.css";

function SectorBoard() {
  const dispatch = useDispatch();
  const { sectorboards } = useSelector((state) => state.sectorBoard);
  const { companies } = useSelector((state) => state.company);
  const [modal, setModal] = useState({ visible: false });
  const translation = useTranslation();
  const [formLayout, setFormLayout] = useState([
    {
      id: 1,
      label: "Industry",
      type: "text",
      placeholder: "Industry",
      key: "industry",
      containerClass: "col-12",
      required: true,
    },
  ]);

  const [formLayoutJap, setFormLayoutJap] = useState([
    {
      id: 1,
      label: "業種",
      type: "text",
      placeholder: "業種",
      key: "industry",
      containerClass: "col-12",
      required: true,
    },
  ]);

  const [selectedSectorBoard, setSelectedSectorBoard] = useState({});
  const [defaultFormdata, setFormdata] = useState({});

  useEffect(() => {
    if (companies) {
      let options = companies.map((company) => {
        const { company_id, name } = company;
        return {
          label: name,
          value: company_id,
        };
      });

      let layout = [...formLayout];
      layout[1] = {
        id: 2,
        type: "selectdropdown",
        label: "Company",
        key: "company_id",
        containerClass: "col-12",
        placeholder: "Select Companies",
        options,
      };
      setFormLayout(layout);
    }
  }, [companies, translation._language]);

  useEffect(() => {
    if (companies) {
      let options = companies.map((company) => {
        const { company_id, name } = company;
        return {
          label: name,
          value: company_id,
        };
      });

      let layout = [...formLayoutJap];
      layout[1] = {
        id: 2,
        type: "selectdropdown",
        label: "会社",
        key: "company_id",
        containerClass: "col-12",
        placeholder: "会社を選択",
        options,
      };
      setFormLayoutJap(layout);
    }
  }, [companies]);

  const addSectorHandler = async ({ company_id, industry }) => {
    const payload = {
      board_id: selectedSectorBoard["board_id"],
      company_id,
      industry,
      operation: "add",
    };

    const { success, message } = await sendRequest(
      apipaths.addSectorBoard,
      payload
    );
    if (success) {
      setModal({ visible: false });
      dispatch(SectorBoardAction());
      Swal.fire({
        position: "center",
        icon: "success",
        title: message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const selectHandler = (data) => {
    setModal({ ...modal, visible: true });
    let company_id = [];
    data?.company?.filter((d) => {
      return company_id.push(d.company_id.toString());
    });

    setSelectedSectorBoard(data);
    setFormdata({ company_id, industry: data.industry });
  };

  useEffect(() => {
    dispatch(SectorBoardAction());
    dispatch(companyListAction());
  }, []);

  return (
    <>
      <Modal
        footer={false}
        visible={modal.visible}
        closeIcon={() => setModal({ ...modal, visible: false })}
        title={
          translation._language === "en"
            ? defaultFormdata.industry === undefined
              ? "Add Company to Sector Board"
              : "Update Company to Sector Board"
            : defaultFormdata.industry === undefined
            ? "会社をセクターボードに追加する"
            : "会社をセクターボードに更新する"
        }
        destroyOnClose
      >
        <div>
          <FormContainer
            submitBtnTitle={
              defaultFormdata.industry === undefined ? "Add" : "Update"
            }
            FormLayoutJap={formLayoutJap}
            layout={formLayout}
            defaultData={defaultFormdata}
            onSubmit={(data) => addSectorHandler(data)}
            onCancel={() => setModal({ ...modal, visible: false })}
          />
        </div>
      </Modal>
      <div className="main-container">
        <Heading title={translation.sector_board} />
        <div className="content-wrapper px-5">
          <CardContainer hideTotal={true} />
          <div className="sector-wrapper row bg-white mt-5 p-3">
            {Array.from(Array(48), (d, i) => {
              return (
                <div className="col-12 col-md-6 col-lg-3" key={i}>
                  <SectorCard
                    data={sectorboards}
                    index={i + 1}
                    onSelect={selectHandler}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default SectorBoard;
