import { sendRequest } from "../../services";
import { apipaths } from "../../services/apiPaths";

export const teamsListAction = () => async (dispatch) => {
  const { success, data } = await sendRequest(apipaths.listTeam);
  const { team } = data;
  if (success) {
    dispatch({ type: "ADD_TEAM", payload: team });
  }
};
