import React, { useEffect } from "react";
import { SettingOutlined } from "@ant-design/icons";

function SectorCard({ data, index, onSelect }) {
  const filldata = data.filter((d) => {
    return d.board_id == index;
  });

  return (
    <div
      className={`sector-card ${index !== 0 && index % 4 === 0 ? "last" : ``}`}
    >
      <div className={`d-flex justify-content-between`}>
        <div>
          <p className="fs-14 fw-600">
            {filldata[0]?.industry ? filldata[0]?.industry : ""}
          </p>
        </div>
        <div>
          <SettingOutlined
            onClick={() =>
              onSelect(filldata[0] ? filldata[0] : { board_id: index })
            }
            style={{ position: "relative", bottom: "3px" }}
            className="sector-settings"
          />
        </div>
      </div>

      <div>
        {filldata?.map((d, i) => {
          return d?.company?.map((c, x) => (
            <p className="mb-0 pb-1 fs-13">{c?.name}</p>
          ));
        })}
      </div>
    </div>
  );
}

export default SectorCard;
