import React, { useEffect, useState } from "react";
import Heading from "../Heading";
import CardContainer from "../Card/cardContainer";
import FormContainer from "../Form";
import {
  companyFormLayout,
  companyFormLayoutJap,
} from "../../Data/FormsLayout/companyFormLayout";
import { Tabs } from "antd";
import {
  addPrefexToObjectProperty,
  companyFormLayoutHandler,
  getSelectOptions,
  modalInitialValue,
  regName,
} from "../../helpers/commonHelpers";
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { companyListAction } from "../actions/comanyAction";
import { sendRequest } from "../../services";
import { apipaths } from "../../services/apiPaths";
import Swal from "sweetalert2";
import { clientListAction } from "../actions/clientAction";
import ClientDataTable from "../Client/clientDataTable";
import ProjectDataTable from "../Project/projectDataTable";
import { notesListAction } from "../actions/notesAction";
import { taskListAction } from "../actions/taskListAction";
import { emailListAction } from "../actions/emailAction";
import NotesDataTable from "../Notes/NotesDataTable";
import EmailDatatable from "../Email/EmailDatatable";
import TaskDataTable from "../Task/TaskDataTable";
import { meetingListAction } from "./../actions/meetingAction";
import MeetingDataTable from "../Meetings/MeetingDataTable";
import ModalContainer from "../Modal";
import useTranslation from "../customHooks/translations";

function EditCompany() {
  const [modal, setModal] = useState(modalInitialValue);
  const [formlayout, setFormlayout] = useState(companyFormLayout);
  const [seletedCompany, setSelectedCompany] = useState({});
  const [key, setKey] = useState("");

  const { TabPane } = Tabs;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: companyId } = useParams();
  const translation = useTranslation();
  const location = useLocation();
  const { companies } = useSelector((state) => state.company);

  const { employees } = useSelector((state) => state.employee);

  useEffect(() => {
    dispatch(companyListAction());
    dispatch(clientListAction());
    dispatch(notesListAction());
    dispatch(emailListAction());
    dispatch(taskListAction());
    dispatch(meetingListAction());
  }, [dispatch]);

  useEffect(() => {
    if (companies.length > 0) {
      let company = companies.filter(
        (com) => parseInt(com.company_id) == parseInt(companyId)
      );
      setSelectedCompany(company[0]);
    }
  }, [companies]);

  const [tabs, setTabs] = useState([
    {
      id: 1,
      key: "company",
      title: "Company",
      titleJap: "会社",
      content: "",
    },
    {
      id: 2,
      key: "client",
      title: "Client",
      titleJap: "クライアント",
      content: <ClientDataTable setModal={setModal} companyId={companyId} />,
    },
    {
      id: 3,
      key: "project",
      title: "Projects",
      titleJap: "プロジェクト",
      content: <ProjectDataTable setModal={setModal} companyId={companyId} />,
    },
    {
      id: 4,
      key: "task",
      title: "Tasks",
      titleJap: "タスク",
      content: <TaskDataTable setModal={setModal} companyId={companyId} />,
    },
    {
      id: 5,
      key: "notes",
      title: "Notes",
      titleJap: "備考",
      content: (
        <NotesDataTable
          setModal={setModal}
          companyId={companyId}
          modal={modal}
        />
      ),
    },
    {
      id: 6,
      key: "emails",
      title: "Emails",
      titleJap: "メール",
      content: <EmailDatatable setModal={setModal} companyId={companyId} />,
    },
    {
      id: 7,
      key: "meetings",
      title: "Meetings",
      titleJap: "ミーティング",
      content: <MeetingDataTable setModal={setModal} companyId={companyId} />,
    },
  ]);
  useEffect(() => {
    if (employees) {
      employees?.map(
        (emp) => (emp.name = emp.first_name + " " + emp.last_name)
      );
      companyFormLayoutHandler(
        employees,
        companyFormLayout,
        "employee_id",
        "name"
      );
    }
  }, [employees, companies]);

  const updateCompanyHandler = async (data) => {
    if (!regName.test(data.name)) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Please Fill Out Company Name !",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      let new_company_details = { ...data };
      new_company_details = addPrefexToObjectProperty(
        "Company_",
        new_company_details
      );
      new_company_details = {
        ...new_company_details,
        OPtype: "Company",
        operation: "update",
        company_type: "keep",
        Company_id: companyId,
      };

      const {
        success,
        data: { message },
      } = await sendRequest(apipaths.addCompany, new_company_details);
      if (success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: message,
          showConfirmButton: false,
          timer: 1500,
        });
        navigate(-1);
      }
    }
  };
  const onChange = (key) => {
    setKey(key);
  };

  return (
    <>
      <ModalContainer
        isOpen={modal.open}
        title={modal.title}
        closeIcon={() => setModal(modalInitialValue)}
        content={modal.content}
        footer={false}
      />

      <div className="main-container">
        <Heading title={translation.edit_company} />
        <div className="content-wrapper px-5">
          <CardContainer hideTotal={true} />
        </div>

        <div className="main-content-wrapper">
          {Object.keys(seletedCompany).length > 0 && (
            <>
              {key === "Company" ? (
                ""
              ) : (
                <h4
                  style={{ float: "right", marginLeft: "-25%" }}
                  className="me-5 fs-20 fw-600 text-capitalize"
                >
                  {seletedCompany.name}
                </h4>
              )}
              <Tabs defaultActiveKey="Company" onChange={onChange}>
                {tabs?.map((tab) => {
                  if (tab.id === 1)
                    tab.content = (
                      <FormContainer
                        layout={formlayout}
                        FormLayoutJap={companyFormLayoutJap}
                        onCancel={() => navigate(`/company`)}
                        onSubmit={updateCompanyHandler}
                        defaultData={seletedCompany}
                        submitBtnTitle={translation.update}
                      />
                    );
                  return (
                    <>
                      <TabPane
                        tab={
                          translation._language === "en"
                            ? tab.title
                            : tab.titleJap
                        }
                        key={tab.title}
                      >
                        {tab.content}
                      </TabPane>
                    </>
                  );
                })}
              </Tabs>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default EditCompany;
