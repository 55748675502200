export const companyFormLayout = [
  {
    id: 1,
    label: "Name",
    type: "text",
    placeholder: "Company",
    key: "name",
    required: true,
  },
  {
    id: 2,
    label: "Name in japanese",
    type: "text",
    placeholder: "Name in japanese",
    key: "name_in_japanese",
    required: false,
  },
  {
    id: 3,
    label: "Industry",
    type: "text",
    placeholder: "Industry",
    key: "industry",
    required: false,
  },
  {
    id: 4,
    label: "Sub Industry",
    type: "text",
    placeholder: "Sub Industry",
    key: "subindustry",
    required: false,
  },
  {
    id: 5,
    label: "Telephone 1",
    type: "text",
    placeholder: "Telephone",
    key: "tel1",
    required: false,
  },
  {
    id: 6,
    label: "Telephone 2",
    type: "text",
    placeholder: "Telephone",
    key: "tel2",
    required: false,
  },
  {
    id: 7,
    label: "Fax",
    type: "text",
    placeholder: "Fax",
    key: "fax",
    required: false,
  },
  {
    id: 8,
    label: "Email 1",
    type: "email",
    placeholder: "Email",
    key: "email1",
    required: false,
  },
  {
    id: 9,
    label: "Email 2",
    type: "email",
    placeholder: "Email",
    key: "email2",
    required: false,
  },
  {
    id: 10,
    label: "Email 3",
    type: "email",
    placeholder: "Email",
    key: "email3",
    required: false,
  },
  {
    id: 11,
    label: "Address",
    type: "text",
    placeholder: "Address",
    key: "address",
    required: false,
  },
  {
    id: 12,
    label: "City",
    type: "text",
    placeholder: "city",
    key: "city",
    required: false,
  },
  {
    id: 13,
    label: "Country",
    type: "text",
    placeholder: "Country",
    key: "country",
    required: false,
  },
  {
    id: 14,
    label: "Postal Code",
    type: "text",
    placeholder: "Postal Code",
    key: "postal_code",
    required: false,
  },
  {
    id: 15,
    label: "Website",
    type: "text",
    placeholder: "Website",
    key: "website",
    required: false,
  },
  {
    id: 16,
    label: "InCharge Employee",
    type: "select",
    placeholder: "Select InCharge Employee",
    key: "employee_id",
    options: [
      {
        label: "Employee 1",
        value: "emp1",
      },
      {
        label: "Employee 2",
        value: "emp2",
      },
    ],
    required: false,
  },
  {
    id: 17,
    label: "Source",
    type: "text",
    placeholder: "Source",
    key: "source",
    required: false,
  },
  {
    id: 18,
    label: "Activity Status",
    type: "select",
    placeholder: "Select Activity Status",
    key: "activity_status",
    options: [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Super Active",
        value: "Super Active",
      },
      {
        label: "Normal",
        value: "Normal",
      },
      {
        label: "Sleeping",
        value: "Sleeping",
      },
    ],
    required: false,
  },
  {
    id: 19,
    label: "Engagement Status",
    type: "select",
    placeholder: "Select Engagement Status",
    key: "engagement_status",
    options: [
      {
        label: "None",
        value: "node",
      },
      {
        label: "Existing Client",
        value: "Existing Client",
      },
      {
        label: "Prospective Client",
        value: "Prospective Client",
      },
      {
        label: "Formal Client",
        value: "Formal Client",
      },
      {
        label: "Suspended",
        value: "Suspended",
      },
      {
        label: "Blacklisted",
        key: "Blacklisted",
      },
    ],
    required: false,
  },
  {
    id: 20,
    label: "NDA Status",
    type: "select",
    placeholder: "Select NDA Status",
    key: "nda_status",
    options: [
      {
        label: "Signed",
        value: "Signed",
      },
      {
        label: "Non Signed",
        value: "Non Signed",
      },
      {
        label: "Under Process",
        value: "Under Process",
      },
      {
        label: "Expired",
        value: "Expired",
      },
    ],
    required: false,
  },
  {
    id: 21,
    label: "Contract Status",
    type: "select",
    placeholder: "Select Contract Status",
    key: "contract_status",
    options: [
      {
        label: "Signed",
        value: "Signed",
      },
      {
        label: "Non Signed",
        value: "Non Signed",
      },
      {
        label: "Under Process",
        value: "Under Process",
      },
      {
        label: "Expired",
        value: "Expired",
      },
    ],
    required: false,
  },
];

export const companyFormLayoutJap = [
  {
    id: 1,
    label: "名前",
    type: "text",
    placeholder: "名前",
    key: "name",
    required: true,
  },
  {
    id: 2,
    label: "日本語での名前",
    type: "text",
    placeholder: "日本語での名前",
    key: "name_in_japanese",
    required: false,
  },
  {
    id: 3,
    label: "業種",
    type: "text",
    placeholder: "業種",
    key: "industry",
    required: false,
  },
  {
    id: 4,
    label: "副業種",
    type: "text",
    placeholder: "副業種",
    key: "subindustry",
    required: false,
  },
  {
    id: 5,
    label: "電話 1",
    type: "text",
    placeholder: "電話 1",
    key: "tel1",
    required: false,
  },
  {
    id: 6,
    label: "電話 2",
    type: "text",
    placeholder: "電話 2",
    key: "tel2",
    required: false,
  },
  {
    id: 7,
    label: "ファックス",
    type: "text",
    placeholder: "ファックス",
    key: "fax",
    required: false,
  },
  {
    id: 8,
    label: "メール 1",
    type: "email",
    placeholder: "メール 1",
    key: "email1",
    required: false,
  },
  {
    id: 9,
    label: "メール 2",
    type: "email",
    placeholder: "メール 2",
    key: "email2",
    required: false,
  },
  {
    id: 10,
    label: "メール 3",
    type: "email",
    placeholder: "メール 3",
    key: "email3",
    required: false,
  },
  {
    id: 11,
    label: "住所",
    type: "text",
    placeholder: "住所",
    key: "address",
    required: false,
  },
  {
    id: 12,
    label: "都市",
    type: "text",
    placeholder: "都市",
    key: "city",
    required: false,
  },
  {
    id: 13,
    label: "国",
    type: "text",
    placeholder: "国",
    key: "country",
    required: false,
  },
  {
    id: 14,
    label: "郵便番号",
    type: "text",
    placeholder: "郵便番号",
    key: "postal_code",
    required: false,
  },
  {
    id: 15,
    label: "Webサイト",
    type: "text",
    placeholder: "Webサイト",
    key: "website",
    required: false,
  },
  {
    id: 16,
    label: "担当社員",
    type: "select",
    placeholder: "選択する 担当社員",
    key: "employee_id",
    options: [
      {
        label: "Employee 1",
        value: "emp1",
      },
      {
        label: "Employee 2",
        value: "emp2",
      },
    ],
    required: false,
  },
  {
    id: 17,
    label: "ソース",
    type: "text",
    placeholder: "ソース",
    key: "source",
    required: false,
  },
  {
    id: 18,
    label: "活動状態",
    type: "select",
    placeholder: "選択する 活動状態",
    key: "activity_status",
    options: [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Super Active",
        value: "Super Active",
      },
      {
        label: "Normal",
        value: "Normal",
      },
      {
        label: "Sleeping",
        value: "Sleeping",
      },
    ],
    required: false,
  },
  {
    id: 19,
    label: "選択する エンゲージメン状態",
    type: "select",
    placeholder: "エンゲージメン状態",
    key: "engagement_status",
    options: [
      {
        label: "None",
        value: "node",
      },
      {
        label: "Existing Client",
        value: "Existing Client",
      },
      {
        label: "Prospective Client",
        value: "Prospective Client",
      },
      {
        label: "Formal Client",
        value: "Formal Client",
      },
      {
        label: "Suspended",
        value: "Suspended",
      },
      {
        label: "Blacklisted",
        key: "Blacklisted",
      },
    ],
    required: false,
  },
  {
    id: 20,
    label: "NDAの状態",
    type: "select",
    placeholder: "選択する NDAの状態",
    key: "nda_status",
    options: [
      {
        label: "Signed",
        value: "Signed",
      },
      {
        label: "Non Signed",
        value: "Non Signed",
      },
      {
        label: "Under Process",
        value: "Under Process",
      },
      {
        label: "Expired",
        value: "Expired",
      },
    ],
    required: false,
  },
  {
    id: 21,
    label: "契約の状態",
    type: "select",
    placeholder: "選択する 契約の状態",
    key: "contract_status",
    options: [
      {
        label: "Signed",
        value: "Signed",
      },
      {
        label: "Non Signed",
        value: "Non Signed",
      },
      {
        label: "Under Process",
        value: "Under Process",
      },
      {
        label: "Expired",
        value: "Expired",
      },
    ],
    required: false,
  },
];
